<template>
  <div>
    <DxTabPanel
      :data-source="tabs"
      :loop="false"
      :animation-enabled="true"
      :swipe-enabled="true"
      :onTitleClick='onItemClick'
      :selected-index="selectedIndex"
    >
      <template #title="{ data: tab }">
        <span class="tab-title">{{ tab.text }}</span>
      </template>
      <template #item="{ data: tab }">
        <div>
          <div v-if="tab.id === 0" id="tab-phytosanitary-plague" class="padding-half tab-content">
            <phytosanitaryProductsPlague  />
          </div>
          <div v-if="tab.id === 1" id="tab-phytosanitary-product" class="padding-half tab-content">
            <phytosanitaryProductsProduct />
          </div>
        </div>
      </template>
    </DxTabPanel>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import phytosanitaryProductsPlague from "./phytosanitaryProductsPlague/index.vue";
import phytosanitaryProductsProduct from "./phytosanitaryProductsProduct/index.vue";
import DxTabs from "devextreme-vue/tabs";
import DxTabPanel from "devextreme-vue/tab-panel";

export default {
  name: "PhytosanitaryProducts",
  components: {
    phytosanitaryProductsPlague,
    phytosanitaryProductsProduct,
    DxTabs,
    DxTabPanel,
  },
  data() {
    return {
      tabs: [
        { id: 0, text: this.$t("Plague") },
        { id: 1, text: this.$t("Product") },
      ],
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapState('Utilities', ['currentPage']),
  },
  beforeMount() {
    if (this.currentPage === "phytosanitaryProductsProduct") {
      this.selectedIndex = 1;
    }
  },
  methods: {
    onItemClick(e) {
      const index = e.itemIndex;

      if (index === 0) {
        this.toShowPlagueTab();
        return;
      } else {
        this.toShowProductTab();
      }
    },
    toShowProductTab() {
      this.setCurrentPage("phytosanitaryProductsProduct");
    },
    toShowPlagueTab() {
      this.setCurrentPage("phytosanitaryProductsPlague");
    },
    ...mapActions("Utilities", ["setCurrentPage"]),
  },
};
</script>
<style lang="scss" scoped>
@import "./PhytosanitaryProducts.styles.scss";
</style>
