export default {
  namespaced: true,
  state: {
    currentFilter: '',
  },
  actions: {
    applyCurrentFilters({ state, dispatch, rootState }) {
      const params = {};
    },
    clearCurrentFilters({ state, dispatch }) {
      /** if (state.currentFilter === 'searcher') {

      } */
    },
  },
  mutations: {
  },

};
