<template>
  <div>
    <f7-row>
      <f7-col width="100">
        <div v-if="!loadedTasks">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div v-else>
          <ListTask />
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ListTask from '../../searchEngine/listTaskByCompany/index.vue';

export default {
  name: 'Tasks',
  components: {
    ListTask,
  },
  data() {
    return {
      loadedTasks: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  async beforeMount() {
    try {
      this.setIdPlantationTask(this.currentPlantation.id);
      this.setIdCompanyTask(this.currentPlantation.companyId);
      this.loadedTasks = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    ...mapActions('TaskByCompany', ['setIdPlantationTask', 'setIdCompanyTask']),
  },
};
</script>

<style lang="scss" scoped>
@import './Tasks.styles.scss';
</style>
