var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx no-padding"},[_c('DxForm',{attrs:{"id":"form","col-count":1,"show-colon-after-label":false}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.sectorName'),"editor-options":{
          value: _vm.currentSector.sectorName,
          mode: 'text',
          placeholder: _vm.$t('sector.register.sectorName'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('sector.register.required_name')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.groundTexture'),"editor-type":"dxSelectBox","editor-options":{
          items: _vm.groundTexturesAvailables,
          onValueChanged: _vm.onValueChanged,
          value: _vm.currentSector.groundTexture,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          placeholder: _vm.$t('select_placeholder'),
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.irrigationType'),"editor-type":"dxSelectBox","editor-options":{
          items: _vm.irrigationTypesAvailables,
          onValueChanged: _vm.onValueChanged,
          value: _vm.currentSector.irrigationType,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          placeholder: _vm.$t('select_placeholder'),
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('sector.register.required_irrigationType')}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":1}})],1)],1),_vm._v(" "),(_vm.currentSector.irrigationType === 'GOTEO')?_c('DxForm',{attrs:{"id":"formGoteo","show-colon-after-label":false}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.flowPerPlant'),"editor-type":"dxNumberBox","editor-options":{
          value: _vm.currentSector.flow,
          placeholder: _vm.$t('sector.register.flowPerPlant'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.dripperPerPlant'),"editor-type":"dxNumberBox","editor-options":{
          value: _vm.currentSector.dripperPerPlant,
          placeholder: _vm.$t('sector.register.dripperPerPlant'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}})],1)],1):_vm._e(),_vm._v(" "),(_vm.currentSector.irrigationType === 'ASPERSIÓN')?_c('DxForm',{attrs:{"id":"formAspersion","show-colon-after-label":false}},[_c('DxGroupItem',{attrs:{"col-count":3}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.sprinklersNumber'),"editor-type":"dxNumberBox","editor-options":{
          value: _vm.currentSector.sprinklersNumber,
          placeholder: _vm.$t('sector.register.sprinklersNumber'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.flow360'),"editor-type":"dxNumberBox","editor-options":{
          value: _vm.currentSector.flow,
          placeholder: _vm.$t('sector.register.flow360'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.separationBetweenLines'),"editor-type":"dxNumberBox","editor-options":{
          value: _vm.currentSector.separationBetweenLines,
          placeholder: _vm.$t('sector.register.separationBetweenLines'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('sector.register.separationBetweenSprinkler'),"editor-type":"dxNumberBox","editor-options":{
          value: _vm.currentSector.separationBetweenSprinkler,
          placeholder: _vm.$t('sector.register.separationBetweenSprinkler'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}})],1)],1):_vm._e(),_vm._v(" "),(_vm.currentSector.irrigationType === 'INUNDACIÓN')?_c('DxForm',{attrs:{"id":"formInundacion","show-colon-after-label":false}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"col-span":1,"data-field":_vm.$t('sector.register.flow'),"editor-type":"dxNumberBox","editor-options":{
          value: _vm.currentSector.flow,
          placeholder: _vm.$t('sector.register.flow'),
          showClearButton: true,
          onValueChanged: _vm.onValueChanged,
        }}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }