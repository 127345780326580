<template>
  <div class="content-devx">
    <f7-block>
      <f7-row class="flexbox">
        <div class="chip-label">
          {{ $t("ViewLocation_parcel_column_area") }}: {{ parseFloat(landSize).toFixed(2) }}
        </div>
        <div class="chip-label">
          {{ $t("Monitoring_point_longitude") }}: {{ parseFloat(lang).toFixed(6) }}
        </div>
        <div class="chip-label">
          {{ $t("Monitoring_point_latitude") }}: {{ parseFloat(lat).toFixed(6) }}
        </div>
      </f7-row>
    </f7-block>
  </div>
</template>

<script>
export default {
  name: 'ParcelInfo',
  props: {
    landSize: {
      type: Number,
      default: 0,
    },
    lang: {
      type: Number,
      default: 0,
    },
    lat: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./ParcelInfo.styles.scss";
</style>
