export default {
  namespaced: true,
  state: {
    // index: "NDVI",
    initDate: null,
    endDate: null,
  },
  actions: {
    initReportGisData({ commit }) {
      // commit("SET_INDEX", "NDVI");
      commit("SET_INIT_DATE", null);
      commit("SET_END_DATE", null);
    },
    // setIndex({ commit }, newIndex) {
    //   commit("SET_INDEX", newIndex);
    // },
    setInitDate({ commit }, newDate) {
      commit("SET_INIT_DATE", newDate);
    },
    setEndDate({ commit }, newDate) {
      commit("SET_END_DATE", newDate);
    }
  },
  mutations: {
    // SET_INDEX(state, newIndex) {
    //   state.index = newIndex;
    // },
    SET_INIT_DATE(state, newDate) {
      state.initDate = newDate;
    },
    SET_END_DATE(state, newDate) {
      state.endDate = newDate;
    }
  },
};
