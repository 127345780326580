import Sync from '../../../services/Sync';
import AccessControl from '../../../services/AccessControl';

export default {
  namespaced: true,
  state: {
    isSyncing: false,
    syncError: null,
    lastSuccessfulSync: null,
    accessControlItemsToSync: 0,
    SyncErrorText: '',
    deviceUuid: '',
  },
  getters: {
    SyncErrorText: (state) => state.SyncErrorText,
    getDeviceUuid(state) {
      return state.deviceUuid;
    },
  },
  mutations: {
    UPDATE_SYNCING_STATE(state, value) {
      state.isSyncing = value;
    },

    UPDATE_SYNC_ERROR(state, value) {
      state.syncError = value;
    },

    UPDATE_LAST_SUCCESSFUL_SYNC_TIMESTAMP(state) {
      state.lastSuccessfulSync = Date.now();
    },

    RESET_LAST_SUCCESSFUL_SYNC_TIMESTAMP(state) {
      state.lastSuccessfulSync = null;
    },

    UPDATE_ITEMS_TO_SYNC(state, value) {
      state.accessControlItemsToSync = value;
    },

    SET_SYNC_ERROR_TEXT(state, payload) {
      state.SyncErrorText = payload;
    },

    UPDATE_DEVICE_UUID(state, value) {
      state.deviceUuid = value;
    },
  },
  actions: {
    /**
     * Sincronización ligera
     * @param {*} param0
     * @returns
     */
    async lightDataBaseSync({ commit, rootState }) {
      if (rootState.databaseSync.isSyncing) {
        return;
      }
      commit('UPDATE_SYNCING_STATE', true);
      commit('UPDATE_SYNC_ERROR', null);
      try {
        await Sync.lightSyncService();
      } catch (e) {
        if (e === 'error') {
          commit('UPDATE_SYNC_ERROR', 'Could not connect to the server');
        } else {
          commit('UPDATE_SYNC_ERROR', 'Unexpected server response');
          commit('UPDATE_SYNCING_STATE', false);
          throw Error('Unexpected server response');
        }
      }
      commit('UPDATE_LAST_SUCCESSFUL_SYNC_TIMESTAMP');
      commit('UPDATE_SYNCING_STATE', false);
    },
    /**
     * Sincronización completa
     * @param {*} param0
     * @returns
     */
    async completeDataBaseSync({ commit, rootState }) {
      if (rootState.databaseSync.isSyncing) {
        return;
      }
      commit('UPDATE_SYNCING_STATE', true);
      commit('UPDATE_SYNC_ERROR', null);
      try {
        await Sync.completeSyncService();
      } catch (e) {
        if (e === 'error') {
          commit('UPDATE_SYNC_ERROR', 'Could not connect to the server');
        } else {
          commit('UPDATE_SYNC_ERROR', 'Unexpected server response');
          commit('UPDATE_SYNCING_STATE', false);
          throw Error('Unexpected server response');
        }
      }
      commit('UPDATE_LAST_SUCCESSFUL_SYNC_TIMESTAMP');
      commit('UPDATE_SYNCING_STATE', false);
    },
    resetLastSync({ commit }) {
      commit('RESET_LAST_SUCCESSFUL_SYNC_TIMESTAMP');
    },
    start(context) {
      context.commit('setSyncingState', true);
    },
    finish(context) {
      context.commit('setSyncingState', false);
    },
    setSyncErrorText({ commit }, value) {
      commit('SET_SYNC_ERROR_TEXT', value);
    },
    updateDeviceUuid({ commit }, { value }) {
      commit('UPDATE_DEVICE_UUID', value);
    },
  },
};
