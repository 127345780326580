import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import Stepper from '../../../components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '../../../components/wizardNutrition/stepper-navigation/index.vue';
import ReferenceValue from '../../../components/wizardNutrition/referenceValue/index.vue';
import NutritionalNeeds from '../../../components/wizardNutrition/nutritionalNeeds/index.vue';
import { fertilizationService } from '../../../services/fertilizationService';
import PopupConfirmWizardExit from '../../../components/wizardNutrition/popupConfirmWizardExit/index.vue';

export default {
  name: 'nutrition-step-one',
  components: {
    Stepper,
    StepperNavigation,
    navbar,
    ReferenceValue,
    NutritionalNeeds,
    DxLoadPanel,
    PopupConfirmWizardExit,
  },
  props: ['id'],
  data() {
    return {
      fertilization: {},
      loaded: false,
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['nutritionSteps', 'stepData']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Nutrition', ['displayConfirmExitPopup']),
    ...mapState('nutritionStepper', ['isEditing', 'editingStepData']),
    disabledNextButton() {
      if (this.stepData?.planDescription === undefined) return true;
      return this.stepData?.planDescription.trim().length === 0;
    },
  },
  async mounted() {
    this.loaded = false;

    if (this.id) {
      const response = await fertilizationService
        .getById(this.id, this.currentPlantation.companyId);
      await this.updateEnableWatchers(false);
      await this.updateWizardData(response);
      await this.updateEnableWatchers(true);
    }
    this.setStep(0);

    const isEmptyEditingStepData = this.editingStepData.initDate === '' && this.editingStepData.endDate === '' && this.editingStepData.timeValues.length === 0;

    if (this.isEditing && isEmptyEditingStepData) {

      this.setEditingStepData({
        initDate: this.stepData.initDate,
        endDate: this.stepData.endDate,
        timeValues: this.stepData.timeValues,
      })
    }

    if (!this.$f7router.previousRoute.path.startsWith('/utilities/nutrition/nutrition')) {
      this.$f7router.on('routeChange', this.resetValues);
    }

    this.loaded = true;
  },
  beforeDestroy() {
    this.setEditingFromContextMenu(null)
  },
  methods: {
    resetValues(newRoute) {
      if (!newRoute.path.startsWith('/utilities/nutrition/nutrition')) {
        this.$f7router.off('routeChange', this.resetValues);
      }
    },
    ...mapActions('nutritionStepper', ['updateClasses', 'setStep', 'resetWizard', 'updateWizardData', 'updateEnableWatchers', 'setEditingStepData']),
    ...mapActions('Nutrition', ['setEditingFromContextMenu']),
  },
};
