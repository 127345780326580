import moment from 'moment';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    name: '',
    init_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    id: '',
    showPopUp: false,
    dataPlantation: [],
    allcampaign: [],
    actual_Plot: '',
    actualCampaign: {},
    campaignId: '',
    isView: false,
    isFromCampaign: true,
    listExplotation: [],
    showRegisterSuccessCampaignPopup: false,
    viewCampaignId: '',
  },
  mutations: {
    SET_NAME(state, payload) {
      state.name = payload;
    },
    SET_INIT_DATE(state, payload) {
      state.init_date = payload;
    },
    SET_END_DATE(state, payload) {
      state.end_date = payload;
    },
    SET_ID(state, payload) {
      state.id = payload;
    },
    SET_SHOW_POPUP(state, payload) {
      state.showPopUp = payload;
    },
    SET_DATA_PLANTATION(state, payload) {
      state.dataPlantation = payload;
    },
    SET_ACTUAL_PLOT(state, payload) {
      state.actual_Plot = payload;
    },
    FETCH_ALLCAMPAIGN(state, payload) {
      state.allcampaign = payload;
    },
    SET_ACTUAL_CAMPAIGN(state, payload) {
      state.actualCampaign = payload;
      state.id = payload.id;
      state.name = payload.name;
      state.init_date = payload.init_date;
      state.end_date = payload.end_date;
    },
    SET_CAMPAING_ID(state, payload) {
      state.campaignId = payload;
    },
    SET_VIEW_CAMPAIGN_ID(state, payload) {
      state.viewCampaignId = payload;
    },
    SET_IS_VIEW(state, payload) {
      state.isView = payload;
    },
    SET_LIST_EXPLOTATION(state, payload) {
      state.listExplotation = payload;
    },
    setShowRegisterSuccessCampaignPopup(state, value) {
      state.showRegisterSuccessCampaignPopup = value;
    },
    setIsFromCampaign(state, value) {
      state.isFromCampaign = value;
    },
  },
  actions: {
    async getCampaignsByCompany({ commit, rootGetters }) {
      try {
        const actualClientData = rootGetters['Explotaciones/actualUser'];
        const xhr = await Api.getCampaignsByCompany(actualClientData.id);
        const campaign = JSON.parse(xhr.response).data;
        const allCamp = [];
        if (campaign.length === 0) {
          commit('FETCH_ALLCAMPAIGN', []);
          return Promise.resolve();
        }
        for (let i = 0; i < campaign.length; i += 1) {
          allCamp.push({
            name: campaign[i].name,
            init_date: campaign[i].init_date,
            end_date: campaign[i].end_date,
            id: campaign[i].id,
          });
        }
        commit(
          'SET_END_DATE',
          campaign[0].init_date,
        );
        commit(
          'SET_INIT_DATE',
          campaign[0].end_date,
        );
        commit(
          'FETCH_ALLCAMPAIGN',
          allCamp,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createCampaign({ state, commit, rootGetters }) {
      try {
        const actualClientData = rootGetters['Explotaciones/actualUser'];
        const CompanyId = actualClientData.id;
        const newCampania = {
          name: state.name,
          init_date: state.init_date,
          end_date: state.end_date,
        };
        const xhr = await Api.createCampaign(CompanyId, newCampania);
        const newDataCampagin = JSON.parse(xhr.response);
        commit(
          'SET_CAMPAING_ID',
          newDataCampagin.id,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateCampaign({ state, commit, rootGetters }) {
      try {
        const actualClientData = rootGetters['Explotaciones/actualUser'];
        const CompanyId = actualClientData.id;
        const newCampania = {
          name: state.name,
          init_date: state.init_date,
          end_date: state.end_date,
        };
        const xhr = await Api.updateCampaign(CompanyId, newCampania, state.id);
        const newDataCampagin = JSON.parse(xhr.response);
        commit(
          'SET_CAMPAING_ID',
          newDataCampagin.id,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getPlantationByCompany({ commit, state, rootGetters }, actualUserId = '') {
      try {
        const actualClientData = rootGetters['Explotaciones/actualUser'];
        const CompanyId = actualClientData.id;
        const campaignId = actualUserId === '' ? state.actualCampaign.id : actualUserId;
        let xhr = await Api.getPlantationByCampaign(CompanyId, campaignId);
        const plantationAvailables = JSON.parse(xhr.response).data;
        xhr = await Api.getTreeLocation(CompanyId);
        const location = JSON.parse(xhr.response);
        const dataPlantationFormatted = [];
        for (let i = 0; i < plantationAvailables.length; i += 1) {
          let sectorId = '';
          let sectorsName = '';
          let parcelsName = '';
          let farmName = '';
          if (plantationAvailables[i].farms.length === 1) {
            sectorId = plantationAvailables[i].farms[0];
          }
          farmName = Helpers.getLocationLevel0NameById(sectorId, location);
          parcelsName = Helpers.getLocationLevel1NameById(sectorId, location);
          sectorsName = Helpers.getLocationLevel2NameById(sectorId, location);
          dataPlantationFormatted.push({
            species: plantationAvailables[i].species,
            id: plantationAvailables[i].id,
            variety: plantationAvailables[i].variety,
            initDate: plantationAvailables[i].init_date,
            endDate: plantationAvailables[i].end_date,
            sectorsName,
            parcelsName,
            farmName,
          });
        }
        commit(
          'SET_DATA_PLANTATION',
          dataPlantationFormatted,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getListExplotation({ commit, rootGetters }) {
      try {
        const actualClientData = rootGetters['Explotaciones/actualUser'];
        const CompanyId = actualClientData.id;
        const xhr = await Api.getExplotation(CompanyId);
        const plantationAvailables = JSON.parse(xhr.response).data;
        commit(
          'SET_LIST_EXPLOTATION',
          plantationAvailables,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getListExplotationDetailed({ commit, rootGetters }) {
      try {
        const actualClientData = rootGetters['Explotaciones/actualUser'];
        const CompanyId = actualClientData.id;
        const xhr = await Api.getExplotationDetailed(CompanyId);
        const plantationAvailables = JSON.parse(xhr.response).data;
        commit(
          'SET_LIST_EXPLOTATION',
          plantationAvailables,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getCampaignsById({ commit, rootGetters }, id) {
      try {
        const actualClientData = rootGetters['Explotaciones/actualUser'];
        const CompanyId = actualClientData.id;
        const xhr = await Api.getCampaignsById(CompanyId, id);
        const dataCampaing = JSON.parse(xhr.response);
        commit(
          'SET_ACTUAL_CAMPAIGN',
          dataCampaing,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setEndDate(context, value) {
      context.commit('SET_END_DATE', value);
    },
    setInitDate(context, value) {
      context.commit('SET_INIT_DATE', value);
    },
    setName(context, value) {
      context.commit('SET_NAME', value);
    },
    setShowPopUp(context, value) {
      context.commit('SET_SHOW_POPUP', value);
    },
    setActualPlot(context, value) {
      context.commit('SET_ACTUAL_PLOT', value);
    },
    initStore({ commit }) {
      commit('SET_ID', '');
      commit('SET_NAME', '');
      commit('SET_INIT_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_END_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_ACTUAL_PLOT', '');
    },
    setActualCampaign(context, value) {
      context.commit('SET_ACTUAL_CAMPAIGN', value);
    },
    setCampaignId(context, value) {
      context.commit('SET_CAMPAING_ID', value);
    },
    setIsView(context, value) {
      context.commit('SET_IS_VIEW', value);
    },
    setViewCampaignId({ commit }, value) {
      commit('SET_VIEW_CAMPAIGN_ID', value);
    },
    setShowRegisterSuccessCampaignPopup(context, value) {
      context.commit('setShowRegisterSuccessCampaignPopup', value);
    },
    setIsFromCampaign({ commit }, value) {
      commit('setIsFromCampaign', value);
    },
  },
};
