<template>
  <section>
    <div class="content-devx padding-half">
      <div class="card-header-title margin-left-half margin-bottom">
        {{ $t("treatment.treatmentPlan.treatmentCreatePlan.observations") }}
      </div>
      <f7-block>
        <f7-row>
          <f7-col>
            {{ getObservations }}
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <div class="margin-top">
              <div class="margin-bottom-half" v-for="(annotation, index) of productsAnnotations" :key="index">
                <div style="font-weight: 500">
                  • {{ annotation.name }}
                </div>
                <div class="padding-left">
                  {{ annotation.annotation }}
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </section>
</template>

<script>
import { DxTextArea } from "devextreme-vue/text-area";

export default {
  name: "Observations",
  props: {
    fertilization: Object,
  },
  components: {
    DxTextArea,
  },
  computed: {
    getObservations() {
      return this.fertilization?.observation ?? this.$t("No_Observation");
    },
    productsAnnotations() {
      return this.fertilization.contribution_products.map(item => {
        return {
          name: item.product_name,
          annotation: item.observation ?? ""
        }
      }).filter(item => item.annotation.length > 0)
    }
  },
};
</script>
