var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-panel',{staticClass:"panel panel-left not-animated content-menu",attrs:{"left":"","resizable":"","visible-breakpoint":1281,"collapsed-breakpoint":1281}},[_c('f7-page',[_c('div',{staticClass:"content-menu-header justify-content-center"},[_c('div',{staticClass:"menu-header-item"},[_c('f7-link',{staticClass:"icon-close",attrs:{"panel-close":""}}),_vm._v(" "),_c('div',{staticClass:"text-center padding-vertical"},[_c('img',{staticClass:"image_logo margin-top",staticStyle:{"width":"90px"},attrs:{"src":_vm.APP_LOGO}})])],1)]),_vm._v(" "),_c('f7-list',{staticClass:"content-list-menu",attrs:{"accordion-list":""}},[_c('ul',_vm._l((_vm.elements),function(item,index){return _c('li',{key:index,class:{
            'accordion-item': true,
          }},[(_vm.isGranted(item) && (_vm.$t(item.name) !== '' && _vm.$t(item.name) !== 'INFORMATION'))?[_c('a',{staticClass:"item-content item-link",on:{"click":function () {
                _vm.parentNavigateTo(item)
                _vm.removeAccordionOpened(item)
              }}},[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":_vm.getIconPath + item.icon + '.svg',"alt":""}}),_vm._v("      \n              "),_c('div',{staticClass:"item-inner",class:{ 'client-tab': item.name === 'LeftPanel_title_ParcelSystem' }},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(_vm.$t(item.name)))])])]),_vm._v(" "),_c('f7-accordion-content',[_c('f7-list',{attrs:{"media-list":""}},_vm._l((item.menus),function(itemMenu,index2){return (_vm.isGranted(itemMenu))?_c('f7-list-item',{key:index2,staticClass:"panel-close close",class:{highlight:_vm.getActualMenuSelected == itemMenu.url},attrs:{"accordion-list":""},on:{"click":function () {
                    _vm.navigateTo(itemMenu.url, itemMenu);
                  }}},[((typeof itemMenu.icon !== "undefined"))?_c('f7-icon',{class:("icon-menu " + (itemMenu.icon)),attrs:{"slot":"media","link":itemMenu.url,"width":"32","height":"32"},slot:"media"}):_vm._e(),_vm._v(" "),((typeof(itemMenu.icon)!=='undefined'))?_c('a',[_vm._v(_vm._s(_vm.$t(itemMenu.name)))]):_c('a',{staticClass:"marginLeft40"},[_vm._v(_vm._s(_vm.$t(itemMenu.name).toLowerCase().capitalize()))]),_vm._v(" "),_c('br')],1):_vm._e()}),1)],1)]:(_vm.$t(item.name) === '' && _vm.getActualMenuSelected === undefined)?_vm._l((item.menus),function(itemMenu,index2){return _c('f7-list-item',{key:index2,staticClass:"panel-close close highlight",class:{highlight:_vm.getActualMenuSelected == itemMenu.url},attrs:{"accordion-list":""},on:{"click":function($event){return _vm.navigateTo(itemMenu.url)}}},[((typeof(itemMenu.icon)!=='undefined'))?_c('f7-icon',{class:("icon-menu " + (itemMenu.icon)),attrs:{"slot":"media","link":itemMenu.url,"width":"32","height":"32"},slot:"media"}):_vm._e(),_vm._v(" "),((typeof(itemMenu.icon)!=='undefined'))?_c('a',[_vm._v(_vm._s(_vm.$t(itemMenu.name)))]):_c('a',[_vm._v(_vm._s(_vm.$t(itemMenu.name)))]),_vm._v(" "),_c('br')],1)}):_vm._l((item.menus),function(itemMenu,index2){return _c('f7-list-item',{key:index2,staticClass:"panel-close close",class:{highlight:_vm.getActualMenuSelected == itemMenu.url},attrs:{"accordion-list":""},on:{"click":function($event){return _vm.navigateTo(itemMenu.url)}}},[((typeof(itemMenu.icon)!=='undefined'))?_c('f7-icon',{class:("icon-menu " + (itemMenu.icon)),attrs:{"slot":"media","link":itemMenu.url,"width":"32","height":"32"},slot:"media"}):_vm._e(),_vm._v(" "),((typeof(itemMenu.icon)!=='undefined'))?_c('a',[_vm._v(_vm._s(_vm.$t(itemMenu.name)))]):_c('a',[_vm._v(_vm._s(_vm.$t(itemMenu.name)))]),_vm._v(" "),_c('br')],1)})],2)}),0)]),_vm._v(" "),_c('div',{staticClass:"left-panel__footer"},[_c('p',{staticClass:"version-app"},[_vm._v("\n        v"+_vm._s(_vm.version)+"\n      ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }