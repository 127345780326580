import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '@/components/wizardNutrition/stepper-navigation/index.vue';
import PlanCompost from '@/components/wizardNutrition/planCompost/index.vue';
import NutritionalDistribution from '@/components/wizardNutrition/nutritionalDistribution/index.vue';
import { mapState } from 'vuex';

export default {
  name: 'nutrition-step-four',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    NutritionalDistribution,
    PlanCompost,
  },
  props: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};
