import Api from '../../services/Api';

export default {
  namespaced: true,
  state: {
    allDataAnalysis: [],
    id: '',
    plantation: '',
    description: '',
    date: '',
    sample_type: '',
    interpretation: '',
    attachment: '', // Base 64.
    isEdit: false,
    actualLaboratoryAnalysis: {},
    productsNotInNutritionPlan: [],
    displayConfirmExitPopup: false,
    routeToGo: null,
    editingFromContextMenu: null
  },
  getters: {
  },
  mutations: {
    FETCH_ALL_DATA_ANALYSIS(state, payload) {
      state.allDataAnalysis = payload;
    },
    SET_ATACHMENT(state, payload) {
      state.attachment = payload;
    },
    SET_DATE(state, payload) {
      state.date = payload;
    },
    SET_DESCRIPTION(state, payload) {
      state.description = payload;
    },
    SET_SAMPLE_TYPE(state, payload) {
      state.sample_type = payload;
    },
    SET_INTERPRETATION(state, payload) {
      state.interpretation = payload;
    },
    SET_PLANTATION(state, payload) {
      state.plantation = payload;
    },
    SET_ISEDIT(state, payload) {
      state.isEdit = payload;
    },
    SET_ACTUALLABORATORYANALYSIS(state, payload) {
      state.actualLaboratoryAnalysis = payload;
    },
    SET_ID(state, payload) {
      state.id = payload;
    },
    setProductsNotInNutritionPlan(state, payload) {
      state.productsNotInNutritionPlan = payload;
    },
    setDisplayConfirmExitPopup(state, payload) {
      state.displayConfirmExitPopup = payload;
    },
    setRouteToGo(state, payload) {
      state.routeToGo = payload;
    },
    SET_EDITING_FROM_CONTEXT_MENU(state, id) {
      state.editingFromContextMenu = id;
    }
  },
  actions: {
    setDisplayConfirmExitPopup({ commit }, value) {
      commit('setDisplayConfirmExitPopup', value);
    },
    setRouteToGo({ commit }, value) {
      commit('setRouteToGo', value);
    },
    async getDataAnalysis({ commit }, data) {
      try {
        const xhr = await Api.getListAnalysis(data.companyId, data.idPlantation);
        const analysis = JSON.parse(xhr.response).data;
        const allData = [];
        for (let i = 0; i < analysis.length; i += 1) {
          allData.push({
            id: analysis[i].id,
            plantation: analysis[i].plantation,
            description: analysis[i].description,
            date: analysis[i].date,
            sample_type: analysis[i].sample_type,
            interpretation: analysis[i].interpretation,
            attachment: analysis[i].attachment,
          });
        }
        commit(
          'FETCH_ALL_DATA_ANALYSIS',
          allData,
        );
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    async postNewAnalysis({ state }, currentPlantation) {
      const dataAnalysis = {
        attachment: state.attachment,
        date: state.date,
        interpretation: state.interpretation,
        sample_type: state.sample_type,
        plantation: currentPlantation.id,
        description: state.description,
      };
      try {
        await Api.createNewAnalysis(dataAnalysis, currentPlantation.companyId);
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    async updateAnalysis({ state }, currentPlantation) {
      const dataAnalysis = {
        attachment: state.attachment === '' ? state.actualLaboratoryAnalysis.attachment : state.attachment,
        date: state.date,
        interpretation: state.interpretation,
        sample_type: state.sample_type,
        plantation: currentPlantation.id,
        description: state.description,
      };
      try {
        await Api.updateAnalysis(dataAnalysis, currentPlantation.companyId, state.id);
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    async getViewDetails({ commit }, data) {
      try {
        const xhr = await Api.getLaboratoryAnalysisById(data.id, data.companyId);
        const analysis = JSON.parse(xhr.response);
        commit('SET_ACTUALLABORATORYANALYSIS', analysis);
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    setAttachment(context, value) {
      context.commit('SET_ATACHMENT', value);
    },
    setDate(context, value) {
      context.commit('SET_DATE', value);
    },
    setInterpretation(context, value) {
      context.commit('SET_INTERPRETATION', value);
    },
    setSampleType(context, value) {
      context.commit('SET_SAMPLE_TYPE', value);
    },
    setDescription(context, value) {
      context.commit('SET_DESCRIPTION', value);
    },
    setPlantation(context, value) {
      context.commit('SET_PLANTATION', value);
    },
    setIsEdit(context, value) {
      context.commit('SET_ISEDIT', value);
    },
    setActualLaboratoryAnalysis(context, value) {
      context.commit('SET_ACTUALLABORATORYANALYSIS', value);
    },
    setId(context, value) {
      context.commit('SET_ID', value);
    },
    setProductsNotInNutritionPlan({ commit }, value) {
      commit('setProductsNotInNutritionPlan', value);
    },
    addProductNotInNutritionPlan({ state }, value) {
      state.productsNotInNutritionPlan.push(value);
    },
    setEditingFromContextMenu({ commit }, id) {
      commit('SET_EDITING_FROM_CONTEXT_MENU', id)
    }
  },
};
