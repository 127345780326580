<template>
  <f7-block class="no-padding">
    <div
      class="content-devx"
    >
      <f7-block>
        <f7-row>
          <f7-col width="20">
            <f7-block-title class="no-padding no-margin">
              {{ $t('Product_Detail_Title') }}
              <f7-block-title />
            </f7-block-title>
          </f7-col>
          <f7-col width="70" />
          <f7-col width="10" class="justify-content-end">
            <f7-button
              class="dx-btn-pdf"
              raised
              type="default"
              styling-mode="outlined"
              :external="true"
              :target="!isMobile ? '_blank': '_system'"
              :href="productDetail.pdf"
            >
              <f7-icon
                md="material:picture_as_pdf"
              />
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block>
        <f7-row class="margin-bottom">
          <f7-col>
            {{ $t('Filter_Commercial_Name') }}
          </f7-col>
          <f7-col>
            {{ $t('Holder') }}
          </f7-col>
          <f7-col>
            {{ $t('Registration_Number') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold margin-bottom">
          <f7-col>
            {{ productDetail.commercialName }}
          </f7-col>
          <f7-col>
            {{ productDetail.holder }}
          </f7-col>
          <f7-col>
            {{ productDetail.registrationNumber }}
          </f7-col>
        </f7-row>
        <f7-row class="margin-bottom">
          <f7-col>
            {{ $t('Active_Principle') }}
          </f7-col>
          <f7-col>
            {{ $t('Display') }}
          </f7-col>
          <f7-col>
            {{ $t('Expiration_Date') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold">
          <f7-col>
            {{ productDetail.activeProduct }}
          </f7-col>
          <f7-col>
            {{ productDetail.containers.toString() }}
          </f7-col>
          <f7-col>
            {{ productDetail.expirationDate }}
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </f7-block>
</template>
<script>
import { Device } from 'framework7/framework7-lite.esm.bundle';

export default {
  name: 'ProductDetail',
  props: {
    productDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMobile: Device.cordova,
    };
  },
};
</script>
<style lang="scss" scoped>
@import './ProductDetail.styles.scss';
</style>
