export default {
  namespaced: true,
  state: {
    actualPestsDiseases: {},
    actualExtremeWeatherCondicions: {},
    point: {},
  },

  getters: {
    getActualPestsDiseases: (state) => state.actualPestsDiseases,
    getActualExtremeWeatherCondicions: (state) => state.actualExtremeWeatherCondicions,
    point: (state) => state.point,
  },
  actions: {
    setActualPestsDiseases(context, value) {
      context.commit('setActualPestsDiseases', value);
    },
    setActualExtremeWeatherCondicions(context, value) {
      context.commit('setActualExtremeWeatherCondicions', value);
    },
    setPoint(context, value) {
      context.commit('setPoint', value);
    },
    setName(context, value) {
      context.commit('setName', value);
    },
    setLatitude(context, value) {
      context.commit('setLatitude', value);
    },
    setLongitude(context, value) {
      context.commit('setLongitude', value);
    },
    setNumberPlants(context, value) {
      context.commit('setNumberPlants', value);
    },
    setLocation(context, value) {
      context.commit('setLocation', value);
    },
    setVariety(context, value) {
      context.commit('setVariety', value);
    },
    setType(context, value) {
      context.commit('setType', value);
    },
    setYearPlanting(context, value) {
      context.commit('setYearPlanting', value);
    },
    setNote(context, value) {
      context.commit('setNote', value);
    },
  },
  mutations: {
    setActualPestsDiseases(state, value) {
      state.actualPestsDiseases = value;
    },
    setActualExtremeWeatherCondicions(state, value) {
      state.actualExtremeWeatherCondicions = value;
    },
    setPoint(state, value) {
      state.point = value;
    },
    setName(state, value) {
      state.point.name = value;
    },
    setLatitude(state, value) {
      state.point.latitude = value;
    },
    setLongitude(state, value) {
      state.point.longitude = value;
    },
    setNumberPlants(state, value) {
      state.point.numberPlants = value;
    },
    setLocation(state, value) {
      state.point.locationId = value;
    },
    setVariety(state, value) {
      state.point.variety = value;
    },
    setType(state, value) {
      state.point.type = value;
    },
    setYearPlanting(state, value) {
      state.point.yearPlanting = value;
    },
    setNote(state, value) {
      state.point.note = value;
    },
  },
};
