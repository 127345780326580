<template>
  <section class="nutritional-needs">
    <div class="content-devx">
      <div class="card-header">
        {{ $t('nutritionalNeeds.title') }}
      </div>
      <div class="card-body">
        <div class="dx-field margin-top-half">
          <div class="dx-field-label no-padding">
            <span>{{ $t('nutritionalNeeds.productionInput') }}</span>
          </div>
          <div class="dx-field-value-static no-padding">
            <DxNumberBox
              v-model="stepData.expectedProduction"
              @keyUp="onKeyUpExpectedProduction"
            />
          </div>
        </div>
        <DxDataGrid
          class="data-grid-reference-value"
          :data-source="currentNutritionalNeeds"
          :show-borders="true"
          :on-editing-start="onEditingStart"
          @row-updated="cleanNutritionalBalanceData"
        >
          <DxEditing
            :allow-updating="true"
            mode="cell"
          />
          <DxColumn caption="">
            <DxColumn
              :caption="$t('nutritionalNeeds.productionInput')"
              data-field="production"
              data-type="number"
              :allow-editing="false"
              :allow-sorting="false"
            />
          </DxColumn>
          <DxColumn
            alignment="center"
            :caption="$t('nutrition_needs_info')"
          >
            <DxColumn
              :caption="$t('nutritionalNeeds.n')"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.p2o5')"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.k2o')"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.cao')"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.mgo')"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
            <DxColumn
              :caption="$t('nutritionalNeeds.so3')"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
              :set-cell-value="setCellValue"
            />
          </DxColumn>
        </DxDataGrid>
      </div>
    </div>
  </section>
</template>
<script>
import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'NutritionalNeeds',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxNumberBox,
  },
  props: [],
  data() {
    return {
      columns: [
        this.$t('nutritionalNeeds.productionInput'),
        this.$t('nutritionalNeeds.n'),
        this.$t('nutritionalNeeds.p2o5'),
        this.$t('nutritionalNeeds.k2o'),
        this.$t('nutritionalNeeds.cao'),
        this.$t('nutritionalNeeds.mgo'),
        this.$t('nutritionalNeeds.so3'),
      ],
      currentNutritionalNeeds: [],
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['stepData']),
    ...mapState('referenceValueStore', ['referenceValues', 'nutritionalNeeds']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  async created() {
    this.$f7.preloader.show();
    await this.initData();
    this.currentNutritionalNeeds.push(this.stepData.nutritionalNeeds[1]);
    this.$f7.preloader.hide();
  },
  methods: {
    onEditingStart(e) {
      this.currentCellDataField = e.column.dataField;
    },
    setCellValue(newData, value, currentRowData) {
      newData[this.currentCellDataField] = Math.abs(value).toFixed(3);
    },
    async initData() {
      await this.getReferenceValues(this.currentPlantation.species);
      if (this.stepData.nutritionalNeeds && this.stepData.nutritionalNeeds.length > 0) {
        return;
      }

      this.stepData.expectedProduction = 1;

      // Si no hay un registro en el array de 'this.nutritionalNeeds',
      //   se dispara un error al añadir los datos al stepData.
      // El arreglo más sencillo es crear un objeto de nutrición vacío.
      let firstNeed = this.nutritionalNeeds[0];
      if (firstNeed == undefined) {
        firstNeed = {
          n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
        };
      }

      this.stepData.nutritionalNeeds.push(firstNeed);
      this.stepData.nutritionalNeeds.push({
        id: null,
        production: 1,
        n: this.roundValue(firstNeed.n * 1),
        p: this.roundValue(firstNeed.p * 1),
        k: this.roundValue(firstNeed.k * 1),
        c: this.roundValue(firstNeed.c * 1),
        m: this.roundValue(firstNeed.m * 1),
        s: this.roundValue(firstNeed.s * 1),
      });
    },
    onKeyUpExpectedProduction(e) {
      this.stepData.nutritionalBalanceData = [];
      let { value } = e.event.target;
      if (value.length === 0) {
        value = 0;
        this.stepData.expectedProduction = 1;
      }

      this.stepData.nutritionalNeeds[1].production = value;
      this.calculateValue(this.stepData.nutritionalNeeds, 'n', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'p', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'k', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'c', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'm', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 's', value);
    },
    cleanNutritionalBalanceData() {
      this.stepData.nutritionalBalanceData = [];
    },
    calculateValue(array, param, value) {
      array[1][param] = this.roundValue(array[0][param] * value);
    },

    roundValue(val) {
      return parseFloat(val.toFixed(2));
    },
    ...mapActions('referenceValueStore', ['getReferenceValues']),
  },
};

</script>
<style src="./nutritionalNeeds.scss" scoped lang="scss"></style>
