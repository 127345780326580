<template>
  <div class="content-gis-editor">
    <div
      :id="`${nameMap}_${initialDate}`"
      :style="minHeight"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import * as turf from '@turf/turf';
import { mapState, mapActions } from 'vuex';
import Api from '../../../services/Api';

export default {
  name: 'GISEditorSigpac',
  props: {
    zoom: { type: Number, default: 1 },
    height: { type: String, default: '80vh' },
    nameMap: { type: String, default: '' },
    center: {
      type: Object,
      default: () => {},
    },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    disabledEditParcel: {
      type: Boolean,
      default: true,
    },
    disabledEditSector: {
      type: Boolean,
      default: true,
    },
    createSector: {
      type: Boolean,
      default: false,
    },
    showMarker: {
      type: Boolean,
      default: false,
    },
    showHomeButton: {
      type: Boolean,
      default: true,
    },
    showGPSButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      parcel: null,
      sector: null,
      initialDate: Date.now(),
      layerGroup: L.layerGroup(),
      layerGroupParcel: L.layerGroup(),
      copyLayer: null,
      controlLayer: null,
      oldImageSigpac: null,
      areaLayer: null,
      lineLayer: null,
      areaLayerPopup: null,
      lineLayerPopup: null,
      pmControlConfig: {
        position: 'topright',
        drawPolygon: true,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
      },
      pmControlConfigEdit: {
        position: 'topright',
        drawPolygon: false,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: true,
        dragMode: false,
        cutPolygon: false,
        removalMode: true,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
      },
      pmControlConfigEmpty: {
        position: 'topright',
        drawPolygon: false,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
      },
      hectareToM2: 10000,
      layerStyles: {
        default: {
          color: 'rgba(255,0,224,0.7)',
          weight: 1,
          fillOpacity: 0.5,
        },
        active: {
          weight: 3,
          color: 'rgba(241,234,234,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
        selected: {
          weight: 2,
          color: 'rgba(250,250,120,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
      },
      markerLayer: L.layerGroup(),
      layer: {},
      layer2: {},
      drawMode: false,
      visibleSigpac: false,
      SIGPACSearch: null,
      statusMeasureTool: false,
      statusUnionTool: false,
      statusSelectFeatureRemoveTool: false,
      unionGeoJson: null,
      confirmUnion: null,
      desactiveUnion: null,
      activeMeasure: null,
      measureArea: null,
      measureLineString: null,
      desactiveMeasure: null,
      polylineGroup: L.layerGroup(),
      arrayBounds: [],
      featuresSelected: [],
      bufferedSelected: [],
      geoJsonUnionLayer: null,
      intersect: null,
      join: null,
      SIGPACLayerId: null,
      statusCalldissolveSelectedLayers: null,
      toggleSearchCoordsclicks: 0,
      stylelayer: {
        defecto: {
          color: 'blue',
          opacity: 1,
          fillcolor: 'blue',
          fillOpacity: 0.1,
          weight: 0.5,
        },
        reset: {
          color: 'blue',
          opacity: 0.4,
          weight: 1,
        },
        highlight: {
          weight: 5,
          color: 'white',
          dashArray: '',
          fillOpacity: 0.7,
        },
        selected: {
          color: 'blue',
          opacity: 0.3,
          weight: 0.5,
        },
        added: {
          weight: 5,
          color: 'yellow',
          dashArray: '',
          fillOpacity: 0.7,
        },
      },
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.height}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
      'currentGeoJSONLayer',
      'currentGeojsonFeature',
    ]),
    ...mapState('GisEditor', [
      'featureInfoSigpac',
      'sigpacMap',
      'sigpacWFS',
      'sigpacIntersect',
      'dissolveFeatures',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    this.renderMap();
    this.addFuncionalities();
    this.addOverlayLayer();
    this.addDrawControl();
    this.createLayer();
    this.layerParcelEvents();
    this.mapEvents();
    this.controlLayerEvents();
  },
  methods: {
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        if (this.treeLocations.length !== 0) {
          for (const geoData of this.treeLocations) {
            for (const children of geoData.children) {
              if (
                typeof children.coordinates !== 'undefined'
                  && children.coordinates !== null
              ) {
                this.map.setView(
                  [
                    children.coordinates.latitude,
                    children.coordinates.longitude,
                  ],
                  this.zoom,
                );
              }
              this.layer = L.geoJson(children.geo_feature, {
                pmIgnore: this.disabledEditParcel,
              }).addTo(this.map);
              this.parcel = this.layer;
              if (!this.disabledEditParcel) {
                this.layerGroup.addLayer(this.layer);
              }
              if (!this.createSector) {
                for (const children2 of children.children) {
                  this.layer2 = L.geoJson(children2.geo_feature, {
                    pmIgnore: this.disabledEditSector,
                  }).addTo(this.map);
                  this.layer2.setStyle(this.layerStyles.active);
                  if (!this.disabledEditSector) {
                    this.layerGroup.addLayer(this.layer2);
                  }
                }
              }
            }
          }
        }
        if (this.center !== null) {
          this.map.setView(
            [this.center.latitude, this.center.longitude],
            this.zoom,
          );
          if (this.showMarker) {
            this.setMarker(this.center.latitude, this.center.longitude);
          }
        } else {
          const bounds = this.layer.getBounds();
          this.map.fitBounds(bounds);
          this.centerMapGeoJsonLayer(this.layer);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(
        this.googleHybrid.route,
        this.googleHybrid.properties,
      ).addTo(this.map);
      this.currentLayerBase = L.tileLayer(
        this.googleHybrid.route,
        this.googleHybrid.properties,
      ).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.layerGroup.addTo(this.map);
      this.markerLayer.addTo(this.map);
      this.layerGroupParcel.addTo(this.map);
      this.polylineGroup.addTo(this.map);

      this.controlLayer = L.control
        .layers(null, null, { collapsed: false })
        .addTo(this.map);
      this.controlLayer.addBaseLayer(this.currentLayerBase, 'Google Hybrid');
    },

    addBounds(layer) {
      this.arrayBounds.push(layer.getBounds());
    },

    removeBounds(layer) {
      this.arrayBounds = this.arrayBounds.filter(
        (bounds) => bounds !== layer.getBounds(),
      );
    },

    setStyleLayer(layer, styleSelected) {
      layer.setStyle(styleSelected);
    },

    removerLayers(feature) {
      this.featuresSelected = this.featuresSelected.filter(
        (obj) => obj.feature.id !== feature.id,
      );
    },

    addLayers(feature) {
      this.featuresSelected.push({
        feature,
      });
    },

    createBuffer(feature) {
      let buffered = null;
      if (this.bufferedSelected.length < 1) {
        buffered = turf.buffer(feature, 1, {
          units: 'meters',
        });
      } else {
        buffered = turf.buffer(feature, 2, {
          units: 'meters',
        });
      }

      this.bufferedSelected.push({
        feature: buffered,
        original: feature,
      });

      this.checkAdjacentFeature(feature);
    },

    removeBuffer(feature) {
      this.bufferedSelected = this.bufferedSelected.filter(
        (obj) => obj.original.id !== feature.id,
      );
    },

    // eslint-disable-next-line consistent-return
    checkAdjacentFeature() {
      if (this.featuresSelected.length > 1) {
        for (let i = 0; i < this.bufferedSelected.length; i += 1) {
          this.intersect = turf.booleanIntersects(
            this.bufferedSelected[0].feature,
            this.bufferedSelected[i].feature,
          );
        }
        return this.intersect;
      }
    },

    checkExistsLayers(feature) {
      let result = false;
      for (let i = 0; i < this.featuresSelected.length; i += 1) {
        if (this.featuresSelected[i].feature.id === feature.id) {
          result = true;
          break;
        }
      }
      return result;
    },

    setMarker(latitude, longitude) {
      this.markerLayer.clearLayers();
      const marker = new L.Marker(
        {
          lat: latitude,
          lng: longitude,
        },
        {
          draggable: false,
        },
      ).addTo(this.markerLayer);
      const redIcon = new L.Icon({
        iconUrl:
            'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl:
            'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      marker.setIcon(redIcon);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Thäles from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        if (this.showHomeButton) {
          this.addHomeButton();
        }
        if (this.showGPSButton) {
          this.addGPSButton();
        }
        // this.addSearchCoordButton();
        this.addSIGPACButton();
        // this.addMeasureToolButton();
        this.addUnionToolButton();
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },

    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeTitle: `${self.$t('Gis.Buttons.ZoomHome')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(
            options.zoomHomeText,
            options.zoomHomeTitle,
            `${controlName}-home`,
            container,
            this.zoomHome,
          );

          return container;
        },

        zoomHome() {
          if (self.center !== null) {
            self.map.setView(
              [self.center.latitude, self.center.longitude],
              self.zoom,
            );
          } else {
            self.map.fitBounds(self.layerBounds);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },

    addGPSButton() {
      const self = this;
      L.Control.zoomLocation = L.Control.extend({
        options: {
          position: 'topleft',
          zoomLocationText: '<i class="fg-position fa-2x"></i>',
          zoomLocationTitle: `${self.$t('Gis.Buttons.CurrentPosition')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomLocationButton = this.createButton(
            options.zoomLocationText,
            options.zoomLocationTitle,
            `${controlName}-home`,
            container,
            this.zoomLocation,
          );

          return container;
        },

        zoomLocation() {
          let marker;
          self.map
            .locate({
              setView: true,
              maxZoom: 120,
              enableHighAccuracy: true,
              maximumAge: 10000,
            })
            .on('locationfound', (e) => {
              self.markerLayer.clearLayers();
              if (!marker) {
                marker = new L.Marker(e.latlng, {
                  draggable: false,
                }).addTo(self.markerLayer);
                const redIcon = new L.Icon({
                  iconUrl:
                      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                  shadowUrl:
                      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41],
                });
                marker.setIcon(redIcon);
                self.map.setZoom(self.zoom);
              } else {
                marker.setLatLng(e.latlng);
              }
            })
            .on('locationerror', () => {
              if (marker) {
                self.map.removeLayer(marker);
                marker = undefined;
              }
            });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomLocation = new L.Control.zoomLocation();
      zoomLocation.addTo(self.map);
    },

    addSearchCoordButton() {
      const self = this;
      const selector = L.control({
        position: 'topleft',
      });

      // eslint-disable-next-line func-names
      selector.onAdd = function () {
        const div = L.DomUtil.create('div', 'mySelector');
        div.innerHTML = '<div id="coords-search-selector"><form id="coords-search-form"><label class="input-lat-label" for="latitude">Latitud: </label><input id="coord_search_input_lat" type="number" step="0.01" value="" placeholder="Escriba latitud"></input><label class="input-lng-label"for="longitude">Longitud: </label><input id="coord_search_input_lng" type="number" step="0.01" max="90" value="" placeholder="Escriba longitud"></input></form><div class="button-container"><button id=coords_search_button>Buscar</button><p class="modal-message"></p></div></div>';

        L.DomEvent.on(
          div,
          'mousedown dblclick',
          L.DomEvent.stopPropagation,
        ).on(div, 'click', L.DomEvent.stop);
        return div;
      };

      selector.addTo(self.map);

      L.Control.searchCoordsToggle = L.Control.extend({
        options: {
          position: 'topleft',
          searchCoordsToggleText:
              '<i class="fa fa-map-marker" style="line-height:1.65 blue;"></i>',
          searchCoordsToggleTitle: `${self.$t(
            'Gis.Buttons.CoordinatesSearcher',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-search-coords';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          self.searchCoordsButton = this.createButton(
            options.searchCoordsToggleText,
            options.searchCoordsToggleTitle,
            `${controlName}-searchCoords`,
            container,
            this.searchCoordsToggle,
          );

          return container;
        },

        searchCoordsToggle() {
          const coordSearchSelector = document.getElementById(
            'coords-search-selector',
          );
          self.toggleSearchCoordsclicks += 1;
          if (self.toggleSearchCoordsclicks % 2 === 0) {
            coordSearchSelector.style.display = 'none';
          } else {
            coordSearchSelector.style.display = 'block';
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const searchCoordsToggle = new L.Control.searchCoordsToggle();
      searchCoordsToggle.addTo(self.map);
    },

    addSIGPACButton() {
      const self = this;
      L.Control.SIGPACSearch = L.Control.extend({
        options: {
          position: 'topleft',
          SIGPACSearchText:
              '<i class="fg-search-feature fa-2x" style="line-height:1.65 blue;"></i>',
          SIGPACSearchTitle: `${self.$t('Gis.Buttons.SigpacSearcher')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._SIGPACSearchButton = this.createButton(
            options.SIGPACSearchText,
            options.SIGPACSearchTitle,
            `${controlName}-home`,
            container,
            this.SIGPACSearch,
          );
          return container;
        },

        SIGPACSearch() {
          const html = self.getSigpacSearchForm();
          self.createModal(html);
          const searchSIGPACButton = document.getElementById('search-sigpac');

          searchSIGPACButton.addEventListener('click', (e) => {
            e.preventDefault();
            self.sigpacSearch();
            self.map.closePopup();
          });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      this.SIGPACSearch = new L.Control.SIGPACSearch();
    },

    addMeasureToolButton() {
      const self = this;
      L.Control.measureArea = L.Control.extend({
        options: {
          position: 'topright',
          measureAreaText: '<i class="fg-measure-area fa-2x"></i>',
          measureAreaTitle: `${self.$t('Gis.Buttons.MeasureArea')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-area';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureAreaButton = this.createButton(
            options.measureAreaText,
            options.measureAreaTitle,
            `${controlName}-measureArea`,
            container,
            this.measureArea,
          );

          return container;
        },

        measureArea() {
          self.map.pm.enableDraw('Polygon', {
            snappable: true,
            continueDrawing: false,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureArea = new L.Control.measureArea();

      L.Control.measureLineString = L.Control.extend({
        options: {
          position: 'topright',
          measureLineStringText: '<i class="fg-measure-line fa-2x"></i>',
          measureLineStringTitle: `${self.$t(
            'Gis.Buttons.MeasureLineString',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-line';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureLineStringButton = this.createButton(
            options.measureLineStringText,
            options.measureLineStringTitle,
            `${controlName}-measureLineString`,
            container,
            this.measureLineString,
          );

          return container;
        },

        measureLineString() {
          self.map.pm.enableDraw('Line', {
            snappable: true,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureLineString = new L.Control.measureLineString();

      L.Control.desactiveMeasure = L.Control.extend({
        options: {
          position: 'topright',
          desactiveMeasureText: '<i class="fas fa-times"></i>',
          desactiveMeasureTitle: `${self.$t(
            'Gis.Buttons.DesactiveMeasureMode',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.desactiveMeasureText,
            options.desactiveMeasureTitle,
            `${controlName}-desactiveMeasure`,
            container,
            this.desactiveMeasure,
          );

          return container;
        },

        desactiveMeasure() {
          self.statusMeasureTool = false;
          self.measureArea.remove(self.map);
          self.measureLineString.remove(self.map);
          // eslint-disable-next-line no-use-before-define
          self.desactiveMeasure.remove(self.map);
          self.map.pm.toggleControls();
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:block';
          if (self.areaLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.areaLayer);
          }
          if (self.lineLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.lineLayer);
          }
          self.map.pm.disableDraw();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveMeasure = new L.Control.desactiveMeasure();

      L.Control.activeMeasure = L.Control.extend({
        options: {
          position: 'topright',
          activeMeasureText: '<i class="fg-measure fa-2x"></i>',
          activeMeasureTitle: `${self.$t('Gis.Buttons.ActiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-active-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.activeMeasureText,
            options.activeMeasureTitle,
            `${controlName}-activeMeasure`,
            container,
            this.activeMeasure,
          );

          return container;
        },

        activeMeasure() {
          self.statusMeasureTool = true;
          self.measureArea.addTo(self.map);
          self.measureLineString.addTo(self.map);
          self.desactiveMeasure.addTo(self.map);
          // activeMeasure.remove(self.map);
          self.map.pm.toggleControls();
          if (self.statusUnionTool === true) {
            self.map.pm.toggleControls();
          }
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:none';
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.id = 'activeMeasure';
          link.href = '#';
          link.style = 'display:block';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.activeMeasure = new L.Control.activeMeasure();
      self.activeMeasure.addTo(self.map);
    },

    addUnionToolButton() {
      const self = this;
      L.Control.unionGeoJson = L.Control.extend({
        options: {
          position: 'topright',
          unionGeoJsonStringText: '<i class="fg-union fa-2x"></i>',
          unionGeoJsonStringTitle: `${self.$t(
            'Gis.Buttons.ActiveMergeMode',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-union-geojson';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._unionGeoJsonButton = this.createButton(
            options.unionGeoJsonStringText,
            options.unionGeoJsonStringTitle,
            `${controlName}-unionGeoJson`,
            container,
            this.unionGeoJson,
          );

          return container;
        },

        async unionGeoJson() {
          // self.activeMeasure.remove(self.map);
          // if (self.statusMeasureTool === true) {
          //   self.measureArea.remove(self.map);
          //   self.measureLineString.remove(self.map);
          //   // eslint-disable-next-line no-use-before-define
          //   self.desactiveMeasure.remove(self.map);
          //   self.statusMeasureTool = false;
          //   self.map.pm.toggleControls();
          // }
          const defaultParameters = {
            // eslint-disable-next-line no-underscore-dangle
            LowerCorner: `${self.map.getBounds()._southWest.lng} ${
              self.map.getBounds()._southWest.lat
            }`,
            // eslint-disable-next-line no-underscore-dangle
            UpperCorner: `${self.map.getBounds()._northEast.lng} ${
              self.map.getBounds()._northEast.lat
            }`,
          };
          await self.fetchSigpacWFS(defaultParameters);

          L.geoJSON(self.sigpacWFS).addTo(self.layerGroupParcel);
          self.layerGroupParcel.remove(self.map);

          self.statusUnionTool = true;
          self.confirmUnion.addTo(self.map);
          self.desactiveUnion.addTo(self.map);
          self.unionGeoJson.remove(self.map);
          if (self.layerGroupParcel.getLayers().length > 0) {
            self.map.pm.addControls(self.pmControlConfigEmpty);
            self.resetAll();
          } else {
            self.map.pm.toggleControls();
            if (self.statusMeasureTool === true) {
              self.map.pm.toggleControls();
            }
          }

          const highlightFeature = function (e) {
            const layer = e.target;
            const { feature } = e.target;
            if (self.checkExistsLayers(feature)) {
              layer.setStyle(self.stylelayer.added);
            } else {
              layer.setStyle(self.stylelayer.highlight);
            }
          };

          const resetHighlight = function (e) {
            const layer = e.target;
            const { feature } = e.target;
            if (self.checkExistsLayers(feature)) {
              self.setStyleLayer(layer, self.stylelayer.highlight);
            } else {
              self.setStyleLayer(layer, self.stylelayer.defecto);
            }
          };
          const zoomToFeature = function (e) {
            const layer = e.target;
            const { feature } = e.target;
            let temporalGeoJson;

            self.statusSelectFeatureTool = true;
            self.createBuffer(feature);

            if (self.checkExistsLayers(feature)) {
              self.removerLayers(
                feature,
                self.setStyleLayer,
                layer,
                self.stylelayer.defecto,
              );
              self.removeBounds(layer);
              self.removeBuffer(feature);

              temporalGeoJson = L.geoJson(feature, {
                color: 'orange',
              }).addTo(self.map);
              setTimeout(() => {
                self.map.removeLayer(temporalGeoJson);
              }, 2000);
              // eslint-disable-next-line no-prototype-builtins
              if (
                e.target.feature.properties.hasOwnProperty('SIGPACReference')
              ) {
                self.layerGroupRemovebyId(self.SIGPACLayerId);
              } else {
                // eslint-disable-next-line no-underscore-dangle
                self.layerGroupRemovebyId(layer._leaflet_id - 7);
                if (self.layerGroupParcel.getLayers().length <= 1) {
                  self.map.pm.toggleControls();
                  self.map.pm.disableGlobalRemovalMode();
                }
              }
            } else {
              self.addLayers(
                feature,
                self.setStyleLayer,
                layer,
                self.stylelayer.highlight,
              );
              self.addBounds(layer);
              if (self.checkAdjacentFeature(feature) === false) {
                temporalGeoJson = L.geoJson(feature, {
                  color: 'red',
                }).addTo(self.map);
                setTimeout(() => {
                  self.map.removeLayer(temporalGeoJson);
                }, 2000);
                self.$f7.dialog.alert(self.$t('Gis.Alerts.AdjacentGeometry'));
                setTimeout(() => {
                  temporalGeoJson.remove(self.map);
                }, 2000);
                self.removerLayers(
                  feature,
                  self.setStyleLayer,
                  layer,
                  self.stylelayer.defecto,
                );
                self.removeBounds(layer);
                self.removeBuffer(feature);
              } else {
                temporalGeoJson = L.geoJson(feature, {
                  color: 'green',
                }).addTo(self.map);
                setTimeout(() => {
                  self.map.removeLayer(temporalGeoJson);
                }, 2000);
              }
            }
            if (self.arrayBounds.length > 0) {
              self.map.fitBounds(self.arrayBounds);
            }
          };

          const onEachFeature = function (feature, layer) {
            layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight,
              click: zoomToFeature,
            });
            if (self.checkExistsLayers(feature)) {
              self.setStyleLayer(layer, self.stylelayer.highlight);
            }
          };
          self.layerGroupParcel.eachLayer((layer) => {
            if (layer.type === 'Draw Layer') {
              self.map.removeLayer(layer);
              // eslint-disable-next-line no-underscore-dangle
              self.layerGroupRemovebyId(layer._leaflet_id);
            }
          });
          const layerGroupToGeoJson = self.layerGroupParcel.toGeoJSON();
          self.geoJsonUnionLayer = L.geoJson(layerGroupToGeoJson, {
            style: self.stylelayer.defecto,
            onEachFeature,
          }).addTo(self.map);

          // eslint-disable-next-line no-prototype-builtins
          if (
            self.geoJsonUnionLayer
              .getLayers()[0]
              .feature.properties.hasOwnProperty('SIGPACReference')
          ) {
            const SIGPAClayer = L.geoJson([
              self.geoJsonUnionLayer.getLayers()[0].feature.geometry,
            ]);
            self.geoJsonUnionLayer
              .addData([
                self.geoJsonUnionLayer.getLayers()[0].feature.geometry,
              ])
              .bringToBack();
            self.createBuffer(self.geoJsonUnionLayer.getLayers()[0].feature);
            self.addLayers(self.geoJsonUnionLayer.getLayers()[0].feature);
            self.addBounds(SIGPAClayer);
            self.setStyleLayer(
              self.geoJsonUnionLayer.getLayers()[0],
              self.stylelayer.highlight,
            );
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.unionGeoJson = new L.Control.unionGeoJson();

      L.Control.confirmUnion = L.Control.extend({
        options: {
          position: 'topright',
          confirmUnionStringText:
              '<i class="fas fa-check" style="line-height:1.65 blue;"></i>',
          confirmUnionStringTitle: `${self.$t('Gis.Buttons.ConfirmMerge')}`,
        },
        onAdd() {
          const controlName = 'gin-control-confirm-union-geojson';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._confirmUnionButton = this.createButton(
            options.confirmUnionStringText,
            options.confirmUnionStringTitle,
            `${controlName}-confirmUnion`,
            container,
            this.confirmUnion,
          );

          return container;
        },

        async confirmUnion() {
          self.statusSelectFeatureTool = false;
          if (self.bufferedSelected.length >= 2) {
            const originalFeatures = [];

            for (let i = 0; i < self.bufferedSelected.length; i += 1) {
              originalFeatures.push(self.bufferedSelected[i].original);
            }
            const collection = turf.featureCollection(originalFeatures);

            await self.dissolveSelectedLayers(collection);
            if (self.statusCalldissolveSelectedLayers !== false) {
              self.join = L.geoJson(self.dissolveFeatures, {
                color: 'black',
              });

              self.geoJsonUnionLayer.remove(self.map);

              self.layerGroupParcel.clearLayers();

              self.join.type = 'Dissolve Layer';

              self.layerGroupParcel.addLayer(self.join);
              self.layerGroupParcel.addTo(self.map);
              self.layerParcelEvents();

              self.desactiveUnion.remove(self.map);
              self.confirmUnion.remove(self.map);

              self.map.pm.addControls(self.pmControlConfigEdit);
              self.resetAll();

              self.setLocation(self.getNewLocation(self.join, 0));
              self.statusUnionTool = false;
              self.statusSelectFeatureRemoveTool = false;

              while (self.arrayBounds.length) {
                self.arrayBounds.pop();
              }
              while (self.featuresSelected.length) {
                self.featuresSelected.pop();
              }
              while (self.bufferedSelected.length) {
                self.bufferedSelected.pop();
              }
            } else {
              self.desactiveUnion.remove(self.map);
              self.confirmUnion.remove(self.map);
              // self.addMeasureToolButton();
              self.unionGeoJson.addTo(self.map);
              self.layerGroupParcel.addTo(self.map);

              self.map.pm.addControls(self.pmControlConfig);
              self.resetAll();

              self.layerGroupParcel.clearLayers();
              self.geoJsonUnionLayer.clearLayers();
              self.geoJsonUnionLayer.remove();

              self.statusUnionTool = false;
              self.geoJsonUnionLayer = null;
              self.map.pm.disableGlobalRemovalMode();
              while (self.arrayBounds.length) {
                self.arrayBounds.pop();
              }
              while (self.featuresSelected.length) {
                self.featuresSelected.pop();
              }
              while (self.bufferedSelected.length) {
                self.bufferedSelected.pop();
              }
              if (self.statusMeasureTool === true) {
                self.map.pm.toggleControls();
              }
              self.statusCalldissolveSelectedLayers = true;
            }
          } else {
            self.$f7.dialog.alert(
              `${self.$t('Gis.Alerts.NumberMinimumPlots')}`,
            );
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.confirmUnion = new L.Control.confirmUnion();

      L.Control.desactiveUnion = L.Control.extend({
        options: {
          position: 'topright',
          desactiveUnionStringText:
              '<i class="fas fa-times" style="line-height:1.65 blue;"></i>',
          desactiveUnionStringTitle: `${self.$t(
            'Gis.Buttons.DesactiveMergeMode',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-union-geojson';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._desactiveUnionButton = this.createButton(
            options.desactiveUnionStringText,
            options.desactiveUnionStringTitle,
            `${controlName}-desactiveUnion`,
            container,
            this.desactiveUnion,
          );

          return container;
        },

        desactiveUnion() {
          self.statusSelectFeatureTool = false;
          self.desactiveUnion.remove(self.map);
          self.confirmUnion.remove(self.map);
          // self.addMeasureToolButton();
          self.unionGeoJson.addTo(self.map);
          self.layerGroupParcel.addTo(self.map);

          self.map.pm.addControls(self.pmControlConfig);
          self.resetAll();

          self.layerGroupParcel.clearLayers();
          self.geoJsonUnionLayer.clearLayers();
          self.geoJsonUnionLayer.remove();

          // self.layerGroupParcel = L.geoJson(self.currentLayers[0].item, {
          //   style: self.stylelayer.defecto,
          // }).addTo(self.map);

          // self.join.bringToFront();
          self.statusUnionTool = false;
          self.geoJsonUnionLayer = null;
          self.arrayBounds = [];
          self.featuresSelected = [];
          self.bufferedSelected = [];
          self.map.pm.disableGlobalRemovalMode();
          if (self.statusMeasureTool === true) {
            self.map.pm.toggleControls();
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveUnion = new L.Control.desactiveUnion();
    },

    getSigpacSearchForm() {
      return `
      <div class="sigpac-inputs">
        <form id="sigpac-form">
          <label for="province">${this.$t('Gis.Province')}</label>
          <input id="province" name="provincia" type="number" min="0" value=''></input>
          <label for="municipality">${this.$t('Gis.Municipality')}</label>
          <input id="municipality" name="municipio" type="number" min="0" value=''></input>
          <label for="aggregate">${this.$t('Gis.Aggregate')}</label>
          <input id="aggregate" name="agregado" type="number" min="0" value=''></input>
          <label for="zone">${this.$t('Gis.Zone')}</label>
          <input id="zone" name="zona" type="number" min="0" value=''></input>
          <label for="polygon">${this.$t('Gis.Polygon')}</label>
          <input id="polygon" name="poligono" type="number" min="0" value=''></input>
          <label for="plot">${this.$t('Gis.Plot')}</label>
          <input id="plot" name="parcela" type="number" min="0" value=''></input>
          <label for="enclosure">${this.$t('Gis.Enclosure')}</label>
          <input id="enclosure" name="recinto" type="number" min="0" value=''></input>
          
        <br>
        </form>
        <div class="button-container">
          <button id='search-sigpac'>${this.$t('Search')}</button>
          <p class="modal-message"></p>
        </div>
      </div>
      `;
    },

    async sigpacSearch() {
      const urlBase = `${Api.getGeoServer()}/`;
      const searchSIGPACButton = document.getElementById('sigpac-form')
        .elements;
      const obj = {};
      for (let i = 0; i < searchSIGPACButton.length; i += 1) {
        const item = searchSIGPACButton.item(i);
        obj[item.name] = item.value;
      }

      const route = 'sigpac_ref/?cql_filter=('
          + `provincia=${obj.provincia}%20`
          + `and%20municipio=${obj.municipio}%20`
          + `and%20agregado=${obj.agregado}%20`
          + `and%20zona=${obj.zona}%20`
          + `and%20poligono=${obj.poligono}%20`
          + `and%20parcela=${obj.parcela}%20`
          + `and%20recinto=${obj.recinto})`;

      const finalUrl = urlBase + route;
      try {
        this.$f7.preloader.show();
        await this.fetchCurrentGeojsonFeature(finalUrl);
        const parcel = this.currentGeojsonFeature;
        const features = parcel.features[0];
        const geoJson = L.geoJson([features.geometry]);
        const turfGeoJson = geoJson.toGeoJSON();
        const inverseGeoJsonCoordinates = turf.flip(turfGeoJson);
        const coordinates = inverseGeoJsonCoordinates.features[0].geometry.coordinates[0];
        const bounds = L.latLngBounds(coordinates);
        this.map.fitBounds(bounds);
        if (this.polyline !== null) {
          this.polylineGroup.clearLayers();
          this.polyline = L.polyline(coordinates, {
            color: 'red',
          }).addTo(this.polylineGroup);
        }
        this.polyline = L.polyline(coordinates, {
          color: 'red',
        }).addTo(this.polylineGroup);
        this.map.fitBounds(this.polyline.getBounds());
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    createModal(content) {
      const html = `
            <div id="myModal" class="modal">
                <div id="modal-content">
                    ${content}
                </div>
            </div>`;

      const center = this.map.getBounds().getCenter();
      this.openPopup(html, [center.lat, center.lng]);
    },

    addDrawControl() {
      if (this.treeLocations.length === 0 || this.createSector) {
        this.map.pm.addControls(this.pmControlConfig);
      } else {
        this.map.pm.addControls(this.pmControlConfigEdit);
      }
    },

    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (self.statusMeasureTool === true) {
            if (e.shape === 'Polygon') {
              self.areaLayer = e.layer.addTo(self.map);
              const toGeoJson = self.areaLayer.toGeoJSON();
              const area = turf.area(toGeoJson);
              const html = `<div class='popup-content'><h3>${self.$t(
                'Gis.Editor.PopupMeasureArea',
              )}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${(
                area / 10000
              ).toFixed(2)} ${self.$t(
                'Gis.Editor.PopupMeasureHas',
              )}</p></div>`;
              const popup = L.popup().setContent(html);
              self.areaLayerPopup = self.areaLayer.bindPopup(popup);
              self.areaLayer.openPopup();
              self.map.fitBounds(self.areaLayer.getBounds());
              self.map.pm.disableDraw();
            }
            if (e.shape === 'Line') {
              self.lineLayer = e.layer.addTo(self.map);
              // eslint-disable-next-line no-underscore-dangle
              if (self.lineLayer._latlngs.length > 2) {
                self.map.removeLayer(self.lineLayer);
              } else {
                const from = turf.point([
                  // eslint-disable-next-line no-underscore-dangle
                  self.lineLayer._latlngs[0].lat,
                  // eslint-disable-next-line no-underscore-dangle
                  self.lineLayer._latlngs[0].lng,
                ]);
                const to = turf.point([
                  // eslint-disable-next-line no-underscore-dangle
                  self.lineLayer._latlngs[1].lat,
                  // eslint-disable-next-line no-underscore-dangle
                  self.lineLayer._latlngs[1].lng,
                ]);
                const options = {
                  units: 'kilometers',
                };
                const distance = turf.distance(from, to, options);
                const html = `<div class='popup-content'><h3>${self.$t(
                  'Gis.Editor.PopupMeasureLineString',
                )}</h3><p>${self.$t(
                  'Gis.Editor.PopupMeasureResult',
                )}: ${distance.toFixed(2)} ${self.$t(
                  'Gis.Editor.PopupMeasureKm',
                )}</p></div>`;
                const popup = L.popup().setContent(html);
                self.lineLayerPopup = self.lineLayer.bindPopup(popup);
                self.lineLayer.openPopup();
                self.map.fitBounds(self.lineLayer.getBounds());
              }
            }
          } else {
            e.layer.type = 'Draw Layer';
            this.layerGroupParcel.addLayer(e.layer);
            this.map.pm.addControls(this.pmControlConfigEdit);
            this.setLocation(this.getNewLocation(e.layer, 0));
            this.layerParcelEvents();
          }
          // if (this.visibleSigpac) {
          //   this.intersectSIGPACLayer(e);
          // }
        } catch (error) {
          this.$f7.dialog.alert(e);
        } finally {
          self.$f7.preloader.hide();
        }
      });
      if (self.areaLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.areaLayer);
      }
      if (self.lineLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.lineLayer);
      }
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },

    // async intersectSIGPACLayer(e) {
    //   const layerToGeoJson = e.layer.toGeoJSON();
    //   const coords = [];
    //   coords.push(layerToGeoJson.geometry.coordinates[0]);
    //   let stringCoordinates = '';
    //   for (let i = 0; i < coords[0].length; i += 1) {
    //     stringCoordinates += `${coords[0][i][0]}%20${coords[0][i][1]},`;
    //   }
    //   const correctStringCoordinates = stringCoordinates.substring(0, stringCoordinates.length - 1);
    //   if (this.statusMeasureTool === false) {
    //     await this.fetchSigpacIntersect(correctStringCoordinates);
    //     const featureIntersectObject = [];
    //     for (let i = 0; i < this.sigpacIntersect.features.length; i += 1) {
    //       const intersectPolygon = {};
    //       const intersecPercentage = Number(((this.sigpacIntersect.features[i].properties.SupInterseccion * 100) / this.sigpacIntersect.features[i].properties.dn_surface).toFixed(2));
    //       intersectPolygon.intersecPercentage = intersecPercentage;
    //       intersectPolygon.idSIGPAC = {
    //         provincia: this.sigpacIntersect.features[i].properties.provincia,
    //         municipio: this.sigpacIntersect.features[i].properties.municipio,
    //         agregado: this.sigpacIntersect.features[i].properties.agregado,
    //         zona: this.sigpacIntersect.features[i].properties.zona,
    //         poligono: this.sigpacIntersect.features[i].properties.poligono,
    //         parcela: this.sigpacIntersect.features[i].properties.parcela,
    //         recinto: this.sigpacIntersect.features[i].properties.recinto,
    //       };
    //       featureIntersectObject.push(intersectPolygon);
    //     }
    //     console.log(featureIntersectObject);
    //   }
    // },

    getNewLocation(layer, index) {
      const polygon = layer.toGeoJSON();
      let coordinates = [];
      // eslint-disable-next-line no-underscore-dangle
      if (typeof layer._latlngs !== 'undefined') {
        coordinates = this.$helpers.getCoordinates(layer.getLatLngs()[index]);
        coordinates[0].push(coordinates[0][0]);
      } else {
        // eslint-disable-next-line no-underscore-dangle
        coordinates = this.$helpers.getCoordinates(
          layer.pm._layers[0].getLatLngs()[0],
        );
        coordinates[0].push(coordinates[0][0]);
      }
      return {
        newCoordinates: coordinates,
        index,
        area: turf.area(polygon) / this.hectareToM2,
        centroide: layer.getBounds().getCenter(),
      };
    },

    layerGroupRemovebyId(id) {
      const self = this;
      this.layerGroupParcel.eachLayer((layer) => {
        // eslint-disable-next-line no-underscore-dangle
        if (layer._leaflet_id === id) {
          self.layerGroupParcel.removeLayer(layer);
        }
        // eslint-disable-next-line no-underscore-dangle
      });
      if (this.geoJsonUnionLayer != null) {
        this.geoJsonUnionLayer.eachLayer((layer) => {
          // eslint-disable-next-line no-underscore-dangle
          if (layer._leaflet_id === id) {
            self.geoJsonUnionLayer.removeLayer(layer);
          }
        });
      }
    },

    layerParcelEvents() {
      const self = this;
      this.layerGroupParcel.eachLayer((layer) => {
        layer.on({
          mouseout(e) {
            // self.map.closePopup(e);
          },
          'pm:remove': (e) => {
            self.map.pm.disableGlobalRemovalMode();
            // eslint-disable-next-line no-underscore-dangle
            const layerId = e.layer._leaflet_id;
            if (e.target.type === 'Dissolve Layer') {
              self.layerGroupRemovebyId(layerId + 1);
              self.unionGeoJson.addTo(self.map);
            }

            if (e.target.type === 'Draw Layer') {
              self.layerGroupRemovebyId(layerId);
              if (self.layerGroupParcel.getLayers().length === 0) {
                self.map.pm.addControls(self.pmControlConfig);
                self.resetAll();
              }
            } else {
              self.layerGroupRemovebyId(layerId + 1);

              if (self.layerGroupParcel.getLayers().length === 0) {
                self.map.pm.addControls(self.pmControlConfig);
                self.resetAll();
              }
            }
          },
          'pm:edit': (e) => {
            // if (self.visibleSigpac) {
            //   self.intersectSIGPACLayer(e);
            // }
            const editLayer = e.layer.toGeoJSON();
            if (e.layer.type === 'Draw Layer') {
              self.setLocation(self.getNewLocation(e.layer, 0));
            } else {
              self.featureInfoSigpac.features[0].geometry = editLayer.geometry;
            }
          },
          'pm:enable': () => {
            this.drawMode = true;
          },
          'pm:disable': () => {
            this.drawMode = false;
          },
        });
      });
    },

    createTemporalLayer(shape) {
      const geojsonFeature = {
        type: 'Feature',
        properties: {
          explotation: shape.feature.properties.explotation,
          id: shape.feature.properties.id,
          parcel: shape.feature.properties.parcel,
        },
        geometry: {
          type: 'Polygon',
          coordinates: shape.feature.geometry.coordinates,
        },
      };

      this.copyLayer = L.geoJson(geojsonFeature);
    },

    controlLayerEvents() {
      this.map.on('overlayadd', (e) => {
        if (e.name === 'SIGPAC') {
          this.visibleSigpac = true;
          this.SIGPACSearch.addTo(this.map);
          this.unionGeoJson.addTo(this.map);
        }
      });
      this.map.on('overlayremove', (e) => {
        if (e.name === 'SIGPAC') {
          this.visibleSigpac = false;
          this.SIGPACSearch.remove(this.map);
          this.unionGeoJson.remove(this.map);
          if (this.statusUnionTool) {
            this.statusUnionTool = false;
            this.layerGroupParcel.clearLayers();
            this.geoJsonUnionLayer.clearLayers();
            this.geoJsonUnionLayer.remove();
            this.geoJsonUnionLayer = null;
            this.desactiveUnion.remove(this.map);
            this.confirmUnion.remove(this.map);
            this.arrayBounds = [];
            this.featuresSelected = [];
            this.bufferedSelected = [];

            this.map.pm.addControls(this.pmControlConfig);
            this.resetAll();

            this.layerGroupParcel.addTo(this.map);
            // this.addMeasureToolButton();
          }
          if (this.join !== null) {
            this.unionGeoJson.remove(this.map);
          }
        }
      });
    },

    async mapEvents() {
      // const coordSearchButton = document.getElementById('coords_search_button');
      // const coordSearchInputLat = document.getElementById('coord_search_input_lat');
      // const coordSearchInputLng = document.getElementById('coord_search_input_lng');
      // coordSearchButton.addEventListener('click', (e) => {
      //   L.DomEvent.stopPropagation(e);
      //   this.map.setView([coordSearchInputLat.value, coordSearchInputLng.value], this.zoom);
      //   this.setMarker(coordSearchInputLat.value, coordSearchInputLng.value);
      // });
      const overlaysGroup = L.layerGroup();

      await this.showSigpacLayer(overlaysGroup);
      this.map.on('click', async (e) => {
        if (this.drawMode) return;
        if (!this.visibleSigpac) return;
        if (this.map.pm.globalRemovalModeEnabled()) return;
        if (this.statusSelectFeatureTool) return;
        L.DomEvent.stopPropagation(e);
        const BBOX = `${e.latlng.lng - 0.00000002},${e.latlng.lat
            - 0.00000002},${e.latlng.lng + 0.00000002},${e.latlng.lat
            + 0.00000002}`;
        const queryParams = {
          // eslint-disable-next-line no-underscore-dangle
          LowerCorner: `${`${this.map.getBounds()._southWest.lng} `}${`${
            this.map.getBounds()._southWest.lat
          } `}`,
          // eslint-disable-next-line no-underscore-dangle
          UpperCorner: `${`${this.map.getBounds()._northEast.lng} `}${`${
            this.map.getBounds()._northEast.lat
          } `}`,
          bboxgetfeatureinfo: BBOX,
        };
        const finalUrl = Api.getGeoServerFeatureInfo()
            + decodeURIComponent(
              L.Util.getParamString(queryParams, Api.getGeoServerFeatureInfo()),
            );
        try {
          this.$f7.preloader.show();
          await this.fetchFeatureInfoSigpac(finalUrl);
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
        }
        this.showSigpacPopup(this.featureInfoSigpac, e);
      });
      this.map.on('moveend', async () => {
        if (!this.visibleSigpac) return;
        await this.showSigpacLayer(overlaysGroup);
      });
    },

    async showSigpacLayer(overlaysGroup) {
      const sSIGPACLayerName = 'SIGPAC';
      let url = null;

      const defaultParameters = {
        // eslint-disable-next-line no-underscore-dangle
        LowerCorner: `${this.map.getBounds()._southWest.lng} ${
          this.map.getBounds()._southWest.lat
        }`,
        // eslint-disable-next-line no-underscore-dangle
        UpperCorner: `${this.map.getBounds()._northEast.lng} ${
          this.map.getBounds()._northEast.lat
        }`,
        width: this.map.getSize().x,
        height: this.map.getSize().y,
      };
      const parameters = L.Util.extend(defaultParameters);

      url = Api.getGeoServerSigpacMap() + L.Util.getParamString(parameters);
      try {
        this.$f7.preloader.show();
        if (this.statusUnionTool && this.map.getZoom() >= 16) {
          if (this.oldImageSigpac !== null) {
            overlaysGroup.clearLayers();
          }
          // eslint-disable-next-line no-underscore-dangle
          if (
            this.layerGroupParcel
              .getLayers()[0]
              .pm._layers[0].feature.properties.hasOwnProperty(
                'SIGPACReference',
              )
          ) {
            const SIGPAClayer = this.layerGroupParcel.getLayers()[0];
            // eslint-disable-next-line no-underscore-dangle
            const SIGPACfeature = SIGPAClayer.pm._layers[0].feature;
            // eslint-disable-next-line no-underscore-dangle
            const SIGPACgeometry = SIGPAClayer.pm._layers[0].feature.geometry;
            const SIGPAClayerGeoJson = L.geoJson(SIGPACgeometry);
            this.geoJsonUnionLayer.clearLayers();
            await this.fetchSigpacWFS(defaultParameters);
            this.geoJsonUnionLayer.addData(this.sigpacWFS.features);
            this.geoJsonUnionLayer.addData(SIGPACfeature);
            this.createBuffer(SIGPACfeature);
            this.addLayers(SIGPACfeature);
            this.addBounds(SIGPAClayerGeoJson);
            this.setStyleLayer(SIGPAClayer, this.stylelayer.highlight);
          } else {
            this.geoJsonUnionLayer.clearLayers();
            await this.fetchSigpacWFS(defaultParameters);
            this.geoJsonUnionLayer.addData(this.sigpacWFS.features);
          }
        } else {
          if (this.geoJsonUnionLayer !== null) {
            this.geoJsonUnionLayer.clearLayers();
          }
          await this.fetchSigpacMap(url);
          const imageBounds = [
            // eslint-disable-next-line no-underscore-dangle
            [
              this.map.getBounds()._southWest.lat,
              this.map.getBounds()._southWest.lng,
            ],
            // eslint-disable-next-line no-underscore-dangle
            [
              this.map.getBounds()._northEast.lat,
              this.map.getBounds()._northEast.lng,
            ],
          ];
          if (this.oldImageSigpac) {
            this.controlLayer.removeLayer(this.oldImageSigpac);
          }

          const imageOverlayNew = L.imageOverlay(
            this.sigpacMap.url,
            imageBounds,
          );
          overlaysGroup.clearLayers();
          imageOverlayNew.addTo(overlaysGroup);
          this.oldImageSigpac = overlaysGroup;
          this.controlLayer.addOverlay(overlaysGroup, sSIGPACLayerName);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    showSigpacPopup(data, e) {
      const features = data.features[0];
      const { properties } = features;
      const sigpacReference = `${properties.provincia}${properties.municipio}${properties.agregado}${properties.zona}${properties.poligono}${properties.parcela}${properties.recinto}`;
      const before = `<span><b>${this.$t(
        'Gis.SigpacReference',
      )}:</b> ${sigpacReference} </span>\n`;
      if (
        this.layerGroupParcel.getLayers().length === 0
          || this.statusUnionTool === true
      ) {
        const after = `<div class="button-container"></div><button id="drawUHCbutton">${this.$t(
          'Gis.Editor.PopupGetGeometry',
        )}</button>`;

        this.openLayerDataPopup(properties, e, { before, after });
        const button = document.getElementById('drawUHCbutton');

        button.addEventListener('click', () => {
          const SIGPAClayer = L.geoJson([features.geometry]);
          // eslint-disable-next-line no-underscore-dangle
          SIGPAClayer.pm._layers[0].type = 'SIGPAC Layer';
          // eslint-disable-next-line no-underscore-dangle
          SIGPAClayer.pm._layers[0].feature.properties = features.properties;
          // eslint-disable-next-line no-underscore-dangle
          SIGPAClayer.pm._layers[0].feature.properties.SIGPACReference = sigpacReference;
          // eslint-disable-next-line no-underscore-dangle
          this.SIGPACLayerId = SIGPAClayer._leaflet_id;
          this.layerGroupParcel.addLayer(SIGPAClayer);
          this.map.closePopup();
          this.setLocation(this.getNewLocation(SIGPAClayer, 0));
          this.layerParcelEvents();
          this.map.pm.addControls(this.pmControlConfigEdit);
          if (this.geoJsonUnionLayer !== null) {
            this.geoJsonUnionLayer.addData(features.geometry);
            if (this.layerGroupParcel.getLayers().length >= 1) {
              this.map.pm.addControls(this.pmControlConfigEdit);
              this.resetAll();
            }
          }
        });
      } else {
        this.openLayerDataPopup(properties, e, { before });
      }
    },

    openLayerDataPopup(properties, e, extra = {}) {
      let htmlString = "<div class='popup-content'>";
      htmlString += extra.before || '';
      let listHtml = '<ul>';
      listHtml += this.getLayerDataPropertiesHtml(properties);
      listHtml += '</ul>';
      htmlString += `${listHtml}</div>`;
      htmlString += extra.after || '';
      this.openPopup(htmlString, e.latlng);
    },

    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (
          properties[property] == null
            || property === 'dn_surface'
            || property === 'uso_sigpac'
            || property === 'pend_media'
            || property === 'coef_rega'
        ) { continue; }
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(properties[property]);
        } else {
          listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },

    openPopup(html, latlng) {
      L.popup({
        maxWidth: 40000,
        minWidth: 200,
        offset: [0, 6],
        className: 'SIGPACPopup',
      })
        .setLatLng(latlng)
        .setContent(html)
        .openOn(this.map);
    },

    async dissolveSelectedLayers(featureCollection) {
      try {
        this.$f7.preloader.show();
        const stringJSON = JSON.stringify(featureCollection);
        if (stringJSON.length <= 80000) {
          await this.fetchDissolveFeatures(stringJSON);
        } else {
          this.statusCalldissolveSelectedLayers = false;
          this.$f7.dialog.alert(
            this.$t('Gis.Alerts.NumberExceededGeometries'),
          );
        }
      } catch (error) {
        // this.$f7.dialog.alert(this.$t(`${error}`));
        this.$f7.dialog.alert(this.$t('Gis.Alerts.NumberExceededGeometries'));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('GisEditor', [
      'setLocation',
      'resetAll',
      'fetchFeatureInfoSigpac',
      'fetchSigpacMap',
      'fetchSigpacWFS',
      'fetchSigpacIntersect',
      'fetchDissolveFeatures',
    ]),
    ...mapActions('Gis', ['fetchCurrentGeojsonFeature']),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
