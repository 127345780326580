import plantationService from '@/services/plantationService';
import productFertilizerService from '@/services/productFertilizerService';
import { fertilizationPeriod } from '@/services/fertilizationService';

import numeral from 'numeral';
import moment from 'moment';
import store from '../../../store/index';

const numeralFormat = '0,0.00';

export default class ProductDataFormatter {
  constructor(fertilizationData) {
    this.fertilization = fertilizationData;
    this.plantation = null;
    this.productResume = null;
    this.stackProduct = [];
  }

  async buildProductResumeData() {
    this.plantation = store.getters['RegisterPlantation/actualPlantation'];
    this.productResume = this.fertilization.contribution_products.map((product) => {
      const dosageUnit = product.periods.reduce((a, b) => a + b, 0);

      const dosagePlant = this.plantation.plants_density > 0 ? numeral((dosageUnit / this.plantation.plants_density) * 1000).format(numeralFormat) : numeral(0).format(numeralFormat);
      const costPlant = this.plantation.plants > 0 ? numeral(product.total_cost / this.plantation.plants).format(numeralFormat) : numeral(0).format(numeralFormat);

      return {
        id: product.product,
        product: product.product_name,
        dosageUnit: numeral(dosageUnit).format(numeralFormat),
        dosagePlant,
        dosageTotal: numeral(dosageUnit * this.fertilization.area).format(numeralFormat),
        price: numeral(product.price).format(numeralFormat),
        costUnit: numeral(product.unit_cost).format(numeralFormat),
        costPlant,
        costTotal: numeral(product.total_cost).format(numeralFormat),
      };
    });
    const timeValues = this.calculateLabels();

    const notFoundProducts = [];
    await Promise.all(this.productResume.map(async (prod, i) => {
      let product;
      try {
        product = await productFertilizerService.getById(prod.id);
      } catch (e) {
        notFoundProducts.push({ id: prod.id, name: prod.product });
        product = prod;
        product.name = prod.product;
      }

      this.productResume[i].status = product?.status ?? false;

      if (product) {
        this.productResume[i].product = product.name;

        this.fertilization.contribution_products[i].periods.forEach((period, indexPeriod) => {
          this.stackProduct.push({
            productName: product.name,
            monthWeek: timeValues[indexPeriod],
            value: period,
          });
        });
      }
    }));
    this.productResume.push({
      product: 'Total',
      dosageUnit: '',
      dosagePlant: '',
      dosageTotal: '',
      price: '',
      status: '',
      costUnit: numeral(this.sumValue('costUnit')).format(numeralFormat),
      costPlant: numeral(this.sumValue('costPlant')).format(numeralFormat),
      costTotal: numeral(this.sumValue('costTotal')).format(numeralFormat),
    });

    if (notFoundProducts.length > 0) throw new Error(JSON.stringify(notFoundProducts));
    return this.productResume;
  }

  calculateLabels() {
    const dateStart = moment(this.fertilization.init_date, 'YYYY-MM-DD');
    const dateEnd = moment(this.fertilization.end_date, 'YYYY-MM-DD');
    const timeValues = [];
    switch (this.fertilization.period) {
      case fertilizationPeriod.WEEKLY:
        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
          const varName = `${dateStart.isoWeek()} - ${dateStart.isoWeekYear()}`;
          timeValues.push(varName);
          dateStart.add(1, 'week');
        }
        break;
      case fertilizationPeriod.MONTHLY:
      default:
        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
          const varName = `${dateStart.format('MMM')} - ${dateStart.format('yy')}`;
          timeValues.push(varName);
          dateStart.add(1, 'month');
        }
        break;
    }
    return timeValues;
  }

  calculateTotals() {
    this.productResume.push({
      product: this.$t('nutritionDetail.productResume.total'),
      dosageUnit: '',
      dosagePlant: '',
      dosageTotal: '',
      price: '',
      costUnit: numeral(this.sumValue('costUnit')).format(numeralFormat),
      costPlant: numeral(this.sumValue('costPlant')).format(numeralFormat),
      costTotal: numeral(this.sumValue('costTotal')).format(numeralFormat),
    });
  }

  numOr0(number) {
    return number === undefined ? 0 : numeral(number).value();
  }

  sumValue(prop) {
    return this.productResume.reduce(
      (a, b) => ({ [prop]: this.numOr0(a[prop]) + this.numOr0(b[prop]) }),
      { [prop]: this.numOr0(0) },
    )[prop];
  }
}
