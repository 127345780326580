import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    observationsTemplates: [],
    currentObservationTemplate: {},
    currentObservationTemplateId: '',
    currentAttribute: {},
    templatesCategories: [],
    showAttributePopup: false,
    readOnly: false,
  },
  actions: {
    async fetchObservationsTemplates({ commit }) {
      try {
        const xhr = await Api.fetchObservationsTemplates();
        const observationsTemplates = JSON.parse(xhr.response).data;
        commit('fetchObservationsTemplates', observationsTemplates);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTemplateById({ commit }, observationTemplateId) {
      try {
        const xhr = await Api.getTemplateById(observationTemplateId);
        const observationTemplate = JSON.parse(xhr.response);
        commit('setCurrentObservationTemplate', observationTemplate);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createObservationTemplate(_, observationTemplate) {
      try {
        await Api.createObservationTemplate(observationTemplate);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateObservationTemplate(_, { observationTemplateId, observationTemplate }) {
      try {
        await Api.updateObservationTemplate(observationTemplateId, observationTemplate);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteObservationTemplateById(_, observationTemplateId) {
      try {
        await Api.deleteObservationTemplateById(observationTemplateId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchTemplatesCategories({ commit }) {
      try {
        const xhr = await Api.getCategoriesOfTemplates();
        const templatesCategories = JSON.parse(xhr.response).data;
        commit('fetchTemplatesCategories', templatesCategories);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    initCurrentObservationTemplate({ commit }) {
      commit('initCurrentObservationTemplate');
    },
    setCurrentObservationTemplateId({ commit }, observationTemplateId) {
      commit('setCurrentObservationTemplateId', observationTemplateId);
    },
    updateCurrentObservationTemplate({ commit }, { attributeKey, value }) {
      commit('updateCurrentObservationTemplate', { attributeKey, value });
    },
    initCurrentAttribute({ commit }) {
      commit('initCurrentAttribute');
    },
    initDynamicValuesCurrentAttribute({ commit }, value) {
      commit('initDynamicValuesCurrentAttribute', value);
    },
    setCurrentAttribute({ commit }, value) {
      commit('setCurrentAttribute', value);
    },
    updateCurrentAttribute({ commit }, { attributeKey, value }) {
      commit('updateCurrentAttribute', { attributeKey, value });
    },
    addAttribute({ commit }) {
      commit('addAttribute');
    },
    deleteAttribute({ commit }, value) {
      commit('deleteAttribute', value);
    },
    setShowAttributePopup({ commit }, value) {
      commit('setShowAttributePopup', value);
    },
    setReadOnly({ commit }, value) {
      commit('setReadOnly', value);
    },
  },
  mutations: {
    fetchObservationsTemplates(state, value) {
      state.observationsTemplates = value;
    },
    setCurrentObservationTemplate(state, value) {
      state.currentObservationTemplate = value;
    },
    initCurrentObservationTemplate(state) {
      state.currentObservationTemplate = {
        category: '',
        contact: '',
        form: [],
        subcategory: '',
      };
    },
    setCurrentObservationTemplateId(state, value) {
      state.currentObservationTemplateId = value;
    },
    updateCurrentObservationTemplate(state, { attributeKey, value }) {
      state.currentObservationTemplate[attributeKey] = value;
    },
    fetchTemplatesCategories(state, value) {
      state.templatesCategories = value;
    },
    initCurrentAttribute(state) {
      state.currentAttribute = {
        field: null,
        description: '',
        type: '',
        required: false,
        allow_null: true,
        allow_blank: null,
        max_length: null,
        min_length: null,
        max_value: null,
        min_value: null,
        max_digits: null,
        decimal_places: null,
        choices: [],
        default: null,
      };
    },
    initDynamicValuesCurrentAttribute(state, value) {
      state.currentAttribute.max_length = null;
      state.currentAttribute.min_length = null;
      state.currentAttribute.max_value = null;
      state.currentAttribute.min_value = null;
      state.currentAttribute.decimal_places = null;
      state.currentAttribute.choices = [];
      state.currentAttribute.default = false;
      if (value === 'FLOAT') {
        state.currentAttribute.max_digits = 15;
      } else {
        state.currentAttribute.max_digits = null;
      }
      if (value === 'STRING') {
        state.currentAttribute.allow_blank = true;
      } else {
        state.currentAttribute.allow_blank = null;
      }
      if (value === 'BOOLEAN') {
        state.currentAttribute.default = true;
      } else {
        state.currentAttribute.default = null;
      }
    },
    setCurrentAttribute(state, value) {
      state.currentAttribute = value;
    },
    updateCurrentAttribute(state, { attributeKey, value }) {
      state.currentAttribute[attributeKey] = value;
    },
    addAttribute(state) {
      let posToUpdate = -1;
      for (let i = 0; i < state.currentObservationTemplate.form.length; i += 1) {
        if (state.currentObservationTemplate.form[i].field === state.currentAttribute.field) {
          posToUpdate = i;
        }
      }
      if (posToUpdate !== -1) {
        state.currentObservationTemplate.form[posToUpdate] = state.currentAttribute;
      } else {
        state.currentObservationTemplate.form.push(state.currentAttribute);
      }
    },
    deleteAttribute(state, value) {
      if (typeof value !== 'undefined') {
        state.currentObservationTemplate.form = state.currentObservationTemplate.form.filter(
          (attribute) => attribute.field !== value.data.field,
        );
      }
    },
    setShowAttributePopup(state, value) {
      state.showAttributePopup = value;
    },
    setReadOnly(state, value) {
      state.readOnly = value;
    },
  },
};
