<template>
  <div class="content-devx">
    <f7-block-title>
      {{ $t("Searcher.observations.observations_title") }}
    </f7-block-title>
    <DxDataGrid
      id="ObservationsDatagrid-datagrid"
      :data-source="customStore"
      :remote-operations="true"
      :column-min-width="45"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
    >
      <DxPaging :page-size="gridSettings.pageSize" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxColumn
        data-field="category"
        :min-width="100"
        :caption="$t('Searcher.observations.category')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="subcategory"
        :min-width="100"
        :caption="$t('Searcher.observations.subcategory')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="date"
        :caption="$t('Searcher.observations.date')"
        :allow-header-filtering="false"
        alignment="left"
        :calculate-display-value="$helpers.dxDateFormatting"
      />
      <DxColumn
        type="buttons"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton name="find" icon="find" :on-click="viewObservation" />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import settings from "../../../../js/commonSettings";

import {
  DxDataGrid,
  DxPaging,
  DxPager,
  DxColumn,
  DxButton,
} from "devextreme-vue/data-grid";
import { mapActions, mapState } from "vuex";

export default {
  name: "HomeObservations",
  components: {
    DxDataGrid,
    DxPaging,
    DxPager,
    DxColumn,
    DxButton,
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      customStore: null,
    };
  },
  computed: {
    ...mapState("Plantation", ["currentPlantation"]),
    gridSettings() {
      return settings.datagrid;
    },
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.customStore = await this.getStore({
        type: "observations",
        data: {
          companyId: this.currentPlantation.companyId,
          currentPlantationId: this.currentPlantation.id,
        },
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        "error",
        3000
      );
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions("Pagination", ["getStore"]),
    ...mapActions("SearcherObservations", ["setIdCurrentObservation"]),
    viewObservation(e) {
      this.setIdCurrentObservation(e.row.data.id);
      this.$f7.views.main.router.navigate("/viewObservation/", {
        reloadCurrent: true,
      });
    },
  },
};
</script>
