import moment from 'moment';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    task: [],
    taskActivity: [],
    taskActivitySelected: '',
    taskDefinition: [],
    taskDefinitionSelected: '',
    showPopUp: false,
    actualTask: {},
    initDate: '',
    endDate: '',
    description: '',
    isEdit: false,
    idTaskSelected: '',
    idCompany: '',
    idPlantation: '',
    isView: false,
  },
  mutations: {
    GET_TASKS(state, value) {
      state.tasks = value;
    },
    GET_TASKS_ACTIVITY(state, value) {
      state.taskActivity = value;
    },
    GET_TASKS_DEFINITION(state, value) {
      state.taskDefinition = value;
    },
    SET_SHOW_POPUP(state, value) {
      state.showPopUp = value;
    },
    SET_ACTUAL_TASK(state, value) {
      state.actualTask = value;
    },
    SET_TASK_ACTIVITY_SELECTED(state, value) {
      state.taskActivitySelected = value;
    },
    SET_TASK_DEFINITION_SELECTED(state, value) {
      state.taskDefinitionSelected = value;
    },
    SET_INIT_DATE(state, value) {
      state.initDate = value;
    },
    SET_END_DATE(state, value) {
      state.endDate = value;
    },
    SET_DESCRIPTION(state, value) {
      state.description = value;
    },
    UPDATE_VALUES(state, value) {
      state.taskActivitySelected = value.activity;
      state.taskDefinitionSelected = value.task_definition;
      state.initDate = value.init_date;
      state.endDate = value.end_date;
      state.description = value.description;
    },
    SET_IS_EDIT(state, value) {
      state.isEdit = value;
    },
    SET_ID_TASK_SELECTED(state, value) {
      state.idTaskSelected = value;
    },
    SET_ID_PLANTATION(state, value) {
      state.idPlantation = value;
    },
    SET_ID_COMPANY(state, value) {
      state.idCompany = value;
    },
    SET_IS_VIEW(state, value) {
      state.isView = value;
    },
  },
  actions: {
    async getTasks({ commit, state }, idPlantation) {
      try {
        const CompanyId = state.idCompany;
        const xhr = await Api.getTasksForCompany(CompanyId, idPlantation);
        const tasks = JSON.parse(xhr.response).data;
        const tasksFormatted = [];
        for (const task of tasks) {
          tasksFormatted.push({
            id: task.id,
            activity: task.activity,
            name: task.name,
            init_date: task.init_date,
            end_date: task.end_date,
          });
        }
        commit('GET_TASKS', tasksFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTaskById({ commit, state }, id) {
      try {
        const CompanyId = state.idCompany;
        const xhr = await Api.getTaskById(CompanyId, id);
        const task = JSON.parse(xhr.response);
        commit('UPDATE_VALUES', task);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTasksForActivity({ commit }) {
      try {
        const xhr = await Api.getTasksActivity();
        const tasksActivity = JSON.parse(xhr.response).data;
        commit('GET_TASKS_ACTIVITY', tasksActivity);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTasksForDefinition({ commit }, taskSelected) {
      try {
        const xhr = await Api.getTasksDefinition(taskSelected);
        const tasksDefinition = JSON.parse(xhr.response).data;
        commit('GET_TASKS_DEFINITION', tasksDefinition);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createTask({ state }) {
      try {
        const CompanyId = state.idCompany;
        await Api.createNewTaskByActivity(CompanyId,
          {
            init_date: state.initDate,
            description: state.description,
            end_date: state.endDate,
            task_definition: state.taskDefinitionSelected,
            plantation: state.idPlantation,
          });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateTask({ state }) {
      try {
        const CompanyId = state.idCompany;
        await Api.updateTaskByCompany(CompanyId,
          {
            init_date: state.initDate,
            description: state.description,
            end_date: state.endDate,
            task_definition: state.taskDefinitionSelected,
            plantation: state.idPlantation,
          },
          state.idTaskSelected);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteTask({ state }, idTask) {
      try {
        const CompanyId = state.idCompany;
        await Api.deleteTaskByCompany(CompanyId, idTask);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setShowPopUp(context, value) {
      context.commit('SET_SHOW_POPUP', value);
    },
    setActualTask(context, value) {
      context.commit('SET_ACTUAL_TASK', value);
    },
    seTaskActivitySelected(context, value) {
      context.commit('SET_TASK_ACTIVITY_SELECTED', value);
    },
    seTaskDefinitionSelected(context, value) {
      context.commit('SET_TASK_DEFINITION_SELECTED', value);
    },
    setEndDate(context, value) {
      context.commit('SET_END_DATE', value);
    },
    setInitDate(context, value) {
      context.commit('SET_INIT_DATE', value);
    },
    setDescription(context, value) {
      context.commit('SET_DESCRIPTION', value);
    },
    setIsEdit(context, value) {
      context.commit('SET_IS_EDIT', value);
    },
    setIdTaskSelected(context, value) {
      context.commit('SET_ID_TASK_SELECTED', value);
    },
    setIdPlantationTask(context, value) {
      context.commit('SET_ID_PLANTATION', value);
    },
    setIdCompanyTask(context, value) {
      context.commit('SET_ID_COMPANY', value);
    },
    setIsView(context, value) {
      context.commit('SET_IS_VIEW', value);
    },
    initStore({ commit }) {
      commit('SET_TASK_ACTIVITY_SELECTED', '');
      commit('SET_TASK_DEFINITION_SELECTED', '');
      commit('SET_END_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_INIT_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_SHOW_POPUP', false);
      commit('SET_ACTUAL_TASK', {});
    },
    setTasks(context, value) {
      context.commit('GET_TASKS', value);
    },
  },
};
