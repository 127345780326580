<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_analisys')} > ${$t('Searcher.irrigation.title')}`" />
    <AnalysisIrrigation :plantations="plantationFormatted" />
  </f7-page>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import AnalysisIrrigation from '../../components/analisys/irrigation/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'IrrigationAnalysisPage',
  components: {
    navbar,
    AnalysisIrrigation,
  },
  data() {
    return {
      selectedTreatmentPlanIds: [],
      chartData: [],
      plansOnDisplay: [],
      loading: false,
      plantationFormatted: [],
    };
  },
  computed: {
    ...mapState('Plantation', ['plantations', 'treeLocations']),
    ...mapState('treatment', ['treatmentPlans', 'treatmentPlansDetailById']),
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchPlantations();
      for (const plantation of this.plantations) {
        this.plantationFormatted.push({
          id: plantation.id,
          companyId: plantation.company_id,
          company: plantation.company,
          name: plantation.name,
          province: plantation.province,
          explotation: plantation.explotation,
          parcel: plantation.parcel,
          sector: plantation.sector,
          campaign: plantation.campaign,
          variety: plantation.variety,
          initDate: plantation.init_date,
          endDate: plantation.end_date,
          species: plantation.species,
          area: plantation.area,
          coordinates: plantation.coordinates,
          sectorId: plantation.sector_id,
        });
      }
    } catch (error) {
      this.$f7.dialog.alert(this.$t(error));
    }
    this.loading = false;
  },
  methods: {
    // FIXME: Maybe move this to a service?
    // COPY from: src/components/wizardTreatment/treatmentDetailPlan/treatmentDetailPlan.js

    async recalculateGraphData() {
      const newChartData = [];

      this.loading = true;

      let currentPlans = [];

      for (const id of this.selectedTreatmentPlanIds) {
        try {
          await this.getTreatmentPlanDetails({ treatmentId: id });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }

        currentPlans.push(this.treatmentPlansDetailById[id]);
      }

      // Se ordenan los planes usando el índce de la semana, es necesario para que la gráfica salga ordenada
      currentPlans = currentPlans.sort((planA, planB) => {
        const monthPlanA = moment(planA.init_date).week();
        const monthPlanB = moment(planB.init_date).week();
        return monthPlanA - monthPlanB;
      });

      for (const current of currentPlans) {
        const timeValuesTmp = current.products.map((product, index) => {
          const timeValues = this.getTimeValues(
            moment(current.init_date, 'YYYY/MM/DD'),
            moment(current.end_date, 'YYYY/MM/DD'),
            current.period,
          );
          let counter = -1;
          for (const prop in timeValues) {
            if (Object.hasOwnProperty.call(timeValues, prop)) {
              timeValues[prop] = product.periods[counter += 1];
            }
          }
          return timeValues;
        });

        current.products.forEach((item, index) => {
          const itemValue = timeValuesTmp[index];
          for (const prop in itemValue) {
            if (Object.hasOwnProperty.call(itemValue, prop)) {
              newChartData.push({
                productName: item.product,
                monthWeek: prop,
                value: itemValue[prop],
              });
            }
          }
        });
      }

      this.loading = false;

      this.chartData = newChartData;
    },

    selectPeriod(period) {
      this.currentPeriod = period;
      this.selectedTreatmentPlanIds = [];

      this.plansOnDisplay = this.treatmentPlans.data.filter((plan) => plan.period === this.currentPeriod);

      this.recalculateGraphData();
    },

    // -----------------------
    // Selection Helpers
    // -----------------------
    isTreatmentselectedTreatmentPlanId(treatmentPlanId) {
      for (const id of this.selectedTreatmentPlanIds) {
        if (treatmentPlanId === id) {
          return true;
        }
      }
      return false;
    },

    selectTreatmentPlan(treatmentPlanId) {
      this.selectedTreatmentPlanIds.push(treatmentPlanId);
      this.recalculateGraphData();
    },

    deselectTreatmentPlan(treatmentPlanId) {
      const index = this.selectedTreatmentPlanIds.indexOf(treatmentPlanId);
      if (index > -1) {
        this.selectedTreatmentPlanIds.splice(index, 1);
      }
      this.recalculateGraphData();
    },

    clearSelection() {
      this.selectedTreatmentPlanIds = [];
      this.recalculateGraphData();
    },

    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({ location: 'before', template: 'periodFilterTemplate' });
    },
    ...mapActions('treatment', ['getTreatmentDataForAnalisis', 'getTreatmentPlanDetails']),
    ...mapActions('Plantation', ['fetchPlantations']),
  },
};
</script>

<style>
</style>
