import { render, staticRenderFns } from "./elementResume.html?vue&type=template&id=59047082&scoped=true&"
import script from "./elementResume.js?vue&type=script&lang=js&"
export * from "./elementResume.js?vue&type=script&lang=js&"
import style0 from "./elementResume.scss?vue&type=style&index=0&id=59047082&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59047082",
  null
  
)

export default component.exports