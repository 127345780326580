import { mapActions, mapGetters, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';

export default {
  name: 'stepper-navigation',
  components: { DxButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    alertMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('treatmentStepper', [
      'treatmentSteps',
      'currentStep',
      'stepIndex',
    ]),
    ...mapState('Plantation', ['isPlantationSelected', 'oldPlantation']),
    ...mapState('treatment', ['editingFromContextMenu']),
  },
  methods: {
    getNextLabel() {
      if (this.stepIndex === this.treatmentSteps.length - 1) {
        return this.$t('Utilities.stepperForm.save');
      }
      return this.$t('Utilities.stepperForm.next');
    },
    nextStep() {
      if (this.disabled) {
        this.$notifyDX(
          {
            message: this.alertMessage,
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }

      if (this.stepIndex === this.treatmentSteps.length - 1) {
        this.$emit('save');
        return;
      }
      this.next();
      this.$f7.views.main.router.navigate(this.currentStep.page, {
        reloadCurrent: true,
      });
    },
    backStep() {
      if (this.isPlantationSelected && this.stepIndex === 0) {
        this.setIsCopying(false);
        this.setCurrentPlantation(this.oldPlantation);
        this.$f7.views.main.router.navigate('/viewCopyTreatmentPlan/', {
          reloadCurrent: true,
        });
        return;
      }
      if (this.stepIndex === 0) {
        this.setDisplayConfirmExitPopup(true);

        if (this.editingFromContextMenu !== null) {
          this.setRouteToGo(
            `/utilities/treatment/treatment-detail-plan/${this.editingFromContextMenu}`,
          );
          this.setTabToGo('treatment');
          return;
        }

        this.setRouteToGo('/searcher/treatment/');
        this.setTabToGo('treatment');
        return;
      }
      this.back();
      this.$f7.views.main.router.navigate(this.currentStep.page, {
        reloadCurrent: true,
      });
    },
    ...mapActions('treatmentStepper', ['next', 'back', 'setIsCopying']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
    ...mapActions('treatment', [
      'setDisplayConfirmExitPopup',
      'setRouteToGo',
      'setEditingFromContextMenu',
    ]),
    ...mapActions('Utilities', ['setTabToGo']),
  },
};
