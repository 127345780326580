<template>
  <f7-row no-gap>
    <f7-col>
      <div
        v-if="!loadedGIS || currentIndexes[`${mainMap}`] === ''"
        class="margin"
      >
        <div class="loadingFalse">
          <div class="preloader" />
        </div>
      </div>
      <div v-else class="margin">
        <GISWMSTimeDimensionMap
          :zoom="zoom"
          :name-map="mainMap"
          :tree-locations="treeLocations"
          :layer="layer"
          :index="currentIndexes[`${mainMap}`]"
          :center="center"
          :bbox="bbox"
          :height="'540px'"
        />
      </div>
    </f7-col>
    <f7-col style="align-self: stretch">
      <div style="display: flex; height: 100%">
        <div class="content-devx margin" style="width: 100%">
          <div class="margin title-section">
            {{ $t("imagesToDisplay") }}
          </div>  
          <div class="dx-field margin">
            <div class="dx-field-label">
              <span>{{ $t("availableDates") }}</span>
            </div>
            <div class="dx-field-value">
              <DxTagBox
                :items="getAvaiableDates"
                :onValueChanged="onAvailableDatesChange"
                displayExpr="name"
                valueExpr="id"
                :value="selectedDates"
              />
            </div>
          </div>
        </div>
      </div>
    </f7-col>
  </f7-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Api from "../../../services/Api";
import GISWMSTimeDimensionMap from "@/components/Gis/GISWMSTimeDimensionMap/index.vue";
import { DxTagBox } from "devextreme-vue/tag-box";
import moment from "moment";
export default {
  name: "ReportStep1",
  components: {
    GISWMSTimeDimensionMap,
    DxTagBox,
  },
  props: {
    selectedDates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: "GisTimeDimension",
      layer: {
        name: "gis",
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: "",
          format: "image/png",
          transparent: true,
          styles: "NDVI",
        },
      },
      center: {},
      bbox: {},
      ws: Api.getGeoServerWorkspace(),
    };
  },
  computed: {
    ...mapState("Gis", [
      "currentIndexes",
      "currentInitIntervalDates",
      "currentEndIntervalDates",
      "timelineIndexes",
      "currentBoundsWMSLayer",
      "datesList",
    ]),
    ...mapState("Plantation", ["treeLocations", "currentPlantation"]),
    getAvaiableDates() {
      return this.datesList.avaiable_dates.map((item) => {
        return {
          id: item,
          name: moment(item).format("DD-MM-YYYY"),
        };
      });
    },
  },
  async beforeMount() {
    this.setCurrentIndexes({
      key: this.mainMap,
      newCurrentIndex: this.currentIndexes.GisTimeDimension,
    });

    this.setCurrentInitIntervalDates({
      key: this.mainMap,
      newCurrentInitIntervalDate: moment(
        this.currentPlantation.initDate
      ).format("YYYY-MM-DDT00:00:00"),
    });
    this.setCurrentEndIntervalDates({
      key: this.mainMap,
      newCurrentEndIntervalDate: moment().format("YYYY-MM-DDT00:00:00"),
    });
    this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
    this.center = { ...this.currentPlantation.coordinates };
    await this.fetchLocationsByPlantation();
    await this.boundsWMSLayer(`timacagro:${this.currentPlantation.sectorId}`);
    this.bbox = {
      ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox,
    };
    this.center = { ...this.currentPlantation.coordinates };

    await this.fetchTimelineIndexes({
      location: this.currentPlantation.sectorId,
      initDate: this.currentPlantation.initDate,
      endDate: moment().format("YYYY-MM-DD"),
      companyId: this.currentPlantation.companyId,
    });

    this.loadedGIS = true;

    await this.fetchDatesLayer(this.currentPlantation.sectorId);
  },
  methods: {
    ...mapActions("Gis", [
      "fetchDatesLayer",
      "setCurrentInitIntervalDates",
      "setCurrentEndIntervalDates",
      "fetchTimelineIndexes",
      "boundsWMSLayer",
      "setCurrentIndexes",
    ]),
    ...mapActions("Plantation", ["fetchLocationsByPlantation"]),
    onAvailableDatesChange(e) {
      if (e.value.length > 6) {
        this.$emit("change-selected-dates", e.previousValue);
        e.component.option("value", e.previousValue);

        this.$notifyDX(
          {
            message: this.$t("limitSelectedDates"),
            width: 450,
          },
          "warning",
          1000
        );

        return;
      }

      this.$emit("change-selected-dates", e.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./reportGis.styles.scss";
</style>