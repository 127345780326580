import { DxColumn, DxDataGrid } from 'devextreme-vue/data-grid';

export default {
  name: 'element-resume',
  components: {
    DxDataGrid, DxColumn,
  },
  props: ['fertilization'],
  data() {
    return {
      elementResume: [],
    };
  },
  computed: {

  },
  mounted() {
    const ufContributed = this.getUfsTotal();
    this.elementResume.push(this.addRowDataSource(this.$t('nutritionDetail.elementResume.necNutritional'),
      (this.convertDataSource(this.fertilization.nutritional_needs))));

    this.elementResume.push(this.addRowDataSource(this.$t('nutritionDetail.elementResume.fertilizationBalance'),
      this.diffRowElement(this.invertRowDataSource(
        this.convertDataSource(this.fertilization.nutritional_needs),
      ),
      ufContributed)));

    this.elementResume.push(this.addRowDataSource(this.$t('nutritionDetail.elementResume.ufContributed'),
      this.calculateUfContributed()));

    this.elementResume.push(this.addRowDataSource(this.$t('nutritionDetail.elementResume.finalBalance'),
      this.calculateFinalBalance()));
  },
  methods: {
    invertRowDataSource(element) {
      return {
        n: element.n * -1,
        p: element.p * -1,
        k: element.k * -1,
        c: element.c * -1,
        m: element.m * -1,
        s: element.s * -1,
      };
    },
    addRowDataSource(titleColumn, element) {
      return {
        uf: titleColumn,
        n: this.limitNumberDecimals(element.n, 3),
        p: this.limitNumberDecimals(element.p, 3),
        k: this.limitNumberDecimals(element.k, 3),
        c: this.limitNumberDecimals(element.c, 3),
        m: this.limitNumberDecimals(element.m, 3),
        s: this.limitNumberDecimals(element.s, 3),
      };
    },
    convertDataSource(element) {
      return {
        n: element.n,
        p: element.p205,
        k: element.k20,
        c: element.ca0,
        m: element.mg0,
        s: element.s03,
        price: element.price,
      };
    },
    getUfsTotal() {
      return {
        n: this.sumUfs('n'),
        p: this.sumUfs('p205'),
        k: this.sumUfs('k20'),
        c: this.sumUfs('ca0'),
        m: this.sumUfs('mg0'),
        s: this.sumUfs('s03'),
      };
    },
    sumUfs(prop) {
      return this.fertilization.uf_available_soil[prop]
          + this.fertilization.uf_irrigation_water[prop]
          + this.fertilization.uf_rest_harvest[prop];
    },
    diffRowElement(object1, object2) {
      return {
        n: object1.n + object2.n,
        p: object1.p + object2.p,
        k: object1.k + object2.k,
        c: object1.c + object2.c,
        m: object1.m + object2.m,
        s: object1.s + object2.s,
      };
    },
    calculateFinalBalance() {
      return {
        n: (this.elementResume[1].n + this.elementResume[2].n).toFixed(3),
        p: (this.elementResume[1].p + this.elementResume[2].p).toFixed(3),
        k: (this.elementResume[1].k + this.elementResume[2].k).toFixed(3),
        c: (this.elementResume[1].c + this.elementResume[2].c).toFixed(3),
        m: (this.elementResume[1].m + this.elementResume[2].m).toFixed(3),
        s: (this.elementResume[1].s + this.elementResume[2].s).toFixed(3),
      };
    },
    calculateElementBalance(value, price) {
      return Math.round((value / 100) * price);
    },
    differenceElementBalance(finalBalance, prop) {
      finalBalance[prop] = this.elementResume[1][prop]
          + finalBalance[prop];
    },
    calculateUfContributed() {
      const ufContributed = {
        n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };

      this.fertilization.contribution_products.forEach((item) => {
        const totalPeriods = item.periods.reduce((a, b) => a + b);
        ufContributed.n += this.calculateElementBalance(item.n, totalPeriods);
        ufContributed.p += this.calculateElementBalance(item.p205, totalPeriods);
        ufContributed.k += this.calculateElementBalance(item.k20, totalPeriods);
        ufContributed.c += this.calculateElementBalance(item.ca0, totalPeriods);
        ufContributed.m += this.calculateElementBalance(item.mg0, totalPeriods);
        ufContributed.s += this.calculateElementBalance(item.s03, totalPeriods);
      });

      return ufContributed;
    },
    limitNumberDecimals(number, precision) {
      return parseFloat(parseFloat(number).toFixed(precision));
    }
  },
};
