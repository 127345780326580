import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardTreatment/stepper/index.vue';
import StepperNavigation from '@/components/wizardTreatment/stepper-navigation/index.vue';
import {
  mapActions,
  mapState,
} from 'vuex';
import PopupConfirmWizardExit from '@/components/wizardTreatment/popupConfirmWizardExit/index.vue';
import moment from 'moment';
import treatmentPlanService from '@/services/treatmentPlanService';
import TreatmentPeriodDetails from '../../../components/wizardTreatment/treatmentPeriodDetails/index.vue';

export default {
  name: 'treatment-step-two',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    PopupConfirmWizardExit,
    TreatmentPeriodDetails,
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation', 'isPlantationSelected']),
    ...mapState('treatmentStepper', ['treatmentPlan', 'isCopying', 'copyingStepData']),
  },
  data() {
    return {
      disabledSaveButton: false,
    };
  },
  methods: {
    ...mapActions('Plantation', ['setOldPlantation', 'setCurrentPlantation']),
    ...mapActions('treatmentStepper', ['setIsCopying']),
    validateForm() {
      if (this.treatmentPlan?.description === '' || this.treatmentPlan?.description === null) {
        this.$notifyDX(
          {
            message: this.$t('treatment.treatmentPlan.treatmentCreatePlan.description_validation'),
            width: 550,
          },
          'error',
          3000,
        );

        return false;
      }

      if (
        this.treatmentPlan.products.some((product) => product?.product_deleted ?? false)
      ) {
        this.$notifyDX(
          {
            message: this.$t('productsDeleted'),
            width: 550,
          },
          'error',
          3000,
        );

        return false;
      }

      if (this.treatmentPlan.default_apply_unit.some((unit) => unit === 'Kg-L / cuva') && (this.treatmentPlan.vat_volume === '' || this.treatmentPlan.vat_volume === null)) {
        this.$notifyDX(
          {
            message: this.$t('treatment.treatmentPlan.treatmentCreatePlan.vatVolumeRequired'),
            width: 550,
          },
          'error',
          3000,
        );

        return false;
      }

      if (this.treatmentPlan.forward_speed === '' || this.treatmentPlan.forward_speed === null) {
        this.$notifyDX(
          {
            message: this.$t('treatment.treatmentPlan.treatmentCreatePlan.forwardSpeedRequired'),
            width: 550,
          },
          'error',
          3000,
        );

        return false;
      }

      return true;
    },
    async save() {
      this.disabledSaveButton = true;
      if (this.validateForm()) {
        const isVatDose = this.treatmentPlan.default_apply_unit.some((unit) => unit === 'Kg-L / cuva');
        const vat_volume = !isVatDose ? null : this.treatmentPlan.vat_volume;

        this.treatmentPlan.periods.forEach((period) => {
          period.products = period.products.map((product) => {
            let dose_volume = 0;

            if (product.dose_type === 'kG-L/HA') {
              dose_volume = product.dose_volume;
            } else {
              dose_volume = product.dose_volume * (product.broth_volume / 1000);
            }

            const cost_per_ha = dose_volume * product.price;
            const fixedResult = Number(cost_per_ha.toFixed(3));

            if (isNaN(fixedResult) || !isFinite(fixedResult)) {
              product.cost_per_ha = 0;
            } else {
              product.cost_per_ha = fixedResult;
            }

            return product;
          });
        });

        const payload = {
          plantation: this.currentPlantation.id,
          description: this.treatmentPlan.description,
          observation: this.treatmentPlan.observation,
          init_date: moment(this.treatmentPlan.init_date).format('YYYY-MM-DD'),
          end_date: moment(this.treatmentPlan.end_date).format('YYYY-MM-DD'),
          period: this.treatmentPlan.period,
          vat_volume,
          forward_speed: this.treatmentPlan.forward_speed,
          periods: this.treatmentPlan.periods,
          default_apply_unit: this.treatmentPlan.default_apply_unit,
        };
        if (this.isPlantationSelected) {
          try {
            await treatmentPlanService.save(payload, this.currentPlantation.companyId);

            if (this.isCopying) {
              this.setCurrentPlantation({ ...this.currentPlantation, name: this.copyingStepData.name });
              this.setIsCopying(false);
            }

            this.setOldPlantation(this.currentPlantation);
            if (this.currentPlanId) {
              this.$f7.views.main.router.navigate('/plans/', {
                reloadCurrent: true,
              });
            } else {
              this.$f7.views.main.router.navigate('/searcher/treatment/', {
                reloadCurrent: true,
              });
            }
          } catch (error) {
            this.disabledSaveButton = false;
            this.$f7.dialog.alert(this.$t(`${error}`));
          }
          return;
        }

        try {
          if (this.treatmentPlan.id) {
            await treatmentPlanService.edit(this.treatmentPlan.id, payload, this.currentPlantation.companyId);
          } else {
            await treatmentPlanService.save(payload, this.currentPlantation.companyId);
          }
          this.$f7.views.main.router.navigate('/searcher/treatment/', {
            reloadCurrent: true,
          });
        } catch (error) {
          this.disabledSaveButton = false;
          this.$f7.dialog.alert(this.$t(`${error}`));
        }
      }
      this.disabledSaveButton = false;
    },
  },
};
