import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import navbar from '../../../components/NavBar.vue';
import Stepper from '../../../components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '../../../components/wizardNutrition/stepper-navigation/index.vue';
import ElementResume from '../../../components/wizardNutrition/elementResume/index.vue';
import ProductResume from '../../../components/wizardNutrition/productResume/index.vue';
import { fertilizationService } from '../../../services/fertilizationService';
import PopupConfirmWizardExit from '../../../components/wizardNutrition/popupConfirmWizardExit/index.vue';
import Observations from '../../../components/wizardNutrition/observations/index.vue';

export default {
  name: 'nutrition-step-five',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    ProductResume,
    ElementResume,
    DxLoadPanel,
    PopupConfirmWizardExit,
    Observations,
  },
  props: [],
  data() {
    return {
      loadingVisible: true,
      position: { of: '#nutrition-step-five' },
      disabledSaveButton: false,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapGetters('nutritionStepper', ['stepData']),
    ...mapGetters('referenceValueStore', ['referenceValues']),
    ...mapState('nutritionStepper', ['productsNotDeleted', 'isCopying', 'copyingStepData']),
    ...mapState('Plantation', ['currentPlantation', 'isPlantationSelected']),
    ...mapState('fertilizationStore', ['currentFertilizationId']),
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
  },
  mounted() {
    this.setProductsNotDeleted(false);

    if (this.stepData.dosageProduct.some((product) => product?.product_deleted ?? false)) {
      this.setProductsNotDeleted(true);
    }
  },
  methods: {
    async save() {
      this.disabledSaveButton = true;
      if (this.stepData.dosageProduct.some((product) => product?.product_deleted ?? false)) {
        this.$notifyDX(
          {
            message: this.$t('productsDeleted'),
            width: 550,
          },
          'error',
          3000,
        );
        Promise.resolve();
        return;
      }

      this.loadingVisible = true;
      try {
        // A veces estos valores están vacíos y el error no está controlado.
        if (this.referenceValues.length == 0) {
          throw new Error(this.$t('nutritionDetail.emptyReferenceValues'));
        }

        const payload = {
          plantation: this.currentPlantation.id,
          description: this.stepData.planDescription,
          init_date: moment(this.stepData.initDate).format('YYYY-MM-DD'),
          end_date: moment(this.stepData.endDate).format('YYYY-MM-DD'),
          observation: this.stepData.observation,
          period: this.stepData.temporalFrame.value,
          reference_values: {
            n: parseFloat(this.referenceValues[0].n).toFixed(3),
            p205: parseFloat(this.referenceValues[0].p).toFixed(3),
            k20: parseFloat(this.referenceValues[0].k).toFixed(3),
            ca0: parseFloat(this.referenceValues[0].c).toFixed(3),
            mg0: parseFloat(this.referenceValues[0].m).toFixed(3),
            s03: parseFloat(this.referenceValues[0].s).toFixed(3),
            production: parseFloat(this.referenceValues[0].production).toFixed(3),
          },
          nutritional_needs_reference: {
            n: parseFloat(this.stepData.nutritionalNeeds[0].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalNeeds[0].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalNeeds[0].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalNeeds[0].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalNeeds[0].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalNeeds[0].s).toFixed(3),
            expected_production: parseFloat(this.stepData.nutritionalNeeds[0].production).toFixed(3),
          },
          nutritional_needs: {
            n: parseFloat(this.stepData.nutritionalNeeds[1].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalNeeds[1].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalNeeds[1].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalNeeds[1].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalNeeds[1].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalNeeds[1].s).toFixed(3),
            production: parseFloat(this.stepData.nutritionalNeeds[1].production).toFixed(3),
          },
          uf_available_soil: {
            n: parseFloat(this.stepData.nutritionalBalanceData[1].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalBalanceData[1].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalBalanceData[1].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalBalanceData[1].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalBalanceData[1].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalBalanceData[1].s).toFixed(3),
          },
          uf_irrigation_water: {
            n: parseFloat(this.stepData.nutritionalBalanceData[2].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalBalanceData[2].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalBalanceData[2].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalBalanceData[2].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalBalanceData[2].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalBalanceData[2].s).toFixed(3),
          },
          uf_rest_harvest: {
            n: parseFloat(this.stepData.nutritionalBalanceData[3].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalBalanceData[3].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalBalanceData[3].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalBalanceData[3].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalBalanceData[3].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalBalanceData[3].s).toFixed(3),
          },
          contribution_products: this.stepData.dosageProduct.map((product, index) => {
            product.product = product.id;
            product.p205 = parseFloat(product.p).toFixed(3);
            product.k20 = parseFloat(product.k).toFixed(3);
            product.ca0 = parseFloat(product.c).toFixed(3);
            product.mg0 = parseFloat(product.m).toFixed(3);
            product.s03 = parseFloat(product.s).toFixed(3);
            product.periods = [];
            for (const prop in this.stepData.timeValues[index]) {
              product.periods.push(parseFloat(this.stepData.timeValues[index][prop]).toFixed(3));
            }
            product.total_cost = parseFloat(this.stepData.productResume[index].costTotal).toFixed(3);
            product.unit_cost = parseFloat(this.stepData.productResume[index].costUnit).toFixed(3);
            product.price = parseFloat(this.stepData.productResume[index].price).toFixed(3);
            return product;
          }),
        };

        if (this.productsNotDeleted) {
          this.$f7.dialog.alert(this.$t('nutritionDosificationProblem'));
          return;
        }

        this.setProductsNotInNutritionPlan([]);

        if (this.isPlantationSelected) {
          await fertilizationService.save(payload, this.currentPlantation.companyId);

          if (this.isCopying) {
            this.setCurrentPlantation({ ...this.currentPlantation, name: this.copyingStepData.name });
          }

          this.initNutritionalSteps();
          this.setOldPlantation(this.currentPlantation);

          if (this.currentFertilizationId) {
            this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
          } else {
            this.$f7.views.main.router.navigate('/plans/', { reloadCurrent: true });
          }

          return;
        }

        if (this.stepData.nutritionalId !== '') {
          await fertilizationService.edit(this.stepData.nutritionalId, payload, this.currentPlantation.companyId);
          this.initNutritionalSteps();
          this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
        } else {
          if (typeof payload.description === 'undefined' || payload.description === '') {
            this.$f7.dialog.alert(this.$t('nutritionDetail.descriptionValidation'));
            return;
          }
          await fertilizationService.save(payload, this.currentPlantation.companyId);

          this.initNutritionalSteps();
          this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
        }
      } catch (error) {
        this.disabledSaveButton = false;
        let errorMessage = '';

        // Los errores pueden ser de varios tipos. En el caso de que sea un objeto Error,
        //   el mensage viene en la propiedad 'message'.
        // Si es otro tipo de error, por ejemplo, un string. Se usa como tal.
        if (error.message) {
          errorMessage = error.message;
        } else {
          errorMessage += error;
        }

        if (errorMessage.includes('date_range_invalid')) {
          this.$f7.dialog.alert(this.$t('nutritionDetail.dateRangeInvalid'));
        } else {
          this.$f7.dialog.alert(error);
        }
      } finally {
        this.loadingVisible = false;
      }
    },
    initNutritionalSteps() {
      this.stepData.nutritionalNeeds = [];
      this.stepData.expectedProduction = 1;
      this.stepData.planDescription = '';
      this.stepData.initDate = '';
      this.stepData.endDate = '';
      this.stepData.temporalFrame = '';
      this.stepData.nutritionalBalanceData = [];
      this.stepData.timeValues = [];
      this.stepData.dosages = [];
      this.stepData.dosageProduct = [];
      this.stepData.dosageProductBalance = [];
      this.stepData.productResume = [];
      this.setIsEditing(false);
      this.setEditingStepData(null);
      this.setIsCopying(false);
      this.setCopyingStepData(null);
    },
    ...mapActions('Plantation', ['setOldPlantation', 'setCurrentPlantation']),
    ...mapActions('nutritionStepper', ['setProductsNotDeleted', 'setIsEditing', 'setEditingStepData', 'setIsCopying', 'setCopyingStepData']),
    ...mapActions('Nutrition', ['setProductsNotInNutritionPlan']),
  },
};
