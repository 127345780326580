<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_analisys')} > ${$t('Title_page_analisys_nutrition')}`" />

    <DxLoadPanel
      :visible="loading"
      shading-color="rgba(0,0,0,0.4)"
    />

    <div class="margin-top-half">
      <div class="content-devx">
        <f7-block>
          <f7-row>
            <f7-col>
              <DxDataGrid
                :data-source="customStore"
                :remote-operations="true"
                :row-alternation-enabled="true"
                :show-borders="true"
                @toolbar-preparing="onToolbarPreparing($event)"
              >
                <template #periodFilterTemplate>
                  <div>
                    <DxButton
                      class="margin-right-half"
                      :width="200"
                      :text="$t('Utilities.stepperForm.monthly')"
                      type="default"
                      :styling-mode="currentPeriod == 'MENSUAL' ? 'contained' : 'outlined'"
                      @click="selectPeriod('MENSUAL')"
                    />
                    <DxButton
                      class="margin-left-half"
                      :width="200"
                      :text="$t('Utilities.stepperForm.weekly')"
                      type="default"
                      :styling-mode="currentPeriod == 'SEMANAL' ? 'contained' : 'outlined'"
                      @click="selectPeriod('SEMANAL')"
                    />
                  </div>
                </template>

                <DxSearchPanel
                  :visible="true"
                  :width="200"
                  :highlight-case-sensitive="true"
                  :placeholder="$t('Search_placeholder')"
                />

                <DxColumn
                  data-field="description"
                  caption=""
                  data-type="string"
                />

                <DxColumn
                  data-field="species"
                  :caption="$t('Plans.crop')"
                  data-type="string"
                />

                <DxColumn
                  data-field="company_name"
                  :caption="$t('client')"
                  data-type="string"
                />

                <DxColumn
                  data-field="explotation"
                  :caption="$t('Explotation')"
                  data-type="string"
                />

                <DxColumn
                  data-field="plantation"
                  :caption="$t('nutritionDetail.report.plantation')"
                  data-type="string"
                />

                <DxColumn
                  data-field="campaign"
                  :caption="$t('nutritionDetail.report.campaign')"
                  data-type="string"
                />

                <DxColumn
                  data-field="cost"
                  :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.cost')"
                  data-type="string"
                />

                <DxColumn
                  width="48px"
                  data-field="id"
                  caption=""
                  cell-template="grid-cell"
                />
                <template #grid-cell="{ data }">
                  <div>
                    <DxButton
                      v-if="isNutritionPlanSelected(data.text)"
                      name="delete"
                      type="danger"
                      icon="close"
                      @click="deselectNutritionPlan(data.text)"
                    />
                    <DxButton
                      v-else
                      name="edit"
                      type="success"
                      icon="add"
                      @click="selectNutritionPlan(data.text)"
                    />
                  </div>
                </template>

                <DxPaging :page-size="gridSettings.pageSize" />
                <DxPager
                  :visible="true"
                  display-mode="full"
                  :allowed-page-sizes="pageSizes"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                  info-text="{2} items"
                />
              </DxDataGrid>
            </f7-col>
          </f7-row>
        </f7-block>
      </div>

      <div class="content-devx text-align-center">
        <span
          v-for="element in nutritionElementList"
          :key="element"
        >
          <DxButton
            v-if="isElementSelected(element)"
            class="margin-horizontal-half"
            name="delete"
            type="danger"
            @click="deselectElement(element)"
          >
            <span class="padding text-uppercase">
              <i class="dx-icon-close" />
              {{ element }}
            </span>
          </DxButton>

          <DxButton
            v-else
            class="margin-horizontal-half"
            name="edit"
            type="success"
            @click="selectElement(element)"
          >
            <span class="padding text-uppercase">
              <i class="dx-icon-add" />
              {{ element }}
            </span>
          </DxButton>
        </span>
      </div>

      <div class="content-devx">
        <DxChart
          v-if="!loading"
          id="chart"
          :data-source="chartData"
        >
          <DxSeries
            v-for="name in chartSeriesNames"
            v-if="selectedElements.length > 0"
            :key="name"
            argument-field="date"
            :value-field="name"
            :name="name.split(' - ')[1] + ' - '+ name.split(' - ')[0].toString().toUpperCase()"
            type="stackedbar"
            stack="primero"
          />

          <DxSeries
            v-for="valSecond in comparePlansSecond"
            v-if="selectedElements.length > 0"
            :key="valSecond"
            argument-field="date"
            :name="valSecond.split(' - ')[1] + ' - '+ valSecond.split(' - ')[0].toString().toUpperCase()"
            :value-field="valSecond"
            type="stackedbar"
            stack="segundo"
          />

          <DxSeries
            v-for="valThird in comparePlansThird"
            v-if="selectedElements.length > 0"
            :key="valThird"
            argument-field="date"
            :name="valThird.split(' - ')[1] + ' - '+ valThird.split(' - ')[0].toString().toUpperCase()"
            :value-field="valThird"
            type="stackedbar"
            stack="tercero"
          />

          <DxTooltip
            :enabled="true"
            :z-index="999999"
            :customize-tooltip="customizeTooltip"
          />
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            :column-count="selectedElements.length"
          />
          <DxScrollBar :visible="true" />
          <DxZoomAndPan argument-axis="both" />
        </DxChart>
      </div>
    </div>
  </f7-page>
</template>

<script>

import { DxLoadPanel } from 'devextreme-vue/load-panel';
import {
  DxChart,
  DxLegend,
  DxSeries,
  DxTooltip,
  DxScrollBar,
  DxZoomAndPan,
} from 'devextreme-vue/chart';

import DxButton from 'devextreme-vue/button';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import DxTabs from 'devextreme-vue/tabs';

import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import navbar from '../../components/NavBar.vue';
import settings from '../../js/commonSettings';

export default {
  name: 'NutritionPage',
  components: {
    DxTabs,
    navbar,
    DxLoadPanel,
    DxChart,
    DxLegend,
    DxSeries,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxButton,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxPaging,
  },
  data() {
    return {
      loading: true,
      selectedNutritionPlanIds: [],
      // Period filters
      currentPeriod: 'MENSUAL',

      plansOnDisplay: [],

      chartData: [],
      comparePlansSecond: [],
      comparePlansThird: [],
      currentPlanSecond: '',
      currentPlanThird: '',
      // Esta lista se usa para añadir dinámicamente las series en la gráfica.
      chartSeriesNames: [],

      nutritionElementList: ['k20', 'n', 'p205', 'ca0', 'mg0', 's03'],
      selectedElements: [],
      pageSizes: [5, 10, 20],
      customStore: null
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('fertilizationAnalysis', ['allPlans', 'plansDetailsById']),
  },
  watch: {
    chartData(newVal) {
      const objNewVal = { ...newVal };

      if (this.selectedNutritionPlanIds.length === 2) {
        let nutritionalValue = '';

        // eslint-disable-next-line guard-for-in
        for (const property in objNewVal) {
          for (const nutritionValue in objNewVal[property]) {
            if (objNewVal[property][nutritionValue] !== 'date' && objNewVal[property][nutritionValue] > 0) {
              const nutrVal = nutritionValue.split('-')[0];
              nutritionalValue = nutrVal;
            }
          }

          if (objNewVal[property][`${nutritionalValue.trim()} - ${this.currentPlanSecond}`]) {
            this.comparePlansSecond = Object.keys(objNewVal[property]).filter((plan) => plan !== 'date' && plan.includes(this.currentPlanSecond));
          }
        }

        this.comparePlansSecond.forEach((el) => {
          const index = this.chartSeriesNames.findIndex((chsn) => chsn.toString() === el.toString());
          if (index >= 0) {
            this.chartSeriesNames.splice(index, 1);
          }
        });
      }

      if (this.selectedNutritionPlanIds.length === 3) {
        let nutritionalValue = '';

        // eslint-disable-next-line guard-for-in
        for (const property in objNewVal) {
          for (const nutritionValue in objNewVal[property]) {
            if (objNewVal[property][nutritionValue] !== 'date' && objNewVal[property][nutritionValue] > 0) {
              const nutrVal = nutritionValue.split('-')[0];
              nutritionalValue = nutrVal;
            }
          }

          if (objNewVal[property][(`${nutritionalValue.trim()} - ${this.currentPlanSecond}`)]) {
            this.comparePlansSecond = Object.keys(objNewVal[property]).filter((plan) => plan !== 'date' && plan.includes(this.currentPlanSecond));
          }
        }

        // eslint-disable-next-line guard-for-in
        for (const property in objNewVal) {
          for (const nutritionValue in objNewVal[property]) {
            if (objNewVal[property][nutritionValue] !== 'date' && objNewVal[property][nutritionValue] > 0) {
              const nutrVal = nutritionValue.split('-')[0];
              nutritionalValue = nutrVal;
            }
          }

          if (objNewVal[property][(`${nutritionalValue.trim()} - ${this.currentPlanThird}`)]) {
            this.comparePlansThird = Object.keys(objNewVal[property]).filter((plan) => plan !== 'date' && plan.includes(this.currentPlanThird));
          }
        }

        this.comparePlansSecond.forEach((el) => {
          const index = this.chartSeriesNames.findIndex((chsn) => chsn.toString() === el.toString());
          if (index >= 0) {
            this.chartSeriesNames.splice(index, 1);
          }
        });

        this.comparePlansThird.forEach((el) => {
          const index = this.chartSeriesNames.findIndex((chsn) => chsn.toString() === el.toString());
          if (index >= 0) {
            this.chartSeriesNames.splice(index, 1);
          }
        });
      }
    },
  },
  async mounted() {
    this.loading = true;

    try {
      this.customStore = await this.getStore({ type: 'analysisFertilization' });
    } catch (error) {
      this.$f7.dialog.alert(this.$t(error));
    }

    this.selectPeriod('MENSUAL');

    this.loading = false;
    this.nutritionElementList.forEach((element) => {
      this.selectedElements.push(element);
    });
  },
  methods: {
    ...mapActions('fertilizationAnalysis', ['fetchAllFertilizationPlans', 'fetchFertilizationPlanById']),
    ...mapActions('Pagination', ['getStore']),
    groupGraphDataByDate(array) {
      const groups = {};

      // mapa con la suma de cada elemento agrupado por fecha
      array.forEach((item) => {
        const { date, ...props } = item;
        if (!groups[date]) groups[date] = {};

        Object.keys(props).forEach((key) => {
          // establece el elemento y plan teniendo en cuenta la fecha
          if (!groups[date][key]) groups[date][key] = 0;

          // suma del elemento y plan establecido anteriormente con el valor del elemento y plan que se está recorriendo
          groups[date][key] += item[key];
        });
      });

      // transformación a array
      return Object.keys(groups).map((key) => ({
        date: key,
        ...groups[key], // suma de cada grupo
      }));

      // quitar comentario si es útil mantener la fecha como key
      // return groups;
    },
    async recalculateGraphData() {
      this.loading = true;

      const newGraphData = [];
      const newSeriesNames = [];

      let currentPlans = [];

      // Obtener todos los planes
      for (const id of this.selectedNutritionPlanIds) {
        try {
          await this.fetchFertilizationPlanById(id);
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }

        currentPlans.push(this.plansDetailsById[id]);

        if (this.selectedNutritionPlanIds.length === 2) {
          this.currentPlanSecond = this.plansDetailsById[id].description;
        }

        if (this.selectedNutritionPlanIds.length === 3) {
          this.currentPlanThird = this.plansDetailsById[id].description;
        }
      }
      // Se ordenan los planes usando el índce de la semana, es necesario para que la gráfica salga ordenada
      currentPlans = currentPlans.sort((planA, planB) => {
        const monthPlanA = moment(planA.init_date).week();
        const monthPlanB = moment(planB.init_date).week();
        return monthPlanA - monthPlanB;
      });

      for (const planDetails of currentPlans) {
        const initDate = moment(planDetails.init_date);
        const endDate = moment(planDetails.end_date);

        const initYear = initDate.year();
        const endYear = endDate.year();

        // Se generan las etiquetas de cada elemento para el plan actual.
        // Así se pueden configurar las series de la gráfica.
        // Eg: k20 - Plan1
        const planName = planDetails.description;
        for (let i = 0; i < this.selectedElements.length; i++) {
          newSeriesNames.push(`${this.selectedElements[i]} - ${planName}`);
        }
        const contributionProducts = planDetails.contribution_products;
        if (planDetails.period === 'MENSUAL') {
          const initMonth = initDate.month();
          const endMonth = endDate.month();

          let countNextYear = 0;
          let mustReduce = 0;

          for (let i = 0; i < contributionProducts.length; i++) {
            const product = contributionProducts[i];
            let periodIndex = 0;

            if (endDate.year() > initDate.year()) {
              countNextYear += 1;
            }

            for (let j = 0; j < 12; j++) {
              // Comprobamos que el período esté en fecha para mostrarlo.
              if (endDate.year() > initDate.year()) {
                if (j >= initMonth) {
                  mustReduce += 1;
                  const dataPoint = { date: `${j + 1} - ${initYear}` };

                  // Se suma la contribución de cada producto por cada elemento.
                  for (const element of this.selectedElements) {
                    const label = `${element} - ${planName}`;

                    if (dataPoint[label] === undefined) {
                      dataPoint[label] = 0;
                    }

                    dataPoint[label] += product.periods[periodIndex] * (product[element] || 0);
                  }
                  newGraphData.push(dataPoint);

                  periodIndex++;
                } else if (contributionProducts.length === 1) {
                  if (endDate.year() > initDate.year()) {
                    countNextYear += 1;
                  }
                }
              } else if (j >= initMonth && j <= endMonth) {
                const dataPoint = { date: `${j + 1} - ${initYear}` };

                // Se suma la contribución de cada producto por cada elemento.
                for (const element of this.selectedElements) {
                  const label = `${element} - ${planName}`;

                  if (dataPoint[label] === undefined) {
                    dataPoint[label] = 0;
                  }

                  dataPoint[label] += product.periods[periodIndex] * (product[element] || 0);
                }
                newGraphData.push(dataPoint);

                periodIndex++;
              }
            }
          }

          if (contributionProducts.length === 1) {
            countNextYear -= mustReduce + 2;
          }

          if (countNextYear > 0) {
            for (let i = 0; i < contributionProducts.length; i++) {
              const product = contributionProducts[i];

              let periodIndex = 0;

              for (let j = 0; j < countNextYear; j++) {
              // Comprobamos que el período esté en fecha para mostrarlo.
                const dataPoint = { date: `${j + 1} - ${endYear}` };

                // Se suma la contribución de cada producto por cada elemento.
                for (const element of this.selectedElements) {
                  const label = `${element} - ${planName}`;

                  if (dataPoint[label] === undefined) {
                    dataPoint[label] = 0;
                  }

                  dataPoint[label] += product.periods[periodIndex] * (product[element] || 0);
                }
                newGraphData.push(dataPoint);

                periodIndex++;
              }
            }
          }
        } else if (planDetails.period === 'SEMANAL') {
          const initWeek = initDate.week();
          const endWeek = endDate.week();

          for (let i = 0; i < contributionProducts.length; i++) {
            const product = contributionProducts[i];

            let periodIndex = 0;
            for (let j = 1; j < 54; j++) {
              if (j >= initWeek && j <= endWeek) {
                const dataPoint = { date: `W${j} - ${initYear}` };

                // Se suma la contribución de cada producto por cada elemento.
                for (const element of this.selectedElements) {
                  const label = `${element} - ${planName}`;

                  if (dataPoint[label] === undefined) {
                    dataPoint[label] = 0;
                  }
                  dataPoint[label] += product.periods[periodIndex] * (product[element] || 0);
                }

                newGraphData.push(dataPoint);

                periodIndex++;
              }
            }
          }
        }
      }

      this.chartSeriesNames = newSeriesNames;
      this.chartData = this.groupGraphDataByDate(newGraphData);
      this.loading = false;
    },

    selectPeriod(period) {
      // TODO: LOGIC TO SELECT PERIOD
      // this.currentPeriod = period;
      // this.selectedNutritionPlanIds = [];

      // this.plansOnDisplay = this.allPlans.filter((plan) => plan.period === this.currentPeriod);

      // this.recalculateGraphData();
    },

    // -----------------------
    // Nutrition element Selection Helpers
    // -----------------------
    isElementSelected(element) {
      for (const elem of this.selectedElements) {
        if (element === elem) {
          return true;
        }
      }
      return false;
    },

    selectElement(element) {
      this.selectedElements.push(element);
      this.recalculateGraphData();
    },

    deselectElement(element) {
      const index = this.selectedElements.indexOf(element);
      if (index > -1) {
        this.selectedElements.splice(index, 1);
      }
      this.recalculateGraphData();
    },

    // -----------------------
    // Selection Helpers
    // -----------------------
    isNutritionPlanSelected(planId) {
      for (const id of this.selectedNutritionPlanIds) {
        if (planId === id) {
          return true;
        }
      }
      return false;
    },

    async selectNutritionPlan(id) {
      const planFound = this.selectedNutritionPlanIds.find((plan) => plan.toString() === id.toString());
      if (this.selectedNutritionPlanIds.length === 3 && !planFound) {
        this.selectedNutritionPlanIds.pop();
        this.selectedNutritionPlanIds.push(id);
        this.recalculateGraphData();
        return;
      }
      if (this.selectedNutritionPlanIds.length === 3) return;

      this.selectedNutritionPlanIds.push(id);

      this.recalculateGraphData();
    },

    deselectNutritionPlan(id) {
      if (this.comparePlansSecond.length > 0) {
        this.comparePlansSecond = [];
        this.comparePlansThird = [];
      }

      if (this.selectedNutritionPlanIds.length === 0) {
        this.comparePlansThird = [];
      }

      const index = this.selectedNutritionPlanIds.indexOf(id);
      if (index > -1) {
        this.selectedNutritionPlanIds.splice(index, 1);
      }
      this.recalculateGraphData();
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${
          pointInfo.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('DxElement')}: </span>${pointInfo.seriesName.split(' - ')[1].toString().toUpperCase()}
          </span> </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}:</span> ${pointInfo.valueText} kg/ha
        </span> </div></div></div>`,
      };
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({ location: 'before', template: 'periodFilterTemplate' });
    },
  },
};
</script>

<style>

</style>
