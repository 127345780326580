import i18next from 'i18next';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    campaign: '',
    parameters: ['TEMPERATURE', 'PLUVIOMETER', 'HUMIDITY_AVG', 'HUMIDITY_MAX', 'HUMIDITY_MIN', 'WIND_SPEED', 'WIND_SPEED_MAX', 'SOLAR_RADIATION'],
    namePlantation: '',
    allCampaigns: [],
    allPlantations: [],
    parameterSelected: '',
    endDate: '',
    initDate: '',
    dataWeather: [],
    category: ['MEASURED', 'FORECAST'],
    dataDate: [],
    plantationSelected: '',
    allClients: [],
    clientSelected: '',
    searchInitDate: null,
    searchEndDate: null,
  },
  mutations: {
    UPDATE_CAMPAIGN(state, value) {
      state.campaign = value;
    },
    UPDATE_PARAMETERS(state, value) {
      state.parameters = value;
    },
    setNamePlantation(state, value) {
      state.namePlantation = value;
    },
    UPDATE_ALL_CAMPAIGNS(state, value) {
      state.allCampaigns = value;
    },
    FETCH_PLANTATION_AVAILABLES(state, value) {
      state.allPlantations = value;
    },
    UPDATE_PARAMETER_SELECTED(state, value) {
      state.parameterSelected = value;
    },
    UPDATE_INIT_DATE(state, value) {
      state.initDate = value;
    },
    UPDATE_END_DATE(state, value) {
      state.endDate = value;
    },
    UPDATE_DATA_WEATHER(state, value) {
      let isSame = false;
      state.dataWeather.forEach((param) => {
        if (Array.isArray(param)) {
          if (param[0].name === value[0].name) {
            isSame = true;
          }
        }
      });

      if (isSame) return;
      state.dataWeather.push(value);
    },
    UPDATE_DATA_DATE(state, value) {
      if (state.dataDate.length !== 0) {
        state.dataDate = state.dataDate.concat(value);
      } else {
        state.dataDate = value;
      }
    },
    UPDATE_DATADATE_RESET(state, value) {
      state.dataDate = value;
    },
    UPDATE_DATAWEATHER_RESET(state, value) {
      state.dataWeather = value;
    },
    setPlantationId(state, value) {
      state.plantationSelected = value;
    },
    setClient(state, value) {
      state.clientSelected = value;
    },
    fetchDataClients(state, value) {
      state.allClients = value;
    },
    setWeatherSearchInitDate(state, value) {
      state.searchInitDate = value;
    },
    setWeatherSearchEndDate(state, value) {
      state.searchEndDate = value;
    },
  },
  actions: {
    async getAllCampaigns({ commit }) {
      try {
        const xhr = await Api.getAllCampaignsForUser();
        const campaign = JSON.parse(xhr.response).data;
        const allCamp = [];
        for (let i = 0; i < campaign.length; i += 1) {
          allCamp.push({
            name: campaign[i].name,
            id: campaign[i].id,
            company: campaign[i].company,
          });
        }
        commit(
          'UPDATE_ALL_CAMPAIGNS',
          allCamp,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getWeatherParameter({ commit, state }) {
      try {
        let idCompany = '';
        for (let i = 0; i < state.allCampaigns.length; i += 1) {
          if (state.campaign === state.allCampaigns[i].id) {
            idCompany = state.allCampaigns[i].company;
          }
        }
        const xhr = await Api.getWeatherByPlantation(idCompany, state.plantationSelected, state.category[0], state.initDate, state.endDate, state.parameterSelected);
        const weatherParameter = JSON.parse(xhr.response);
        const dataParameters = [];
        const dataDate = [];
        for (const parameter in weatherParameter[0].parameters) {
          if (Object.hasOwnProperty.call(weatherParameter[0].parameters, parameter)) {
            if (parameter === state.parameterSelected) {
              for (const dataParameter of weatherParameter[0].parameters[parameter]) {
                dataDate.push({
                  date: dataParameter.date,
                  [`${i18next.t(`WeatherParameter.${state.parameterSelected.split('-')[0]}`)}-${state.namePlantation}`]: dataParameter.value,
                });
              }
              commit(
                'UPDATE_DATA_DATE',
                dataDate,
              );
            }
          }
        }
        dataParameters.push({
          name: `${i18next.t(`WeatherParameter.${state.parameterSelected.split('-')[0]}`)}-${state.namePlantation}`,
          value: `${i18next.t(`WeatherParameter.${state.parameterSelected.split('-')[0]}`)}-${state.namePlantation}`,
        });
        commit(
          'UPDATE_DATA_WEATHER',
          dataParameters,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getAllClients({ commit }) {
      try {
        const xhr = await Api.getClient();
        const allClients = JSON.parse(xhr.response);
        const user = allClients.contact;
        const dataClients = [];
        for (let i = 0; i < user.companies.length; i += 1) {
          dataClients.push({
            id: user.companies[i].id,
            name: user.companies[i].name,
          });
        }
        commit(
          'fetchDataClients',
          dataClients,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getPlantationByCompany({ commit, state, rootGetters }, actualUserId = '') {
      try {
        const CompanyId = state.clientSelected;
        const campaingId = state.campaign;
        const xhr = await Api.getPlantationByCampaign(CompanyId, campaingId);
        const plantationAvailables = JSON.parse(xhr.response).data;
        const dataPlantationFormatted = [];
        for (let i = 0; i < plantationAvailables.length; i += 1) {
          dataPlantationFormatted.push({
            id: plantationAvailables[i].id,
            name: plantationAvailables[i].name,
            campaign: plantationAvailables[i].campaign,
          });
        }
        commit(
          'FETCH_PLANTATION_AVAILABLES',
          dataPlantationFormatted,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setCampaign(context, value) {
      context.commit('UPDATE_CAMPAIGN', value);
    },
    setNamePlantation(context, value) {
      context.commit('setNamePlantation', value);
    },
    setParameterSelected(context, value) {
      context.commit('UPDATE_PARAMETER_SELECTED', value);
    },
    setInitDate(context, value) {
      context.commit('UPDATE_INIT_DATE', value);
    },
    setEndDate(context, value) {
      context.commit('UPDATE_END_DATE', value);
    },
    setPlantationId(context, value) {
      context.commit('setPlantationId', value);
    },
    setClient(context, value) {
      context.commit('setClient', value);
    },
    initStoreGraph({ commit }) {
      commit('UPDATE_DATAWEATHER_RESET', []);
      commit('UPDATE_DATADATE_RESET', []);
    },
    setWeatherSearchInitDate({ commit }, value) {
      commit('setWeatherSearchInitDate', value);
    },
    setWeatherSearchEndDate({ commit }, value) {
      commit('setWeatherSearchEndDate', value);
    },
    initStoreRegister({ commit }) {
      commit('UPDATE_DATAWEATHER_RESET', []);
      commit('UPDATE_ALL_CAMPAIGNS', []);
      commit('fetchDataClients', []);
      commit('UPDATE_PARAMETER_SELECTED', '');
      commit('UPDATE_INIT_DATE', '');
      commit('UPDATE_END_DATE', '');
      commit('setPlantationId', '');
      commit('UPDATE_CAMPAIGN', '');
      commit('FETCH_PLANTATION_AVAILABLES', []);
    },
  },
};
