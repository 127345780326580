<template>
  <DxChart
    id="chart"
    :data-source="timelineIndexesFormatted"
    palette="Material"
  >
    <DxCommonSeriesSettings
      type="line"
      argument-field="date"
    />
    <DxSeries
      value-field="mean"
      :name="index"
    />
    <DxArgumentAxis
      :value-margins-enabled="false"
      discrete-axis-division-mode="crossLabels"
    />
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
      item-text-position="right"
    />
    <DxTitle :text="''" />
    <DxTooltip
      :enabled="true"
      :z-index="999999"
      :customize-tooltip="customizeTooltip"
    />
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLegend,
  DxTitle,
  DxTooltip,
} from 'devextreme-vue/chart';
import moment from 'moment';

export default {
  name: 'TimelineGraph',
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLegend,
    DxTitle,
    DxTooltip,
  },
  props: {
    timelineIndexes: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: '',
    },
  },
  computed: {
    timelineIndexesFormatted() {
      const timelineIndexesFormatted = [];
      for (const timelineIndex of this.timelineIndexes) {
        if (timelineIndex.values.length !== 0) {
          const timeLineFiltered = this.applyTimeLinesFilterByIndex(timelineIndex.values);
          timeLineFiltered.date = timelineIndex.date;
          timelineIndexesFormatted.push(timeLineFiltered);
        }
      }
      return timelineIndexesFormatted;
    },
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${moment(pointInfo.argument).format('DD-MM-YYYY')}</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('index')}: </span>${pointInfo.seriesName}
          </span> </div>
          <span class="bold"> ${this.$t('mean')}:</span> ${pointInfo.valueText}
        </span> </div></div></div>`,
      };
    },
    applyTimeLinesFilterByIndex(values) {
      let timeLine = null;
      values.forEach((value) => {
        if (value.index.toString().toUpperCase() === this.index.toString().toUpperCase()) {
          timeLine = value;
        }
      });

      return timeLine;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './TimelineGraph.styles.scss';
</style>
