var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-block',[_vm._v(_vm._s(_vm.$t('Configuration.ObservationsForms.registerTitle')))]),_vm._v(" "),_c('f7-block',[_c('DxForm',{attrs:{"id":"headerTemplateForm-form","show-colon-after-label":false}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.ObservationsForms.category'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.templatesCategories,
            value: _vm.templateCategory,
            placeholder: _vm.$t('Configuration.ObservationsForms.category'),
            onValueChanged: _vm.onValueChanged,
          }}},[_c('DxRequiredRule')],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.ObservationsForms.formName'),"editor-options":{
            value: _vm.formName,
            placeholder: _vm.$t('Configuration.ObservationsForms.formName'),
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Configuration.ObservationsForms.formNameRequired')}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }