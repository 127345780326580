<template>
  <div class="margin-bottom plant-forms">
    <f7-row class="row-items-center">
      <f7-col width="40">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>Unidad de aplicación</span>
          </div>
          <div class="dx-field-value">
            <DxSelectBox
              :items="applyUnit"
              v-model="period.default_apply_unit"
              displayExpr="name"
              valueExpr="id"
              :on-value-changed="onChangeApplyUnit"
            />
          </div>
        </div>
      </f7-col>
      <f7-col width="10"></f7-col>
      <f7-col width="50" class="right-field no-padding no-margin">
        <div class="dx-field">
          <div class="dx-field-label align-right">
            <span>Nº de plantas/ha</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox
              v-model="actualPlantation.plants_density"
              :read-only="true"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label align-right">
            <span>Velocidad de avance</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox v-model="treatmentPlan.forward_speed" />
          </div>
          <div class="margin-right margin-left">km/h</div>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";
import DxTextBox from "devextreme-vue/text-box";
import DxNumberBox from "devextreme-vue/number-box";
import { mapState } from "vuex";

export default {
  name: "TreatmentStepTwoFormPlant",
  components: {
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
  },
  props: {
    period: {
      type: Object,
      default: () => {},
    },
    selectedIndex: Number,
  },
  data() {
    return {
      applyUnit: [
        {
          id: "default",
          name: "Defecto"
        },
        {
          id: "Kg-L / planta",
          name: "Kg-L / planta"
        }
      ],
    };
  },
  computed: {
    ...mapState("RegisterPlantation", ["actualPlantation"]),
    ...mapState("treatmentStepper", ["treatmentPlan"]),
  },
  methods: {
    onChangeApplyUnit(e) {
      let index = 0;
      let count = this.selectedIndex;

      for (const period of this.treatmentPlan.periods) {
        const doseVolumeNotEmpty = period.products.some(
          ({ dose_volume, active }) => (dose_volume > 0 && active)
        );

        if (doseVolumeNotEmpty) {
          if (count === 0) {
            break;
          }

          count--;
        }
        index++;
      }

      this.treatmentPlan.default_apply_unit.splice(
        index,
        1,
        e.value
      );
    },
  },
};
</script>

<style src="./TreatmentStepTwoForms.styles.scss" scoped lang="scss"></style>