import productFertilizerService from '../../services/productFertilizerService';

// to handle state
const state = {
  productFertilizers: [],
};

// to handle state
const getters = {
  productFertilizers: (stateL) => stateL.productFertilizers,
};

// to handle actions
const actions = {
  getProductFertilizers({ commit }, companyId) {
    return new Promise((resolve) => {
      productFertilizerService.getAll(companyId)
        .then((response) => {
          const mapData = response.data
            .filter(item => item.status)
            .map((data) => ({
              id: data.id,
              name: data.name,
              code: data.code,
              n: data.NT,
              p: data.P2015_Total,
              k: data.K2O,
              c: data.CaO,
              m: data.MgO,
              s: data.SO3,
            }));
          commit('SET_PRODUCT_FERTILIZER_VALUES', mapData);
          resolve(mapData);
        });
    });
  },
};

// to handle mutations
const mutations = {
  SET_PRODUCT_FERTILIZER_VALUES(stateL, productFertilizers) {
    stateL.productFertilizers = productFertilizers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
