<template v-if="loaded">
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Tasks_Title')}`" />
    <TasksDatabase
      :task-definitions="taskDefinitions"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import TasksDatabase from '../../../components/configuration/tasks/tasksDatabase/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Tasks',
  components: {
    TasksDatabase,
    navbar,
  },

  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('TaskDefinition', ['taskDefinitions']),

  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getTaskDefinitions();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('TaskDefinition', ['getTaskDefinitions']),

  },
};
</script>
