<template>
  <f7-page
    v-if="loaded"
    class="margin-top-block"
  >
    <f7-row>
      <f7-col class="margin-right-half">
        <!-- Card 01 -->
        <div class="content-devx-margin-block">
          <f7-block-title>
            {{ $t('ViewPlantation.viewPlantationTitle') }}
          </f7-block-title>
          <f7-row>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_description') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.name }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_campaign') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.campaign }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_status') }}
              </div>
              <div
                class="no-margin margin-bottom-half"
                :class="setClassByStatus(getStatusCampaign())"
              >
                {{ getStatusCampaign() }}
              </div>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_client') }}
              </div>
              <div class="item-title-bold x2">
                {{ currentPlantation.company }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_init_date') }}
              </div>
              <div class="item-title-bold x2">
                {{ dateFormatting(actualPlantation.init_date) }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_end_date') }}
              </div>
              <div class="item-title-bold x2">
                {{ dateFormatting(actualPlantation.end_date) }}
              </div>
            </f7-col>
          </f7-row>
        </div>
        <!-- Card 02 -->
        <div class="content-devx">
          <f7-row>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_explotation') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.explotation }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_name_parcel') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.parcel }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_name_sector') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.farms[0].name }}
              </div>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_superficie') }}
              </div>
              <div class="item-title-bold x2">
                {{ parseFloat(properties.area).toFixed(2) }} ha
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_textura_suelo') }}
              </div>
              <div class="item-title-bold x2">
                {{ properties.ground_texture }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_system_irrigation') }}
              </div>
              <div class="item-title-bold x2">
                {{ properties.irrigation_type }}
              </div>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <div
                v-if="properties.irrigation_type === 'GOTEO' || properties.irrigation_type === 'ASPERSIÓN' || properties.irrigation_type === 'INUNDACIÓN' "
                class="label-text-data"
              >
                {{ $t('ViewPlantation.view_caudal') }}
              </div>
              <div
                v-if="properties.irrigation_type === 'GOTEO' ||
                  properties.irrigation_type === 'ASPERSIÓN' || properties.irrigation_type === 'INUNDACIÓN' "
                class="item-title-bold x2"
              >
                {{ properties.flow }} L/h
              </div>
            </f7-col>
            <f7-col v-if="properties.irrigation_type === 'GOTEO'">
              <div
                class="label-text-data"
              >
                {{ $t('ViewPlantation.view_goteros') }}
              </div>
              <div
                v-if="properties.irrigation_type === 'GOTEO'"
                class="item-title-bold x2"
              >
                {{ properties.dripper_per_plant }}
              </div>
            </f7-col>
            <f7-col v-if="properties.irrigation_type === 'ASPERSIÓN'">
              <div
                class="label-text-data"
              >
                {{ $t('ViewPlantation.view_number_sprinkler_number') }}
              </div>
              <div
                v-if="properties.irrigation_type === 'ASPERSIÓN'"
                class="item-title-bold x2"
              >
                {{ properties.sprinkler_number }}
              </div>
            </f7-col>
            <f7-col>
              <div
                v-if="properties.irrigation_type === 'ASPERSIÓN'"
                class="label-text-data"
              >
                {{ $t('ViewPlantation.transformedArea') }}
              </div>
              <div
                v-if="properties.irrigation_type === 'ASPERSIÓN'"
                class="item-title-bold x2"
              >
                {{ properties.transformed_surface }} ㎡
              </div>
            </f7-col>
          </f7-row>
        </div>
        <!-- Card 03 -->
        <div class="content-devx">
          <f7-row>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_crop_type') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.crop_type }}
              </div>
            </f7-col>

            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_protection') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.protection }}
              </div>
            </f7-col>

            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_management') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.management }}
              </div>
            </f7-col>
          </f7-row>

          <f7-row>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_specie') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.species }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_variety') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.variety }}
              </div>
            </f7-col>
            <f7-col />
          </f7-row>
          <f7-row>
            <f7-col v-if="actualPlantation.crop_type !== 'HERBÁCEO'">
              <div
                class="label-text-data"
              >
                {{ $t('ViewPlantation.view_planting_frame') }}
              </div>
              <div
                v-if="actualPlantation.plantation_frame !== null"
                class="item-title-bold x2"
              >
                {{ `${actualPlantation.plantation_frame.x} m x ${actualPlantation.plantation_frame.y} m` }}
              </div>
              <div v-else />
            </f7-col>
            <f7-col v-if="actualPlantation.crop_type !== 'HORTÍCOLA' && actualPlantation.crop_type !== 'LEÑOSO'">
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_planting_density') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.planting_density ? actualPlantation.planting_density : '' }} {{ actualPlantation.planting_unit ? actualPlantation.planting_unit : '' }}
              </div>
            </f7-col>
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_density_plants') }}
              </div>
              <div
                v-if="actualPlantation.plants_density !== null"
                class="item-title-bold x2"
              >
                {{ actualPlantation.plants_density }} {{ $t('ViewPlantation.plants_ha_unit') }}
              </div>
            </f7-col>
            <f7-col v-if="actualPlantation.crop_type === 'HERBÁCEO'" />
            <f7-col v-if="actualPlantation.crop_type !== 'HERBÁCEO'">
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_number_plants') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.plants }}
              </div>
            </f7-col>
          </f7-row>

          <f7-row v-if="actualPlantation.crop_type === 'LEÑOSO'">
            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_date_plantation') }}
              </div>
              <div class="item-title-bold x2">
                {{ actualPlantation.plantation_date ? dateFormatting(actualPlantation.plantation_date) : '' }}
              </div>
            </f7-col>

            <f7-col>
              <div class="label-text-data">
                {{ $t('ViewPlantation.view_cup_diameter') }}
              </div>
              <div
                class="item-title-bold x2"
              >
                {{ actualPlantation.cup_diameter ? actualPlantation.cup_diameter : '' }} m
              </div>
            </f7-col>

            <f7-col>
              <div
                class="label-text-data"
              >
                {{ this.$t('cropAge') }}
              </div>
              <div
                class="item-title-bold x2"
              >
                {{ difference('years') }} {{ this.$t('years') }} y
                {{ difference('months') }} {{ this.$t('months') }}
              </div>
            </f7-col>
          </f7-row>
        </div>
      </f7-col>
      <f7-col style="margin-right: 3px">
        <!-- Map -->
        <div class="content-devx main-gis-map no-padding no-margin">
          <GISGeoJsonMap
            :zoom="zoom"
            :name-map="mainMap"
            :selected-min-height="'500px'"
            :tree-locations="treeLocations"
          />
        </div>

        <div class="content-devx">
          <f7-block-title class="no-margin text-uppercase">
            {{ $t('enclosureSigpacTable.title') }}
          </f7-block-title>

          <DxDataGrid
          class="margin-bottom"
          :show-borders="true"
          :data-source="intersecadosTable"
          :allow-column-resizing="true"
          :column-auto-width="true"
        >
          <DxColumn data-field="reference" :caption="$t('enclosureSigpacTable.reference')" :allow-sorting="false" />
          <DxColumn data-field="interseccion" :caption="$t('enclosureSigpacTable.overlap')" :allow-sorting="false" />
          <DxColumn data-field="surface" :caption="$t('enclosureSigpacTable.enclosure')" :allow-sorting="false" />
        </DxDataGrid>
        </div>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import GISGeoJsonMap from '../../Gis/GISGeoJsonMap/index.vue';
import Api from '../../../services/Api';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';

export default {
  name: 'PageViewPlantationDetail',
  components: {
    GISGeoJsonMap,
    DxDataGrid,
	  DxColumn
  },
  data() {
    return {
      properties: {},
      actualDate: moment().format('YYYY-MM-DD'),
      keyName: 'searcher/detail',
      zoom: 17,
      mainMap: 'plantationMainMap',
      loaded: false,
      intersecados: {}
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['treeLocations', 'id', 'currentPlantation']),
    ...mapState('Explotaciones', ['actualUser']),
    intersecadosTable() {
      return this.intersecados?.features?.map(item => {
        const prop = item.properties;
        return {
          reference: `${prop.provincia}-${prop.municipio}-${prop.agregado}-${prop.zona}-${prop.poligono}-${prop.parcela}-${prop.recinto}`,
          interseccion: parseFloat(prop.SupInterseccion).toFixed(3) + ' ha',
          surface: parseFloat(prop.dn_surface).toFixed(3) + ' ha',
        }
      }) ?? [];
    }
  },
  async beforeMount() {
    this.actualPlantationFormated();
    this.$f7.preloader.show();
    try {
      const data = {
        companyId: this.currentPlantation.companyId,
        plantationId: this.currentPlantation.id,
      };
      await this.getPlantationById(data);
      await this.fetchLocationsByPlantation(data);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }

    await this.getCordenades();
  },
  methods: {
    dateFormatting(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    difference(time) {
      const startDate = moment(this.actualPlantation.plantation_date);

      return moment().diff(startDate, time);
    },
    actualPlantationFormated() {
      this.properties.variety = '';
      this.properties.area = '';
      this.properties.irrigation_type = '';
      this.properties.flow = '';
      this.properties.dripper_per_plant = '';
      this.properties.ground_texture = '';
      this.properties.sprinkler_number = '';
      this.properties.transformed_surface = '';
      for (const propertie of this.actualPlantation.farms[0].properties) {
        this.properties.variety = propertie.key === 'variety' ? propertie.value : this.properties.variety;
        this.properties.area = propertie.key === 'area' ? propertie.value : this.properties.area;
        this.properties.irrigation_type = propertie.key === 'irrigation_type' ? propertie.value : this.properties.irrigation_type;
        this.properties.flow = propertie.key === 'flow' ? propertie.value : this.properties.flow;
        this.properties.dripper_per_plant = propertie.key === 'dripper_per_plant' ? propertie.value : this.properties.dripper_per_plant;
        this.properties.ground_texture = propertie.key === 'ground_texture' ? propertie.value : this.properties.ground_texture;
        this.properties.sprinkler_number = propertie.key === 'sprinkler_number' ? propertie.value : this.properties.sprinkler_number;
        this.properties.transformed_surface = propertie.key === 'transformed_surface' ? propertie.value : this.properties.transformed_surface;
      }
    },
    getStatusCampaign() {
      if (moment(this.actualDate).isBetween(this.actualPlantation.init_date, this.actualPlantation.end_date)) {
        return this.$t('status_campania_active');
      } if (this.actualPlantation.end_date < this.actualDate) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case this.$t('plantations.grid.active'):
          colorCell = 'Activa';
          break;
        case this.$t('plantations.grid.end'):
          colorCell = 'Finalizada';
          break;
        case this.$t('plantations.grid.pending'):
          colorCell = 'Pendiente';
          break;
        default:
          break;
      }
      return colorCell;
    },
    async getCordenades() {
      const coordinates = this.treeLocations[0].children[0].children[0].geo_feature.features[0].geometry.coordinates[0];
      const polygon = coordinates.map(c => `${c[0]} ${c[1]}`).join(',');

      try {
        this.$f7.preloader.show();

        const xhr = await Api.geoserverSigpacSurfaceData(polygon);
        this.intersecados = JSON.parse(xhr.response);
      } catch (error) {
        this.$notifyDX(
          {
            message: 'No se han podido cargar los recintos SIGPAC solapados',
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('Plantation', ['fetchTreeLocationById', 'fetchLocationsByPlantation']),
    ...mapActions('RegisterPlantation', ['getPlantationById', 'fetchTreeLocationParcelSystemById']),
  },

};
</script>

<style lang="scss" scoped>
.Activa, .Finalizada, .Pendiente {
    border-radius: 2px;
    font-size: 12px;
    padding: 4px 2px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .Activa {
    color: #219653 !important;
    background: #DEFCE8 !important;
    margin-right:auto;
    text-align:center;
    width: 100px;
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #219653;
  }
  .Finalizada {
    color: #74838C;
    background: #F4F5F7;
    margin-right:auto;
    text-align:center;
    width: 100px;
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #74838C;
  }
  .Pendiente {
    color: #23465E;
    background: white;
    margin-right:auto;
    text-align:center;
    width: 100px;
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #23465E;
  }
</style>
