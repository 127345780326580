<template>
  <f7-page>
    <navbar
      text="Informe de índice radiométrico"
    />

    <div v-if="step === 1">
      <transition :css="false" @leave="leave">
        <ReportStep1
          :selectedDates="selectedDates"
          @change-selected-dates="changeSelectedDates"
        />
      </transition>
    </div>

    <div v-if="step === 2">
      <ReportStep2 />
    </div>

    <div class="margin">
      <f7-row>
        <f7-col>
          <f7-button
            class="dx-btn-cancel"
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="goBack()"
          >
            {{ $t("Button_go_to_back") }}
          </f7-button>
        </f7-col>
        <f7-col class="justify-content-end">
          <f7-button
            class="dx-btn-success"
            raised
            type="success"
            styling-mode="contained"
            @click="nextStep()"
          >
            {{ getNextButtonText }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import navbar from "../../../components/NavBar.vue";
import ReportStep1 from "./reportStep1.vue";
import ReportStep2 from "./reportStep2.vue";
import PDFMakerGis from "../../../js/pdf-maker-gis.js";
import { Device } from "framework7/framework7-lite.esm.bundle";
import Api from "../../../services/Api";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "ReportGis",
  components: {
    navbar,
    ReportStep1,
    ReportStep2,
  },
  data() {
    return {
      step: 1,
      selectedDates: [],
    };
  },
  computed: {
    ...mapState("Plantation", ["currentPlantation"]),
    ...mapState("Gis", ["currentIndexes"]),
    ...mapState("authentication", ["user"]),
    getNextButtonText() {
      if (this.step === 1) {
        return this.$t("next");
      }

      return this.$t("nutritionDetail.report.download");
    },
  },
  methods: {
    goBack() {
      if (this.step === 2) {
        this.step--;
        return;
      }

      this.$f7.views.main.router.navigate("/searcher/gissearcher/", {
        reloadCurrent: true,
      });
    },
    nextStep() {
      if (this.step === 1) {
        this.step++;
        return;
      }

      this.$notifyDX(
        {
          message: this.$t("downloadingPDF"),
          width: 450,
        },
        "success",
        2000
      );

      this.downloadReport();
    },
    changeSelectedDates(data) {
      this.selectedDates = data;
    },
    leave() {
      const timeDimension =
        document.getElementsByClassName("timecontrol-play")[0];
      if (timeDimension) {
        const timeDimensionClassList = timeDimension.classList;

        if (timeDimensionClassList.contains("pause")) {
          timeDimension.click();
        }
      }
    },
    async downloadReport() {
      const pdfmakergis = new PDFMakerGis();

      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;

      pdfmakergis.insertTableWithoutBorders([
        [
          { title: this.$t("nutritionDetail.report.client"), text: AtcName },
          {
            title: this.$t("nutritionDetail.report.date"),
            text: `${this.currentPlantation.initDate} - ${this.currentPlantation.endDate}`,
          },
          {
            title: this.$t("name"),
            text: this.currentPlantation.name,
          },
        ],
        [
          {
            title: this.$t("nutritionDetail.report.cropType"),
            text: this.currentPlantation.species,
          },
          {
            title: this.$t("nutritionDetail.report.variety"),
            text: this.currentPlantation.variety,
          },
          {
            title: this.$t("nutritionDetail.report.area"),
            text: `${this.currentPlantation.area.toFixed(3)} ha`,
          },
        ],
      ]);

      let images = [];

      for (const index in this.selectedDates) {
        const formattedDate =
          moment(this.selectedDates[index]).format("YYYY-MM-DDT00:00:00") + "Z";
        const url = `${Api.getGeoServerPrintMap()}?ws=${Api.getGeoServerWorkspace()}&layer=${
          this.currentPlantation.sectorId
        }&date=${formattedDate}&index=${this.currentIndexes.GisTimeDimension}`;

        images.push(this.imageToDataUrl(url));
      }

      const promiseImages = await Promise.all(images);

      let dataImages = [];

      if (promiseImages.length % 3 !== 0) {
        promiseImages.push(
          ...new Array(3 - (promiseImages.length % 3)).fill([])
        );
      }

      for (let i = 0; i < promiseImages.length; i += 3) {
        dataImages.push(promiseImages.slice(i, i + 3));
      }

      if (dataImages.length > 0) {
        pdfmakergis.inserTableSatelliteImages([...dataImages]);
      }

      const chart = document.getElementById("chart-gis-report");

      await pdfmakergis.insertChart(chart);

      const title = "test";

      if (Device.desktop) {
        pdfmakergis.buildAndDownload(title);
      } else {
        const pdfdata = pdfmakergis.buildForMobile();

        pdfdata.getBase64((base64) => {
          const path = `${cordova.file.externalRootDirectory}Download/`;
          const fileExtension = ".pdf";

          this.savebase64AsPDF(
            path,
            title + fileExtension,
            base64,
            "application/pdf"
          );
        });
      }
    },
    async imageToDataUrl(path) {
      const blob = await fetch(path).then((res) => res.blob());
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              this.$f7.dialog.alert(this.$t("FileDownloadedInDownloads"));
            },
            () => {
              this.$f7.dialog.alert(this.$t("SheetExport_sheet_export_error"));
            }
          );
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./reportGis.styles.scss";
</style>