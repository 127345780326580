<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualLocation.name} > ${currentParcel.name} > ${$t('sectors')} > ${$t('sector.register.title')}`" />
    <GISEditor
      :zoom="zoom"
      :name-map="mainMap"
      :center="currentParcel.coordinates"
      :tree-locations="treeLocations"
      :disabled-edit-sector="typeof currentSector.id === 'undefined'"
      :create-sector="typeof currentSector.id === 'undefined'"
      height="50vh"
    />
    <Parcel-info
      :land-size="areaToDisplay"
      :lang="lngToDisplay"
      :lat="latToDisplay"
    />

    <RegisterSectorStep1
      :ground-textures-availables="groundTexturesAvailables"
      :irrigation-types-availables="irrigationTypesAvailables"
      :current-sector="currentSector"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="registerSector"
        >
          {{ $t("save") }}
        </f7-button>
      </f7-col>
    </f7-row>
    <PopupRegisterSectorStep2 />
  </f7-page>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';
import GISEditor from '../../components/Gis/GISEditor/index.vue';
import ParcelInfo from '../../components/parcel/parcelInfo/index.vue';
import PopupRegisterSectorStep2 from '../../components/sector/popupRegisterSectorStep2/index.vue';
import Formatter from './formatter';
import RegisterSectorStep1 from '../../components/sector/registerSectorStep1/index.vue';

export default {
  name: 'RegisterSectorStep2Page',
  components: {
    navbar,
    GISEditor,
    ParcelInfo,
    PopupRegisterSectorStep2,
    RegisterSectorStep1,
  },
  data() {
    return {
      zoom: 20,
      mainMap: 'registerSectorStep2',
      editing: false,
      groundTexturesAvailables: [],
      irrigationTypesAvailables: [],
    };
  },

  computed: {
    areaToDisplay() {
      if (this.editing) {
        return this.area;
      }

      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.area;
      }
      return this.area;
    },
    lngToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lng;
      }

      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.currentCentroid.longitude;
      }
      return this.viewCentroide.lng;
    },
    latToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lat;
      }

      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.currentCentroid.latitude;
      }
      return this.viewCentroide.lat;
    },
    ...mapState('RegisterLocation', [
      'name',
      'registerNumber',
      'province',
      'mobile',
      'farm',
      'ownerFarm',
    ]),
    ...mapState('GisEditor', ['area', 'centroide', 'currentGeoFeature']),
    ...mapState('Sector', ['currentSector', 'currentKeyValues']),
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
    ...mapState('Plantation', ['treeLocations']),
    ...mapGetters('GisEditor', ['viewCentroide']),
  },
  async beforeMount() {
    if (typeof this.currentSector.id === 'undefined') {
      this.editing = false;
      this.resetCentroide();
    } else {
      this.setCentroide({ lat: this.currentSector.currentCentroid.latitude, lng: this.currentSector.currentCentroid.longitude });
      this.setArea(this.currentSector.area);
      this.editing = true;
    }

    try {
      await this.fetchKeyValuesByFamily('GROUND_TEXTURE');
      this.groundTexturesAvailables = this.currentKeyValues;
      await this.fetchKeyValuesByFamily('IRRIGATION_TYPE');
      this.irrigationTypesAvailables = this.currentKeyValues;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async registerSector() {
      if (this.validationForm()) {
        // Esto es para el cuadro de diálogo que crea un sector que ocupa toda la parcela.
        if (this.currentParcel.children?.length === 0 && !this.currentSector.id) {
          this.setShowAutoSector(true);
        }
      } else {
        return;
      }

      if (
        typeof this.actualUser.id !== 'undefined'
        && this.actualUser.id !== ''
        && typeof this.currentParcel.id !== 'undefined'
        && this.currentParcel.id !== ''
      ) {
        if (
          (this.currentGeoFeature.length !== null
          && this.currentGeoFeature.length !== {}
          && this.centroide !== null) || this.editing
        ) {
          this.$f7.preloader.show();
          try {
            let geoFeature = null;

            if (this.area === 0) {
              this.setCentroide({ lat: this.currentSector.currentCentroid.latitude, lng: this.currentSector.currentCentroid.longitude });
              this.setArea(this.currentSector.area);
              geoFeature = this.currentSector.geoFeature;
            } else if (this.currentGeoFeature.features[0].geometry.coordinates.length !== 0) {
              geoFeature = this.currentGeoFeature;
            } else {
              geoFeature = this.currentSector.geoFeature;
            }
            const companyId = this.actualUser.id;
            const newSector = {
              name: this.currentSector.sectorName,
              geo_feature: geoFeature,
              type: 'AGRARIAN', // Para sectores siempre tiene ese valor
              coordinates: {
                latitude: this.centroide.lat,
                longitude: this.centroide.lng,
              },
              code: `${this.currentSector.sectorName}${Date.now()}`,
              parent: this.currentParcel.id,
              properties: Formatter.formatSectorProperties(this.currentSector, this.area),
            };

            if (typeof this.currentSector.id === 'undefined') {
              await this.createSector({ companyId, newSector });
            } else {
              await this.updateSector({
                companyId,
                sectorUpdated: newSector,
                sectorId: this.currentSector.id,
              });
            }
          } catch (error) {
            this.$f7.dialog.alert(this.$t(`${error}`));
          } finally {
            this.$f7.preloader.hide();
            this.setShowPopup(true);
          }
        } else {
          this.$f7.dialog.alert(this.$t('sector.register.gisValidation'));
        }
      } else {
        this.$f7.dialog.alert(this.$t('sector.register.registerValidation'));
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/viewParcel/', {
        reloadCurrent: true,
      });
    },
    validationForm() {
      let validationMainForm = false;
      let validationIrrigationForm = false;
      if (this.currentSector.sectorName === '') {
        this.$f7.dialog.alert(this.$t('sector.register.required_name'));
      } else if (this.currentSector.groundTexture === '' || this.currentSector.groundTexture === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_groundTexture'));
      } else if (this.currentSector.irrigationType === '' || this.currentSector.irrigationType === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_irrigationType'));
      } else {
        validationMainForm = true;
      }

      if (this.currentSector.irrigationType !== ''
      && this.currentSector.irrigationType !== null) {
        switch (this.currentSector.irrigationType) {
          case 'GOTEO':
            validationIrrigationForm = this.validationIrrigationTypeGoteo();
            break;
          case 'ASPERSIÓN':
            validationIrrigationForm = this.validationIrrigationTypeAspersion();
            break;
          case 'INUNDACIÓN':
            validationIrrigationForm = this.validationIrrigationTypeInundacion();
            break;
          default:
            validationIrrigationForm = true;
            break;
        }
      }
      return validationMainForm && validationIrrigationForm;
    },
    validationIrrigationTypeGoteo() {
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_flowPerPlant'));
        return false;
      }
      if (this.currentSector.dripperPerPlant === 0 || this.currentSector.dripperPerPlant === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_dripperPerPlant'));
        return false;
      }
      return true;
    },
    validationIrrigationTypeAspersion() {
      if (this.currentSector.sprinklersNumber === 0 || this.currentSector.sprinklersNumber === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_sprinklersNumber'));
        return false;
      }
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_flowPerPlant'));
        return false;
      }
      if (this.currentSector.transformedArea === 0 || this.currentSector.transformedArea === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_transformedArea'));
        return false;
      }
      return true;
    },
    validationIrrigationTypeInundacion() {
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_flowPerPlant'));
        return false;
      }
      return true;
    },
    ...mapActions('Sector', ['createSector', 'updateSector', 'setShowPopup', 'fetchKeyValuesByFamily', 'setShowAutoSector']),

    ...mapActions('GisEditor', ['resetCentroide', 'setArea', 'setCentroide']),
  },
};
</script>

<style scoped>
.dx-btn-cancel.margin-right {
  width: 15%;
  margin-left: 2%;
}
.dx-btn-success {
  width: 30%;
  margin-left: 68%;
}
</style>
