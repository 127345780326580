import navbar from '@/components/NavBar.vue';
import treatmentDetailOrderComponent from '@/components/wizardTreatment/treatmentDetailOrder/index.vue';
import { mapState } from 'vuex';

export default {
  name: 'treatment-detail-order',
  components: {
    navbar,
    treatmentDetailOrderComponent,
  },
  props: ['id'],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};
