import Request from './Request';
import Api from './Api';

const ReferenceValueService = {

  async getAll(species) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/crop_needs?crop=${species}`, { data: {} }, 'GET', false, 'application/json', Api.getHeaderAuth());
    return JSON.parse(xhr.response);
  },

};

export default ReferenceValueService;
