var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_c('DxPopup',{attrs:{"visible":_vm.showPopUp,"drag-enabled":false,"close-on-outside-click":false,"on-hidden":_vm.onHidden,"show-title":true,"show-close-button":true,"width":500,"height":"auto","title":_vm.popUpTitle}},[[_c('div',{staticClass:"content-form"},[_c('DxForm',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('taskByCompany_group'),"caption":_vm.$t('taskByCompany_group'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.taskActivity,
              value: _vm.taskActivitySelected,
              readOnly: _vm.isView,
              searchEnabled: true,
              placeholder: _vm.$t('taskByCompany_placeholder_group'),
              onValueChanged: _vm.onSelectedGroupTask,
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('taskByCompany_Task'),"caption":_vm.$t('taskByCompany_Task'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.taskDefinition,
              value: _vm.taskDefinitionSelected,
              displayExpr: 'name',
              valueExpr: 'id',
              readOnly: _vm.isView,
              searchEnabled: true,
              placeholder: _vm.$t('taskByCompany_placeholder_task'),
              onValueChanged: _vm.onSelectedTask,
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('taskByCompany_init_date'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeInitDate,
              displayFormat: 'dd/MM/yyyy',
              value: _vm.initDate,
              readOnly: _vm.isView,
              placeholder: _vm.$t('taskByCompany_placeholder_init_date'),
              invalidDateMessage: _vm.$t('dates_isInvalid'),
              disabledDates: _vm.disabledDates,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_init_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('taskByCompany_end_date'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeEndDate,
              displayFormat: 'dd/MM/yyyy',
              value: _vm.endDate,
              readOnly: _vm.isView,
              placeholder: _vm.$t('taskByCompany_placeholder_init_date'),
              invalidDateMessage: _vm.$t('dates_isInvalid'),
              disabledDates: _vm.disabledDates,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('taskByCompany_description'),"caption":_vm.$t('taskByCompany_description'),"editor-type":"dxTextArea","editor-options":{
              onValueChanged: _vm.onChangeDescription,
              value: _vm.description,
              readOnly: _vm.isView,
              placeholder: _vm.$t('taskByCompany_placeholder_description'),
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1)],1)],1)],_vm._v(" "),_c('div',{staticClass:"content-action-botton"},[(!_vm.isView)?_c('f7-button',{staticClass:"dx-btn-cancel btn-add",attrs:{"raised":"","type":"success","styling-mode":"contained","disabled":_vm.disabledSaveButton},on:{"click":_vm.validateForm}},[_vm._v("\n        "+_vm._s(_vm.$t("Button_go_create_new_Campania"))+"\n      ")]):_vm._e()],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }