<template>
  <f7-block class="content-devx-margin-block no-margin-horizontal margin-bottom">
    <f7-row>
      <f7-col width="100">
        <div v-if="!loadedGIS || (currentIndexes[`${mainMap}Left`] === '' && currentIndexes[`${mainMap}Right`] === '')">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div v-else>
          <GISWMSSwipeMap
            :zoom="zoom"
            :name-map="mainMap"
            :options-left-key="`${mainMap}Left`"
            :options-right-key="`${mainMap}Right`"
            :tree-locations="treeLocations"
            :layer="layer"
            :indexes="[currentIndexes[`${mainMap}Left`], currentIndexes[`${mainMap}Right`]]"
            :center="center"
            :bbox="bbox"
            :height="'450px'"
          />
        </div>
      </f7-col>
    </f7-row>
    <f7-row class="margin-bottom-less-5">
      <f7-col
        v-if="loadedGIS"
        width="50"
      >
        <GISLayerOptionsComparator
          :key-id="`${mainMap}Left`"
          :default-index="'NDVI'"
          :dates-list="currentDatesList"
          :one-column="true"
        />
      </f7-col>
      <f7-col
        v-if="loadedGIS"
        width="50"
      >
        <GISLayerOptionsComparator
          :key-id="`${mainMap}Right`"
          :default-index="'NDVI'"
          :dates-list="currentDatesList"
          :one-column="true"
        />
      </f7-col>
    </f7-row>
  </f7-block>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GISWMSSwipeMap from '../../../Gis/GISWMSSwipeMap/index.vue';
import GISLayerOptionsComparator from '../../../Gis/LayerOptionsComparator/index.vue';
import Api from '../../../../services/Api';

export default {
  name: 'GisScanning',
  components: {
    GISWMSSwipeMap,
    GISLayerOptionsComparator,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'gisScanning',
      currentDatesList: {},
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      center: {},
      ws: Api.getGeoServerWorkspace(),
      bbox: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['treeLocations', 'currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'datesList', 'currentBoundsWMSLayer']),
  },
  async beforeMount() {
    try {
      this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      await this.boundsWMSLayer(`timacagro:${this.currentPlantation.sectorId}`);
      await this.fetchDatesLayer(this.currentPlantation.sectorId);
      this.bbox = { ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox };
      this.center = { ...this.currentPlantation.coordinates };
      this.currentDatesList = this.datesList;
      this.loadedGIS = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['fetchDatesLayer', 'resetCurrentIntervalDates', 'boundsWMSLayer']),
  },
};
</script>

<style lang="scss" scoped>
@import './GisScanning.styles.scss';
</style>
