export default {
  namespaced: true,
  state: {
    searchFromDate: null,
    searchToDate: null,
  },
  actions: {
    setSearchFromDate({ commit }, value) {
      commit('setSearchFromDate', value);
    },
    setSearchToDate({ commit }, value) {
      commit('setSearchToDate', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setSearchFromDate(state, value) {
      state.searchFromDate = value;
    },
    setSearchToDate(state, value) {
      state.searchToDate = value;
    },
    clearFilters(state) {
      state.searchFromDate = null;
      state.searchToDate = null;
    },
  },

};
