import { fertilizationStatus } from '@/services/fertilizationService';
import numeral from 'numeral';
import moment from 'moment';

import contextMenu from "@/components/contextMenu/index.vue";
import PopupSelectObservationsReport from "@/components/reports/popupSelectObservationsReport.vue";
import { mapActions, mapState } from 'vuex';
import PDFMaker from "../../../js/pdf-maker-plans.js";
import { Device } from 'framework7/framework7-lite.esm.bundle';
import Api from "../../../services/Api";
import reportHeaderImage from "../../../static/img/TIMAC-Agro.png";
import ProductDataFormatter from '@/pages/wizardNutrition/report-formatter/product-data-formatter';

const numeralFormat = '0,0.00';

export default {
  name: 'nutrition-detail',
  components: {
    contextMenu,
    PopupSelectObservationsReport
  },
  props: {
    fertilization: {
      type: Object,
      default: () => {},
    },
    plantationProperty: {
      type: Object,
      default: () => {},
    },
    hideReportButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productResume: [],
      showSelectObservationsReport: false,
      reportHeaderImage
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('Plantation', ['currentPlantation']),
    items() {
      if (this.hideReportButton) {
        return [
          {
            icon: 'copy',
            text: this.$t('ContextMenu.Copy'),
            onItemClick: this.goToViewCopyNutritionPlan,
          },
          {
            icon: 'edit',
            text: this.$t('ContextMenu.Edit'),
            onItemClick: this.editItem,
          },
        ]
      }

      return [
        {
          icon: 'download',
          text: this.$t('ContextMenu.Download'),
          onItemClick: this.viewReport,
        },
        {
          icon: 'copy',
          text: this.$t('ContextMenu.Copy'),
          onItemClick: this.goToViewCopyNutritionPlan,
        },
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.editItem,
        },
      ];
    },
    fertilizationBalance() {
      const object1 = this.getUfsTotal();
      const object2 = this.fertilization.nutritional_needs;

      return {
        n: object1.n - object2.n,
        p205: object1.p - object2.p205,
        k20: object1.k - object2.k20,
        ca0: object1.c - object2.ca0,
        mg0: object1.m - object2.mg0,
        s03: object1.s - object2.s03,
      };
    },
    calculateUfContributed() {
      const ufContributed = {
        n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };

      this.fertilization.contribution_products.forEach((item) => {
        const totalPeriods = item.periods.reduce((a, b) => a + b);
        ufContributed.n += this.calculateElementBalance(item.n, totalPeriods);
        ufContributed.p += this.calculateElementBalance(item.p205, totalPeriods);
        ufContributed.k += this.calculateElementBalance(item.k20, totalPeriods);
        ufContributed.c += this.calculateElementBalance(item.ca0, totalPeriods);
        ufContributed.m += this.calculateElementBalance(item.mg0, totalPeriods);
        ufContributed.s += this.calculateElementBalance(item.s03, totalPeriods);
      });

      return ufContributed;
    },
    calculateFinalBalance() {
      const a = this.fertilizationBalance;
      const b = this.calculateUfContributed;

      return {
        n: parseFloat((a.n + b.n).toFixed(3)),
        p205: parseFloat((a.p205 + b.p).toFixed(3)),
        k20: parseFloat((a.k20 + b.k).toFixed(3)),
        ca0: parseFloat((a.ca0 + b.c).toFixed(3)),
        mg0: parseFloat((a.mg0 + b.m).toFixed(3)),
        s03: parseFloat((a.s03 + b.s).toFixed(3)),
      }
    }
  },
  async mounted() {
    const formatter = new ProductDataFormatter(this.fertilization);
    try {
      await formatter.buildProductResumeData();
    } catch (e) {
      const notFoundNutritionProducts = JSON.parse(e.message);
    }
    this.productResume = formatter.productResume;
  },
  methods: {
    ...mapActions('Plantation', ['setIsPlantationSelected']),
    ...mapActions('Nutrition', ['setEditingFromContextMenu']),
    ...mapActions('nutritionStepper', ['setIsEditing']),
    getNameStatus(status) {
      return this.$t(status?.toUpperCase()) ?? '';
    },
    goToViewCopyNutritionPlan() {
      this.$f7.views.main.router.navigate('/viewCopyNutritionPlan/', { reloadCurrent: true });
    },
    getClassStatus(status) {
      switch (status) {
        case fertilizationStatus.ACTIVE:
          return 'Activos';
        case fertilizationStatus.CANCELLED:
          return 'label-danger';
        default:
        case fertilizationStatus.PLANNED:
          return 'Planificado';
      }
    },
    formatNumber(number) {
      return numeral(number).format(numeralFormat);
    },
    formatingDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    viewReport() {
      this.openSelectObservationsReport();
      // this.$f7.views.main.router.navigate('/viewNutritionReport/', { reloadCurrent: true });
    },
    async downloadReport(selectedObservations) {
      const pdfmaker = new PDFMaker();

      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;

      pdfmaker.insertTableWithoutBorders([
        [
          { title: this.$t("nutritionDetail.report.client"), text: AtcName },
          {
            title: this.$t("nutritionDetail.report.date"),
            text: `${this.fertilization.init_date} - ${this.fertilization.end_date}`,
          },
          {
            title: this.$t("nutritionDetail.report.description"),
            text: this.fertilization.description,
          },
        ],
        [
          {
            title: this.$t("nutritionDetail.report.cropType"),
            text: this.currentPlantation.species,
          },
          {
            title: this.$t("nutritionDetail.report.variety"),
            text: this.currentPlantation.variety,
          },
          {
            title: this.$t("nutritionDetail.report.area"),
            text: `${this.fertilization.area} ha`,
          },
        ],
      ]);

      pdfmaker.insertTableGeneric('RESUMEN POR ELEMENTOS', [
        [
          this.$t('nutritionDetail.elementResume.uf'),
          this.$t('nutritionDetail.elementResume.n'),
          // this.$t('nutritionDetail.elementResume.p'),
          'P2O5',
          // this.$t('nutritionDetail.elementResume.k'),
          'K2O',
          this.$t('nutritionDetail.elementResume.c'),
          this.$t('nutritionDetail.elementResume.m'),
          // this.$t('nutritionDetail.elementResume.s'),
          'SO3',
        ],
        [
          this.$t('nutritionDetail.elementResume.necNutritional'),
          this.fertilization.nutritional_needs.n,
          this.fertilization.nutritional_needs.p205,
          this.fertilization.nutritional_needs.k20,
          this.fertilization.nutritional_needs.ca0,
          this.fertilization.nutritional_needs.mg0,
          this.fertilization.nutritional_needs.s03,
        ],
        [
          this.$t('nutritionDetail.elementResume.fertilizationBalance'),
          this.fertilizationBalance.n,
          this.fertilizationBalance.p205,
          this.fertilizationBalance.k20,
          this.fertilizationBalance.ca0,
          this.fertilizationBalance.mg0,
          this.fertilizationBalance.s03,
        ],
        [
          this.$t('nutritionDetail.elementResume.ufContributed'),
          this.calculateUfContributed.n,
          this.calculateUfContributed.p,
          this.calculateUfContributed.k,
          this.calculateUfContributed.c,
          this.calculateUfContributed.m,
          this.calculateUfContributed.s,
        ],
        [
          this.$t('nutritionDetail.elementResume.finalBalance'),
          this.calculateFinalBalance.n,
          this.calculateFinalBalance.p205,
          this.calculateFinalBalance.k20,
          this.calculateFinalBalance.ca0,
          this.calculateFinalBalance.mg0,
          this.calculateFinalBalance.s03,
        ]
      ])

      const graph = document.querySelector('.product-chart');

      const productsResumeTable = [];

      this.productResume.forEach(product => {
        productsResumeTable.push([
          product.product,
          product.dosageUnit,
          product.dosagePlant,
          product.dosageTotal,
          product.price,
          product.costUnit,
          product.costPlant,
          product.costTotal,
        ]);
      })

      await pdfmaker.insertTableAndGraph("RESUMEN POR PRODUCTOS", [
        [
          this.$t('nutritionDetail.productResume.product'),
          this.$t('nutritionDetail.productResume.dosageUnit'),
          this.$t('nutritionDetail.productResume.dosagePlant'),
          this.$t('nutritionDetail.productResume.dosageTotal'),
          this.$t('nutritionDetail.productResume.price'),
          this.$t('nutritionDetail.productResume.costUnit'),
          this.$t('nutritionDetail.productResume.costPlant'),
          this.$t('nutritionDetail.productResume.costTotal'),
        ],
        ...productsResumeTable
      ], graph);

      const observations = await Promise.all(
        selectedObservations.map(async (item, index) => {
          const xhr = await Api.getObservationDetail(
            this.currentPlantation.companyId,
            item.id
          );
          const observation = JSON.parse(xhr.response);

          const comment = [];

          observation.template_form.forEach((element) => {
            comment.push([element.description, observation[element.field]]);
          });

          return {
            id: observation.id,
            title: `Observación ${index + 1}:`,
            date: observation.date,
            multimedia: observation.multimedia.filter(
              (extension) =>
                extension.extension === ".jpg" || extension.extension === ".png"
            ),
            comment,
          };
        })
      );

      const temporalImage = await this.imageToBase64(this.reportHeaderImage);

      if (
        observations.length > 0 ||
        (this.fertilization.observation !== "" &&
          this.fertilization.observation != null)
      ) {
        pdfmaker.insertObservations(
          this.fertilization.observation,
          [...observations],
          temporalImage
        );
      }
      
      pdfmaker.insertTableBottomSignature([
        [
          ["Nombre", AtcName],
          ["Fecha", moment().format("DD/MM/YYYY")],
          ["Firma", ""],
        ],
      ]);

      const title = `${this.currentPlantation.name} - ${this.fertilization.description} - ${moment().format("DD-MM-YYYY")}`;

      if (Device.desktop) {
        pdfmaker.buildAndDownload(title);
      } else {
        const pdfdata = pdfmaker.buildForMobile();

        pdfdata.getBase64((base64) => {
          const path = `${cordova.file.externalRootDirectory}Download/`;
          const fileExtension = ".pdf";

          this.savebase64AsPDF(
            path,
            title + fileExtension,
            base64,
            "application/pdf"
          );
        });
      }
    },
    editItem() {
      this.setIsEditing(true);
      this.setIsPlantationSelected(false);
      this.setEditingFromContextMenu(this.fertilization.id)
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_nutrition_nutrition-step-one',
        params: { id: this.fertilization.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    openSelectObservationsReport() {
      this.showSelectObservationsReport = true;
    },
    closeSelectObservationsReport() {
      this.showSelectObservationsReport = false;
    },
    async imageToBase64(path) {
      const blob = await fetch(path).then((res) => res.blob());
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              this.$f7.dialog.alert(this.$t("FileDownloadedInDownloads"));
            },
            () => {
              this.$f7.dialog.alert(this.$t("SheetExport_sheet_export_error"));
            }
          );
        });
      });
    },
    getUfsTotal() {
      return {
        n: this.sumUfs('n'),
        p: this.sumUfs('p205'),
        k: this.sumUfs('k20'),
        c: this.sumUfs('ca0'),
        m: this.sumUfs('mg0'),
        s: this.sumUfs('s03'),
      };
    },
    sumUfs(prop) {
      return this.fertilization.uf_available_soil[prop]
          + this.fertilization.uf_irrigation_water[prop]
          + this.fertilization.uf_rest_harvest[prop];
    },
    calculateElementBalance(value, price) {
      return Math.round((value / 100) * price);
    },
  },
};
