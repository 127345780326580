<template>
  <DxForm :show-colon-after-label="false">
    <DxGroupItem>
      <DxSimpleItem
        :data-field="$t('Searcher.observations.name')"
        :editor-options="{
          onValueChanged: onChangeName,
        }"
      />
    </DxGroupItem>
  </DxForm>
</template>

<script>

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import { mapActions } from 'vuex';

export default {
  name: 'SectorTab',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
  },
  beforeMount() {
    this.setTypePoint('ALL');
  },
  methods: {
    onChangeName(e) {
      this.setName(e.value);
    },
    ...mapActions('SearcherObservations', ['setName', 'setTypePoint']),
  },
};
</script>
<style lang="scss" scoped>
@import './SectorTab.styles.scss';
</style>
