var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"content-devx"},[_c('f7-block-title',[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]),_vm._v(" "),_c('f7-block',[_c('DxForm',{attrs:{"id":"form","col-count":1,"show-colon-after-label":false}},[_c('DxGroupItem',{attrs:{"col-count":4}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Utilities.ViewLocation_name'),"name":"name","caption":_vm.$t('Register_farm_name'),"editor-options":{
            value: _vm.currentExplotation.name,
            mode: 'text',
            placeholder: _vm.$t('register_location_placeholder_name'),
            showClearButton: true,
            onValueChanged: _vm.onNameChanged,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxEmployeeForm_name_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_Farm_numeroRegistro'),"name":"name","caption":_vm.$t('Register_Farm_numeroRegistro'),"editor-options":{
            value: _vm.currentExplotation.registerNumber,
            mode: 'number',
            placeholder: _vm.$t('register_location_placeholder_number_register'),
            showClearButton: true,
            onValueChanged: _vm.onNumberRegisterChange,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"purchase","data-field":_vm.$t('Register_farm_provincia'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.allProvince,
            displayExpr: 'name',
            valueExpr: 'code',
            onValueChanged: _vm.onProvinceChange,
            value: _vm.currentExplotation.province,
            placeholder: _vm.$t('register_location_placeholder_province'),
            searchEnabled: true,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('register_location_required_province')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('Register_farm_municipio'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.allCities,
            displayExpr: 'name',
            valueExpr: 'code',
            onValueChanged: _vm.onFarmChange,
            value: _vm.currentExplotation.farm,
            placeholder: _vm.$t('register_location_placeholder_municipality'),
            searchEnabled: true,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('register_location_required_locality')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_Farm_titularExplotacion'),"name":"name","caption":_vm.$t('Register_Farm_titularExplotacion'),"editor-options":{
            value: _vm.currentExplotation.ownerFarm,
            placeholder: _vm.$t('register_location_placeholder_owner'),
            showClearButton: true,
            onValueChanged: _vm.onOwnerFarmChange,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Register_Farm_mobile'),"name":"name","caption":_vm.$t('Register_Farm_mobile'),"editor-options":{
            value: _vm.currentExplotation.mobile,
            placeholder: _vm.$t('register_location_placeholder_number_phone'),
            showClearButton: true,
            onValueChanged: _vm.onMobileChanged,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('register_location_postalCode'),"name":"postalCode","caption":_vm.$t('register_location_postalCode'),"editor-options":{
            value: _vm.currentExplotation.postalCode,
            mode: 'number',
            placeholder: _vm.$t('register_location_placeholder_postalCode'),
            showClearButton: true,
            onValueChanged: _vm.onPostalCodeChange
          }}})],1)],1),_vm._v(" "),_c('DxPopUpRegisterPloit')],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }