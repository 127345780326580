import moment from 'moment';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    dateFields: [[], []],
    dateIcons: [[], []],
    farmsData: [],
    // Guardo las localizaciones para obtener el nombre.
    locations: [],
    AllLocation: [],
    // Guardo los parametros del viento  para mostrarlos en el componente weatherForecast.
    windSpeed: [],
    windDirection: [],
  },
  getters: {
  },
  mutations: {
    UPDATE_FARMS_DATA(state, value) {
      state.farmsData = [];
      state.farmsData.push(value);
    },
    SET_DATAICON(state, value) {
      state.dateIcons = value;
    },
    UPDATE_DATAICON(state, value) {
      state.dateIcons[0].push(value.index_0);
      state.dateIcons[1].push(value.index_1);
    },
    UPDATE_DATEFIELDS(state, value) {
      state.dateFields[0].push(value.index_0);
      state.dateFields[1].push(value.index_1);
    },
    SET_DATEFIELDS(state, value) {
      state.dateFields.push(value);
    },
    UPDATE_LOCATIONS(state, value) {
      state.locations.push(value);
    },
    UPDATE_ALL_LOCATIONS(state, value) {
      state.AllLocation = value;
    },
    UPDATE_WINDS_SPEED(state, value) {
      state.windSpeed.push(value);
    },
    UPDATE_WINDS_DIRECTION(state, value) {
      state.windDirection = value;
    },
  },
  actions: {
    async getWeatherForecastParameter({ commit, dispatch, state }, plantation) {
      try {
        const xhr = await Api.getWeatherForParameterByPlantation(plantation.companyId, plantation.id, 'FORECAST', moment.utc().format('YYYY-MM-DDT00:00:00'),
          moment.utc().add(4, 'd').format('YYYY-MM-DDT23:59:59'), ['TEMPERATURE', 'SKY_STATE', 'WIND_DIRECTION', 'WIND_SPEED']);
        const weatherParameter = JSON.parse(xhr.response);
        for (let i = 0; i < weatherParameter.length; i += 1) {
          const checkLocation = state.locations.indexOf(weatherParameter[i].location);
          // Comprobamos que el id leido del JSON existe en el array de ids de vuex.
          if (checkLocation === -1) {
            commit(
              'SET_DATEFIELDS',
              [[], []],
            );
            commit(
              'SET_DATAICON',
              [[], []],
            );
            let nameFarm = '';
            for (const location of plantation.locations) {
              if (location.id === weatherParameter[i].location) {
                nameFarm = location.name;
              }
            }
            const temperatures = weatherParameter[i].parameters.TEMPERATURE;
            const skyState = weatherParameter[i].parameters.SKY_STATE;
            const windDirection = weatherParameter[i].parameters.WIND_DIRECTION;
            const windSpeed = weatherParameter[i].parameters.WIND_SPEED;
            if (skyState) {
              for (let j = 0; j < skyState.length; j += 1) {
                const actualHour = moment(skyState[j].date).format('H');
                if (actualHour === '12') {
                  const actualDate = moment(skyState[j].date).format(
                    'YYYY-MM-DD',
                  );
                  commit(
                    'UPDATE_DATAICON',
                    { index_0: actualDate, index_1: skyState[j].value },
                  );
                }
              }
            }
            // Cargo los datos de windDirection y windSpeed para usarlos en buildTemperature.
            commit(
              'UPDATE_WINDS_SPEED',
              windSpeed,
            );
            commit(
              'UPDATE_WINDS_DIRECTION',
              windDirection,
            );
            // Cargo las localizaciones para sacar el nombre.
            commit(
              'UPDATE_LOCATIONS',
              weatherParameter[i].location,
            );
            dispatch('buildTemperature', temperatures);
            // Añado la granja con todos sus datos de prediccione
            commit(
              'UPDATE_FARMS_DATA',
              { farm: nameFarm, data: state.dateFields[1] },
            );
          }
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    buildTemperature({ commit, state }, temperatures) {
      const newDatefields = [[], []];
      let directionValue = 0;
      let speedValue = 0;
      const windDirectionSize = state.windDirection.length;
      const windSpeedSize = state.windSpeed[0].length;
      for (let k = 0; k < state.windDirection.length; k += 1) {
        directionValue += state.windDirection[k].value;
        speedValue += state.windSpeed[0][k].value;
      }
      let windDirectionDeg = 0;
      if (windDirectionSize !== 0) {
        windDirectionDeg = (directionValue / windDirectionSize);
      }
      if (windDirectionDeg >= 0 && windDirectionDeg < 45) {
        directionValue = 'Norte';
      } else if (windDirectionDeg <= 45) {
        directionValue = 'Noreste';
      } else if (windDirectionDeg <= 90) {
        directionValue = 'Este';
      } else if (windDirectionDeg <= 135) {
        directionValue = 'Sureste';
      } else if (windDirectionDeg <= 180) {
        directionValue = 'Sur';
      } else if (windDirectionDeg <= 225) {
        directionValue = 'Suroeste';
      } else if (windDirectionDeg <= 270) {
        directionValue = 'Oeste';
      } else if (windDirectionDeg <= 315) {
        directionValue = 'Noroeste';
      } else {
        directionValue = 'Norte';
      }
      // eslint-disable-next-line guard-for-in
      for (let j = 0; j < temperatures.length; j += 1) {
        const actualDate = moment(temperatures[j].date).format(
          'YYYY-MM-DD',
        );
        const actualValue = temperatures[j].value;
        const checkExist = state.dateFields[0].indexOf(actualDate);
        if (checkExist === -1) {
          // Si no existe el día para la farm actual, lo añado tal cual
          const weekday = moment(temperatures[j].date).format('dddd');
          const numDay = moment(temperatures[j].date)
            .format('YYYY-MM-DD')
            .split('-');
          const checkExistIcon = state.dateIcons[0].indexOf(actualDate);
          // Establezco el icono que venga del dataset, o en caso de no haberlo, clear-day
          let iconWeather = 'clear-day';
          if (checkExistIcon !== -1) {
            iconWeather = state.dateIcons[1][checkExistIcon];
          }
          if (windSpeedSize !== 0) {
            speedValue /= windSpeedSize;
          } else {
            speedValue = 0;
          }
          commit(
            'UPDATE_DATEFIELDS',
            {
              index_0: actualDate,
              index_1: {
                day: weekday,
                numberday: numDay[2],
                minTemp: actualValue,
                maxTemp: actualValue,
                averageTemp: actualValue,
                numTemps: 1,
                temperatures: [
                  {
                    nameBar: '',
                    temperatureMin: actualValue,
                    temperatureMax: actualValue,
                  },
                ],
                icon: iconWeather,
                windDirecction: directionValue,
                speedValue,
              },
            },
          );
        } else {
          // Si ya existe el día, lo actualizo
          const changeField = state.dateFields[1][checkExist];
          if (changeField.minTemp > actualValue) {
            changeField.minTemp = actualValue;
          }
          if (changeField.maxTemp < actualValue) {
            changeField.maxTemp = actualValue;
          }
          changeField.numTemps += 1;
          changeField.averageTemp = (changeField.maxTemp + changeField.minTemp) / 2;
          changeField.temperatures = [
            {
              nameBar: '',
              temperatureMin: changeField.minTemp,
              temperatureMax: changeField.maxTemp,
            },
          ];

          newDatefields[1][checkExist] = changeField;
        }
      }
      commit(
        'SET_DATEFIELDS',
        newDatefields,
      );
    },
    setCampaign(context, value) {
      context.commit('UPDATE_CAMPAIGN', value);
    },
    setParcel(context, value) {
      context.commit('UPDATE_PARCEL', value);
    },
    setParameterSelected(context, value) {
      context.commit('UPDATE_PARAMETER_SELECTED', value);
    },
    setInitDate(context, value) {
      context.commit('UPDATE_INIT_DATE', value);
    },
    setEndDate(context, value) {
      context.commit('UPDATE_END_DATE', value);
    },
    initStoreGraph({ commit }) {
      commit('UPDATE_DATAWEATHER_RESET', []);
      commit('UPDATE_DATADATE_RESET', []);
    },
    initStoreRegister({ commit }) {
      commit('UPDATE_DATAWEATHER_RESET', []);
      commit('UPDATE_ALL_CAMPAIGNS', []);
      commit('UPDATE_PARAMETER_SELECTED', '');
      commit('UPDATE_INIT_DATE', '');
      commit('UPDATE_END_DATE', '');
      commit('UPDATE_PARCEL', '');
      commit('UPDATE_CAMPAIGN', '');
      commit('UPDATE_PARCEL_AVAILABLES', '');
    },
  },
};
