<template>
  <div>
    <div>
      <f7-row>
        <f7-col>
          <div class="content-devx padding-half">
            <f7-row>
              <f7-col width="80">
                <f7-block-title class="no-margin">
                  {{ $t('ViewPlantation.viewPlantationTitle') }}
                </f7-block-title>
              </f7-col>
              <f7-col
                class="justify-content-end"
                width="20"
              >
                <contextMenu :items="items" />
              </f7-col>
            </f7-row>

            <div>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_description') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.name }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_campaign') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.campaign }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_status') }}
                  </div>
                  <div
                    class="no-margin margin-bottom-half"
                    :class="setClassByStatus(getStatusCampaign(actualPlantation.init_date, actualPlantation.end_date))"
                  >
                    {{ getStatusCampaign(actualPlantation.init_date, actualPlantation.end_date) }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_client') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualUser.name }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_init_date') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ dateFormatting(actualPlantation.init_date) }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_end_date') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ dateFormatting(actualPlantation.end_date) }}
                  </div>
                </f7-col>
              </f7-row>
            </div>
          </div>
          <!--CARD 2-->
          <div class="content-devx padding-half">
            <div>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_explotation') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.explotation }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_name_parcel') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.parcel }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_name_sector') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.farms[0].name }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_superficie') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ parseFloat(properties.area).toFixed(2) }} ha
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_textura_suelo') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ properties.ground_texture }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_system_irrigation') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ properties.irrigation_type }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col>
                  <div
                    v-if="properties.irrigation_type === 'GOTEO' || properties.irrigation_type === 'ASPERSIÓN' || properties.irrigation_type === 'INUNDACIÓN' "
                    class="label-text-data"
                  >
                    {{ $t('ViewPlantation.view_caudal') }}
                  </div>
                  <div
                    v-if="properties.irrigation_type === 'GOTEO' ||
                      properties.irrigation_type === 'ASPERSIÓN' || properties.irrigation_type === 'INUNDACIÓN' "
                    class="item-title-bold x2"
                  >
                    {{ properties.flow }} L/h
                  </div>
                </f7-col>
                <f7-col v-if="properties.irrigation_type === 'GOTEO'">
                  <div
                    class="label-text-data"
                  >
                    {{ $t('ViewPlantation.view_goteros') }}
                  </div>
                  <div
                    v-if="properties.irrigation_type === 'GOTEO'"
                    class="item-title-bold x2"
                  >
                    {{ properties.dripper_per_plant }}
                  </div>
                </f7-col>
                <f7-col v-if="properties.irrigation_type === 'ASPERSIÓN'">
                  <div
                    class="label-text-data"
                  >
                    {{ $t('ViewPlantation.view_number_sprinkler_number') }}
                  </div>
                  <div
                    v-if="properties.irrigation_type === 'ASPERSIÓN'"
                    class="item-title-bold x2"
                  >
                    {{ properties.sprinkler_number }}
                  </div>
                </f7-col>
                <f7-col>
                  <div
                    v-if="properties.irrigation_type === 'ASPERSIÓN'"
                    class="label-text-data"
                  >
                    {{ $t('ViewPlantation.transformedArea') }}
                  </div>
                  <div
                    v-if="properties.irrigation_type === 'ASPERSIÓN'"
                    class="item-title-bold x2"
                  >
                    {{ properties.transformed_surface }} ㎡
                  </div>
                </f7-col>
              </f7-row>
            </div>
          </div>
          <!--CARD 3-->
          <div class="content-devx padding-half">
            <div>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_crop_type') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.crop_type }}
                  </div>
                </f7-col>

                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_protection') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.protection }}
                  </div>
                </f7-col>

                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_management') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.management }}
                  </div>
                </f7-col>
              </f7-row>

              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_specie') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.species }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_variety') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.variety }}
                  </div>
                </f7-col>
                <f7-col />
              </f7-row>
              <f7-row>
                <f7-col v-if="actualPlantation.crop_type !== 'HERBÁCEO'">
                  <div
                    class="label-text-data"
                  >
                    {{ $t('ViewPlantation.view_planting_frame') }}
                  </div>
                  <div
                    v-if="actualPlantation.plantation_frame !== null"
                    class="item-title-bold x2"
                  >
                    {{ `${actualPlantation.plantation_frame.x} m x ${actualPlantation.plantation_frame.y} m` }}
                  </div>
                  <div v-else />
                </f7-col>
                <f7-col v-if="actualPlantation.crop_type !== 'HORTÍCOLA' && actualPlantation.crop_type !== 'LEÑOSO'">
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_planting_density') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.planting_density ? actualPlantation.planting_density : '' }} {{ actualPlantation.planting_unit ? actualPlantation.planting_unit : '' }}
                  </div>
                </f7-col>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_density_plants') }}
                  </div>
                  <div
                    v-if="actualPlantation.plants_density !== null"
                    class="item-title-bold x2"
                  >
                    {{ actualPlantation.plants_density }} {{ $t('ViewPlantation.plants_ha_unit') }}
                  </div>
                </f7-col>
                <f7-col v-if="actualPlantation.crop_type === 'HERBÁCEO'" />
                <f7-col v-if="actualPlantation.crop_type !== 'HERBÁCEO'">
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_number_plants') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.plants }}
                  </div>
                </f7-col>
              </f7-row>

              <f7-row v-if="actualPlantation.crop_type === 'LEÑOSO'">
                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_date_plantation') }}
                  </div>
                  <div class="item-title-bold x2">
                    {{ actualPlantation.plantation_date ? dateFormatting(actualPlantation.plantation_date) : '' }}
                  </div>
                </f7-col>

                <f7-col>
                  <div class="label-text-data">
                    {{ $t('ViewPlantation.view_cup_diameter') }}
                  </div>
                  <div
                    class="item-title-bold x2"
                  >
                    {{ actualPlantation.cup_diameter ? actualPlantation.cup_diameter : '' }} m
                  </div>
                </f7-col>

                <f7-col>
                  <div
                    class="label-text-data"
                  >
                    {{ this.$t('cropAge') }}
                  </div>
                  <div
                    class="item-title-bold x2"
                  >
                    {{ difference('years') }} {{ this.$t('years') }} y
                    {{ difference('months') }} {{ this.$t('months') }}
                  </div>
                </f7-col>
              </f7-row>
            </div>
          </div>
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import contextMenu from '@/components/contextMenu/index.vue';

export default {
  name: 'PageViewPlantation',
  components: {
    contextMenu,
  },
  props: {
    properties: {
      type: Object, default: () => {},
    },
  },
  data() {
    return {
      actualDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation', 'sectorArea']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('Campania', ['actual_Plot']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Gis', ['parcelCentroide', 'sectorCentroide']),
    items() {
      return [
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.goEditPlantation,
        },
        {
          icon: 'delete',
          text: this.$t('ContextMenu.Delete'),
          onItemClick: this.deletePlantation,
        },
        {
          icon: 'map',
          text: `${this.$t('viewParcel')} (Google maps)`,
          onItemClick: () => {
            window.open(
              `https://www.google.es/maps/@${this.parcelCentroide.latitude},${this.parcelCentroide.longitude},700m/data=!3m1!1e3!5m2!1e4!1e1?hl=es`, '_blank',
            );
          },
        },
        {
          icon: 'map',
          text: `${this.$t('viewSector')} (Google maps)`,
          onItemClick: () => {
            window.open(
              `https://www.google.es/maps/@${this.sectorCentroide.latitude},${this.sectorCentroide.longitude},700m/data=!3m1!1e3!5m2!1e4!1e1?hl=es`, '_blank',
            );
          },
        },
      ];
    },
  },
  beforeMount() {
    this.actualPlantationFormated();
    if (this.sectorArea === 0) {
      for (const propertie of this.actualPlantation.farms[0].properties) {
        if (propertie.key === 'area') {
          this.setSectorArea(propertie.value);
        }
      }
    }
  },
  methods: {
    deletePlantation() {
      this.togglePopupConfirmDelete(true);
    },
    dateFormatting(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    difference(time) {
      const startDate = moment(this.actualPlantation.plantation_date);

      return moment().diff(startDate, time);
    },
    actualPlantationFormated() {
      for (const propertie of this.actualPlantation.farms[0].properties) {
        this.properties.variety = propertie.key === 'variety' ? propertie.value : this.properties.variety;
        this.properties.area = propertie.key === 'area' ? propertie.value : this.properties.area;
        this.properties.irrigation_type = propertie.key === 'irrigation_type' ? propertie.value : this.properties.irrigation_type;
        this.properties.flow = propertie.key === 'flow' ? propertie.value : this.properties.flow;
        this.properties.dripper_per_plant = propertie.key === 'dripper_per_plant' ? propertie.value : this.properties.dripper_per_plant;
        this.properties.ground_texture = propertie.key === 'ground_texture' ? propertie.value : this.properties.ground_texture;
        this.properties.sprinkler_number = propertie.key === 'sprinkler_number' ? propertie.value : this.properties.sprinkler_number;
        this.properties.transformed_surface = propertie.key === 'transformed_surface' ? propertie.value : this.properties.transformed_surface;
      }
    },
    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    async goEditPlantation() {
      this.setIsEdit(true);
      this.setSectorSelected(this.actualPlantation.farms[0].id);
      this.setParcelSelected(this.actualPlantation.parcel_id);
      this.setActualPlot(this.actualPlantation.explotation_id);
      this.getCampaignsById(this.actualPlantation.campaign_id);
      this.setViewCampaignId(this.actualPlantation.campaign_id);
      try {
        let data = {
          companyId: this.actualUser.id,
          explotation: (typeof this.actual_Plot === 'undefined') ? this.actualPlantation.explotation_id : this.actual_Plot,
        };
        await this.getParcelByExplotation(data);
        data = {
          companyId: this.actualUser.id,
          parcel: this.actualPlantation.parcel_id,
        };
        await this.getSectorByParcel(data);
        await this.getListExplotation();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
      this.$f7.views.main.router.navigate('/RegisterPlantationStep1/', { reloadCurrent: true });
    },
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case this.$t('plantations.grid.active'):
          colorCell = 'Activa';
          break;
        case this.$t('plantations.grid.end'):
          colorCell = 'Finalizada';
          break;
        case this.$t('plantations.grid.pending'):
          colorCell = 'Pendiente';
          break;
        default:
          break;
      }
      return colorCell;
    },
    ...mapActions('Campania', ['getPlantationByCompany', 'setActualPlot', 'getListExplotation', 'getCampaignsByCompany', 'getCampaignsById', 'setViewCampaignId']),
    ...mapActions('RegisterPlantation', ['setSectorSelected', 'setParcelSelected', 'setSectorArea', 'getSectorByParcel',
      'getParcelByExplotation', 'setIsEdit']),
    ...mapActions('Plantation', ['togglePopupConfirmDelete']),
  },

};
</script>

<style lang="scss" scoped>
.icon {
  color: black !important;
}
.Activa, .Finalizada, .Pendiente {
    border-radius: 2px;
    font-size: 12px;
    padding: 4px 2px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .Activa {
    color: #219653 !important;
    background: #DEFCE8 !important;
    text-align:center;
    width: 100px;
    border-width: 1px;
    border-style: solid;
    border-color: #219653;
  }
  .Finalizada {
    color: #74838C;
    background: #F4F5F7;
    text-align:center;
    width: 100px;
    border-width: 1px;
    border-style: solid;
    border-color: #74838C;
  }
  .Pendiente {
    color: #23465E;
    background: white;
    text-align:center;
    width: 100px;
    border-width: 1px;
    border-style: solid;
    border-color: #23465E;
  }
</style>
