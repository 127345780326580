<template>
  <div>
    <DxPopup
      :visible="showPopUp"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :on-hidden="onHidden"
      :show-title="true"
      :width="500"
      height="auto"
      :title="$t('registerPlantation.title_select_campaign')"
    >
      <template>
        <div class="title">
          <span>{{ $t('registerPlantation.subtitle_select_campaign') }}</span>
        </div>
        <div class="content-form">
          <DxForm>
            <DxSimpleItem
              :data-field="$t('weather_campaign')"
              :caption="$t('weather_campaign')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: allcampaign,
                displayExpr: 'name',
                value: campaignId,
                valueExpr: 'id',
                placeholder: $t('registerClima_campaign_placeholder'),
                onValueChanged: onSelectedCampaign,
              }"
            >
              <DxRequiredRule :message="$t('')" />
            </DxSimpleItem>
          </DxForm>
        </div>
      </template>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          class="dx-btn-succes-pop-up"
          raised
          type="success"
          styling-mode="contained"
          @click="createNewPlantation"
        >
          {{ $t("title_create_campania") }}
        </f7-button>
      </f7-block-footer>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

export default {
  name: 'PopUpBeforeCreatePlantation',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxRequiredRule,

  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['parcelSelected']),
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Campania', ['campaignId', 'showPopUp', 'allcampaign']),
  },
  methods: {
    onHidden() {
      this.setShowPopUp(false);
      this.setCampaignId('');
    },
    createNewPlantation() {
      if (this.campaignId !== '') {
        this.setShowPopUp(false);
        this.setIdPlantation('');
        this.resetSpecieAvailablesAndVariety();
        this.$f7.views.main.router.navigate('/registerPlantationStep2/', { reloadCurrent: true });
      }
    },
    onSelectedCampaign(e) {
      this.setCampaignId(e.value);
    },
    ...mapActions('Campania', ['setCampaignId', 'setShowPopUp']),
    ...mapActions('Plantation', ['setIdPlantation']),
    ...mapActions('RegisterPlantation', ['resetSpecieAvailablesAndVariety']),
  },
};
</script>

<style lang="scss" scoped>
.content-form{
  width: 80%;
  padding-left: 50px;
}
.title {
  margin: 1rem;
  padding: 1rem;
  /* IMPORTANTE */
  text-align: center;
}
.finishedRegister {
    margin-left: 55%;
}
.center{
  padding-top: 3%;
  font-weight: bold;
}
</style>
