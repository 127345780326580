<template>
  <section class="treatment-period-details">
    <ProductPopupDetail :owner="owner" v-if="displayProductDetailPopup" />
    <div class="content-devx">
      <div class="area-field">
        <div class="dx-field">
          <div class="dx-field-label">
            {{ $t("treatment.treatmentPlan.treatmentCreatePlan.area") }}
          </div>
          <div class="dx-field-value-static">
            <DxNumberBox :value="getArea" :read-only="true" />
          </div>
          <div class="margin-right">ha</div>
        </div>
      </div>

      <div class="margin-left margin-right margin-bottom">
        <DxTabPanel
          :data-source="getPeriods"
          :selected-index.sync="selectedIndex"
          :loop="false"
          :animation-enabled="false"
          :swipe-enabled="false"
        >
          <template #title="{ data: period }">
            <span> {{ period.title }} </span>
          </template>
          <template #item="{ data: period }">
            <div>
              <TreatmentStepTwoFormDefault
                v-if="period.default_apply_unit === 'default'"
                :period="period"
                :selected-index="selectedIndex"
              />
              <TreatmentStepTwoFormPlant
                v-if="period.default_apply_unit === 'Kg-L / planta'"
                :period="period"
                :selected-index="selectedIndex"
              />
              <TreatmentStepTwoFormVat
                v-if="period.default_apply_unit === 'Kg-L / cuva'"
                :period="period"
              />

              <TreatmentStepTwoTableDefault
                v-if="period.default_apply_unit === 'default'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
              />
              <TreatmentStepTwoTablePlant
                v-if="period.default_apply_unit === 'Kg-L / planta'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
              />
              <TreatmentStepTwoTableVat
                v-if="period.default_apply_unit === 'Kg-L / cuva'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
              />
            </div>
          </template>
        </DxTabPanel>
      </div>
    </div>
  </section>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPopover,
  DxEditing,
} from "devextreme-vue/data-grid";
import { DxTabPanel } from "devextreme-vue/tab-panel";
import { mapState } from "vuex";
import moment from "moment";
import { treatmentPlanPeriod } from "../../../services/treatmentPlanService";
import DxNumberBox from "devextreme-vue/number-box";
import DxTextBox from "devextreme-vue/text-box";
import TreatmentStepTwoFormDefault from "../TreatmentStepTwoForms/default.vue";
import TreatmentStepTwoFormPlant from "../TreatmentStepTwoForms/plant.vue";
import TreatmentStepTwoFormVat from "../TreatmentStepTwoForms/vat.vue";
import TreatmentStepTwoTableDefault from "../treatmentStepTwoTables/default.vue";
import TreatmentStepTwoTablePlant from "../treatmentStepTwoTables/plant.vue";
import TreatmentStepTwoTableVat from "../treatmentStepTwoTables/vat.vue";
import DxSelectBox from "devextreme-vue/select-box";
import ProductPopupDetail from "../../wizardTreatment/productDetail/index.vue";

export default {
  name: "TreatmentPeriodDetails",
  components: {
    DxEditing,
    DxDataGrid,
    DxColumn,
    DxPopover,
    DxTabPanel,
    DxNumberBox,
    DxTextBox,
    TreatmentStepTwoFormDefault,
    TreatmentStepTwoFormPlant,
    TreatmentStepTwoFormVat,
    TreatmentStepTwoTableDefault,
    TreatmentStepTwoTablePlant,
    TreatmentStepTwoTableVat,
    DxSelectBox,
    ProductPopupDetail,
  },
  data() {
    return {
      selectedIndex: 0,
      applyUnit: ["default", "Kg-L / planta"],
      owner: "",
    };
  },
  computed: {
    ...mapState("treatmentStepper", ["treatmentPlan"]),
    ...mapState("Plantation", ["currentPlantation"]),
    ...mapState("treatment", ["displayProductDetailPopup"]),
    getPeriods() {
      const dateStart = moment(this.treatmentPlan.init_date);
      const dateEnd = moment(this.treatmentPlan.end_date);
      const timeValues = this.calculateTimeValues(dateStart, dateEnd);

      return this.treatmentPlan.periods
        .map((period, index) => ({
          ...period,
          title: timeValues[index],
          default_apply_unit: this.treatmentPlan.default_apply_unit[index],
        }))
        .filter((period) => {
          const doseVolumeNotEmpty = period.products.some(
            ({ dose_volume, active }) => (dose_volume > 0 && active)
          );

          return doseVolumeNotEmpty;
        });
    },
    getArea() {
      return parseFloat(parseFloat(this.currentPlantation.area).toFixed(3));
    }
  },
  methods: {
    calculateTimeValues(dateStart, dateEnd) {
      const timeValues = [];

      switch (this.treatmentPlan.period) {
        case treatmentPlanPeriod.WEEKLY:
          const firstYear = dateStart.year();
          const lastYear = dateEnd.year();

          for (let i = firstYear; i <= lastYear; i++) {
            const firstDayOfYear = moment([i]).startOf("year");
            const lastDayOfYear = moment([i]).endOf("year");
            let firstWeek = "";
            let lastWeek = "";

            if (dateStart.isAfter(firstDayOfYear)) {
              firstWeek = dateStart.isoWeek();
            } else {
              firstWeek = firstDayOfYear.isoWeek();
            }

            if (lastDayOfYear.isAfter(dateEnd)) {
              lastWeek = dateEnd.isoWeek();
            } else {
              lastWeek = lastDayOfYear.isoWeek();
            }

            if (!dateStart.isAfter(firstDayOfYear) && firstWeek !== 1) {
              const varName = `${firstWeek} - ${i - 1}`;

              timeValues.push(varName);

              firstWeek = 1;
            }

            for (let j = firstWeek; j <= lastWeek; j++) {
              const varName = `${j} - ${i}`;
              timeValues.push(varName);
            }
          }

          break;
        case treatmentPlanPeriod.MONTHLY:
        default:
          while (
            dateEnd > dateStart ||
            dateStart.format("M") === dateEnd.format("M")
          ) {
            const varName = `${dateStart
              .format("MMM")
              .split(".")[0]
              .toUpperCase()} - ${dateStart.format("yy")}`;
            timeValues.push(varName);
            dateStart.add(1, "month");
          }
          break;
      }

      return timeValues;
    },
    onChangeApplyUnit(e) {
      this.treatmentPlan.default_apply_unit[this.selectedIndex] = e.value;
    },
    setOwner(data) {
      this.owner = data;
    },
  },
};
</script>

<style src="./treatmentPeriodDetails.styles.scss" scoped lang="scss"></style>