export default class Formatter {
  static formatSectorProperties(sector, area) {
    const propertiesFormatted = [];
    propertiesFormatted.push({ key: 'area', value: area });
    propertiesFormatted.push({
      key: 'ground_texture',
      value: sector.groundTexture,
    });
    propertiesFormatted.push({
      key: 'irrigation_type',
      value: sector.irrigationType,
    });
    switch (sector.irrigationType) {
      case 'GOTEO':
        propertiesFormatted.push({
          key: 'dripper_per_plant',
          value: sector.dripperPerPlant,
        });
        propertiesFormatted.push({
          key: 'flow',
          value: sector.flow,
        });
        break;
      case 'ASPERSIÓN':
        propertiesFormatted.push({
          key: 'transformed_surface',
          value: sector.transformedArea,
        });
        propertiesFormatted.push({
          key: 'sprinkler_number ',
          value: sector.sprinklersNumber,
        });
        propertiesFormatted.push({
          key: 'flow',
          value: sector.flow,
        });
        propertiesFormatted.push({
          key: 'separation_between_lines',
          value: sector.separationBetweenLines,
        });
        propertiesFormatted.push({
          key: 'separation_between_sprinkler',
          value: sector.separationBetweenSprinkler,
        });
        break;
      case 'INUNDACIÓN':
        propertiesFormatted.push({
          key: 'flow',
          value: sector.flow,
        });
        break;
      default:
        break;
    }
    return propertiesFormatted;
  }
}