<template>
  <f7-block v-if="!loaded">
    <div class="loadingFalse">
      <div class="preloader" />
    </div>
  </f7-block>
  <div
    v-else
    class="no-padding-right"
  >
    <div
      class="content-devx no-margin-top"
    >
      <f7-block-title class="title-top">
        <span>{{ `${$t('Location_title')}` }}</span>
      </f7-block-title>
      <DxDataGrid
        id="Location"
        :data-source="dataSource"
        :remote-operations="true"
        :column-min-width="50"
        :column-auto-width="true"
        :allow-column-resizing="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :on-editing-start="onEditingStart"
        :onRowPrepared="onRowPrepared"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <DxSelection
          mode="single"
        />
        <DxSorting mode="single"/>
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxSystemParcelLocation"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="5" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          info-text="{2} items"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxGrouping :auto-expand-all="false"/>
        <DxColumn
          :group-index="0"
          caption="Explotación"
          data-field="exploitation_id_name"
          group-cell-template="groupCellTemplateExploitation"
        />
        <DxColumn
          :group-index="1"
          caption="Parcela"
          data-field="parcel_id_name"
          group-cell-template="groupCellTemplateParcel"
        />
        <DxColumn
          :group-index="2"
          caption="sector"
          data-field="sector_id_name"
          group-cell-template="groupCellTemplateSector"
        />

        <template #groupCellTemplateExploitation="{ data }">
            <div>
              <span>{{ getExploitationTitle(data.value) }}</span>
              <a href="#" class="dx-icon-find group-cell-icon" @click="viewExploitation(data.value)"></a>
            </div>
        </template>

        <template #groupCellTemplateParcel="{ data }">
            <div>
              <span>{{ getParcelTitle(data.value) }}</span>
              <a href="#" class="dx-icon-find group-cell-icon" @click="viewParcel(data.value)"></a>
            </div>
        </template>

        <template #groupCellTemplateSector="{ data }">
            <div>
              <span>{{ getSectorTitle(data.value) }}</span>
              <a href="#" class="dx-icon-find group-cell-icon" @click="viewSector(data.value)"></a>
            </div>
        </template>
        <DxColumn
          data-field="species"
          :caption="$t('Crop')"
        />
        <DxColumn
          data-field="variety"
          :caption="$t('variety')"
        />
        <DxColumn
          data-field="status"
          :caption="$t('status')"
        />
        <DxColumn
          data-field="end_date"
          :caption="$t('searchEngine_end_date')"
        />
        <DxColumn
          type="buttons"
          :fixed="gridSettings.fixed"
          :fixed-position="gridSettings.fixedPosition"
          width="80"
        >
          <DxButton
            icon="find"
            alignment="right"
            :on-click="showViewLocation"
          />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
  DxSorting,
  DxGrouping
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import settings from '../../../js/commonSettings';
import moment from 'moment';

export default {
  name: 'DxSystemParcelLocation',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxSorting,
    DxGrouping
  },
  data() {
    return {
      loaded: false,
      LocationFormated: [],
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
      emptyGroups: {}
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    isEditing() {
      return this.isEdit === true ? this.$t('DxClient_edit_Client') : this.$t('DxClient_register_Client');
    },
    dataSource() {
      const data = [];

      this.listExplotation.forEach(exploitation => {
        if (exploitation.parcels.length === 0) {
          data.push({
            exploitation_id_name: `${exploitation._id} ${exploitation.name}`,
            exploitation_id: exploitation._id,
            exploitation_name: exploitation.name,
          })

          this.emptyGroups[`${exploitation._id} ${exploitation.name}`] = 1;
        }

        exploitation.parcels.forEach(parcel => {
          if (parcel.sectors.length === 0) {
            data.push({
              exploitation_id_name: `${exploitation._id} ${exploitation.name}`,
              exploitation_id: exploitation._id,
              exploitation_name: exploitation.name,
              parcel_id_name: `${parcel._id} ${parcel.name}`,
              parcel_id: parcel._id,
              parcel_name: parcel.name,
            })

            this.emptyGroups[`${parcel._id} ${parcel.name}`] = 1;
          }

          parcel.sectors.forEach(sector => {
            if (sector.plantations.length === 0) {
              data.push({
                exploitation_id_name: `${exploitation._id} ${exploitation.name}`,
                exploitation_id: exploitation._id,
                exploitation_name: exploitation.name,
                parcel_id_name: `${parcel._id} ${parcel.name}`,
                parcel_id: parcel._id,
                parcel_name: parcel.name,
                sector_id_name: `${sector._id} ${sector.name}`,
                sector_id: sector._id,
                sector_name: sector.name,
              })

              this.emptyGroups[`${sector._id} ${sector.name}`] = 1;
            }

            sector.plantations.forEach(plantation => {
              data.push({
                ...plantation,
                status: this.$t(`plantations.grid.${plantation.status}`),
                end_date: moment(plantation.end_date).format('DD-MM-YY'),
                exploitation_id_name: `${exploitation._id} ${exploitation.name}`,
                exploitation_id: exploitation._id,
                exploitation_name: exploitation.name,
                parcel_id_name: `${parcel._id} ${parcel.name}`,
                parcel_id: parcel._id,
                parcel_name: parcel.name,
                sector_id_name: `${sector._id} ${sector.name}`,
                sector_id: sector._id,
                sector_name: sector.name,
              })
            })
          })
        })
      });

      return data;
    },
    ...mapState('Campania', ['listExplotation']),
    ...mapState('Sector', ['currentSector']),
  },
  async mounted() {
    try {
      await this.getListExplotationDetailed();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onRowPrepared(e) {
      if (e.rowType === "group") {
        const { rowElement } = e;
        const { key } = e;

        if (key.length <= 3) {
          const lastElement = key[key.length - 1];

          if (this.emptyGroups[lastElement] === 1) {
            rowElement.childNodes[key.length - 1].style.pointerEvents = "none";
            rowElement.childNodes[key.length - 1].classList.add("remove-arrow-icon");
          }
        }
      }
    },
    getExploitationTitle(idName) {
      const id = idName.split(' ')[0];
      const name = this.dataSource.find(item => item.exploitation_id === id)?.exploitation_name;

      return `${this.$t('Explotation')}: ${name}`;
    },
    getParcelTitle(idName) {
      const id = idName.split(' ')[0];
      const name = this.dataSource.find(item => item.parcel_id === id)?.parcel_name;

      return `${this.$t('Parcel')}: ${name}`;
    },
    getSectorTitle(idName) {
      const id = idName.split(' ')[0];
      const name = this.dataSource.find(item => item.sector_id === id)?.sector_name;

      return `${this.$t('Sector')}: ${name}`;
    },
    async viewExploitation(idName) {
      const id = idName.split(' ')[0];
      try {
        this.$f7.preloader.show();
        await this.fetchActualLocation({ id });
        this.$f7.views.main.router.navigate('/viewLocation/', { reloadCurrent: true });

        this.setIsFromCampaign(false);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async viewParcel(idName) {
      const id = idName.split(' ')[0];
      try {
        const exploitationId = this.dataSource.find(item => item.parcel_id === id)?.exploitation_id;
        this.$f7.preloader.show();
        await this.fetchActualLocation({ id: exploitationId });
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }

      this.setCurrentParcelId(id);
      this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
    },
    async viewSector(idName) {
      const id = idName.split(' ')[0];
      const row = this.dataSource.find(item => item.sector_id === id);

      try {
        this.$f7.preloader.show();
        await this.fetchActualLocation({ id: row?.exploitation_id });
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }

      this.setCurrentParcelId(row?.parcel_id);

      this.setCurrentSectorId(id);
      this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
    },
    async showViewLocation(e) {
      const row = this.dataSource.find(item => item._id === e.row.data._id);

      try {
        this.$f7.preloader.show();
        await this.fetchActualLocation({ id: row?.exploitation_id });

        this.setIsFromCampaign(false);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }

      this.setCurrentParcelId(row?.parcel_id);
      this.setCurrentSectorId(row?.sector_id);

      this.setCurrentPlantation(e.row.data._id);
      this.setIdPlantation(e.row.data._id);
      this.setSectorArea(this.currentSector.area);
      this.setIsView(true);
      this.setIsEdit(false);
      this.setIsPlantationCreated(false);
      this.$f7.views.main.router.navigate('/ViewPlantation/', { reloadCurrent: true });
    },
    onHidden(e) {
      this.isEdit = false;
    },
    onEditingStart(e) {
      this.isEdit = true;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('SystemParcelLocation');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SystemParcelLocation.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.initStore();
            this.$f7.views.main.router.navigate('/registerLocation/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('Explotaciones', ['fetchActualLocation']),
    ...mapActions('RegisterLocation', ['initStore']),
    ...mapActions('Campania', ['getListExplotation', 'getListExplotationDetailed', 'setIsFromCampaign', 'setIsView']),
    ...mapActions('Parcel', ['setCurrentParcelId']),
    ...mapActions('Sector', ['setCurrentSectorId']),
    ...mapActions('Plantation', ['setCurrentPlantation', 'setIdPlantation']),
    ...mapActions('RegisterPlantation', ['setSectorArea', 'setIsEdit', 'setIsPlantationCreated']),
  },
};
</script>

<style scoped>
@import './SistemParceelLocation.styles.scss';
</style>
