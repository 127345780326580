<template>
  <section class="stepper-component">
    <div class="content-devx">
      <div class="card-header">
        {{ title }}
      </div>
      <div class="card-body">
        <section class="steps">
          <div
            v-for="(step, index) in nutritionSteps"
            :key="index"
            :class="'step ' + getCompleteClassStatus(step) "
            @click="setCurrentStep(index)"
          >
            <div class="dot-container">
              <span :class="'dot ' + step.statusClass">
                <i
                  v-if="step.statusClass && step.statusClass.includes('complete')"
                  class="dx-icon-check"
                />
              </span>
            </div>
            <span class="dot-label">{{ step.label }}</span>
          </div>
        </section>
        <section
          v-if="stepIndex === 0"
          class="data-form margin-top padding-top"
        >
          <f7-row class="margin-bottom-block">
            <f7-col>
              <div class="dx-field">
                <div class="margin-right-5">
                  {{ $t('Utilities.stepperForm.planDescription') }}
                </div>
                <div>
                  <DxTextBox v-model="stepData.planDescription" />
                </div>
              </div>
            </f7-col>
            <f7-col>
              <div class="dx-field">
                <div class="margin-right-5">
                  {{ $t('Utilities.stepperForm.temporalFrame') }}
                </div>
                <div>
                  <DxSelectBox
                    v-model="temporalFrameId"
                    display-expr="label"
                    value-expr="id"
                    :items="temporalFrameItems"
                    :disabled="isEditing || isCopying"
                    @value-changed="updateTemporalFrame"
                  />
                </div>
              </div>
            </f7-col>
            <f7-col>
              <div class="dx-field">
                <div class="margin-right-5">
                  {{ $t('Utilities.stepperForm.init') }}
                </div>
                <div>
                  <DxDateBox
                    v-model="stepData.initDate"
                    :display-format="dateboxFormat"
                    :disabled-dates="disabledDates"
                    width="100%"
                    type="date"
                    @value-changed="initData"
                  >
                    <DxCalendarOptions
                      :max-zoom-level="maxZoomLevel"
                      min-zoom-level="decade"
                      zoom-level="year"
                    />
                  </DxDateBox>
                </div>
              </div>
            </f7-col>
            <f7-col>
              <div class="dx-field">
                <div class="margin-right-5">
                  {{ $t('Utilities.stepperForm.end') }}
                </div>
                <div>
                  <DxDateBox
                    v-model="stepData.endDate"
                    :display-format="dateboxFormat"
                    :disabled-dates="disabledDates"
                    type="date"
                    width="100%"
                    @value-changed="initData"
                  >
                    <DxCalendarOptions
                      :max-zoom-level="maxZoomLevel"
                      min-zoom-level="decade"
                      zoom-level="year"
                    />
                  </DxDateBox>
                </div>
              </div>
            </f7-col>
          </f7-row>
          <div class="bottom" />
        </section>
        <section
          v-if="false"
          class="data-form-label"
        >
          <div class="top">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.planDescription') }}
              </div>
              <div class="dx-field-value">
                {{ stepData.planDescription }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.temporalFrame') }}
              </div>
              <div class="dx-field-value">
                {{ stepData.temporalFrame.label }}
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.init') }}
              </div>
              <div class="dx-field-value">
                {{ formatInitDate() }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.end') }}
              </div>
              <div class="dx-field-value">
                {{ formatEndDate() }}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import moment from 'moment';

export default {
  name: 'Stepper',
  components: {
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
  },
  props: [],
  data() {
    return {
      title: this.$t('Register_fertilization_plan'),
      stepSelected: {},
      temporalFrameId: 0,
      temporalFrameItems: [
        { id: 0, label: this.$t('Utilities.stepperForm.monthly'), value: 'MENSUAL' },
        { id: 1, label: this.$t('Utilities.stepperForm.weekly'), value: 'SEMANAL' },
      ],
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['nutritionSteps', 'stepData', 'currentStep', 'stepIndex', 'enableWatchers', 'isEditing', 'editingStepData', 'isCopying', 'copyingStepData', 'isCopying']),
    ...mapState('Plantation', ['currentPlantation']),
    dateboxFormat() {
      return ['MM/yyyy', 'dd/MM/yyyy'][this.temporalFrameId];
    },
    maxZoomLevel() {
      return ['year', 'month'][this.temporalFrameId];
    },
  },
  beforeMount() {
    this.updateClasses();
    this.initData();

    if (!this.stepData.temporalFrame) {
      this.setStepDataTemporalFrame(this.temporalFrameItems[0]);
    } else {
      this.temporalFrameId = this.stepData.temporalFrame.id;
      // Se vuelve a asignar el marco temporal para añadir la traducción correcta.
      this.setStepDataTemporalFrame(this.temporalFrameItems[this.temporalFrameId]);
    }
    if (!this.stepData.initDate) {
      this.setStepDataInitDate(this.currentPlantation.initDate);
      this.setStepDataEndDate(this.currentPlantation.endDate);
    }
  },
  methods: {
    disabledDates(args) {
      const actualDate = moment(args.date, 'YYYY-MM-DD');
      const initDate = moment(this.currentPlantation.initDate, 'YYYY-MM-DD');
      const endDate = moment(this.currentPlantation.endDate, 'YYYY-MM-DD');

      if (args.view === 'month') {
        return !actualDate.isBetween(initDate, endDate, 'days', '[]');
      }

      if (args.view === 'year') {
        return !actualDate.isBetween(initDate, endDate, 'months', '[]');
      }

      if (args.view === 'decade') {
        return !actualDate.isBetween(initDate, endDate, 'years', '[]');
      }

      if (args.view === 'century') {
        const exactYear = parseInt(actualDate.year() / 10) * 10;
        const initDateIsBetween = initDate.year() >= exactYear && initDate.year() <= exactYear + 9;
        const endDateIsBetween = endDate.year() >= exactYear && endDate.year() <= exactYear + 9;

        return !(initDateIsBetween || endDateIsBetween);
      }

      return false;
    },
    formatInitDate() {
      return moment(this.stepData.initDate).format('MM/yyyy');
    },
    formatEndDate() {
      return moment(this.stepData.endDate).format('MM/yyyy');
    },
    setCurrentStep(index) {
      this.setStep(index);
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    initData() {
      if (!this.enableWatchers) {
        return;
      }

      if (this.isEditing) {
        const timeValuePeriods = this.getTimeValues(moment(this.stepData.initDate), moment(this.stepData.endDate), this.stepData.temporalFrame.value);
        const newTimeValues = [];

        this.editingStepData.timeValues.forEach((item, index) => {
          newTimeValues[index] = {};
          Object.entries(timeValuePeriods).forEach(([key, value]) => {
            let numberOfItems = Object.values(newTimeValues[index] || {}).length ?? 0;
            newTimeValues[index][key] = Object.values(item)[numberOfItems] ?? 0;
          })
        })

        const extraTimeValues = Math.max(0, this.stepData.timeValues.length - this.editingStepData.timeValues.length);

        for (let i = 0; i < extraTimeValues; i++) {
          newTimeValues.push(timeValuePeriods);
        }

        this.stepData.timeValues = newTimeValues;
        return;
      }

      if (this.isCopying) {
        if (this.copyingStepData.timeValues.length === 0) {
          this.stepData.initDate = this.copyingStepData.initDate;
          this.stepData.endDate = this.copyingStepData.endDate;
          this.copyingStepData.timeValues = this.stepData.timeValues;
        }

        const timeValuePeriods = this.getTimeValues(moment(this.stepData.initDate), moment(this.stepData.endDate), this.stepData.temporalFrame.value);
        const newTimeValues = [];

        this.copyingStepData.timeValues.forEach((item, index) => {
          newTimeValues[index] = {};
          Object.entries(timeValuePeriods).forEach(([key, value]) => {
            let numberOfItems = Object.values(newTimeValues[index] || {}).length ?? 0;
            newTimeValues[index][key] = Object.values(item)[numberOfItems] ?? 0;
          })
        })

        const extraTimeValues = Math.max(0, this.stepData.timeValues.length - this.copyingStepData.timeValues.length);

        for (let i = 0; i < extraTimeValues; i++) {
          newTimeValues.push(timeValuePeriods);
        }

        this.stepData.timeValues = newTimeValues;
        return;
      }

      if (!this.stepData.initDate || !this.stepData.endDate || !this.stepData.temporalFrame.value) return;

      const timeValuePeriods = this.getTimeValues(moment(this.stepData.initDate), moment(this.stepData.endDate), this.stepData.temporalFrame.value);
      const newTimeValues = [];

      this.stepData.timeValues.forEach((item, index) => {
        newTimeValues[index] = {};
        Object.entries(timeValuePeriods).forEach(([key, value]) => {
          let numberOfItems = Object.values(newTimeValues[index] || {}).length ?? 0;
          newTimeValues[index][key] = Object.values(item)[numberOfItems] ?? 0;
        })
      })

      this.stepData.timeValues = newTimeValues;

      // this.stepData.nutritionalBalanceData = [];
      // this.stepData.timeValues = [];
      // this.stepData.dosages = [];
      // this.stepData.dosageProduct = [];
      // this.stepData.dosageProductBalance = [];
      // this.stepData.productResume = [];
    },
    getCompleteClassStatus(step) {
      if (!step || !step.statusClass) {
        return 'no-pointer-event';
      }
      return !step.statusClass.includes('complete') ? 'no-pointer-event' : '';
    },
    updateTemporalFrame({ value }) {
      this.initData();
      this.setStepDataTemporalFrame(this.temporalFrameItems[value]);
    },
    getTimeValues(dateStart, dateEnd, period) {
      const timeValues = {}
      switch (period) {
        case 'SEMANAL':
          const firstYear = dateStart.year();
          const lastYear = dateEnd.year();

          for (let i = firstYear; i <= lastYear; i++) {
            const firstDayOfYear = moment([i]).startOf("year");
            const lastDayOfYear = moment([i]).endOf("year");
            let firstWeek = "";
            let lastWeek = "";

            if (dateStart.isAfter(firstDayOfYear)) {
              firstWeek = dateStart.isoWeek();
            } else {
              firstWeek = firstDayOfYear.isoWeek();
            }

            if (lastDayOfYear.isAfter(dateEnd)) {
              lastWeek = dateEnd.isoWeek();
            } else {
              lastWeek = lastDayOfYear.isoWeek();
            }

            if (!dateStart.isAfter(firstDayOfYear) && firstWeek !== 1) {
              const varName = `${firstWeek} - ${i - 1}`;
              timeValues[varName] = 0;
              firstWeek = 1;
            }

            for (let j = firstWeek; j <= lastWeek; j++) {
              const varName = `${j} - ${i}`;
              timeValues[varName] = 0;
            }
          }  

          break;
        case 'MENSUAL':
        default:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.format('MMM').split('.')[0].toUpperCase()} - ${dateStart.format('yy')}`;
            timeValues[varName] = 0;
            dateStart.add(1, 'month');
          }
          break;
      }
      return timeValues;
    },
    ...mapActions('nutritionStepper', ['next', 'back', 'findSelectedIndex', 'updateClasses', 'setStep',
      'setStepDataTemporalFrame',
      'setStepDataInitDate',
      'setStepDataEndDate',
      'setEditingStepData']),
  },
};

</script>
<style src="./stepper.scss" scoped lang="scss"></style>
