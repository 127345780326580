<template>
  <div
    class="main-giswms-map"
    :class="{
      'main-giswms-map': true,
      'content-devx': nameMap === 'createObservationParcel' ||
        nameMap === 'viewObservationParcel'
    }"
  >
    <div class="content-gis-map">
      <div
        v-if="(treeLocations.length !== 0)"
        :id="`${nameMap}_${initialDate}`"
        :style="minHeight"
      />
      <div
        v-else
        style="display: block; text-align: center;"
      >
        {{ $t('GISGeoJsonMap_not_locations') }}
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import * as turf from '@turf/turf';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { Device } from 'framework7/framework7-lite.esm.bundle';

export default {
  name: 'GISGeoJsonMap',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '50vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => null,
    },
    showGPSButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      groupBounds: null,
      initialDate: Date.now(),
      locationProperties: {},
      actualDate: moment().format('YYYY-MM-DD'),
      actualPlantation: {},
      environment: process.env.NODE_ENV,
      statusMeasureTool: false,
      areaLayer: null,
      lineLayer: null,
      areaLayerPopup: null,
      lineLayerPopup: null,
      markerLayer: L.layerGroup(),
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
    ]),
    getIconPath() {
      if (Device.cordova) {
        return this.environment === 'development' ? '../../../static/img/'
          : 'static/img/';
      }
      return this.environment === 'development' ? '../../../static/img/'
        : './static/img/';
    },
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Sector', ['currentPlantation', 'currentSector']),
    ...mapState('Explotaciones', ['actualUser']),
  },
  mounted() {
    if (this.treeLocations.length !== 0) {
      this.setCurrentLayersMap([]);
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();
    }
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        let treeLocationIds = {};
        const group = L.featureGroup();
        for (const geoData of this.treeLocations) {
          for (const children of geoData.children) {
            layer = L.geoJson(children.geo_feature).addTo(this.map);
            group.addLayer(layer);
            const bounds = layer.getBounds();
            this.layerBounds = bounds;
            treeLocationIds = { explotationId: geoData.bd_id, parcelId: children.bd_id };
            this.layersEvents(layer, treeLocationIds);
            for (const children2 of children.children) {
              layer2 = L.geoJson(children2.geo_feature).addTo(this.map);
              layer2.setStyle(layerStyles.active);
              treeLocationIds = {
                explotationId: geoData.bd_id, parcelId: children.bd_id, sectorId: children2.bd_id,
              };
              this.layersEvents(layer2, treeLocationIds);
            }
          }
        }
        if (this.center !== null) {
          this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
        } else {
          const bounds = group.getBounds();
          this.groupBounds = bounds;
          this.map.fitBounds(bounds);
        }
      } catch (error) {
        if (error.message === 'Bounds are not valid.') {
          this.$f7.dialog.alert(this.$t('BoundsAreNotValid'));
        } else {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    layersEvents(target, treeLocationIds) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            L.DomEvent.stopPropagation(e);
            self.locationProperties = {};
            self.actualPlantation = {};
            self.openLayerDataPopup(e.target.feature.properties, e, treeLocationIds);
          },
          dblclick(e) {
          },
          mouseover(e) {
          },
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.markerLayer.addTo(this.map);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        this.addHomeButton();
        this.addMeasureToolButton();
        if (this.showGPSButton) {
          this.addGPSButton();
        }
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },

    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
            `${controlName}-home`, container, this.zoomHome);

          return container;
        },

        zoomHome() {
          self.map.fitBounds(self.groupBounds);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },
    addGPSButton() {
      const self = this;
      L.Control.zoomLocation = L.Control.extend({
        options: {
          position: 'topleft',
          zoomLocationText: '<i class="fg-position fa-2x"></i>',
          zoomLocationTitle: `${self.$t('Gis.Buttons.CurrentPosition')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomLocationButton = this.createButton(
            options.zoomLocationText,
            options.zoomLocationTitle,
            `${controlName}-home`,
            container,
            this.zoomLocation,
          );

          return container;
        },

        zoomLocation() {
          let marker;

          self.map
            .locate({
              setView: true,
              maxZoom: 120,
            })
            .on('locationfound', (e) => {
              self.markerLayer.clearLayers();
              if (!marker) {
                marker = new L.Marker(e.latlng, {
                  draggable: false,
                }).addTo(self.markerLayer);
                const redIcon = new L.Icon({
                  iconUrl:
                      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                  shadowUrl:
                      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41],
                });
                marker.setIcon(redIcon);
                self.map.setZoom(self.zoom);
              } else {
                marker.setLatLng(e.latlng);
              }
            })
            .on('locationerror', () => {
              if (marker) {
                self.map.removeLayer(marker);
                marker = undefined;
              }
            });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomLocation = new L.Control.zoomLocation();
      zoomLocation.addTo(self.map);
    },
    addMeasureToolButton() {
      const self = this;
      L.Control.measureArea = L.Control.extend({
        options: {
          position: 'topright',
          measureAreaText:
              '<i class="fg-measure-area fa-2x"></i>',
          measureAreaTitle: `${self.$t('Gis.Buttons.MeasureArea')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-area';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureAreaButton = this.createButton(
            options.measureAreaText,
            options.measureAreaTitle,
            `${controlName}-measureArea`,
            container,
            this.measureArea,
          );

          return container;
        },

        measureArea() {
          self.map.pm.enableDraw('Polygon', {
            snappable: true,
            continueDrawing: false,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureArea = new L.Control.measureArea();

      L.Control.measureLineString = L.Control.extend({
        options: {
          position: 'topright',
          measureLineStringText:
              '<i class="fg-measure-line fa-2x"></i>',
          measureLineStringTitle: `${self.$t('Gis.Buttons.MeasureLineString')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-line';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureLineStringButton = this.createButton(
            options.measureLineStringText,
            options.measureLineStringTitle,
            `${controlName}-measureLineString`,
            container,
            this.measureLineString,
          );

          return container;
        },

        measureLineString() {
          self.map.pm.enableDraw('Line', {
            snappable: true,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureLineString = new L.Control.measureLineString();

      L.Control.desactiveMeasure = L.Control.extend({
        options: {
          position: 'topright',
          desactiveMeasureText: '<i class="fas fa-times"></i>',
          desactiveMeasureTitle: `${self.$t('Gis.Buttons.DesactiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.desactiveMeasureText,
            options.desactiveMeasureTitle,
            `${controlName}-desactiveMeasure`,
            container,
            this.desactiveMeasure,
          );

          return container;
        },

        desactiveMeasure() {
          self.statusMeasureTool = false;
          self.measureArea.remove(self.map);
          self.measureLineString.remove(self.map);
          // eslint-disable-next-line no-use-before-define
          self.desactiveMeasure.remove(self.map);
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:block';
          if (self.areaLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.areaLayer);
          }
          if (self.lineLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.lineLayer);
          }
          self.map.pm.disableDraw();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveMeasure = new L.Control.desactiveMeasure();

      L.Control.activeMeasure = L.Control.extend({
        options: {
          position: 'topright',
          activeMeasureText: '<i class="fg-measure fa-2x"></i>',
          activeMeasureTitle: `${self.$t('Gis.Buttons.ActiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-active-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.activeMeasureText,
            options.activeMeasureTitle,
            `${controlName}-activeMeasure`,
            container,
            this.activeMeasure,
          );

          return container;
        },

        activeMeasure() {
          self.statusMeasureTool = true;
          self.measureArea.addTo(self.map);
          self.measureLineString.addTo(self.map);
          self.desactiveMeasure.addTo(self.map);
          // activeMeasure.remove(self.map);

          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:none';
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.id = 'activeMeasure';
          link.href = '#';
          link.style = 'display:block';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.activeMeasure = new L.Control.activeMeasure();
      self.activeMeasure.addTo(self.map);
    },
    async openLayerDataPopup(properties, e, treeLocationIds) {
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        await this.getActivePlantationIdBySectorId(treeLocationIds.sectorId);
      }
      this.locationProperties = { ...properties, ...treeLocationIds, ...this.actualPlantation };
      let htmlString = `
      <div class='popup-content'>
        <div>
          <p>
          <img id="find-explotation" src='${`${this.getIconPath}find.svg`}' class='cursor-pointer'/>
          <b>${this.$t('Explotation')}:</b> ${properties.explotation}
          </p>
          <p>
          <img id="find-parcel" src='${`${this.getIconPath}find.svg`}' class='cursor-pointer'/>
          <b>${this.$t('Parcel')}:</b> ${properties.parcel}
          </p>`;
      const htmlStringSector = `
          <p>
            <img id="find-sector" src='${`${this.getIconPath}find.svg`}' class='cursor-pointer'/>
            <b>${this.$t('Sector')}:</b> ${properties.sector ? properties.sector : ''}
          </p>`;
      const htmlStringPlantation = `
          <p>
          <img id="find-plantation" src='${`${this.getIconPath}find.svg`}' class='cursor-pointer'/>
          <b>${this.$t('Plantation')}:</b> ${this.locationProperties.plantationName ? this.locationProperties.plantationName : ''}
          </p>`;
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        htmlString += htmlStringSector;
      }
      if (typeof this.actualPlantation.plantationId !== 'undefined') {
        htmlString += htmlStringPlantation;
      }
      htmlString += `
        </div>
      </div>`;
      this.openPopup(htmlString, e.latlng, treeLocationIds);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null) continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(properties[property]);
        } else {
          listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openPopup(html, latlng, treeLocationIds) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
      const buttonExplotation = document.getElementById('find-explotation');
      buttonExplotation.addEventListener('click', () => {
        this.goTo('explotation');
      });
      const buttonParcel = document.getElementById('find-parcel');
      buttonParcel.addEventListener('click', () => {
        this.goTo('parcel');
      });
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        const buttonSector = document.getElementById('find-sector');
        buttonSector.addEventListener('click', () => {
          if (typeof this.locationProperties.sectorId !== 'undefined' && this.locationProperties.sectorId !== null) {
            this.goTo('sector');
          }
        });
      }
      if (typeof this.actualPlantation.plantationId !== 'undefined') {
        const buttonPlantation = document.getElementById('find-plantation');
        buttonPlantation.addEventListener('click', () => {
          if (typeof this.locationProperties.plantationId !== 'undefined' && this.locationProperties.plantationId !== null) {
            this.goTo('plantation');
          }
        });
      }
    },
    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (e.shape === 'Polygon') {
            self.areaLayer = e.layer.addTo(self.map);
            const toGeoJson = self.areaLayer.toGeoJSON();
            const area = turf.area(toGeoJson);
            const html = `<div class='popup-content'><h3>${self.$t('Gis.Editor.PopupMeasureArea')}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${(
              area / 10000
            ).toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureHas')}</p></div>`;
            const popup = L.popup().setContent(html);
            self.areaLayerPopup = self.areaLayer.bindPopup(popup);
            self.areaLayer.openPopup();
            self.map.fitBounds(self.areaLayer.getBounds());
            self.map.pm.disableDraw();
          }
          if (e.shape === 'Line') {
            self.lineLayer = e.layer.addTo(self.map);
            // eslint-disable-next-line no-underscore-dangle
            if (self.lineLayer._latlngs.length > 2) {
              self.map.removeLayer(self.lineLayer);
            } else {
              const from = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lng,
              ]);
              const to = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lng,
              ]);
              const options = {
                units: 'kilometers',
              };
              const distance = turf.distance(from, to, options);
              const html = `<div class='popup-content'><h3>${self.$t('Gis.Editor.PopupMeasureLineString')}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${distance.toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureKm')}</p></div>`;
              const popup = L.popup().setContent(html);
              self.lineLayerPopup = self.lineLayer.bindPopup(popup);
              self.lineLayer.openPopup();
              self.map.fitBounds(self.lineLayer.getBounds());
            }
          }
        } catch (error) {
          this.$f7.dialog.alert(e);
        } finally {
          self.$f7.preloader.hide();
        }
      });
      if (self.areaLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.areaLayer);
      }
      if (self.lineLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.lineLayer);
      }
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },
    async goTo(levelLocation) {
      const self = this;
      try {
        await this.fetchActualLocation({ id: this.locationProperties.explotationId });
        switch (levelLocation) {
          case 'explotation':
            this.$f7.views.main.router.navigate('/viewLocation/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          case 'parcel':
            this.setCurrentParcelId(this.locationProperties.parcelId);
            this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          case 'sector':
            this.setCurrentName(this.locationProperties.parcel);
            this.setCurrentParcelId(this.locationProperties.parcelId);
            this.setCurrentSectorId(this.locationProperties.sectorId);
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          case 'plantation':
            this.setCurrentName(this.locationProperties.parcel);
            this.setCurrentParcelId(this.locationProperties.parcelId);
            this.setCurrentSectorId(this.locationProperties.sectorId);
            this.setIdPlantation(this.locationProperties.plantationId);
            this.$f7.views.main.router.navigate('/viewPlantation/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          default:
            break;
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    async getActivePlantationIdBySectorId(sectorId) {
      try {
        await this.getSectorById({ companyId: this.actualUser.id, currentSectorId: sectorId });
        for (const plantation of this.currentSector.plantation) {
          if (moment(this.actualDate).isBetween(plantation.init_date, plantation.end_date)) {
            this.actualPlantation = { plantationId: plantation.id, plantationName: plantation.species };
          }
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer']),
    ...mapActions('Explotaciones', ['fetchActualLocation']),
    ...mapActions('Parcel', ['setCurrentParcelId', 'setCurrentName']),
    ...mapActions('Sector', ['setCurrentSectorId', 'getSectorById']),
    ...mapActions('Plantation', ['setIdPlantation']),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
