<template>
  <div>
    <DxPopup
      :visible="showAutoSector"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :width="600"
      :height="230"
      :show-close-button="false"
      :title="$t('sector.autoSector.promptHeader')"
    >
      <template>
        <f7-block class="center">
          <div class="title">
            {{ $t('sector.autoSector.prompt') }}
          </div>

          <f7-block-footer class="content-action-botton">
            <f7-button
              class="button-popup margin-right"
              raised
              col
              fill
              @click="cancelAutoSector"
            >
              {{ $t('sector.autoSector.no') }}
            </f7-button>
            <f7-button
              class="button-popup"
              raised
              col
              fill
              type="success"
              @click="createAutoSector"
            >
              {{ $t('sector.autoSector.yes') }}
            </f7-button>
          </f7-block-footer>
        </f7-block>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';
import Formatter from '../formatter';

export default {
  name: 'AutoRegisterSectorCoordinatesPopup',
  components: {
    DxPopup,
  },
  computed: {
    ...mapState('Sector', ['showAutoSector', 'currentSector']),
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
  },
  methods: {
    onHidden() {
      this.setShowAutoSector(false);
    },
    cancelAutoSector() {
      this.setShowAutoSector(false);
      this.$f7.views.main.router.navigate('/registerSectorStep2Page/', {
        reloadCurrent: false,
      });
    },
    async createAutoSector() {
      const companyId = this.actualUser.id;

      // Sacamos el área de la parcela actual para mezclarla con el resto de propiedades.
      let area = 0;
      this.currentParcel.properties.forEach((prop) => {
        if (prop.key == 'area') {
          area = prop.value;
        }
      });

      const newSector = {
        name: this.currentSector.sectorName,
        geo_feature: this.currentParcel.geo_feature,
        type: 'AGRARIAN', // Para sectores siempre tiene ese valor
        coordinates: this.currentParcel.coordinates,
        code: `${this.currentSector.sectorName}${Date.now()}`,
        parent: this.currentParcel.id,
        properties: Formatter.formatSectorProperties(this.currentSector, area),
      };

      try {
        await this.createSector({ companyId, newSector });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }

      this.setShowAutoSector(false);
      this.setShowPopup(true);
    },
    ...mapActions('Sector', ['setShowAutoSector', 'createSector', 'setShowPopup']),
  },
};
</script>

<style>
</style>
