var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-block',[_vm._v("\n    "+_vm._s(_vm.$t('Register_campaign'))+"\n  ")]),_vm._v(" "),_c('f7-block',[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('Campania_title')}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxRegisterCampania_nombre'),"name":"name","caption":_vm.$t('DxRegisterCampania_nombre'),"editor-options":{
            value: _vm.currentCampaign.name,
            showClearButton: true,
            onValueChanged: _vm.setNameChanged,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_name_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('dateRange'),"isRequired":true},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('f7-input',{staticClass:"range-date-picker",staticStyle:{"width":"200px"},attrs:{"type":"datepicker","placeholder":_vm.$t('PlanningComponent_datepicker_multiple_placeholder'),"clear-button":true,"close-on-select":true,"calendar-params":{
                value: [_vm.currentCampaign.initDate, _vm.currentCampaign.endDate],
                closeOnSelect: true,
                rangePicker: true,
                dateFormat: {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                },
              }},on:{"calendar:change":_vm.setSearcherDates}})]},proxy:true}])})],1)],1),_vm._v(" "),_c('DxPopUpRegisterCamapnia')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }