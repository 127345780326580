<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${$t('DxViewClient_detail_Client')}`" />
    <DxSystemParcelViewClient />
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxSystemParcelViewClient from '../../components/systemParcelClient/viewParcelClient/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxSystemParcelViewClient,
    navbar,
  },
  computed: {
    ...mapState('Explotaciones', ['actualUser']),
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate('/systemParcelClients/', { reloadCurrent: true });
    },
  },
};
</script>
<style scoped>
.dx-btn-cancel.margin-right {
width: 15%;
margin-left: 2%;
}
.dx-btn-success {
width: 30%;
margin-left: 68%;

}
</style>
