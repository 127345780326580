<template>
  <f7-block>
    <DxForm>
      <DxGroupItem :col-count="setThreeCols ? 3 : !setThreeCols && oneColumn ? 1 : 2">
        <DxSimpleItem
          :col-span="1"
          :data-field="$t('layerOptions_select_index')"
          :caption="$t('layerOptions_select_index')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: indexAvailables,
            value: currentIndexes[`${keyId}`],
            placeholder: $t('layerOptions_index_availables'),
            onValueChanged: onSelectedIndex,
            width: !setThreeCols && oneColumn ? '250px' : '100%'
          }"
        >
          <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
        </DxSimpleItem>
        <DxSimpleItem
          :col-span="1"
          name="init-date"
          :data-field="$t('date')"
          editor-type="dxDateBox"
          :editor-options="{
            value: actualDate,
            onValueChanged: onChangeInitDate,
            displayFormat: 'dd/MM/yyyy',
            placeholder: $t('registerClima_initDate_placeholder'),
            invalidDateMessage: $t('dates_isInvalid'),
            pickerType: 'calendar',
            disabledDates:disabledDates,
            inputAttr:{ inputmode: 'none' },
            width: !setThreeCols && oneColumn ? '250px' : '100%'
          }"
        />
      </DxGroupItem>
    </DxForm>
  </f7-block>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import EventBus from '../../../js/event-bus';

export default {
  name: 'LayerOptionsComparator',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    keyId: {
      type: String,
      default: 'layerOptions',
    },
    defaultIndex: {
      type: String,
      default: 'NDVI',
    },
    datesList: {
      type: Object,
      default: () => {},
    },
    setThreeCols: {
      type: Boolean,
      default: false,
    },
    oneColumn: {
      type: Boolean,
      default: false,
    },
    isComparator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actualDate: this.datesList
        ? moment(this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1])
        : this.datesList[this.datesList.length - 1],
      dateRequired: '',
      inputAttr: { inputmode: 'none' },
      mappedDates: {
        month: null,
        year: null,
        decade: null,
        century: null,
      },
    };
  },
  computed: {
    ...mapState('Gis', ['indexAvailables', 'currentIndexes', 'currentInitIntervalDates', 'currentEndIntervalDates', 'currentOverlayLayer', 'histogramValues']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  beforeMount() {
    this.setCurrentIndexes({
      key: this.keyId,
      newCurrentIndex: this.defaultIndex,
    });
    this.dateRequired = moment(this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).format('YYYY-MM-DDT00:00:00');
  },
  methods: {
    disabledDates(args) {
      // console.log(args)
      if (args.view === 'century') {
        if (this.mappedDates.century == null) {
          this.mappedDates.century = {};
          this.datesList.avaiable_dates.forEach((currentDate) => {
            this.mappedDates.century[parseInt(parseInt(moment(currentDate).format('YYYY')) / 10) * 10] = true;
          });
        } else {
          return !(this.mappedDates.century[parseInt(parseInt(moment(args.date).format('YYYY')) / 10) * 10] ?? false);
        }
      }
      if (args.view === 'decade') {
        if (this.mappedDates.decade == null) {
          this.mappedDates.decade = {};
          this.datesList.avaiable_dates.forEach((currentDate) => {
            this.mappedDates.decade[moment(currentDate).format('YYYY')] = true;
          });
        } else {
          return !(this.mappedDates.decade[moment(args.date).format('YYYY')] ?? false);
        }
      }

      if (args.view === 'year') {
        if (this.mappedDates.year == null) {
          this.mappedDates.year = {};
          this.datesList.avaiable_dates.forEach((currentDate) => {
            this.mappedDates.year[moment(currentDate).format('YYYY-MM')] = true;
          });
        } else {
          return !(this.mappedDates.year[moment(args.date).format('YYYY-MM')] ?? false);
        }
      }

      return !this.isDateAvailable(args.date);
    },
    // init(e) {
    //   e.component.option('calendarOptions',
    //     {
    //       cellTemplate: (itemData, itemIndex, itemElement) => {
    //         if (itemData.view === 'decade') return itemData.text;
    //         if (itemData.view === 'year') return itemData.text;

    //         let customMarkup = '';
    //         const currentDateToCompare = moment(new Date()).format('YYYY-MM-DD');
    //         const itemDataToCompare = moment(itemData.date).format('YYYY-MM-DD');

    //         if (this.isDateAvailable(itemData.date)) {
    //           customMarkup = `<div style='background-color: #E9E9E9; color: black;'>${itemData.text}</div>`;
    //         } else if (moment(itemDataToCompare).isSameOrAfter(moment(currentDateToCompare))) {
    //           customMarkup = `<div style='background-color: white; color: grey; padding: 2px'>${itemData.text}</div>`;
    //         } else {
    //           customMarkup = itemData.text;
    //         }
    //         return customMarkup;
    //       },

    //     });
    // },
    onChangeInitDate(e) {
      let date;
      if (e.value === null) {
        date = '1970/01/01';
      } else {
        date = e.value;
      }
      const dateInit = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00');
      this.setCurrentInitIntervalDates({
        key: this.keyId,
        newCurrentInitIntervalDate: dateInit,
      });
      const dateList = this.datesList.avaiable_dates.filter((currentDate) => moment(this.currentInitIntervalDates[this.keyId]).isSameOrBefore(currentDate));
      if (dateList.length > 0) {
        this.dateRequired = dateList[0];
        EventBus.$emit(`updateDateLayer${this.keyId}`, dateList[0]);
      }

      if (!this.isComparator) {
        this.callToFetchTemperatureHistogram(false);
      }
    },
    async onSelectedIndex(e) {
      this.setCurrentIndexes({
        key: this.keyId,
        newCurrentIndex: e.value,
      });
      EventBus.$emit(`indexLayer${this.keyId}`, e.value);
      this.callToFetchTemperatureHistogram(true);
    },
    async callToFetchTemperatureHistogram(indexChanged) {
      try {
        if (indexChanged) {
          this.filterHistogramByIndex();
          return;
        }
        await this.fetchHistogram({ location: `${this.currentPlantation.sectorId}`, date: moment(this.currentInitIntervalDates.GisWMS).format('YYYY-MM-DD'), company: this.currentPlantation.companyId });
        this.filterHistogramByIndex();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    filterHistogramByIndex() {
      if (!this.isDateAvailable(this.currentInitIntervalDates.GisWMS ? this.currentInitIntervalDates.GisWMS : this.actualDate)) return;

      const histogramDate = this.currentInitIntervalDates.GisWMS ? this.currentInitIntervalDates.GisWMS : moment(this.actualDate).format('YYYY-MM-DD');
      const histogramInformation = [];
      this.histogramValues.forEach((histogramWithIndex) => {
        if (histogramWithIndex.index.toString().toUpperCase() === this.currentIndexes.GisWMS.toString()) {
          histogramInformation.push({ histogram: histogramWithIndex.histogram, iso_date: histogramDate });
        }
      });

      if (histogramInformation.length < 1) return;
      this.setHistogram(histogramInformation);
    },
    isDateAvailable(date) {
      if (this.mappedDates.month == null) {
        this.mappedDates.month = {};
        this.datesList.avaiable_dates.forEach((currentDate) => {
          this.mappedDates.month[moment(currentDate).format('YYYY-MM-DD')] = true;
        });
      } else {
        return this.mappedDates.month[moment(date).format('YYYY-MM-DD')] ?? false;
      }

      // if (this.datesList.avaiable_dates.length > 0) {
      //   const dateFormatted = moment(date).format('YYYY-MM-DD');

      //   for (let i = 0; i < this.datesList.avaiable_dates.length; i += 1) {
      //     const dateAvailableFormatted = moment(this.datesList.avaiable_dates[i]).format('YYYY-MM-DD');

      //     if (moment(dateFormatted).isSame(dateAvailableFormatted.toString())) {
      //       return true;
      //     }
      //   }
      // }

      // return false;
    },

    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'fetchHistogram', 'setHistogram', 'setHistogramValues']),
  },
};
</script>

<style lang="scss" scoped>
@import './LayerOptionsComparator.styles.scss';
</style>
