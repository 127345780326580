import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'converter-ground',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: {},
      unitStatic: {},
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsGround', 'soilDepth', 'apparentDensity']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsGround[0]);
      this.unitStatic = { ...this.itemUnitsGround[0] };
      this.unitStatic.apparentDensity = this.apparentDensity;
      this.unitStatic.soilDepth = this.soilDepth;
      this.unitSelected = { ...this.unitStatic };
      this.initCalculate();
    },
    cleanData() {
      this.initData();
    },
    initCalculate() {
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);
      this.unitSelected.percent = this.calculatePercent(this.unitSelected.ppm);
      this.unitSelected.percent = this.roundValue(this.unitSelected.percent);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    onChangeUnit(e) {
      this.unitStatic = { ...e.value };
      this.unitStatic.apparentDensity = this.apparentDensity;
      this.unitStatic.soilDepth = this.soilDepth;
      this.unitSelected = { ...this.unitStatic };
      this.initCalculate();
    },
    changeApparentDensity(e) {
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        e.event.target.value,
        this.unitSelected.soilDepth);

      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changeSoilDepth(e) {
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        e.event.target.value);

      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changeMeq(e) {
      this.unitSelected.ppm = this.calculatePpm(e.event.target.value);
      this.unitSelected.percent = this.calculatePercent(this.unitSelected.ppm);
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);

      this.unitSelected.ppm = this.roundValue(this.unitSelected.ppm);
      this.unitSelected.percent = this.roundValue(this.unitSelected.percent);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changePpm(e) {
      this.unitSelected.meq = this.calculateMeq(e.event.target.value);
      this.unitSelected.percent = this.calculatePercent(e.event.target.value);
      this.unitSelected.kg = this.calculateKg(e.event.target.value,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);

      this.unitSelected.meq = this.roundValue(this.unitSelected.meq);
      this.unitSelected.percent = this.roundValue(this.unitSelected.percent);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
    },
    changePercent(e) {
      this.unitSelected.ppm = e.event.target.value * 10000;
      this.unitSelected.meq = this.calculateMeq(this.unitSelected.ppm);
      this.unitSelected.kg = this.calculateKg(this.unitSelected.ppm,
        this.unitSelected.apparentDensity,
        this.unitSelected.soilDepth);
      this.unitSelected.kg = this.roundValue(this.unitSelected.kg);
      this.unitSelected.meq = this.roundValue(this.unitSelected.meq);
      this.unitSelected.ppm = this.roundValue(this.unitSelected.ppm);
    },
    changeKg(e) {
      this.unitSelected.ppm = ((e.event.target.value / 10) / this.unitSelected.soilDepth)
                / this.unitSelected.apparentDensity;
      this.unitSelected.meq = this.calculateMeq(this.unitSelected.ppm);
      this.unitSelected.meq = this.roundValue(this.unitSelected.meq);
      this.unitSelected.ppm = this.roundValue(this.unitSelected.ppm);
    },
    calculateKg(ppm, da, sd) {
      return parseFloat(ppm) * parseFloat(da) * parseFloat(sd) * 10;
    },
    calculatePpm(meq) {
      return meq * this.unitStatic.ppm;
    },
    calculatePercent(ppm) {
      return ppm / 10000;
    },
    calculateMeq(ppm) {
      return ppm / this.unitStatic.ppm;
    },
    roundValue(val) {
      return parseFloat(val.toFixed(2));
    },
  },
};
