<template>
  <f7-page>
    <navbar :text="$t('Campania_title')" />
    <ParcelSystemCampania />
  </f7-page>
</template>

<script>
import ParcelSystemCampania from '../../components/campaña/parcelSystemCampania/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    ParcelSystemCampania,
    navbar,
  },
  data() {
    return {
    };
  },
};
</script>

<style>

</style>
