<template>
  <DxPopup
    id="attributesPopup-popup"
    :title="$t('observationsToBeDisplayed')"
    :visible="true"
    :close-on-outside-click="false"
    height="auto"
    :width="900"
    :on-hidden="close"
    :dragEnabled="false"
  >
    <DxTileView
      v-if="refresh"
      class="margin-bottom margin-top"
      :items="observations"
      :height="440"
      :base-item-height="170"
      :base-item-width="185"
      :item-margin="10"
    >
      <template #item="{ data }">
        <div class="dx-tile-content" @click="select(data)">
          <div class="selected" v-if="data.selected">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C9.48912 0 11.3968 0.790176 12.8033 2.1967C14.2098 3.60322 15 5.51088 15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C5.51088 15 3.60322 14.2098 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5H0ZM7.072 10.71L11.39 5.312L10.61 4.688L6.928 9.289L4.32 7.116L3.68 7.884L7.072 10.711V10.71Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            :style="{
              backgroundImage: 'url(' + data.image + ')',
            }"
            class="image"
          />
          <div class="overlay">
            <div>Fecha: {{ data.date }}</div>
            <div>{{ data.category }}</div>
            <div>{{ data.subcategory }}</div>
          </div>
        </div>
      </template>
    </DxTileView>
    <f7-row>
      <f7-col>
        <f7-button
          class="
            dx-btn-cancel
            margin-left-block
            btn-width-150
            outlined-gray
            margin-top-block margin-bottom-block
          "
          raised
          type="default"
          styling-mode="contained"
        >
          Cancelar
        </f7-button>
      </f7-col>
      <f7-col class="justify-content-end">
        <f7-button
          class="
            dx-btn-cancel
            margin-right-block
            btn-width-150
            margin-top-block margin-bottom-block
            standard-blue-btn
          "
          raised
          type="success"
          styling-mode="contained"
          @click="download()"
        >
          Descargar
        </f7-button>
      </f7-col>
    </f7-row>
  </DxPopup>
</template>
<script>
import DxTileView from "devextreme-vue/tile-view";
import { DxPopup } from "devextreme-vue/popup";
import { mapState } from "vuex";
import Api from "../../services/Api";
export default {
  name: "popupSelectObservationsReport",
  components: {
    DxPopup,
    DxTileView,
  },
  data() {
    return {
      refresh: true,
      observations: [],
    };
  },
  computed: {
    ...mapState("Plantation", ["currentPlantation"]),
  },
  async mounted() {
    const xhr = await Api.getObservations("?sort=null", {
      companyId: this.currentPlantation.companyId,
      currentPlantationId: this.currentPlantation.id,
    });
    const observations = JSON.parse(xhr.response).results;
    this.observations = observations.map((observation, index) => {
      observation.selected = false;
      observation.image = `https://picsum.photos/id/${index + 400}/1920/1080`;
      return observation;
    });
  },
  methods: {
    select(data) {
      this.observations = this.observations.map((item) => {
        if (item.id === data.id) {
          item.selected = !item.selected;
        }
        return item;
      });
    },
    close() {
      this.$emit("close");
    },
    download() {
      this.$emit(
        "download",
        this.observations.filter((item) => item.selected)
      );
    },
  },
};
</script>

<style scoped>
.dx-tile-content {
  height: 100%;
  padding: 0;
  position: relative;
}

.dx-tile-content .image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  padding: 10px;
  display: block;
}

.dx-tile {
  border: 1px solid #999;
}

.dx-tile.dx-state-hover {
  border-color: #eee;
}

.dx-tile-content .selected {
  display: flex;
  font-size: 12px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10000px;
  padding: 1px;
  right: 9px;
  top: 9px;
}

.dx-tile-content .overlay {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  background-color: rgb(255, 255, 255);
  color: black;
  opacity: 0.7;
  padding: 4px;
  bottom: 0;
  width: 100%;
}
</style>
