<template>
  <div
    v-if="loaded"
    class="content-devx"
  >
    <f7-block-title>
      {{ title }}
    </f7-block-title>
    <f7-block>
      <DxForm
        id="form"
        :col-count="1"
        :show-colon-after-label="false"
      >
        <DxGroupItem :col-count="4">
          <DxSimpleItem
            :data-field="$t('Utilities.ViewLocation_name')"
            name="name"
            :caption="$t('Register_farm_name')"
            :editor-options="{
              value: currentExplotation.name,
              mode: 'text',
              placeholder: $t('register_location_placeholder_name'),
              showClearButton: true,
              onValueChanged: onNameChanged,
            }"
          >
            <DxRequiredRule :message="$t('DxEmployeeForm_name_require')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('Register_Farm_numeroRegistro')"
            name="name"
            :caption="$t('Register_Farm_numeroRegistro')"
            :editor-options="{
              value: currentExplotation.registerNumber,
              mode: 'number',
              placeholder: $t('register_location_placeholder_number_register'),
              showClearButton: true,
              onValueChanged: onNumberRegisterChange,
            }"
          />
          <DxSimpleItem
            name="purchase"
            :data-field="$t('Register_farm_provincia')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: allProvince,
              displayExpr: 'name',
              valueExpr: 'code',
              onValueChanged: onProvinceChange,
              value: currentExplotation.province,
              placeholder: $t('register_location_placeholder_province'),
              searchEnabled: true,
            }"
          >
            <DxRequiredRule :message="$t('register_location_required_province')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="name"
            :data-field="$t('Register_farm_municipio')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: allCities,
              displayExpr: 'name',
              valueExpr: 'code',
              onValueChanged: onFarmChange,
              value: currentExplotation.farm,
              placeholder: $t('register_location_placeholder_municipality'),
              searchEnabled: true,
            }"
          >
            <DxRequiredRule :message="$t('register_location_required_locality')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('Register_Farm_titularExplotacion')"
            name="name"
            :caption="$t('Register_Farm_titularExplotacion')"
            :editor-options="{
              value: currentExplotation.ownerFarm,
              placeholder: $t('register_location_placeholder_owner'),
              showClearButton: true,
              onValueChanged: onOwnerFarmChange,
            }"
          />
          <DxSimpleItem
            :data-field="$t('Register_Farm_mobile')"
            name="name"
            :caption="$t('Register_Farm_mobile')"
            :editor-options="{
              value: currentExplotation.mobile,
              placeholder: $t('register_location_placeholder_number_phone'),
              showClearButton: true,
              onValueChanged: onMobileChanged,
            }"
          />
          <DxSimpleItem
            :data-field="$t('register_location_postalCode')"
            name="postalCode"
            :caption="$t('register_location_postalCode')"
            :editor-options="{
              value: currentExplotation.postalCode,
              mode: 'number',
              placeholder: $t('register_location_placeholder_postalCode'),
              showClearButton: true,
              onValueChanged: onPostalCodeChange
            }"
          />
        </DxGroupItem>
      </DxForm>
      <DxPopUpRegisterPloit />
    </f7-block>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';
import DxPopUpRegisterPloit from '../PopUpRegisterPloit/index.vue';

export default {
  name: 'RegisterLocation',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxPopUpRegisterPloit,
  },
  props: {
    currentExplotation: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('RegisterLocation', ['allProvince', 'allCities']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      await this.getProvinces();
      if (this.currentExplotation.name !== '') {
        await this.getCities();
      }
      this.setName(this.currentExplotation.name);
      this.setRegisterNumber(this.currentExplotation.registerNumber);
      this.setProvince(this.currentExplotation.province);
      this.setNumberMobile(this.currentExplotation.mobile);
      this.setFarm(this.currentExplotation.farm);
      this.setOwnerFarm(this.currentExplotation.ownerFarm);
      this.setPostalCode(this.currentExplotation.postalCode);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    onNameChanged(e) {
      this.setName(e.value);
    },
    onNumberRegisterChange(e) {
      this.setRegisterNumber(e.value);
    },
    async onProvinceChange(e) {
      this.setProvince(e.value);
      try {
        await this.getCities();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    onMobileChanged(e) {
      this.setNumberMobile(e.value);
    },
    onFarmChange(e) {
      this.setFarm(e.value);
    },
    onOwnerFarmChange(e) {
      this.setOwnerFarm(e.value);
    },
    onPostalCodeChange(e) {
      this.setPostalCode(e.value);
    },
    ...mapActions('RegisterLocation', ['setName', 'setRegisterNumber', 'setProvince', 'setNumberMobile',
      'setFarm', 'setOwnerFarm', 'setProperties', 'getProvinces', 'getCities', 'setPostalCode']),
  },
};
</script>
<style>
@import './RegsiterLocation.styles.scss';
</style>
