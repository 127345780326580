import pdfmake from "pdfmake";
import html2canvas from "html2canvas";
import pdfImage from "../json/pdfImages.json";

export default class PDFMaker {
  constructor() {
    pdfmake.fonts = {
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
    };

    this.pdfData = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: `Página ${currentPage.toString()}`,
            alignment: "right",
            margin: [0, 20, 10, 0],
          },
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            margin: [20, 10, 20, 0],
            columns: [
              {
                image: pdfImage.timac_image,
                width: 200,
              },
              {
                margin: [100, 0, 0, 0],
                image: pdfImage.contact_image,
                width: 160,
              },
              {
                margin: [110, 10, 0, 0],
                image: pdfImage.roullier_image,
                width: 30,
              },
              {
                margin: [125, 10, 0, 0],
                image: pdfImage.iso_image,
                width: 30,
              },
            ],
          },
        ];
      },
      content: [],
      pageSize: "A4",
      pageMargins: [20, 80, 20, 60],
      styles: {
        h1: { fontSize: 18, bold: true, margin: [0, 14, 0, 8] },
        h2: {
          fontSize: 16,
          bold: true,
          margin: [0, 20, 0, 8],
          color: "#444",
        },
        h3: {
          fontSize: 14,
          bold: true,
          margin: [0, 22, 0, 8],
          color: "#666",
        },
        tableHeaderCell: {
          margin: [2, 2, 2, 2],
          fontSize: 7,
          fillColor: "#eee",
          bold: true,
        },
        tableNormalCell: {
          margin: [2, 2, 2, 2],
          fontSize: 8,
          fillColor: "#fff",
          bold: false,
        },
        blueContainer: {
          margin: [0, 14, 0, 14],
        },
        blueContainerTitle: {
          margin: [8, 8, 8, 8],
          fillColor: "#006FA8;",
          color: "#fff",
          bold: true,
        },
        noBordersCell: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
          bold: false,
        },
        bigger: {
          fontSize: 15,
          italics: true,
        },
        commentStyleTitle: {
          fontSize: 10,
          italics: true,
          bold: true,
        },
        commentStyle: {
          fontSize: 10,
          italics: false,
        },
        normalStyle: {
          fontSize: 10,
        },
        normalStyle2: {
          fontSize: 12,
        },
        observationsTitle: {
          fontSize: 12,
          margin: [0, 8, 0, 4],
        },
        observationsList: {
          fontSize: 12,
          margin: [10, 0, 0, 0],
        },
        legalText: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
        },
        footer: {
          margin: [10, 10, 10, 10],
        },
      },
    };
  }

  getBlueContainer(title, breakTabla = true) {
    let tableOptions = {};

    if (breakTabla) {
      tableOptions = {
        // headerRows: 1,
        keepWithHeaderRows: true,
      };
    }

    return {
      style: "blueContainer",
      table: {
        ...tableOptions,
        headerRows: 1,
        // headerRows: 1,
        // keepWithHeaderRows: true,
        // dontBreakRows: true,
        // unbreakable: true,
        widths: ["*"],
        body: [[{ text: title, style: "blueContainerTitle" }], []],
      },
    };
  }

  insertTableWithoutBorders(data) {
    const blueContainer = this.getBlueContainer("DATOS DE LA EXPLOTACIÓN");

    const table = {
      widths: Array(data[0].length).fill(
        `${(100 / data[0].length).toFixed(2)}%`
      ),
      body: [],
    };

    // const celldimension = (100 / data[0].length).toFixed(2);
    // data[0].forEach((_) => {
    //   table.widths.push(`${celldimension}%`);
    // });

    data.forEach((row) => {
      const headers = row.map((cell) => ({
        style: "noBordersCell",
        text: [
          { text: `${cell.title}: `, fontSize: 10, bold: false },
          { text: cell.text, fontSize: 10, bold: true },
        ],
      }));
      table.body.push(headers);
    });

    blueContainer.table.body[1].push({ table: table, layout: "noBorders" });

    this.pdfData.content.push(blueContainer);
  }

  insertTableTreatmentTable(title, tableData, topData) {
    const blueContainer = this.getBlueContainer(title);

    const topTable = {
      widths: Array(topData.length).fill(
        `${(100 / topData.length).toFixed(2)}%`
      ),
      body: [[]],
    };

    const table = {
      widths: Array(tableData[0].length).fill(
        `${(100 / tableData[0].length).toFixed(2)}%`
      ),
      body: [],
    };

    topData.forEach((row) => {
      topTable.body[0].push({
        style: "noBordersCell",
        text: [
          { text: `${row[0]}: `, fontSize: 10, bold: false },
          { text: row[1], fontSize: 10, bold: true },
        ],
      });
    });

    tableData.forEach((row, index) => {
      if (index === 0) {
        table.body.push(
          row.map((cellText) => ({ style: "tableHeaderCell", text: cellText }))
        );
      } else {
        table.body.push(
          row.map((cellText) => ({ style: "tableNormalCell", text: cellText }))
        );
      }
    });

    blueContainer.table.body[1].push([
      { table: topTable, layout: "noBorders" },
      { table: table },
    ]);

    this.pdfData.content.push(blueContainer);
  }

  insertTableGeneric(title, data) {
    const blueContainer = this.getBlueContainer(title);

    const table = {
      widths: Array(data[0].length).fill(
        `${(100 / data[0].length).toFixed(2)}%`
      ),
      body: [],
    };

    data.forEach((row, index) => {
      if (index === 0) {
        table.body.push(
          row.map((cellText) => ({ style: "tableHeaderCell", text: cellText }))
        );
      } else {
        table.body.push(
          row.map((cellText) => ({ style: "tableNormalCell", text: cellText }))
        );
      }
    });

    blueContainer.table.body[1].push({ table: table });

    this.pdfData.content.push(blueContainer);
  }

  insertObservations(generalObservation, data, temporalImage) {
    const blueContainer = this.getBlueContainer("OBSERVACIONES", false);

    const table = {
      widths: ["100%"],
      body: [],
    };

    if (generalObservation) {
      table.body.push([{ text: generalObservation, style: "observationsTitle", margin: [0, 0, 0, 15] }]);
    }

    data.forEach((row, index) => {
      table.body.push([{ text: row.title, style: "observationsTitle" }]);

      const comments = [];

      row.comment.forEach((comment) => {
        comments.push({ text: `${comment[0]}: `, style: "commentStyleTitle" });
        comments.push({ text: `${comment[1]}. `, style: "normalStyle" });
      });

      table.body.push([
        {
          style: "observationsList",
          ul: [
            { text: `Fecha: ${row.date}`, style: "normalStyle" },
            {
              text: comments,
            },
          ],
        },
      ]);

      for (let i = 0; i < row.multimedia.length; i++) {
        table.body.push([
          {
            image: temporalImage,
            width: 120,
            alignment: "center",
          },
        ]);
      }
    });

    blueContainer.table.body[1].push({ table: table, layout: "noBorders" });

    this.pdfData.content.push(blueContainer);
  }

  insertTableBottomSignature(data) {
    const blueContainer = this.getBlueContainer("RESPONSABLE DE LOS CÁLCULOS");

    const table = {
      widths: [],
      body: [],
    };

    const celldimension = (100 / data[0].length).toFixed(2);
    data[0].forEach((_) => {
      table.widths.push(`${celldimension}%`);
    });

    data.forEach((row) => {
      const headers = row.map((cell) => ({
        style: "noBordersCell",
        text: [
          { text: `${cell[0]}: `, fontSize: 10, bold: false },
          { text: cell[1], fontSize: 10, bold: true },
        ],
      }));
      table.body.push(headers);
    });

    table.body.push([
      {
        colSpan: 3,
        style: "legalText",
        text:
          "Este es un informe de recomendación. Timac Agro no se hará responsable en caso de un uso incorrecto del producto o de una indebida aplicación del mismo en campo por parte del cliente. Timac Agro no será responsable en la relación con la aplicación de ningún producto que no haya sido fabricado bajo su marca.",
      },
      "",
      "",
    ]);

    blueContainer.table.body[1].push({ table: table, layout: "noBorders" });

    this.pdfData.content.push(blueContainer);
  }

  async insertTableAndGraph(title, tableData, domElement) {
    const blueContainer = this.getBlueContainer(title);
    const canvasToImage = await html2canvas(domElement);

    const table = {
      widths: Array(tableData[0].length).fill(
        `${(100 / tableData[0].length).toFixed(2)}%`
      ),
      body: [],
    };

    const graph = {
      widths: ["*"],
      body: [],
    };

    tableData.forEach((row, index) => {
      if (index === 0) {
        table.body.push(
          row.map((cellText) => ({ style: "tableHeaderCell", text: cellText }))
        );
      } else {
        table.body.push(
          row.map((cellText) => ({ style: "tableNormalCell", text: cellText }))
        );
      }
    });

    graph.body.push([
      {
        image: canvasToImage.toDataURL(),
        width: 520
      }
    ])

    blueContainer.table.body[1].push([
      { table: table },
      { table: graph, layout: "noBorders", alignment: "center", margin: [0, 15, 0, 15] },
    ]);

    this.pdfData.content.push(blueContainer);
  }

  buildAndDownload(filename) {
    pdfmake.createPdf(this.pdfData).download(filename);
  }

  buildForMobile() {
    const pdfBuild = pdfmake.createPdf(this.pdfData);
    return pdfBuild;
  }
}
