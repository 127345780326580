<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Vademecum_Title')}`" />
    <ProductDetail :product-detail="nutritionalProductDetail[0].productDetail" />
    <Application :application="nutritionalProductDetail[0].application" />
    <f7-block-footer
      class="go-back-button"
    >
      <f7-button
        class="dx-btn-cancel btnBack"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToVademecum"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import ProductDetail from '../../../components/utilities/vademecum/nutritionalProductDetail/productDetail/index.vue';
import Application from '../../../components/utilities/vademecum/nutritionalProductDetail/application/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'ViewNutritionalProductDetail',
  components: {
    ProductDetail,
    Application,
    navbar,
  },
  computed: {
    ...mapState('Vademecum', ['nutritionalProductDetail']),
  },
  methods: {
    goToVademecum() {
      this.$f7.views.main.router.navigate('/vademecum/nutritional/', { reloadCurrent: true });
    },
  },
};
</script>
