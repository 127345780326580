<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualLocation.name} > ${$t('parcels')} > ${$t('parcel.register.title')}`" />
    <f7-block v-if="!loadedGIS">
      {{ $t("formAction_Alert_EnableGPS") }}
    </f7-block>
    <f7-block v-else>
      <GISEditorSigpac
        :zoom="zoom"
        :name-map="mainMap"
        :center="center"
        :tree-locations="treeLocations"
        :disabled-edit-parcel="false"
        :show-marker="showMarker"
        :show-g-p-s-button="true"
        height="70vh"
      />
    </f7-block>

    <Parcel-info
      :land-size="areaToDisplay"
      :lang="lngToDisplay"
      :lat="latToDisplay"
    />

    <RegisterParcelStep1 :current-name="currentParcel.name" />

    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          v-if="loadedGIS"
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="registerParcel"
        >
          {{ $t("save") }}
        </f7-button>
      </f7-col>
    </f7-row>
    <PopupRegisterParcelStep2 />
  </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import GISEditorSigpac from '../../components/Gis/GISEditorSigpac/index.vue';
import PopupRegisterParcelStep2 from '../../components/parcel/popupRegisterParcelStep2/index.vue';
import ParcelInfo from '../../components/parcel/parcelInfo/index.vue';
import RegisterParcelStep1 from '../../components/parcel/RegisterParcelStep1/index.vue';

export default {
  name: 'RegisterParcelStep2Page',
  components: {
    navbar,
    GISEditorSigpac,
    PopupRegisterParcelStep2,
    ParcelInfo,
    RegisterParcelStep1,
  },
  data() {
    return {
      zoom: 17,
      mainMap: 'mainMapParcel',
      center: {},
      loadedGIS: false,
      showMarker: false,
      editing: false,
    };
  },
  computed: {
    areaToDisplay() {
      if (this.editing) {
        return this.area;
      }

      if (typeof this.currentParcel.area !== 'undefined') {
        return this.currentParcel.area;
      }
      return this.area;
    },
    lngToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lng;
      }

      if (typeof this.currentParcel.area !== 'undefined') {
        return this.currentParcel.coordinates.longitude;
      }
      return this.viewCentroide.lng;
    },
    latToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lat;
      }

      if (typeof this.currentParcel.area !== 'undefined') {
        return this.currentParcel.coordinates.latitude;
      }
      return this.viewCentroide.lat;
    },
    ...mapState('RegisterLocation', [
      'name',
      'registerNumber',
      'province',
      'mobile',
      'farm',
      'ownerFarm',
      'registerExplotationId',
    ]),
    ...mapState('GisEditor', ['area', 'centroide', 'currentGeoFeature', 'featureInfoSigpac']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('Parcel', ['currentParcel', 'currentParcelId']),
    ...mapState('Plantation', ['treeLocations']),
    ...mapGetters('GisEditor', ['viewCentroide']),
  },
  beforeMount() {
    if (typeof this.currentParcel.id === 'undefined') {
      this.editing = false;
      // Establecer geolocalización
      const navigatorOptions = {
        enableHighAccuracy: true,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          this.center = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            radius: position.coords.accuracy,
          };
          this.loadedGIS = true;
          this.showMarker = true;
        },
        (error) => {
          this.$f7.dialog.alert(
            `${error.message} - ${this.$t('formAction_Alert_EnableGPS')}`,
          );
        },
        navigatorOptions,
      );
    } else {
      this.setCentroide({ lat: this.currentParcel.coordinates.latitude, lng: this.currentParcel.coordinates.longitude });
      this.setArea(this.currentParcel.area);
      this.editing = true;
      this.center = this.currentParcel.coordinates;
      this.showMarker = false;
      this.loadedGIS = true;
    }
  },
  mounted() {
    if (this.sigpacReferenceExists()) {
      delete this.featureInfoSigpac.features[0].properties.SIGPACReference;
    }
  },
  methods: {
    sigpacReferenceExists() {
      return !this.$helpers.isEmptyObject(this.featureInfoSigpac)
        ? this.featureInfoSigpac.features[0].properties.SIGPACReference : false;
    },
    async registerParcel() {
      this.$f7.preloader.show();
      try {
        if (this.area === 0 && this.editing === false) {
          this.$f7.dialog.alert(
            this.$t('parcel.register.geofeatures.requiredRule'),
          );
        } else if (typeof this.currentParcel.name === 'undefined' || this.currentParcel.name === '') {
          this.$f7.dialog.alert(this.$t('parcel.register.name.requiredRule'));
        } else {
          const companyId = this.actualUser.id;
          let geoFeature = null;
          if (this.area === 0) {
            this.setCentroide({ lat: this.currentParcel.coordinates.latitude, lng: this.currentParcel.coordinates.longitude });
            this.setArea(this.currentParcel.area);
            geoFeature = this.currentParcel.geo_feature;
          }

          if (this.currentGeoFeature.features[0].geometry.coordinates.length !== 0) {
            if (!this.$helpers.isEmptyObject(this.featureInfoSigpac)) {
              if (this.featureInfoSigpac.features[0].properties.SIGPACReference) {
                geoFeature = this.featureInfoSigpac;
              } else {
                geoFeature = this.currentGeoFeature;
              }
            } else {
              geoFeature = this.currentGeoFeature;
            }
          }

          const newParcel = {
            name: this.currentParcel.name,
            geo_feature: geoFeature,
            type: 'ORGANIZATIVE', // Para parcelas siempre tiene ese valor
            coordinates: {
              latitude: this.centroide.lat,
              longitude: this.centroide.lng,
            },
            code: `${this.currentParcel.name}${Date.now()}`,
            parent:
              typeof this.actualLocation.bdId === 'undefined'
                ? this.registerExplotationId
                : this.actualLocation.bdId,
            properties: [
              {
                key: 'area',
                value: this.area,
              },
            ],
          };

          if (typeof this.currentParcel.id === 'undefined') {
            await this.createParcel({ companyId, newParcel });
            this.setShowPopup(true);
          } else {
            await this.updateParcel({ companyId, parcelUpdated: newParcel, parcelId: this.currentParcel.id });
            this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
          }
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/viewLocation/', {
        reloadCurrent: true,
      });
    },
    ...mapActions('Parcel', ['createParcel', 'updateParcel', 'setShowPopup']),
    ...mapActions('GisEditor', ['setCentroide', 'resetCentroide', 'setArea']),
  },
};
</script>

<style scoped>
.dx-btn-cancel.margin-right {
  width: 15%;
  margin-left: 2%;
}
.dx-btn-success {
  width: 30%;
  margin-left: 68%;
}
</style>
