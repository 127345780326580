import Api from '../../services/Api';
import Helpers from '../../js/helpers';
import Vademecum from '../../js/models/Vademecum';
import Product from '../../js/models/Product';

export default {
  namespaced: true,
  state: {
    phytosanitaryProductsPlague: [],
    phytosanitaryProductsProduct: [],
    phytosanitaryProductDetail: {},
    optionsCultiveFilter: [],
    optionsPlagueFilter: [],
    optionsCommercialNameFilter: [],
    nutritionalProducts: [],
    nutritionalProductDetail: {},
    filterCrop: null,
    filterAgent: null,
    filterCommercialName: null,
    filterActiveIngredient: null,
  },
  mutations: {
    GET_PHYTOSANITARY_PRODUCTS_PLAGUE(state, value) {
      state.phytosanitaryProductsPlague = value;
    },
    GET_PHYTOSANITARY_PRODUCTS_PRODUCT(state, value) {
      state.phytosanitaryProductsProduct = value;
    },
    GET_NUTRITIONAL_PRODUCTS(state, value) {
      state.nutritionalProducts = value;
    },
    GET_OPTIONS_CULTIVE_FILTER(state, value) {
      state.optionsCultiveFilter = value;
    },
    GET_OPTIONS_PLAGUE_FILTER(state, value) {
      state.optionsPlagueFilter = value;
    },
    GET_OPTIONS_COMMERCIAL_NAME_FILTER(state, value) {
      state.optionsCommercialNameFilter = value;
    },
    GET_PHYTOSANITARY_PRODUCT_DETAIL(state, value) {
      state.phytosanitaryProductDetail = value;
    },
    GET_NUTRITIONAL_PRODUCT_DETAIL(state, value) {
      state.nutritionalProductDetail = value;
    },
    SET_FILTER_CROP(state, value) {
      state.filterCrop = value;
    },
    SET_FILTER_AGENT(state, value) {
      state.filterAgent = value;
    },
    SET_FILTER_COMMERCIAL_NAME(state, value) {
      state.filterCommercialName = value;
    },
    SET_FILTER_ACTIVE_INGREDIENT(state, value) {
      state.filterActiveIngredient = value;
    },
  },
  actions: {
    async getPhytosanitaryProductsPlague({ commit, state }) {
      try {
        let phytosanitaryProducts;

        let phytosanitaryProductsFormatted = [];
        const vademecumOffline = await Vademecum.getAll();

        if (vademecumOffline.length > 0) {
          if (state.filterAgent === '') {
            phytosanitaryProducts = await Vademecum.getByCultive(state.filterCrop);
          } else {
            phytosanitaryProducts = await Vademecum.getByCultiveAndAgent(state.filterCrop, state.filterAgent);
          }

          phytosanitaryProductsFormatted = phytosanitaryProducts;
        } else {
          const xhr = await Api.getPhytosanitaryProducts(state.filterCrop, state.filterAgent);
          phytosanitaryProducts = JSON.parse(xhr.response).data;

          for (const phytosanitaryProduct of phytosanitaryProducts) {
            phytosanitaryProductsFormatted.push({
              id: phytosanitaryProduct.id,
              crop: phytosanitaryProduct.cultive,
              agent: phytosanitaryProduct.agent,
              activeProduct: phytosanitaryProduct.composition,
              commercialName: phytosanitaryProduct.comercial_name,
              holder: phytosanitaryProduct.owner,
              registrationNumber: phytosanitaryProduct.code,
            });
          }
        }
        commit('GET_PHYTOSANITARY_PRODUCTS_PLAGUE', phytosanitaryProductsFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getPhytosanitaryProductsProduct({ commit }) {
      try {
        const vademecumOffline = await Vademecum.getAll();
        let phytosanitaryProducts;

        if (vademecumOffline.length > 0) {
          phytosanitaryProducts = vademecumOffline;
        } else {
          const xhr = await Api.getPhytosanitaryProducts();
          phytosanitaryProducts = JSON.parse(xhr.response).data;
        }

        const phytosanitaryProductsFormatted = [];
        for (const phytosanitaryProduct of phytosanitaryProducts) {
          phytosanitaryProductsFormatted.push({
            id: phytosanitaryProduct.id,
            crop: phytosanitaryProduct.cultive,
            agent: phytosanitaryProduct.agent,
            activeProduct: phytosanitaryProduct.composition,
            commercialName: phytosanitaryProduct.comercial_name,
            holder: phytosanitaryProduct.owner,
            registrationNumber: phytosanitaryProduct.code,
          });
        }
        commit('GET_PHYTOSANITARY_PRODUCTS_PRODUCT', phytosanitaryProductsFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getNutritionalProducts(context) {
      try {
        const nutritionalProductsOffline = await Product.getAll();
        let nutritionalProducts;

        if (nutritionalProductsOffline.length > 0) {
          nutritionalProducts = nutritionalProductsOffline;
        } else {
          const xhr = await Api.getNutritionalProducts();
          nutritionalProducts = JSON.parse(xhr.response).data;
        }

        const nutritionalProductsFormatted = [];
        for (const nutritionalProduct of nutritionalProducts) {
          nutritionalProductsFormatted.push({
            id: nutritionalProduct.id,
            typeProduct: nutritionalProduct.tipology,
            commercialName: nutritionalProduct.name,
            holder: nutritionalProduct.owner,
            registrationNumber: nutritionalProduct.code,
          });
        }
        context.commit('GET_NUTRITIONAL_PRODUCTS', nutritionalProductsFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getOptionsCultiveFilter(context) {
      try {
        const vademecumOffline = await Vademecum.getCultives();
        let optionsCultiveFilter;

        if (vademecumOffline.length > 0) {
          optionsCultiveFilter = vademecumOffline;
        } else {
          const xhr = await Api.getOptionsCultiveFilter();
          optionsCultiveFilter = JSON.parse(xhr.response).data;
        }

        context.commit('GET_OPTIONS_CULTIVE_FILTER', optionsCultiveFilter);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getOptionsPlagueFilter(context, crop) {
      try {
        const xhr = await Api.getOptionsPlagueFilter(crop);
        const optionsPlagueFilter = JSON.parse(xhr.response).data;

        context.commit('GET_OPTIONS_PLAGUE_FILTER', optionsPlagueFilter);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getOptionsPlagueFilterVademecum(context, crop) {
      try {
        const vademecumOffline = await Vademecum.getAll();
        let optionsPlagueFilter;

        if (vademecumOffline.length > 0) {
          optionsPlagueFilter = await Vademecum.getAgentsByCultive(crop);
        } else {
          const xhr = await Api.getOptionsPlagueFilterVademecum(crop);
          optionsPlagueFilter = JSON.parse(xhr.response).data;
        }

        context.commit('GET_OPTIONS_PLAGUE_FILTER', optionsPlagueFilter);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getOptionsCommercialNameFilter(context) {
      try {
        const xhr = await Api.getOptionsCommercialNameFilter();
        const optionsCommercialNameFilter = JSON.parse(xhr.response).data;
        context.commit('GET_OPTIONS_COMMERCIAL_NAME_FILTER', optionsCommercialNameFilter);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getPhytosanitaryProductDetail(context, product) {
      try {
        const vademecumOffline = await Vademecum.getAll();
        let vademecumProductDetail;

        if (vademecumOffline.length > 0) {
          if (product.agent) {
            vademecumProductDetail = await Vademecum.getDetailByIdCropAndAgent(product.id, product.crop, product.agent);
          } else if (product.crop) {
            vademecumProductDetail = await Vademecum.getDetailsByCrop(product.id, product.crop);
          } else {
            vademecumProductDetail = await Vademecum.getDetailsById(product.id);
          }

          if (vademecumProductDetail == null) {
            const xhr = await Api.getPhytosanitaryProductDetail(product);
            vademecumProductDetail = JSON.parse(xhr.response);
          }
        } else {
          const xhr = await Api.getPhytosanitaryProductDetail(product);
          vademecumProductDetail = JSON.parse(xhr.response);
        }

        const vademecumProductDetailFormatted = [];
        vademecumProductDetailFormatted.push({
          productDetail: {
            commercialName: vademecumProductDetail.comercial_name,
            holder: vademecumProductDetail.owner,
            registrationNumber: vademecumProductDetail.code,
            activeProduct: vademecumProductDetail.composition,
            containers: vademecumProductDetail.containners,
            expirationDate: vademecumProductDetail.expiration_date,
            pdf: vademecumProductDetail.pdf,
          },
          usesDosesAuth: {
            id: vademecumProductDetail.id,
            recommendations: vademecumProductDetail.recommendations,
          },
        });
        context.commit('GET_PHYTOSANITARY_PRODUCT_DETAIL', vademecumProductDetailFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getNutritionalProductDetail(context, idProduct) {
      try {
        const nutritionalProductsOffline = await Product.getAll();
        let nutritionalProductDetail;

        if (nutritionalProductsOffline.length > 0) {
          nutritionalProductDetail = await Product.getById(idProduct);
        } else {
          const xhr = await Api.getNutritionalProductDetail(idProduct);
          nutritionalProductDetail = JSON.parse(xhr.response);
        }

        if (nutritionalProductDetail == null) {
          const xhr = await Api.getNutritionalProductDetail(idProduct);
          nutritionalProductDetail = JSON.parse(xhr.response);
        }

        const nutritionalProductDetailFormatted = [];
        nutritionalProductDetailFormatted.push({
          productDetail: {
            commercialName: nutritionalProductDetail.name,
            holder: nutritionalProductDetail.owner,
            registrationNumber: nutritionalProductDetail.code,
            typeProduct: nutritionalProductDetail.tipology,
          },
          application: nutritionalProductDetail,
        });
        context.commit('GET_NUTRITIONAL_PRODUCT_DETAIL', nutritionalProductDetailFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setFilterCrop(context, value) {
      context.commit('SET_FILTER_CROP', value);
    },
    setFilterAgent(context, value) {
      context.commit('SET_FILTER_AGENT', value);
    },
    setFilterCommercialName(context, value) {
      context.commit('SET_FILTER_COMMERCIAL_NAME', value);
    },
    setFilterActiveIngredient(context, value) {
      context.commit('SET_FILTER_ACTIVE_INGREDIENT', value);
    },
  },
};
