import { f7 } from 'framework7-vue';
import moment from 'moment';
import Request from './Request';
import EventBus from '../js/event-bus';
import { MIN_SECONDS_TO_REFRESH_TOKEN } from '../js/constants';

let email = '';
let accessToken = '';
let refreshToken = '';
let headerAuth = {};

const baseUrlGis = 'https://gis.development.hispatecanalytics.com'; // Endpoint preproducción Geoserver
// const baseUrlGis = 'https://gis.hispatecanalytics.com';
const baseUrl = 'https://timac.development.hispatecanalytics.com'; // Endpoint estable desarrollo

const geoServerWorkspace = 'timacagro';
const geoServerTimacagro = `${baseUrlGis}/geoserver/${geoServerWorkspace}/wms?`;
const geoServerProjectWorkSpace = `${baseUrlGis}/geoserver/${geoServerWorkspace}/wms?`;
const geoServerRasterDates = `${baseUrlGis}/gisw/ws/get_raster_dates/`;
const geoServer = `${baseUrlGis}/gisw/ws`;
const geoServerPort = `${baseUrlGis}/geoserver`;
const geoServerSigpacMap = `${baseUrlGis}/gisw/ws/getmap/`;
const geoServerFeatureInfo = `${baseUrlGis}/gisw/ws/getfeatureinfo/`;
const geoServerTemperatureHistogram = `${baseUrlGis}/gisw/ws/get_index_stats/`;
const geoServerSigpacSurface = `${baseUrlGis}/gisw/ws/getsuperficie/`;
const geoServerDissolve = `${baseUrlGis}/gisw/ws/get_dissolve/`;
const geoServerSigpacWFSGeom = `${baseUrlGis}/gisw/ws/get_sigpac_geom/`;
const geoServerPrintMap = `${baseUrlGis}/gisw/ws/get_print_map/`;
// const baseUrl = 'https://mobile.development.hispatecanalytics.com'; // Endpoint beta
// const baseUrl = 'https://api.pre.hcoagriplatform.com'; // Endpoint preproducción
// const baseUrl = 'https://api.hcoagriplatform.com'; // Endpoint producción
const azureAuthCallback = 'http://localhost/'; // Callback azure dev
// const azureAuthCallback = 'https://timac.pre.hispatecanalytics.com/'; // Callback azure pre
// const azureAuthCallback = 'https://timac.hispatecanalytics.com/'; // Callback azure pro

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  email = data.user.email;
  accessToken = data.access;
  refreshToken = data.refresh;
  headerAuth = `Bearer ${accessToken}`;
});

export default {
  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!email) return;
        email = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  getAzureAuthCallback() {
    return azureAuthCallback;
  },
  getToken() {
    return accessToken;
  },
  getHeaderAuth() {
    return headerAuth;
  },
  getBaseUrl() {
    return baseUrl;
  },
  getGeoServer() {
    return geoServer;
  },
  getGeoServerProjectWorkSpace() {
    return geoServerProjectWorkSpace;
  },
  getGeoServerRasterDates() {
    return geoServerRasterDates;
  },
  getGeoServerPort() {
    return geoServerPort;
  },
  getGeoServerSigpacMap() {
    return geoServerSigpacMap;
  },
  getGeoServerTimacAgro() {
    return geoServerTimacagro;
  },
  getGeoServerFeatureInfo() {
    return geoServerFeatureInfo;
  },
  getGeoServerWorkspace() {
    return geoServerWorkspace;
  },
  getGeoServerSigpacSurface() {
    return geoServerSigpacSurface;
  },
  getGeoServerDissolve() {
    return geoServerDissolve;
  },
  getGeoServerSigpacWFSGeom() {
    return geoServerSigpacWFSGeom;
  },
  getGeoServerPrintMap() {
    return geoServerPrintMap;
  },
  // INICIO: Login y autentificación
  login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = {
      data: JSON.stringify({
        email: credentials.username,
        password: credentials.password,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json').then(
      this.handleLoginResult,
    );
  },

  // Get authorize Azure
  AzureAuthorization() {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json');
  },
  AzureGetToken(code) {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: JSON.stringify({
        code,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json').then(
      this.handleLoginResult,
    );
  },

  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
    }
  },
  async setAccessToken(result) {
    if (result.response !== '') {
      accessToken = JSON.parse(result.response).access;
      headerAuth = `Bearer ${accessToken}`;
      EventBus.$emit('newAccessToken', accessToken);
    }
  },
  async renewAccessToken() {
    let oAuthPayload = accessToken.split('.')[1];
    oAuthPayload = window.atob(oAuthPayload);
    oAuthPayload = JSON.parse(oAuthPayload);
    const secondsBeforeExpire = oAuthPayload.exp - moment.utc().unix();
    if (secondsBeforeExpire > MIN_SECONDS_TO_REFRESH_TOKEN) return {};
    const url = `${baseUrl}/user/refresh`;
    const payload = {
      data: JSON.stringify({ refresh: refreshToken }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.setAccessToken);
  },

  // FIN: Login y autentificación

  getException(errorcode) {
    return errorcode in this.errors
      ? this.errors[errorcode]
      : this.errors.default;
  },
  checkInitEndDate(actualFilter) {
    let init = '';
    let end = '';
    if (
      typeof actualFilter !== 'undefined'
      && actualFilter.init !== 'Invalid date'
      && actualFilter.end !== 'Invalid date'
    ) {
      init = actualFilter.init;
      end = actualFilter.end;
    }
    return {
      init,
      end,
    };
  },
  async getCampaignsByCompany(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async createCampaign(idCompanyHeader, newCampaignData) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign`;
    const payload = {
      data: JSON.stringify(newCampaignData),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateCampaign(idCompanyHeader, newCampaignData, campaignId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign/${campaignId}`;
    const payload = {
      data: JSON.stringify(newCampaignData),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getLocationByLevel(idCompanyHeader, level = '') {
    await this.renewAccessToken();
    const url = `${baseUrl}/location?level=${level}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTreeLocation(idCompanyHeader, plantationId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/tree/location`;
    const queryParams = new URLSearchParams();
    if (plantationId !== '') {
      queryParams.set('plantation', plantationId);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getParcelSystemTreeLocation(idCompanyHeader, campaignId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/tree/plantation`;
    const queryParams = new URLSearchParams();
    if (campaignId !== '') {
      queryParams.set('campaign', campaignId);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async createLocation(idCompanyHeader, newLocationData) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location`;
    const payload = {
      data: JSON.stringify(newLocationData),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateLocation(idCompanyHeader, newLocationData, sectorId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location/${sectorId}`;
    const payload = {
      data: JSON.stringify(newLocationData),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async fetchTreeLocationById(idCompanyHeader, locationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tree/location?location=${locationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getClient() {
    await this.renewAccessToken();
    const url = `${baseUrl}/user`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getSales(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sale`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },

  async getProductsSync(owner) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sync/product?owner=${owner}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },

  async getProducts() {
    await this.renewAccessToken();
    const url = `${baseUrl}/product`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewProduct(idCompanyHeader, newProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product`;
    const payload = {
      data: JSON.stringify(newProduct),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getProductDetail(idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateProduct(product, idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: JSON.stringify(product),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteProduct(idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getListAnalysis(idCompanyHeader, plantationId) {
    await this.renewAccessToken();
    let url = `${baseUrl}/laboratory_analysis`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async createNewAnalysis(dataAnalysis, idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis`;
    const payload = {
      data: JSON.stringify(dataAnalysis),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getPhytosanitaryProducts(filterCrop, filterAgent) {
    await this.renewAccessToken();
    let url = `${baseUrl}/vademecum`;
    const queryParams = new URLSearchParams();
    if (typeof filterCrop !== 'undefined') {
      queryParams.set('cultive', filterCrop);
    }
    if (typeof filterCrop !== 'undefined') {
      queryParams.set('agent', filterAgent);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getNutritionalProducts() {
    await this.renewAccessToken();
    const url = `${baseUrl}/product`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsCultiveFilter() {
    await this.renewAccessToken();
    const url = `${baseUrl}/cultive/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsPlagueFilter(crop) {
    await this.renewAccessToken();
    let url = `${baseUrl}/treatment/plague`;
    const queryParams = new URLSearchParams();
    queryParams.set('cultive', crop);
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsPlagueFilterVademecum(crop) {
    await this.renewAccessToken();
    let url = `${baseUrl}/plague/vademecum`;
    const queryParams = new URLSearchParams();
    queryParams.set('cultive', crop);
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsCommercialNameFilter() {
    await this.renewAccessToken();
    const url = `${baseUrl}/commercial_name/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getPhytosanitaryProductDetail(product) {
    await this.renewAccessToken();
    let url = `${baseUrl}/vademecum/${product.id}`;
    const queryParams = new URLSearchParams();
    if (typeof product.agent !== 'undefined') {
      queryParams.set('agent', product.agent);
    }
    if (typeof product.crop !== 'undefined') {
      queryParams.set('cultive', product.crop);
    }
    url += `?${queryParams.toString()}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getNutritionalProductDetail(idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getPlantationByCampaign(idCompanyHeader, campaignId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/plantation`;
    if (campaignId !== '') {
      url += `?campaign=${campaignId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getAllCampaignsForUser(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/contact/campaign`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getlocationById(idCompanyHeader, Id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location/${Id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getLocationByCampaign(idCompanyHeader, idCampaign) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location?campaign=${idCampaign}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeather(
    idCompanyHeader,
    locations,
    category,
    initDate,
    endDate,
    parameter,
  ) {
    await this.renewAccessToken();
    const url = `${baseUrl}/climate?locations=${locations}&category=${category}&init_date=${initDate}&end_date=${endDate}&parameters=${parameter}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeatherByPlantation(
    idCompanyHeader,
    plantation,
    category,
    initDate,
    endDate,
    parameter,
  ) {
    await this.renewAccessToken();
    const url = `${baseUrl}/climate/plantation/data?plantation=${plantation}&category=${category}&init_date=${initDate}&end_date=${endDate}&parameters=${parameter}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeatherForParameter(
    idCompanyHeader,
    locations,
    category,
    initDate,
    endDate,
    parameters = [],
  ) {
    await this.renewAccessToken();
    let url = `${baseUrl}/climate?locations=${locations}&category=${category}&init_date=${initDate}&end_date=${endDate}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeatherForParameterByPlantation(
    companyId,
    plantationId,
    category,
    initDate,
    endDate,
    parameters = [],
  ) {
    await this.renewAccessToken();
    let url = `${baseUrl}/climate/plantation/data?plantation=${plantationId}&category=${category}&init_date=${initDate}&end_date=${endDate}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  getVarieties(params) {
    const url = `${baseUrl}/variety${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewVariety(newVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety`;
    const payload = {
      data: JSON.stringify(newVariety),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getVarietyDetail(idVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateVariety(variety, idVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: JSON.stringify(variety),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteVariety(idVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  getObservations(params, { companyId, currentPlantationId = '' }) {
    const url = `${baseUrl}/scouting${params}&plantation=${currentPlantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async createNewObservation(idCompanyHeader, newObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting`;
    const payload = {
      data: JSON.stringify(newObservation),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getObservationDetail(idCompanyHeader, idObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateObservation(idCompanyHeader, idObservation, observation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: JSON.stringify(observation),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deleteObservation(idCompanyHeader, idObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async removeObservationFile(idCompanyHeader, idObservation, fileName) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}/file/${fileName}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTasks() {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewTask(newTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks`;
    const payload = {
      data: JSON.stringify(newTask),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTaskDetail(idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateTask(task, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: JSON.stringify(task),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteTask(idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketSlices() {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/markets`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketFamilies(nameSlice) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/families?market=${nameSlice}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketProduct(nameSlice, nameFamily) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/products?market=${nameSlice}&family=${nameFamily}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketData(
    nameSlice,
    nameFamily,
    nameProduct,
    nameOrigin,
    initDate,
    endDate,
  ) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/data?market=${nameSlice}&family=${nameFamily}&product=${nameProduct}&origin=${nameOrigin}&date_ini=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketOrigins(nameSlice, nameFamily, nameProduct, initDate, endDate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/origins?market=${nameSlice}&family=${nameFamily}&product=${nameProduct}&date_ini=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllProvince() {
    await this.renewAccessToken();
    const url = `${baseUrl}/geonames/country/ES/province`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllCities(codeProvince) {
    await this.renewAccessToken();
    const url = `${baseUrl}/geonames/country/ES/city?admin1_code=${codeProvince}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  getContactPlantation(params) {
    const url = `${baseUrl}/contact/plantation${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  getContactPlantationByDate(params, data) {
    const url = `${baseUrl}/contact/plantation${params}&init_date=${data.init}&end_date=${data.end}&status=ACTIVE`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getIrrigation(idCompany, plantationId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/irrigation`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async postIrrigation(dataIrrigation, idCompany) {
    await this.renewAccessToken();
    const url = `${baseUrl}/irrigation`;
    const payload = {
      data: JSON.stringify(dataIrrigation),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async putIrrigation(dataIrrigation, irrigationId, idCompany) {
    await this.renewAccessToken();
    const url = `${baseUrl}/irrigation/${irrigationId}`;
    const payload = {
      data: JSON.stringify(dataIrrigation),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async deleteIrrigation(irrigationId, companyId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/irrigation/${irrigationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async updateAnalysis(dataAnalysis, idCompanyHeader, idAnalysis) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: JSON.stringify(dataAnalysis),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deleteAnalysis(idCompanyHeader, idAnalysis) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  // GIS Methods
  async geoserverGetBBOX(addedQuery) {
    await this.renewAccessToken();
    const url = `${geoServer}/${addedQuery}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', '');
  },
  async geoserverWMSLayerDataRequest(addedQuery) {
    await this.renewAccessToken();
    const url = `${geoServerPort}/${addedQuery}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  async geoserverSigpacSurfaceData(polygon) {
    await this.renewAccessToken();
    const url = `${geoServerSigpacSurface}?polygon=${polygon}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', '');
  },
  async getCapabilities(workSpaces) {
    await this.renewAccessToken();
    const url = `https://gis.development.hispatecanalytics.com:8443/geoserver/${workSpaces}/wms?service=WMS&version=1.3.0&request=GetCapabilities&format=text/xml`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/xml',
      headerAuth,
    );
  },
  async getTasksForCompany(idCompany, plantationId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/task`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async getTasksActivity() {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_activity`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTasksDefinition(activiy) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition?activity=${activiy}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllTasksDefinition() {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTaskDefinitionById(id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchActivities() {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_activity`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createTaskDefinition(newTaskDefinition) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition`;
    const payload = {
      data: JSON.stringify(newTaskDefinition),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateTaskDefinition(id, data) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteTaskDefinition(id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getScoutingPoints(idCompanyHeader, idPlantation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_point?plantation=${idPlantation}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getCategoriesOfTemplates() {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_category`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTemplatesByCategory(category) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form?category=${category}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTemplateById(id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewTaskByActivity(idCompanyHeader, newTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task`;
    const payload = {
      data: JSON.stringify(newTask),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deleteTaskByCompany(idCompanyHeader, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateTaskByCompany(idCompanyHeader, updTask, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: JSON.stringify(updTask),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTaskById(idCompanyHeader, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getGeoServerTemperatureHistogram(location, date, idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${geoServerTemperatureHistogram}?location=${location}&init_date=${date}&histogram=${true}
    &client=${geoServerWorkspace}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async createPointObservation(idCompanyHeader, pointObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_point`;
    const payload = {
      data: JSON.stringify(pointObservation),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getGroupsAvailables() {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety_group`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getSpeciesAvailables(group) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety_specie?group=${group}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getLaboratoryAnalysisById(idAnalysis, idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getScoutingPointById(companyIdHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_point/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyIdHeader,
    );
  },
  async getExplotation(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/explotation`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getExplotationDetailed(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/explotation/detailed`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  async getKeyValues() {
    await this.renewAccessToken();
    const url = `${baseUrl}/keyvalues`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getVarietyBySpecie(specie) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety?specie=${specie}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createPlantation(idCompanyHeader, newPlantationData) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation`;
    const payload = {
      data: JSON.stringify(newPlantationData),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getParcelByExplotation(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/explotation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getSectorByParcel(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/parcel/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getPlantationById(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deletePlantationById(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getCampaignsById(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTreatmentProducts(crop, agent) {
    await this.renewAccessToken();
    const url = `${baseUrl}/treatment/product?cultive=${crop}&agent=${agent}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTreatmentProduct(crop, agent, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/treatment/product/${id}?cultive=${crop}&agent=${agent}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  getTreatmentDataForAnalisis(params) {
    const url = `${baseUrl}/analysis/treatment_v2${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getParcelById(idCompanyHeader, parcelId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/parcel/${parcelId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getSectorById(idCompanyHeader, sectorId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sector/${sectorId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async fetchKeyValuesByFamily(family) {
    await this.renewAccessToken();
    const url = `${baseUrl}/keyvalues?family=${family}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updatePlantation(idCompanyHeader, updateDataPLantation, plantationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation/${plantationId}`;
    const payload = {
      data: JSON.stringify(updateDataPLantation),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getContactLocations(latitude, longitude, radio = 0) {
    await this.renewAccessToken();
    let url = `${baseUrl}/contact/location?latitude=${latitude}&longitude=${longitude}`;

    const queryParams = new URLSearchParams();
    if (radio > 0) {
      queryParams.set('radio', radio);
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getRegistersResume(companyId, plantationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/register?plantation=${plantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  fetchFertilizations(params) {
    const url = `${baseUrl}/utils/fertilization${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  fetchTreatments(params) {
    const url = `${baseUrl}/utils/treatment${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getFertilizationById(fertilizationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/utils/fertilization/${fertilizationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTreatmentById(treatmentId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/utils/treatment/${treatmentId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTreatmentPlanByIdandCompanyId(companyId, treatmentId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/treatment_plan/${treatmentId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async fetchObservationsTemplates() {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteObservationTemplateById(observationTemplateId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form/${observationTemplateId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createObservationTemplate(observationTemplate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form`;
    const payload = {
      data: JSON.stringify(observationTemplate),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateObservationTemplate(observationTemplateId, observationTemplate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form/${observationTemplateId}`;
    const payload = {
      data: JSON.stringify(observationTemplate),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  fetchFertilizationsForAnalysis(params) {
    const url = `${baseUrl}/analysis/fertilization${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchFertilizationByIdDetailsForAnalysis(fertId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/utils/fertilization/${fertId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchIrrigationDataForAnalysis(plantationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/analysis/irrigation?plantation=${plantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchTimelineIndexes(location, initDate, endDate, companyId) {
    await this.renewAccessToken();
    const url = `${geoServerTemperatureHistogram}`;
    const payload = {
      data: {
        location,
        init_date: initDate,
        end_date: endDate,
        client: geoServerWorkspace,
      },
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  getCompanies(params) {
    const url = `${baseUrl}/company${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getCompanyById(companyId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/company/${companyId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllRecordsToSync(endpoint) {
    await this.renewAccessToken();
    const url = `${baseUrl}/${endpoint}`;
    const payload = {
      data: null,
    };
    return this.request(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },

  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    let responseServer = null;
    const xhr = await Request.async(
      url,
      payload,
      verb,
      processData,
      contentType,
      headerAuth,
    );
    try {
      responseServer = JSON.parse(xhr.response);
      responseServer = responseServer.data
        ? responseServer.data
        : responseServer;
    } catch (error) {
      responseServer = xhr.status;
    }
    return responseServer;
  },
};
