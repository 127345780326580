<template>
  <div class="margin-top-half">
    <div
      class="content-devx"
    >
      <f7-block-title>
        <f7-row>
          <f7-col width="20">
            {{ $t('Product_Detail_Title') }}
          </f7-col>
          <f7-col width="80" />
        </f7-row>
      </f7-block-title>
      <f7-block>
        <f7-row class="margin-bottom">
          <f7-col>
            {{ $t('Filter_Commercial_Name') }}
          </f7-col>
          <f7-col>
            {{ $t('Holder') }}
          </f7-col>
          <f7-col>
            {{ $t('Vademecum_product_code') }}
          </f7-col>
          <f7-col>
            {{ $t('Type_Product') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold margin-bottom">
          <f7-col>
            {{ productDetail.commercialName }}
          </f7-col>
          <f7-col>
            {{ productDetail.holder }}
          </f7-col>
          <f7-col>
            {{ productDetail.registrationNumber }}
          </f7-col>
          <f7-col>
            {{ productDetail.typeProduct }}
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ProductDetail',
  props: {
    productDetail: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './ProductDetail.styles.scss';
</style>
