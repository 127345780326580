<template>
  <f7-page
    class="main-viewsector"
  >
    <div v-if="loaded">
      <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualLocation.name} > ${currentParcel.name} > ${currentSector.sectorName}`" />
      <f7-row no-gap>
        <f7-col class="">
          <SectorDetail
            :current-sector="currentSector"
            :current-client="actualUser.name"
          />
          <PlantationsDataGrid
            :plantations-availables="plantationAvailableFormatted"
            :current-client-id="actualUser.id"
          />
        </f7-col>
        <f7-col>
          <f7-block class="no-padding padding-top-half padding-right-half">
            <GISGeoJsonMap
              :zoom="zoom"
              :name-map="mainMap"
              :tree-locations="treeLocations"
              selected-min-height="455px"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <div
        class="go-back-button margin-left margin-top-half"
      >
        <f7-button
          class="dx-btn-cancel padding-horizontal"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import SectorDetail from '../../components/sector/sectorDetail/index.vue';
import GISGeoJsonMap from '../../components/Gis/GISGeoJsonMap/index.vue';
import PlantationsDataGrid from '../../components/sector/plantationsDataGrid/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ViewSector',
  components: {
    SectorDetail,
    GISGeoJsonMap,
    PlantationsDataGrid,
    navbar,
  },
  data() {
    return {
      zoom: 17,
      loaded: false,
      mainMap: 'sectorMainMap',
      plantationAvailableFormatted: [],
      actualDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapState('Parcel', ['currentParcelId', 'currentParcel']),
    ...mapState('Sector', ['currentSectorId', 'currentSector']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('Plantation', ['treeLocations']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getSectorById({ companyId: this.actualUser.id, currentSectorId: this.currentSectorId });
      await this.fetchTreeLocationById({ companyId: this.actualUser.id, locationId: this.currentSectorId });
      this.formattingPlantationsAvailables();
      this.plantationAvailableFormatted = [...this.currentSector.plantation];
      this.setCentroides();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    setCentroides() {
      this.setSectorCentroide(this.treeLocations[0].children[0].children[0].coordinates);
    },
    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    formattingPlantationsAvailables() {
      for (const plantationAvailable of this.currentSector.plantation) {
        plantationAvailable.status = this.getStatusCampaign(plantationAvailable.init_date, plantationAvailable.end_date);
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
    },
    ...mapActions('Sector', ['getSectorById']),
    ...mapActions('Plantation', ['fetchTreeLocationById']),
    ...mapActions('Gis', ['setSectorCentroide']),

  },
};
</script>

<style lang="scss">
.main-viewsector{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>
