<template>
  <div>
    <DxPopup
      :visible="showPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :width="500"
      :height="230"
      :show-close-button="false"
      :title="$t('parcel.register.registeredParcel')"
    >
      <template>
        <f7-block
          class="center"
        >
          <div class="title">
            {{ $t('parcel.register.completedSuccessfullyParcelRegister') }}
          </div>

          <f7-button
            class="button-popup"
            raised
            type="success"
            styling-mode="contained"
            @click="goToCreateSector()"
          >
            + {{ $t("sector.grid.createSector") }}
          </f7-button>
        </f7-block>
      </template>
    </DxPopup>
  </div>
</template>

<script>

import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PopupRegisterParcelStep2',
  components: {
    DxPopup,
  },
  computed: {
    ...mapState('Parcel', ['showPopup', 'currentParcelId']),
    ...mapState('Explotaciones', ['actualUser']),
  },
  methods: {
    onHidden() {
      this.setShowPopup(false);
    },
    async goToCreateSector() {
      try {
        this.setShowPopup(false);
        this.initCurrentSector();
        await this.fetchTreeLocationById({ companyId: this.actualUser.id, locationId: this.currentParcelId });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.views.main.router.navigate('/registerSectorStep2Page/', { reloadCurrent: true });
      }
    },
    ...mapActions('Sector', ['initCurrentSector']),
    ...mapActions('Parcel', ['setShowPopup']),
    ...mapActions('Plantation', ['fetchTreeLocationById']),
  },
};
</script>

<style>
@import './PopupRegisterParcelStep2.styles.scss';
</style>
