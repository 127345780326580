<template>
  <div class="content-devx">
    <DxForm
      id="templateData-form"
      ref="templateDataForm"
      :form-data="{}"
      :show-colon-after-label="false"
      :read-only="readOnly"
      @field-data-changed="formFieldDataChanged"
    >
      <DxGroupItem :col-count="3">
        <DxSimpleItem
          v-for="(simpleItem, index) in templateForm"
          :key="index"
          :data-field="simpleItem.field"
          :editor-type="simpleItem.type"
          :editor-options="simpleItem.editorOptions"
        >
          <DxLabel :text="simpleItem.description" />
          <DxRequiredRule v-if="simpleItem.required" />
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
  DxLabel,
} from 'devextreme-vue/form';
import 'devextreme-vue/tag-box';

export default {
  name: 'TemplateData',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxLabel,
  },
  props: {
    templateForm: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formFieldDataChanged() {
      this.setTemplateValue(this.$refs.templateDataForm.formData);
    },
    ...mapActions('SearcherObservations', ['setTemplateValue']),
  },
};
</script>

<style>

</style>
