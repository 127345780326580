<template>
  <div
    class="content-devx no-margin min200"
  >
    <p class="card-header-title title-top">
      {{ $t('Register.resume.title') }}
    </p>
    <DxDataGrid
      id="registersResume-datagrid"
      :data-source="registers"
      :remote-operations="true"
      :column-min-width="45"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="RegistersResume"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="3" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="date"
        name="date"
        :caption="$t('date')"
        :allow-header-filtering="false"
        :calculate-display-value="formatingDate"
        alignment="left"
      />
      <DxColumn
        data-field="type"
        :min-width="100"
        name="type"
        :caption="$t('type')"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataTypeStatus }"
        :calculate-display-value="formatingType"
        alignment="left"
      />
      <DxColumn
        data-field="action"
        name="action"
        :caption="$t('action')"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataActionStatus }"
        :calculate-display-value="formatingAction"
        alignment="left"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import settings from '../../../../js/commonSettings';

export default {
  name: 'RegistersResume',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  props: {
    registers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [3, 10, 20],
      dataTypeStatus: [{
        text: this.$t('Register.type.Fertilization'),
        value: 'Fertilization',
      }, {
        text: this.$t('Register.type.Campaign'),
        value: 'Campaign',
      },
      {
        text: this.$t('Register.type.Climate'),
        value: 'Climate',
      },
      {
        text: this.$t('Register.type.FertilizationPlan'),
        value: 'FertilizationPlan',
      }, {
        text: this.$t('Register.type.Irrigation_recommendation'),
        value: 'Irrigation_recommendation',
      },
      {
        text: this.$t('Register.type.Irrigation_register'),
        value: 'Irrigation_register',
      },
      {
        text: this.$t('Register.type.LaboratoyAnalisis'),
        value: 'LaboratoyAnalisis',
      }, {
        text: this.$t('Register.type.Location'),
        value: 'Location',
      },
      {
        text: this.$t('Register.type.Plantation'),
        value: 'Plantation',
      },
      {
        text: this.$t('Register.type.Product'),
        value: 'Product',
      }, {
        text: this.$t('Register.type.ScoutingPoint'),
        value: 'ScoutingPoint',
      },
      {
        text: this.$t('Register.type.Scouting'),
        value: 'Scouting',
      },
      {
        text: this.$t('Register.type.TaskDefinition'),
        value: 'TaskDefinition',
      }, {
        text: this.$t('Register.type.Task'),
        value: 'Task',
      },
      {
        text: this.$t('Register.type.Template'),
        value: 'Plantilla de observaciones',
      },
      {
        text: this.$t('Register.type.TreatmentOrder'),
        value: 'TreatmentOrder',
      }, {
        text: this.$t('Register.type.TreatmentPlan'),
        value: 'TreatmentPlan',
      },
      {
        text: this.$t('Register.type.Variety'),
        value: 'Variety',
      }],
      dataActionStatus: [{
        text: this.$t('DELETE'),
        value: 'DELETE',
      }, {
        text: this.$t('POST'),
        value: 'POST',
      },
      {
        text: this.$t('PUT'),
        value: 'PUT',
      }],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
  },
  beforeMount() {
    this.getOnlyUniqueTypesForFilter();
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('resume');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'registersResume.xlsx');
          });
      });
      e.cancel = true;
    },
    formatingType(e) {
      return this.$t(`Register.type.${e.type}`);
    },
    formatingAction(e) {
      return this.$t(e.action);
    },
    formatingDate(e) {
      return moment(e.date).format('DD/MM/YYYY HH:mm');
    },
    getOnlyUniqueTypesForFilter() {
      const uniq = [...new Set(this.registers.map((obj) => obj.type))];
      this.dataTypeStatus = this.dataTypeStatus.filter((elem) => uniq.find((id) => elem.value === id) && elem.text);
    },
    ...mapActions('SearcherObservations', ['getObservationDetail', 'createObservation', 'deleteObservation', 'setIdCurrentObservation']),
  },
};
</script>
<style lang="scss" scoped>
@import './RegistersResume.styles.scss';
</style>
