<template>
  <section>
    <div class="content-devx padding-half">
      <div class="card-header-title margin-left-half margin-bottom">
        {{ $t("treatment.treatmentPlan.treatmentCreatePlan.observations") }}
      </div>
      <f7-block>
        <f7-row>
          <f7-col>
            <div class="dx-field">
              <div>
                <DxTextArea :value="getObservations" @value-changed="onChangeDescription" />
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </section>
</template>

<script>
import { DxTextArea } from "devextreme-vue/text-area";
import { mapActions, mapState } from "vuex";

export default {
  name: "Observations",
  components: {
    DxTextArea,
  },
  computed: {
    ...mapState("nutritionStepper", ["stepData"]),
    getObservations(){
      return this.stepData.observation ?? "";
    }
  },
  methods: {
    ...mapActions("nutritionStepper", ["setObservation"]),
    onChangeDescription(e) {
      this.setObservation(e.value ?? "");
    },
  },
};
</script>