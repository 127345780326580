<template>
  <div
    id="chart-demo"
    class="content-devx"
  >
    <DxButton @click="resetGraph">
      <i class="dx-icon dx-icon-trash" />{{ $t('clear') }}
    </DxButton>
    <DxChart
      id="chart"
      :data-source="getDataDate"
      palette="Material"
      @legend-click="onLegendClick"
    >
      <DxValueAxis :max-value-margin="0.01">
        <DxTitle :text="$t('productResume.unitPrice')" />
      </DxValueAxis>

      <DxCommonSeriesSettings
        type="line"
        argument-field="date"
      />
      <template v-for="data in dataProduct">
        <DxSeries
          v-for="d in data"
          :key="d.value"
          :value-field="d.value"
          :name="d.name"
        />
      </template>
      <DxArgumentAxis
        discrete-axis-division-mode="crossLabels"
        argument-type="date"
      >
        <DxLabel
          display-mode="stagger"
          format="d MMM yyyy"
        />
      </DxArgumentAxis>
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="bottom"
      />
      <DxExport :enabled="false" />
      <DxTitle text="">
        <DxSubtitle text="" />
      </DxTitle>
      <DxTooltip
        :enabled="true"
        :z-index="999999"
        :customize-tooltip="customizeTooltip"
      />
      <DxScrollBar :visible="true" />
      <DxZoomAndPan argument-axis="both" />
    </DxChart>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxLabel,
  DxArgumentAxis,
  DxZoomAndPan,
  DxScrollBar,
  DxValueAxis,
} from 'devextreme-vue/chart';
import DxButton from 'devextreme-vue/button';
import moment from 'moment';

export default {
  name: 'GraphicWeather',
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxExport,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxArgumentAxis,
    DxLabel,
    DxButton,
    DxZoomAndPan,
    DxScrollBar,
    DxValueAxis,
  },
  props: {
    date: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allDataDate: '',
    };
  },
  computed: {
    ...mapState('Slices', ['dataProduct', 'SelectedProduct', 'dataDate']),
    getDataDate() {
      this.date.forEach((date) => {
        const currentDate = moment(date.date).locale('es').format('D MMM YY');
        date.date = currentDate;
      });
      return this.date;
    },
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    resetGraph() {
      this.initStoreGraph();
    },
    customizePriceAxis({ valueText }) {
      return `${valueText} €`;
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${
          pointInfo.seriesName.split('-')[1]
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('Product')}: </span>${pointInfo.seriesName.split('-')[0]}
          </span> </div>
          <span class="bold"> ${this.$t('price')}:</span> ${pointInfo.valueText} €
          <br/>
          <span class="bold"> ${this.$t('date')}: </span>${pointInfo.argumentText}
        </span> </div></div></div>`,
      };
    },
    ...mapActions('Slices', ['initStoreGraph']),
  },
};
</script>
<style>
.bold {
  font-weight: bold;
}
</style>
