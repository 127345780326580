<template>
  <div>
    <DxPopup
      :visible="showPopUpRecommended"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :show-close-button="true"
      :width="500"
      height="auto"
      :title="$t('register_irrigation_title_datagridRecommendation')"
    >
      <template>
        <div class="content-form">
          <DxForm>
            <DxSimpleItem
              :data-field="$t('registerAndEditIrrigation_date')"
              :caption="$t('registerAndEditIrrigation_date')"
              editor-type="dxDateBox"
              :editor-options="{
                value: initDate,
                onValueChanged: onChangeDateIrrigation,
                displayFormat: 'dd/MM/yyyy',
                readOnly: isView,
                invalidDateMessage: $t('dates_isInvalid'),
                disabledDates: disabledDates,
              }"
            />
            <DxSimpleItem
              :data-field="$t('registerAndEditIrrigation_doses')"
              :caption="$t('registerAndEditIrrigation_doses')"
              editor-type="dxNumberBox"
              :editor-options="{
                min: 0,
                mode: 'number',
                value: actualIrrigationRecommended.doses,
                onValueChanged: onChangeDosesIrrigation,
                readOnly: isView,
                placeholder: '0',
              }"
            />
          </DxForm>
        </div>
      </template>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          v-if="!isView"
          class="dx-btn-succes-pop-up"
          raised
          type="success"
          styling-mode="contained"
          @click="goToBack"
        >
          {{ $t("End_Campaign") }}
        </f7-button>
      </f7-block-footer>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import moment from 'moment';

export default {
  name: 'DxPopUpRegisterCampania',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
  },
  data() {
    return {
      actualPlot: '',
    };
  },
  computed: {
    ...mapState('Irrigation', ['showPopUpRecommended', 'actualIrrigationRecommended', 'isEdit', 'isView']),
    ...mapState('Plantation', ['currentPlantation']),
    initDate() {
      return moment(this.currentPlantation.initDate);
    }
  },
  methods: {
    disabledDates(args) {
      const actualDate = moment(args.date, "YYYY-MM-DD");
      const initDate = moment(this.currentPlantation.initDate, "YYYY-MM-DD");
      const endDate = moment(this.currentPlantation.endDate, "YYYY-MM-DD");

      if (args.view === "month") {
        return !actualDate.isBetween(initDate, endDate, 'days', '[]');
      }

      if (args.view === "year") {
        return !actualDate.isBetween(initDate, endDate, 'months', '[]');
      }

      if (args.view === "decade") {
        return !actualDate.isBetween(initDate, endDate, 'years', '[]');
      }

      if (args.view === "century") {
        const exactYear = parseInt(actualDate.year() / 10) * 10;
        const initDateIsBetween = initDate.year() >= exactYear && initDate.year() <= exactYear + 9;
        const endDateIsBetween = endDate.year() >= exactYear && endDate.year() <= exactYear + 9;
        
        return !(initDateIsBetween || endDateIsBetween);
      }

      return false;
    },
    onHidden() {
      this.setShowPopUpRecommended(false);
      this.setIsView(false);
      this.setIsEdit(false);
      this.initStoreIrrigationRecommended();
    },
    async goToBack() {
      if (!this.actualIrrigationRecommended.doses || typeof this.actualIrrigationRecommended.doses === 'undefined') {
        this.$f7.dialog.alert(this.$t('Register.type.Irrigation_register_doses_valitaion'));
        return null;
      }
      try {
        if (this.isEdit) {
          await this.updateDataIrrigationRecommended();
          this.setShowPopUpRecommended(false);
        } else {
          await this.createDataIrrigationRecommended();
          this.setShowPopUpRecommended(false);
        }
        this.$f7.views.main.router.navigate('/searcher/irrigation/', { reloadCurrent: true });
        return Promise.resolve();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(error));
        return Promise.reject(this.$t(error));
      }
    },
    onChangeDateIrrigation(e) {
      this.setDateRecommended(moment(e.value).format('YYYY-MM-DD'));
    },
    onChangeDosesIrrigation(e) {
      this.setDosesRecommended(e.value);
    },
    ...mapActions('Irrigation', ['setShowPopUpRecommended', 'createDataIrrigationRecommended', 'setDateRecommended', 'setDosesRecommended',
      'setTimeRecommended', 'updateDataIrrigationRecommended', 'setIsView', 'setIsEdit', 'initStoreIrrigationRecommended']),
  },
};
</script>

<style>
@import '../irrigationRecommendedFormPopup/irrigationRecommendedFormPopup.styles.scss';
</style>
