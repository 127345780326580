<template>
  <div>
    <div
      :id="`${nameMap}_${initialDate}`"
      :style="minHeight"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import 'leaflet-timedimension-new';
import * as turf from '@turf/turf';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import Api from '../../../services/Api';
import EventBus from '../../../js/event-bus';

export default {
  name: 'GISWMSTimeDimensionMap',
  props: {
    zoom: { type: Number, default: 1 },
    height: { type: String, default: '450px' },
    nameMap: { type: String, default: '' },
    index: { type: String, default: 'NDVI' },
    layer: {
      type: Object,
      default: () => {},
    },
    center: {
      type: Object,
      default: () => {},
    },
    bbox: {
      type: Object,
      default: () => {},
    },
    timeDimension: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      initialDate: Date.now(),
      currentLayers: [],
      currentDates: [],
      timeDimensionControl: null,
      timeDimensionLayer: null,
      currentIndex: null,
      statusMeasureTool: false,
      statusZoningTool: false,
      areaLayer: null,
      lineLayer: null,
      areaLayerPopup: null,
      lineLayerPopup: null,
      toggleZoningButtonclicks: null,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.height}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
      'featureInfo',
      'datesList',
      'currentInitIntervalDates',
      'currentEndIntervalDates',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
  },
  beforeMount() {
    EventBus.$on(`indexLayer${this.nameMap}`, this.indexLayer);
    EventBus.$on(
      `updateTimeDimension${this.nameMap}`,
      this.updateTimeDimension,
    );
  },
  mounted() {
    this.setCurrentLayersMap([]);
    this.renderMap();
    this.addOverlayLayer();
    this.addFuncionalities();
    this.updateLayer();
    this.indexLayer(this.index);
    this.setMapEvents();
  },
  methods: {
    /**
       * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
       */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        this.layer.options.env = 'min:0;max:0';
        const layer = L.tileLayer.wms(this.layer.baseUrl, this.layer.options);
        const bounds = L.latLngBounds([
          L.latLngBounds(
            [this.bbox.miny, this.bbox.minx],
            [this.bbox.maxy, this.bbox.maxx],
          ),
        ]);

        this.map.fitBounds(bounds, { maxZoom: 18 });
        // this.layerDates(layer.options.layers.split(':')[1]);

        const item = this.layer;
        this.currentLayers.push({ item, layer });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    indexLayer(index) {
      this.currentIndex = index;
      if (this.timeDimensionLayer !== null) {
        if (this.statusZoningTool) {
          this.timeDimensionLayer
            .setParams({
              styles: `${index}_variable`,
            })
            .addTo(this.map);
          const legendContent = '';
          this.map.legend.setContent(legendContent);
        } else {
          this.timeDimensionLayer
            .setParams({
              styles: index,
            })
            .addTo(this.map);
          this.legendContent(index);
        }
      } else {
        this.legendContent(index);
      }
    },

    legendContent(index) {
      let legendContent = `<h6 class="legend-index-name">${index}</h6><img src=${Api.getGeoServerProjectWorkSpace()}REQUEST=GetLegendGraphic&VERSION=2.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=10&LAYER=${
        this.layer.options.layers.split(':')[1]
      }&style=${index}&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:8;bgColor:0xFFFFFF;dpi:91&SCALE=1001; style="opacity:0.8";>`;
      if (index !== 'TCI') {
        this.map.legend.setContent(legendContent);
        const indexNameClass = document.getElementsByClassName(
          'legend-index-name',
        );

        if (index === 'NDMI' || index === 'VARI' || index === 'RECI') {
          indexNameClass[0].style.width = '40px';
          indexNameClass[0].style.top = '-46px';
        } else {
          indexNameClass[0].style.width = '37px';
        }
      } else {
        legendContent = '';
        this.map.legend.setContent(legendContent);
      }
    },

    /**
       * Anidamos los datos para adjuntar en la ruta.
       */
    generateRouteTocenterMapWMSLayer(item) {
      const layersArray = item.options.layers.split(':');
      if (layersArray.length < 2) return '';
      const fullUrl = `getbbox/?ws=${layersArray[0]}&layer=${layersArray[1]}&ds=${item.ds}`;
      return fullUrl;
    },

    /**
       *
       */
    async dataRequestWMSLayers(route, e) {
      await this.fetchFeatureInfo({ queryParams: `wms/${route}` });
      if (
        !('features' in this.featureInfo)
          || !this.featureInfo.features.length
      ) {
        // return false;
      } else {
        const features = this.featureInfo.features[0];
        const { properties } = features;

        this.openLayerDataPopup(properties, e);
      }
    },

    /**
       *
       */
    generateRouteToDataRequestWMSLayers(item, e) {
      const BBOX = `${e.latlng.lng - 0.00002},${e.latlng.lat - 0.00002},${e
        .latlng.lng + 0.00002},${e.latlng.lat + 0.00002}`;
        // TODO: PONER PARÁMETRO TIME PARA CUANDO SE SELECCIONE UNA FECHA DE UNA CAPA
      const queryParams = {
        REQUEST: 'GetFeatureInfo',
        SRS: 'EPSG:4326',
        BBOX,
        HEIGHT: this.map.getSize().y,
        WIDTH: this.map.getSize().x,
        LAYERS: item,
        // eslint-disable-next-line no-underscore-dangle
        TIME: this.timeDimensionControl._timeDimension.getCurrentTime(),
        QUERY_LAYERS: item,
        INFO_FORMAT: 'application/json',
        X: Math.round(this.map.layerPointToContainerPoint(e.layerPoint).x),
        Y: Math.round(this.map.layerPointToContainerPoint(e.layerPoint).y),
      };

      if (this.currentIndex != null) {
        queryParams.STYLES = this.currentIndex;
      }

      const fullUrl = L.Util.getParamString(queryParams);
      this.dataRequestWMSLayers(fullUrl, e);
    },

    async updateLayer() {
      this.$f7.preloader.show();
      try {
        const currentOverlayLayer = this.layer.options.layers.split(':')[1];
        await this.fetchDatesLayer(currentOverlayLayer);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        if (this.timeDimension) {
          this.createTimeDimension(this.currentLayers[0].item);
        }
      }
    },
    updateTimeDimension() {
      this.createTimeDimension(this.currentLayers[0].item);
    },
    createTimeDimension(item) {
      if (this.timeDimensionControl !== null) {
        this.timeDimensionControl.remove(this.map);
        this.timeDimensionLayer.remove(this.map);
      }
      const url = item.baseUrl;
      let dateList = this.datesList.avaiable_dates;
      if (this.currentInitIntervalDates[this.nameMap] !== '') {
        dateList = dateList.filter((date) => moment(this.currentInitIntervalDates[this.nameMap]).isSameOrBefore(
          date,
        ));
      }
      if (this.currentEndIntervalDates[this.nameMap] !== '') {
        dateList = dateList.filter((date) => moment(this.currentEndIntervalDates[this.nameMap]).isSameOrAfter(
          date,
        ));
      }
      const timeDimension = L.timeDimension({
        times: dateList,
      });
      const temporalLayer = L.tileLayer.wms(
        url,
        {
          layers: item.options.layers,
          format: item.options.format,
          transparent: item.options.transparent,
          visible: item.visible,
          styles: this.index,
        },
        timeDimension,
      );

      this.timeDimensionControl = L.control
        .timeDimension({
          timeDimension,
          autoPlay: false,
          loopButton: true,
          timeSteps: 1,
          playReverseButton: false,
          backwardButton: false,
          forwardButton: false,
          speedSlider: false,
          limitSliders: true,
          maxSpeed: 10,
          speedStep: 10,
          timeZones: ['Local', 'UTC'],
          playerOptions: {
            buffer: 1,
            transitionTime: 1500,
            loop: true,
          },
        })
        .addTo(this.map);

      this.timeDimensionLayer = L.timeDimension.layer
        .wms(temporalLayer, {
          timeDimension,
          requestTimeFromCapabilities: false,
          updateTimeDimension: false,
          setDefaultTime: false,
          getCapabilitiesUrl: 'https://www.google.com/',
        })
        .addTo(this.map);
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(
        this.googleHybrid.route,
        this.googleHybrid.properties,
      ).addTo(this.map);
      this.currentLayerBase = L.tileLayer(
        this.googleHybrid.route,
        this.googleHybrid.properties,
      ).addTo(this.map);
      this.currentLayerBase.bringToBack();
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        this.addHomeButton();
        this.addLegend();
        // this.addZoningButton();
        this.addOpacitySlider();
        this.addMeasureToolButton();
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(
            options.zoomHomeText,
            options.zoomHomeTitle,
            `${controlName}-home`,
            container,
            this.zoomHome,
          );

          return container;
        },

        zoomHome() {
          const bounds = L.latLngBounds([
            L.latLngBounds(
              [self.bbox.miny, self.bbox.minx],
              [self.bbox.maxy, self.bbox.maxx],
            ),
          ]);

          self.map.fitBounds(bounds, { maxZoom: 18 });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },
    addZoningButton() {
      const self = this;
      const selector = L.control({
        position: 'topleft',
      });

      // eslint-disable-next-line func-names
      selector.onAdd = function () {
        const div = L.DomUtil.create('div', 'zoningSelector');
        div.innerHTML = '<div id="zoning-selector"><form id="zoning-form"><label class="input-min-label" for="Mininum">Min: </label><input id="zoning_input_min" type="number" min=-1 step="0.1" value="" placeholder="Escriba mínimo"></input><label class="input-max-label"for="Maximun">Max: </label><input id="zoning_input_max" type="number" step="0.1" max=1 value="" placeholder="Escriba máximo"></input></form><div class="button-container"><button id=zoning_button>Ejecutar</button><p class="modal-message"></p></div></div>';

        L.DomEvent.on(
          div,
          'mousedown dblclick',
          L.DomEvent.stopPropagation,
        ).on(div, 'click', L.DomEvent.stop);
        return div;
      };

      selector.addTo(self.map);

      L.Control.zoningToggle = L.Control.extend({
        options: {
          position: 'topleft',
          zoningToggleText:
              '<i class="fg-layer-stat" style="font-size: 2.6em;"></i>',
          zoningToggleTitle: `${self.$t('Gis.Buttons.Zoning')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoning';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          self.zoningButton = this.createButton(
            options.zoningToggleText,
            options.zoningToggleTitle,
            `${controlName}-zoning`,
            container,
            this.zoningToggle,
          );

          return container;
        },

        zoningToggle() {
          const zoningSelector = document.getElementById('zoning-selector');
          self.toggleZoningButtonclicks += 1;
          if (self.toggleZoningButtonclicks % 2 === 0) {
            self.statusZoningTool = false;
            zoningSelector.style.display = 'none';
            self.indexLayer(self.currentIndex);
          } else {
            self.statusZoningTool = true;
            zoningSelector.style.display = 'block';
            const legendContent = '';
            self.map.legend.setContent(legendContent);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoningToggle = new L.Control.zoningToggle();
      zoningToggle.addTo(self.map);
    },
    addLegend() {
      const self = this;
      L.Control.legend = L.Control.extend({
        options: {
          position: 'bottomright',
        },
        onAdd() {
          self.map.legend = this;

          const container = L.DomUtil.create(
            'div',
            'legend-control-container',
          );

          if (this.options.content) {
            container.innerHTML = this.options.content;
          }
          return container;
        },
        onRemove() {
          delete self.map.legend;
        },

        setContent(str) {
          this.getContainer().innerHTML = str;
        },
      });
      // eslint-disable-next-line new-cap
      const legend = new L.Control.legend();
      legend.addTo(self.map);
    },

    addOpacitySlider() {
      const self = this;
      L.Control.opacitySlider = L.Control.extend({
        update(value) {
          return value;
        },
        options: {
          title: `${self.$t('Gis.Buttons.Opacity')}`,
          position: 'bottomright',
          min: 0.0,
          max: 1.0,
          step: 0.1,
          id: 'opacitySlider',
          value: 1.0,
          orientation: 'vertical',
          getValue(value) {
            return value;
          },
          showValue: true,
          syncSlider: true,
        },
        initialize(f, options) {
          L.setOptions(this, options);
          if (typeof f === 'function') {
            this.update = f;
          } else {
            this.update = function (value) {
              self.currentLayers[0].layer.setOpacity(value);
              if (self.timeDimensionLayer !== null) {
                self.timeDimensionLayer.setOpacity(value);
              }
            };
          }
          if (typeof this.options.getValue !== 'function') {
            this.options.getValue = function (value) {
              return value;
            };
          }
          if (this.options.orientation !== 'vertical') {
            this.options.orientation = 'horizontal';
          }
        },
        onAdd() {
          self.map.opacitySlider = this;
          this.initLayout();
          this.update(`${this.options.value}`);
          return this.container;
        },
        initLayout() {
          const className = 'leaflet-control-opacity-slider';
          this.container = L.DomUtil.create(
            'div',
            `${className} ${className}-${this.options.orientation}`,
          );
          if (this.options.showValue) {
            this.sliderValue = L.DomUtil.create(
              'p',
              `${className}-value`,
              this.container,
            );
            this.sliderValue.innerHTML = this.options.getValue(
              this.options.value,
            );
          }
          this.sliderContainer = L.DomUtil.create(
            'div',
            'leaflet-opacity-slider-container',
            this.container,
          );
          this.slider = L.DomUtil.create(
            'input',
            'leaflet-opacity-slider',
            this.sliderContainer,
          );
          if (this.options.orientation === 'vertical') {
            this.slider.setAttribute('orient', 'vertical');
          }
          this.slider.setAttribute('title', this.options.title);
          this.slider.setAttribute('id', this.options.id);
          this.slider.setAttribute('type', 'range');
          this.slider.setAttribute('min', this.options.min);
          this.slider.setAttribute('max', this.options.max);
          this.slider.setAttribute('step', this.options.step);
          this.slider.setAttribute('value', this.options.value);

          if (this.options.syncSlider) {
            L.DomEvent.on(
              this.slider,
              'input',
              function (e) {
                this.updateValue();
              },
              this,
            );
          } else {
            L.DomEvent.on(
              this.slider,
              'change',
              function (e) {
                this.updateValue();
              },
              this,
            );
          }

          L.DomEvent.disableClickPropagation(this.container);
        },
        updateValue() {
          this.value = this.slider.value;
          if (this.options.showValue) {
            this.sliderValue.innerHTML = this.options.getValue(this.value);
          }
          this.update(this.value);
        },
      });
      // eslint-disable-next-line new-cap
      const opacitySlider = new L.Control.opacitySlider();
      opacitySlider.addTo(self.map);
    },
    addMeasureToolButton() {
      const self = this;
      L.Control.measureArea = L.Control.extend({
        options: {
          position: 'topright',
          measureAreaText: '<i class="fg-measure-area fa-2x"></i>',
          measureAreaTitle: `${self.$t('Gis.Buttons.MeasureArea')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-area';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureAreaButton = this.createButton(
            options.measureAreaText,
            options.measureAreaTitle,
            `${controlName}-measureArea`,
            container,
            this.measureArea,
          );

          return container;
        },

        measureArea() {
          self.map.pm.enableDraw('Polygon', {
            snappable: true,
            continueDrawing: false,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureArea = new L.Control.measureArea();

      L.Control.measureLineString = L.Control.extend({
        options: {
          position: 'topright',
          measureLineStringText: '<i class="fg-measure-line fa-2x"></i>',
          measureLineStringTitle: `${self.$t(
            'Gis.Buttons.MeasureLineString',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-line';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureLineStringButton = this.createButton(
            options.measureLineStringText,
            options.measureLineStringTitle,
            `${controlName}-measureLineString`,
            container,
            this.measureLineString,
          );

          return container;
        },

        measureLineString() {
          self.map.pm.enableDraw('Line', {
            snappable: true,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureLineString = new L.Control.measureLineString();

      L.Control.desactiveMeasure = L.Control.extend({
        options: {
          position: 'topright',
          desactiveMeasureText: '<i class="fas fa-times"></i>',
          desactiveMeasureTitle: `${self.$t(
            'Gis.Buttons.DesactiveMeasureMode',
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.desactiveMeasureText,
            options.desactiveMeasureTitle,
            `${controlName}-desactiveMeasure`,
            container,
            this.desactiveMeasure,
          );

          return container;
        },

        desactiveMeasure() {
          self.statusMeasureTool = false;
          self.measureArea.remove(self.map);
          self.measureLineString.remove(self.map);
          // eslint-disable-next-line no-use-before-define
          self.desactiveMeasure.remove(self.map);
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:block';
          if (self.areaLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.areaLayer);
          }
          if (self.lineLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.lineLayer);
          }
          self.map.pm.disableDraw();
          self.map.opacitySlider.addTo(self.map);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveMeasure = new L.Control.desactiveMeasure();

      L.Control.activeMeasure = L.Control.extend({
        options: {
          position: 'topright',
          activeMeasureText: '<i class="fg-measure fa-2x"></i>',
          activeMeasureTitle: `${self.$t('Gis.Buttons.ActiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-active-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.activeMeasureText,
            options.activeMeasureTitle,
            `${controlName}-activeMeasure`,
            container,
            this.activeMeasure,
          );

          return container;
        },

        activeMeasure() {
          self.statusMeasureTool = true;
          self.measureArea.addTo(self.map);
          self.measureLineString.addTo(self.map);
          self.desactiveMeasure.addTo(self.map);
          // activeMeasure.remove(self.map);

          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:none';
          self.map.opacitySlider.remove(self.map);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.id = 'activeMeasure';
          link.href = '#';
          link.style = 'display:block';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.activeMeasure = new L.Control.activeMeasure();
      self.activeMeasure.addTo(self.map);
    },
    openLayerDataPopup(properties, e, extra = {}) {
      let htmlString = "<div class='popup-content'>";
      htmlString += extra.before || '';
      let listHtml = '<ul>';
      listHtml += this.getLayerDataPropertiesHtml(properties);
      listHtml += '</ul>';
      htmlString += `${listHtml}</div>`;
      htmlString += extra.after || '';
      if (properties.Band1 !== 0) this.openPopup(htmlString, e.latlng);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      const bands = 'Band1';
      let result = 0;
      if (bands in properties) {
        switch (this.currentIndex) {
          case 'NDVI':
            result = (properties.Band8 - properties.Band4)
                / (properties.Band8 + properties.Band4);
            break;
          case 'SAVI':
            result = ((properties.Band8 - properties.Band4)
                  / (properties.Band8 + properties.Band4 + 0.428))
                * 0.428;
            break;
          case 'GNDVI':
            result = (properties.Band8 - properties.Band3)
                / (properties.Band8 + properties.Band3);
            break;
          case 'ARVI':
            result = (properties.Band8
                  - 0.106 * properties.Band4
                  + properties.Band2)
                / (properties.Band8
                  + 0.106 * properties.Band4
                  + properties.Band2);
            break;
          case 'AVI':
            result = (properties.Band8
                  * (1 - properties.Band4)
                  * (properties.Band8 - properties.Band4))
                  ** 1
                / 3;
            break;
          case 'EVI':
            result = 2.5
                * ((properties.Band8 - properties.Band4)
                  / (properties.Band8
                    + 6 * properties.Band4
                    - 7.5 * properties.Band2
                    + 1));
            break;
          case 'VARI':
            result = (properties.Band3 - properties.Band4)
                / (properties.Band3 + properties.Band4 - properties.Band2);
            break;
          case 'LAI':
            result = 0.57
                ** (2.33
                  * ((properties.Band8 - properties.Band4)
                    / (properties.Band8 + properties.Band4)));
            break;
          case 'NDRE':
            result = (properties.Band8 - properties.Band5)
                / (properties.Band8 + properties.Band5);
            break;
          case 'RECI':
            result = (properties.Band8 - properties.Band11)
                / (properties.Band8 + properties.Band11);
            break;
          case 'NDMI':
            result = properties.Band8 / (properties.Band8 + properties.Band11);
            break;
          case 'MSI':
            result = properties.Band11 / properties.Band8;
            break;
          case 'GCI':
            result = properties.Band9 / properties.Band3 - 1;
            break;
          case 'NBRI':
            result = properties.Band8 / (properties.Band8 + properties.Band2);
            break;
          default:
          case 'BSI':
            result = properties.Band11
                + properties.Band4
                - (properties.Band8 + properties.Band2)
                  / (properties.Band11 + properties.Band4)
                + (properties.Band8 + properties.Band2);
            break;
        }
        listHtml += `<li><b>${this.currentIndex}</b>: ${result.toFixed(
          2,
        )}</li>`;
      // eslint-disable-next-line no-prototype-builtins
      } else if (properties.hasOwnProperty('jiffle')) {
        listHtml += `<li><b>${this.currentIndex}</b>: ${properties.jiffle.toFixed(
          2,
        )}</li>`;
      } else {
        for (const property in properties) {
          // eslint-disable-next-line no-continue
          if (properties[property] == null) continue;
          if (typeof properties[property] === 'object') {
            listHtml += this.getLayerDataPropertiesHtml(properties[property]);
          } else {
            listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
          }
        }
      }
      return listHtml;
    },
    openPopup(html, latlng) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
    },
    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (e.shape === 'Polygon') {
            self.areaLayer = e.layer.addTo(self.map);
            const toGeoJson = self.areaLayer.toGeoJSON();
            const area = turf.area(toGeoJson);
            const html = `<div class='popup-content'><h3>${self.$t(
              'Gis.Editor.PopupMeasureArea',
            )}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${(
              area / 10000
            ).toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureHas')}</p></div>`;
            const popup = L.popup().setContent(html);
            self.areaLayerPopup = self.areaLayer.bindPopup(popup);
            self.areaLayer.openPopup();
            self.map.fitBounds(self.areaLayer.getBounds());
            self.map.pm.disableDraw();
          }
          if (e.shape === 'Line') {
            self.lineLayer = e.layer.addTo(self.map);
            // eslint-disable-next-line no-underscore-dangle
            if (self.lineLayer._latlngs.length > 2) {
              self.map.removeLayer(self.lineLayer);
            } else {
              const from = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lng,
              ]);
              const to = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lng,
              ]);
              const options = {
                units: 'kilometers',
              };
              const distance = turf.distance(from, to, options);
              const html = `<div class='popup-content'><h3>${self.$t(
                'Gis.Editor.PopupMeasureLineString',
              )}</h3><p>${self.$t(
                'Gis.Editor.PopupMeasureResult',
              )}: ${distance.toFixed(2)} ${self.$t(
                'Gis.Editor.PopupMeasureKm',
              )}</p></div>`;
              const popup = L.popup().setContent(html);
              self.lineLayerPopup = self.lineLayer.bindPopup(popup);
              self.lineLayer.openPopup();
              self.map.fitBounds(self.lineLayer.getBounds());
            }
          }
        } catch (error) {
          this.$f7.dialog.alert(e);
        } finally {
          self.$f7.preloader.hide();
        }
      });
      if (self.areaLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.areaLayer);
      }
      if (self.lineLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.lineLayer);
      }
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },
    /**
       *
       */
    setMapEvents() {
      const self = this;
      // const coordSearchButton = document.getElementById('zoning_button');
      // const zoningInputMin = document.getElementById('zoning_input_min');
      // const zoningInputMax = document.getElementById('zoning_input_max');

      // coordSearchButton.addEventListener('click', (e) => {
      //   L.DomEvent.stopPropagation(e);
      //   this.timeDimensionLayer.setParams({
      //     styles: `${this.currentIndex}_variable`,
      //     env: `min:${zoningInputMin.value};max:${zoningInputMax.value}`,
      //   }).addTo(this.map);
      // });
      this.map.on('click', (e) => {
        if (this.currentLayers.length < 1) return;
        if (this.currentIndex === 'TCI') return;
        if (this.currentLayers.length < 1 || this.statusMeasureTool) return;
        const layerId = this.currentLayers[0].item.options.layers.split(':');
        self.generateRouteToDataRequestWMSLayers(layerId[1], e);
      });
    },
    ...mapActions('Gis', [
      'setCurrentLayersMap',
      'setCurrentOverlayLayer',
      'fetchFeatureInfo',
      'fetchDatesLayer',
    ]),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
