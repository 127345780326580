<template>
  <div class="padding-10">
    <p>{{ $t('Uses_Doses_Detail_Title') }}</p>
    <DxDataGrid
      :data-source="data"
      :column-min-width="90"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
    >
      <DxColumn
        data-field="cultive"
        :caption="$t('searchEngine_botanical_species')"
        :allow-header-filtering="false"
        alignment="left"
      />

      <DxColumn
        data-field="agent"
        :caption="$t('nutritionDetail.report.plagueAgent')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="application_num.max"
        :caption="$t('Application_Num')"
        :allow-header-filtering="false"
        alignment="left"
        cell-template="applicationNum"
      />
      <template #applicationNum="{ data }">
        <div>
          {{
            data.data.application_num
              ? Math.round(data.data.application_num.max)
              : ''
          }}
        </div>
      </template>
      <DxColumn
        :caption="$t('Liquid_Vol') + '(L/ha)'"
        :allow-header-filtering="false"
        alignment="left"
        cell-template="liquidVol"
      />
      <template #liquidVol="{ data }">
        <div>
          {{
            data.data.liquid_vol
              ? Number(data.data.liquid_vol.min).toFixed(2)
              : ''
          }}
          -
          {{
            data.data.liquid_vol
              ? Number(data.data.liquid_vol.max).toFixed(2)
              : ''
          }}
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
  import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';

  export default {
    components: {
      DxDataGrid,
      DxColumn,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>
