import pdfmake from "pdfmake";
import html2canvas from "html2canvas";
import pdfImage from "../json/pdfImages.json";

export default class PDFMakerGis {
  constructor() {
    pdfmake.fonts = {
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
    };

    this.pdfData = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: `Página ${currentPage.toString()}`,
            alignment: "right",
            margin: [0, 20, 10, 0],
          },
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            margin: [20, 10, 20, 0],
            columns: [
              {
                image: pdfImage.timac_image,
                width: 200,
              },
              {
                margin: [100, 0, 0, 0],
                image: pdfImage.contact_image,
                width: 160,
              },
              {
                margin: [110, 10, 0, 0],
                image: pdfImage.roullier_image,
                width: 30,
              },
              {
                margin: [125, 10, 0, 0],
                image: pdfImage.iso_image,
                width: 30,
              },
            ],
          },
        ];
      },
      content: [],
      pageSize: "A4",
      pageMargins: [20, 80, 20, 60],
      styles: {
        h1: { fontSize: 18, bold: true, margin: [0, 14, 0, 8] },
        h2: {
          fontSize: 16,
          bold: true,
          margin: [0, 20, 0, 8],
          color: "#444",
        },
        h3: {
          fontSize: 14,
          bold: true,
          margin: [0, 22, 0, 8],
          color: "#666",
        },
        tableHeaderCell: {
          margin: [2, 2, 2, 2],
          fontSize: 7,
          fillColor: "#eee",
          bold: true,
        },
        tableNormalCell: {
          margin: [2, 2, 2, 2],
          fontSize: 8,
          fillColor: "#fff",
          bold: false,
        },
        blueContainer: {
          margin: [0, 14, 0, 14],
        },
        blueContainerTitle: {
          margin: [8, 8, 8, 8],
          fillColor: "#006FA8;",
          color: "#fff",
          bold: true,
        },
        noBordersCell: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
          bold: false,
        },
        bigger: {
          fontSize: 15,
          italics: true,
        },
        commentStyleTitle: {
          fontSize: 10,
          italics: true,
          bold: true,
        },
        commentStyle: {
          fontSize: 10,
          italics: false,
        },
        normalStyle: {
          fontSize: 10,
        },
        normalStyle2: {
          fontSize: 12,
        },
        observationsTitle: {
          fontSize: 12,
          margin: [0, 8, 0, 4],
        },
        observationsList: {
          fontSize: 12,
          margin: [10, 0, 0, 0],
        },
        legalText: {
          margin: [8, 8, 8, 8],
          fontSize: 8,
        },
        footer: {
          margin: [10, 10, 10, 10],
        },
      },
    };
  }

  getBlueContainer(title, breakTabla = true) {
    let tableOptions = {};

    if (breakTabla) {
      tableOptions = {
        // headerRows: 1,
        keepWithHeaderRows: true,
      };
    }

    return {
      style: "blueContainer",
      table: {
        ...tableOptions,
        headerRows: 1,
        // headerRows: 1,
        // keepWithHeaderRows: true,
        // dontBreakRows: true,
        // unbreakable: true,
        widths: ["*"],
        body: [[{ text: title, style: "blueContainerTitle" }], []],
      },
    };
  }

  insertTableWithoutBorders(data) {
    const blueContainer = this.getBlueContainer("DATOS DE LA EXPLOTACIÓN");

    const table = {
      widths: Array(data[0].length).fill(
        `${(100 / data[0].length).toFixed(2)}%`
      ),
      body: [],
    };

    data.forEach((row) => {
      const headers = row.map((cell) => ({
        style: "noBordersCell",
        text: [
          { text: `${cell.title}: `, fontSize: 10, bold: false },
          { text: cell.text, fontSize: 10, bold: true },
        ],
      }));
      table.body.push(headers);
    });

    blueContainer.table.body[1].push({ table: table, layout: "noBorders" });

    this.pdfData.content.push(blueContainer);
  }

  inserTableSatelliteImages(data) {
    const table = {
      widths: Array(data[0].length).fill(
        `${(100 / data[0].length).toFixed(2)}%`
      ),
      body: [],
    };

    data.forEach((row) => {
      const rows = row.map((cell) => {
        if (cell.length > 0) {
          return [
            {
              alignment: "center",
              image: cell,
              width: 195,
            },
            {
              alignment: "center",
              text: "Stack placeholder",
            },
          ];
        }

        return [];
      });

      table.body.push(rows);
    });

    this.pdfData.content.push({ table: table, layout: "noBorders", });
  }

  async insertChart(domElement) {
    const canvasToImage = await html2canvas(domElement);

    const chart = {
      widths: ["*"],
      body: [],
    };

    chart.body.push([
      {
        alignment: "center",
        image: canvasToImage.toDataURL(),
        width: 520,
      },
    ]);

    this.pdfData.content.push({
      table: chart,
      layout: "noBorders",
      margin: [0, 15, 0, 15],
    });
  }

  buildAndDownload(filename) {
    pdfmake.createPdf(this.pdfData).download(filename);
  }

  buildForMobile() {
    const pdfBuild = pdfmake.createPdf(this.pdfData);
    return pdfBuild;
  }
}
