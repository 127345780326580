<template>
  <div>
    <div class="margin-top-half">
      <div
        class="content-devx"
      >
        <f7-block>
          <f7-row>
            <f7-col>{{ $t('DxClients_client') }}</f7-col>
            <f7-col>{{ $t('parcelViewClient_number_phone') }}</f7-col>
            <f7-col>{{ $t('DxClients_email') }}</f7-col>
            <f7-col>{{ $t('DxClients_provincia') }}</f7-col>
            <f7-col>{{ $t('DxClients_localidad') }}</f7-col>
            <f7-col>{{ $t('DxClients_postal_code') }}</f7-col>
          </f7-row>
          <f7-row class="bold">
            <f7-col>{{ actualUser.name }}</f7-col>
            <f7-col>{{ actualUser.mobile_phone }}</f7-col>
            <f7-col>{{ actualUser.email }}</f7-col>
            <f7-col>{{ actualUser.province }}</f7-col>
            <f7-col>{{ actualUser.city }}</f7-col>
            <f7-col>{{ actualUser.postalCode }}</f7-col>
          </f7-row>
        </f7-block>
      </div>
    </div>
    <f7-row no-gap>
      <f7-col width="50">
        <ParcelSystemCampania />
        <DxSystemParcelLocation />
        <div class="margin margin-right-half">
          <f7-row class="margin-top">
            <f7-col width="20">
              <f7-button
                class="dx-btn-cancel margin-right btnBack"
                type="default"
                text-color="grey"
                styling-mode="outlined"
                name="ButtonCancel"
                @click="goToParcelSystem"
              >
                {{ $t("Button_go_to_back") }}
              </f7-button>
            </f7-col>

            <f7-col width="60" />
          </f7-row>
        </div>
      </f7-col>
      <f7-col width="50">
        <f7-block v-if="!loadedGIS">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <div
          v-else
          class="margin-right-block"
        >
          <GISGeoJsonMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            selected-min-height="610px"
          />
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import ParcelSystemCampania from '../../campaña/parcelSystemCampania/index.vue';
import DxSystemParcelLocation from '../../systemParcelExplotaciones/SistemParceelLocation/index.vue';
import GISGeoJsonMap from '../../Gis/GISGeoJsonMap/index.vue';
import optionsList from '../../../json/fakeListLayer.json';

export default {
  name: 'DxSystemParcelViewClient',
  components: {
    ParcelSystemCampania,
    DxSystemParcelLocation,
    GISGeoJsonMap,
  },
  data() {
    return {
      VarietyFormated: [],
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
      optionsList,
      loadedGIS: false,
      zoom: 17,
      mainMap: 'mainMap',
    };
  },
  computed: {
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Plantation', ['treeLocations']),
  },
  async beforeMount() {
    try {
      await this.fetchLocationsByCompany(this.actualUser.id);
      this.loadedGIS = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByCompany']),
    goToParcelSystem() {
      this.$f7.views.main.router.navigate('/systemParcelClients/', { reloadCurrent: true });
    },
    goToClientSales() {
      this.$f7.views.main.router.navigate('/viewClient/systemParcelClients/', { reloadCurrent: true });
    },
  },
};
</script>
<style scoped>
@import './SystemParcelViewClient.styles.scss';
.bold{
  margin-top: 15px;
  font-weight: bold;
}
.dx-btn-success{
  width: 100%;
  margin: 0;
}
.dx-btn-cancel {
  background-color: white;
}
</style>
