import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'converter-water',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: { },
      unitStatic: { },
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsWater']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsWater[0]);
      this.unitSelected = { ...this.itemUnitsWater[0] };
      this.unitStatic = { ...this.itemUnitsWater[0] };
    },
    cleanData() {
      this.initData();
    },
    onChangeUnit(e) {
      this.unitSelected = { ...e.value };
      this.unitStatic = { ...e.value };
    },
    changeMeq(e) {
      this.unitSelected.mg = e.event.target.value * this.unitStatic.mg;
      this.unitSelected.kg = (this.unitSelected.mg * this.unitSelected.m3) / 1000;
      this.unitSelected.mg = parseFloat(this.unitSelected.mg.toFixed(2));
      this.unitSelected.kg = parseFloat(this.unitSelected.kg.toFixed(2));
    },
    changeMg(e) {
      this.unitSelected.meq = e.event.target.value / this.unitStatic.mg;
      this.unitSelected.kg = (e.event.target.value * this.unitSelected.m3) / 1000;
      this.unitSelected.kg = parseFloat(this.unitSelected.kg.toFixed(2));
      this.unitSelected.meq = parseFloat(this.unitSelected.meq.toFixed(2));
    },
    changeKg(e) {
      this.unitSelected.mg = (e.event.target.value * 1000) / this.unitSelected.m3;
      this.unitSelected.meq = this.unitSelected.mg / this.unitStatic.mg;
      this.unitSelected.mg = parseFloat(this.unitSelected.mg.toFixed(2));
      this.unitSelected.meq = parseFloat(this.unitSelected.meq.toFixed(2));
    },
    changeM3(e) {
      this.unitSelected.meq = this.unitSelected.mg / this.unitStatic.mg;
      this.unitSelected.mg = this.unitStatic.meq * this.unitStatic.mg;
      this.unitSelected.kg = (this.unitSelected.mg * e.event.target.value) / 1000;
      this.unitSelected.mg = parseFloat(this.unitSelected.mg.toFixed(2));
      this.unitSelected.meq = parseFloat(this.unitSelected.meq.toFixed(2));
      this.unitSelected.kg = parseFloat(this.unitSelected.kg.toFixed(2));
    },
  },
};
