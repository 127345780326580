import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '@/components/wizardNutrition/stepper-navigation/index.vue';
import DosageProduct from '@/components/wizardNutrition/dosageProduct/index.vue';
import { mapGetters, mapState } from 'vuex';
import PopupConfirmWizardExit from '../../../components/wizardNutrition/popupConfirmWizardExit/index.vue';

export default {
  name: 'nutrition-step-three',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    DosageProduct,
    PopupConfirmWizardExit,
  },
  props: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
    disabledNextButton() {
      return this.alertMessage.length > 0;
    },
    alertMessage() {
      if (this.stepData.dosageProduct.some(product => product?.product_deleted ?? false)) {
        return this.$t('productsDeleted');
      }

      return '';
    }
  },
  mounted() {
    
  },
  methods: {

  },
};
