/* eslint-disable max-len */
import moment from 'moment';
import i18next from 'i18next';
import Api from '../../services/Api';

const defaultConfig = {
  selectedRangeOfDays: 1,
  selectedMeasuringFrequency: 'HOURLY',
  rangeOfDays: ['1', '7', '10', '14'],
  measuringFrequency: ['HOURLY', 'DAILY'],
};

export default {
  namespaced: true,
  state: {
    weatherForecastedDataByLocations: [],
    weatherMeasuredDataByLocations: [],
    rangeOfDays: defaultConfig.rangeOfDays,
    selectedRangeOfDays: defaultConfig.selectedRangeOfDays,
    measuringFrequency: defaultConfig.measuringFrequency,
    selectedMeasuringFrequency: defaultConfig.selectedMeasuringFrequency,
    selectedFarmID: '',
    measuredProvider: '',
    foreCastProvider: '',
    hasMeasuredTemperature: true,
    hasForecastTemperature: true,
  },
  getters: {
    hourlyMeasuredTemperatures(state) {
      const locationMeasuredTemperatures = state.weatherMeasuredDataByLocations;
      if (!locationMeasuredTemperatures[0].parameters.TEMPERATURE_MIN) {
        return [];
      }
      const temperaturaAriaMin = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MIN;
      const temperaturaAriaMed = locationMeasuredTemperatures[0].parameters.TEMPERATURE;
      const temperaturaAriaMax = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MAX;
      const hourlyMeasuredTemperatures = [];
      // Antes se recorria AVG.
      for (let i = 0; i < temperaturaAriaMed.length; i += 1) {
        hourlyMeasuredTemperatures.push({
          [`minmeasured${i18next.t('Temperature')}`]: temperaturaAriaMin[i]?.value,
          [`maxmeasured${i18next.t('Temperature')}`]: temperaturaAriaMax[i]?.value,
          [`avgmeasured${i18next.t('Temperature')}`]: temperaturaAriaMed[i]?.value,
          date: temperaturaAriaMed[i].date,
          unitOfMessurement: temperaturaAriaMed[i].unit,

        });
      }
      return hourlyMeasuredTemperatures;
    },
    hourlyForecastedTemperatures(state) {
      const locationForecastedTemperatures = state.weatherForecastedDataByLocations;
      if (!locationForecastedTemperatures[0].parameters.TEMPERATURE_MIN) {
        return [];
      }
      const temperatureMin = locationForecastedTemperatures[0].parameters.TEMPERATURE_MIN;
      const temperatureMed = locationForecastedTemperatures[0].parameters.TEMPERATURE;
      const temperatureMax = locationForecastedTemperatures[0].parameters.TEMPERATURE_MAX;
      const hourlyForecastedTemperature = [];
      for (let i = 0; i < temperatureMed.length; i += 1) {
        hourlyForecastedTemperature.push({
          [`avgforecasted${i18next.t('Temperature')}`]: temperatureMed[i].value,
          date: temperatureMed[i].date,
          unitOfMessurement: temperatureMed[i].unit,
        });
      }
      for (let i = 0; i < temperatureMin.length; i += 1) {
        hourlyForecastedTemperature.push({
          [`minforecasted${i18next.t('Temperature')}`]: temperatureMin[i].value,
          date: temperatureMin[i].date,
          unitOfMessurement: temperatureMin[i].unit,
        });
      }
      for (let i = 0; i < temperatureMax.length; i += 1) {
        hourlyForecastedTemperature.push({
          [`maxforecasted${i18next.t('Temperature')}`]: temperatureMax[i].value,
          date: temperatureMax[i].date,
          unitOfMessurement: temperatureMax[i].unit,
        });
      }
      return hourlyForecastedTemperature;
    },
    dailyMeasuredTemperatures(state) {
      const locationMeasuredTemperatures = state.weatherMeasuredDataByLocations;
      if (!locationMeasuredTemperatures[0].parameters.TEMPERATURE) {
        return [];
      }
      const temperaturaAriaMedia = locationMeasuredTemperatures[0].parameters.TEMPERATURE;
      const dailyMeasuredTemperaturesAVG = [];
      const averageDatesDone = [];
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
      temperaturaAriaMedia.forEach((measuredTemperatureAVG) => {
        const date = measuredTemperatureAVG.date.split(' ')[0];
        if (averageDatesDone.indexOf(date) === -1) {
          averageDatesDone.push(date);
          const currentDayTemperaturesAVG = temperaturaAriaMedia
            .filter((temperature) => date === temperature.date.split(' ')[0]);
          const currentDayTemperaturesAVGValues = currentDayTemperaturesAVG.map((currentDayTemperature) => currentDayTemperature.value);
          const totalTemperaturesAVG = currentDayTemperaturesAVGValues.reduce(sumReducer);
          const average = totalTemperaturesAVG / currentDayTemperaturesAVGValues.length;
          dailyMeasuredTemperaturesAVG.push({ date, unit: 'celsius', value: parseInt(average, 10) });
        }
      });

      const temperaturaAriaMin = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MIN;
      const dailyMeasuredTemperaturesMIN = [];
      const minDatesDone = [];

      temperaturaAriaMin.forEach((measuredTemperatureMIN) => {
        const date = measuredTemperatureMIN.date.split(' ')[0];

        if (minDatesDone.indexOf(date) === -1) {
          minDatesDone.push(date);
          const currentDayTemperaturesMIN = temperaturaAriaMin
            .filter((temperature) => date === temperature.date.split(' ')[0]);

          const currentDayTemperaturesMINValues = currentDayTemperaturesMIN
            .map((currentDayTemperature) => currentDayTemperature.value);

          const min = Math.min(...currentDayTemperaturesMINValues);

          dailyMeasuredTemperaturesMIN.push({ date, unit: 'celsius', value: parseInt(min, 10) });
        }
      });

      const temperaturaAriaMax = locationMeasuredTemperatures[0].parameters.TEMPERATURE_MAX;
      const dailyMeasuredTemperaturesMAX = [];
      const maxDatesDone = [];

      temperaturaAriaMax.forEach((measuredTemperatureMAX) => {
        const date = measuredTemperatureMAX.date.split(' ')[0];

        if (maxDatesDone.indexOf(date) === -1) {
          maxDatesDone.push(date);
          const currentDayTemperaturesMAX = temperaturaAriaMin
            .filter((temperature) => date === temperature.date.split(' ')[0]);

          const currentDayTemperaturesMAXValues = currentDayTemperaturesMAX
            .map((currentDayTemperature) => currentDayTemperature.value);

          const max = Math.max(...currentDayTemperaturesMAXValues);

          dailyMeasuredTemperaturesMAX.push({ date, unit: 'celsius', value: parseInt(max, 10) });
        }
      });

      const dailyMeasuredTemperatures = [];

      for (let i = 0; i < dailyMeasuredTemperaturesAVG.length; i += 1) {
        dailyMeasuredTemperatures.push({
          [`minmeasured${i18next.t('Temperature')}`]: dailyMeasuredTemperaturesMIN[i].value,
          [`maxmeasured${i18next.t('Temperature')}`]: dailyMeasuredTemperaturesMAX[i].value,
          [`avgmeasured${i18next.t('Temperature')}`]: dailyMeasuredTemperaturesAVG[i].value,
          date: dailyMeasuredTemperaturesAVG[i].date,
        });
      }

      return dailyMeasuredTemperatures;
    },
    dailyForecastedTemperatures(state) {
      const locationForecastedTemperatures = state.weatherForecastedDataByLocations;
      if (!locationForecastedTemperatures[0].parameters.TEMPERATURE) {
        return [];
      }
      const temperatures = locationForecastedTemperatures[0].parameters.TEMPERATURE;
      const temperaturesMin = locationForecastedTemperatures[0].parameters.TEMPERATURE_MIN;
      const temperaturesMax = locationForecastedTemperatures[0].parameters.TEMPERATURE_MAX;

      const dailyForecastedTemperaturesAVG = [];
      const dailyForecastedTemperaturesMIN = [];
      const dailyForecastedTemperaturesMAX = [];
      const datesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
      temperatures.forEach((temperatureData) => {
        const date = temperatureData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);
          const currentDayTemperatures = temperatures.filter((temperature) => date === temperature.date.split(' ')[0]);
          const currentDayTemperaturesValues = currentDayTemperatures.map((currentDayTemperature) => currentDayTemperature.value);
          const totalTemperaturesAVG = currentDayTemperaturesValues.reduce(sumReducer);
          const avg = totalTemperaturesAVG / currentDayTemperaturesValues.length;
          dailyForecastedTemperaturesAVG.push({ date, unit: 'celsius', value: parseInt(avg, 10) });
        }
      });

      temperaturesMin.forEach((temperatureData) => {
        const date = temperatureData.date.split(' ')[0];
        const currentDayTemperatures = temperaturesMin.filter((temperature) => date === temperature.date.split(' ')[0]);
        const currentDayTemperaturesValues = currentDayTemperatures.map((currentDayTemperature) => currentDayTemperature.value);

        const min = Math.min(...currentDayTemperaturesValues);
        dailyForecastedTemperaturesMIN.push({ date, unit: 'celsius', value: parseInt(min, 10) });
      });
      temperaturesMax.forEach((temperatureData) => {
        const date = temperatureData.date.split(' ')[0];
        const currentDayTemperatures = temperaturesMax.filter((temperature) => date === temperature.date.split(' ')[0]);
        const currentDayTemperaturesValues = currentDayTemperatures.map((currentDayTemperature) => currentDayTemperature.value);

        const max = Math.max(...currentDayTemperaturesValues);
        dailyForecastedTemperaturesMAX.push({ date, unit: 'celsius', value: parseInt(max, 10) });
      });

      const dailyForecastedTemperatures = [];
      for (let i = 0; i < dailyForecastedTemperaturesAVG.length; i += 1) {
        dailyForecastedTemperatures.push({
          [`minforecasted${i18next.t('Temperature')}`]: dailyForecastedTemperaturesMIN[i].value,
          [`maxforecasted${i18next.t('Temperature')}`]: dailyForecastedTemperaturesMAX[i].value,
          [`avgforecasted${i18next.t('Temperature')}`]: dailyForecastedTemperaturesAVG[i].value,
          date: dailyForecastedTemperaturesAVG[i].date,
        });
      }
      return dailyForecastedTemperatures;
    },
    hourlyMeasuredHumidities(state) {
      const locationMeasuredHumidities = state.weatherMeasuredDataByLocations;
      if (!locationMeasuredHumidities[0].parameters.HUMIDITY) {
        return [];
      }
      const hourlyMeasuredHumiditiesAVG = locationMeasuredHumidities[0].parameters.HUMIDITY;
      // const hourlyMeasuredHumiditiesMIN = locationMeasuredHumidities[0].parameters.HUMIDITY_MIN.umiditaRelativaMin;
      // const hourlyMeasuredHumiditiesMAX = locationMeasuredHumidities[0].parameters.HUMIDITY_MAX.umiditaRelativaMax;

      const hourlyMeasuredHumidities = [];
      for (let i = 0; i < hourlyMeasuredHumiditiesAVG.length; i += 1) {
        hourlyMeasuredHumidities.push({
          // minTemp: hourlyMeasuredHumiditiesMIN[i].value,
          // maxTemp: hourlyMeasuredHumiditiesMAX[i].value,
          [`avgmeasured${i18next.t('Humidity')}`]: hourlyMeasuredHumiditiesAVG[i].value,
          date: hourlyMeasuredHumiditiesAVG[i].date,
          unitOfMessurement: hourlyMeasuredHumiditiesAVG[i].unit,

        });
      }

      return hourlyMeasuredHumidities;
    },
    hourlyForecastedHumidities(state) {
      const locationForecastedHumidities = state.weatherForecastedDataByLocations;
      if (!locationForecastedHumidities[0].parameters.HUMIDITY) {
        return [];
      }
      const humidity = locationForecastedHumidities[0].parameters.HUMIDITY;
      const hourlyForecastedHumidity = [];
      for (let i = 0; i < humidity.length; i += 1) {
        hourlyForecastedHumidity.push({
          [`avgforecasted${i18next.t('Humidity')}`]: humidity[i].value,
          date: humidity[i].date,
          unitOfMessurement: humidity[i].unit,
        });
      }
      return hourlyForecastedHumidity;
    },
    dailyMeasuredHumidities(state) {
      const locationMeasuredHumidities = state.weatherMeasuredDataByLocations;
      if (!locationMeasuredHumidities[0].parameters.HUMIDITY) {
        return [];
      }
      const umiditaRelativaMedia = locationMeasuredHumidities[0].parameters.HUMIDITY;
      const dailyMeasuredHumiditiesAVG = [];
      const averageDatesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      umiditaRelativaMedia.forEach((measuredHumidityAVG) => {
        const date = measuredHumidityAVG.date.split(' ')[0];

        if (averageDatesDone.indexOf(date) === -1) {
          averageDatesDone.push(date);
          const currentDayHumiditiesAVG = umiditaRelativaMedia
            .filter((humidity) => date === humidity.date.split(' ')[0]);

          const currentDayHumidityAVGValues = currentDayHumiditiesAVG
            .map((currentDayHumidity) => currentDayHumidity.value);

          const totalHumiditiesAVG = currentDayHumidityAVGValues.reduce(sumReducer);

          const average = totalHumiditiesAVG / currentDayHumidityAVGValues.length;

          dailyMeasuredHumiditiesAVG.push({ date, unit: 'percentage', value: average });
        }
      });

      const umiditaRelativaMin = locationMeasuredHumidities[0].parameters.HUMIDITY;
      const dailyMeasuredHumiditiesMIN = [];
      const minDatesDone = [];

      umiditaRelativaMin.forEach((measuredHumidityMIN) => {
        const date = measuredHumidityMIN.date.split(' ')[0];

        if (minDatesDone.indexOf(date) === -1) {
          minDatesDone.push(date);
          const currentDayHumiditiesMIN = umiditaRelativaMin
            .filter((humidity) => date === humidity.date.split(' ')[0]);

          const currentDayHumiditiesMINValues = currentDayHumiditiesMIN
            .map((currentDayHumidity) => currentDayHumidity.value);

          const min = Math.min(...currentDayHumiditiesMINValues);

          dailyMeasuredHumiditiesMIN.push({ date, unit: 'percentage', value: min });
        }
      });
      // const umiditaRelativaMax = locationMeasuredHumidities[0].parameters.HUMIDITY_MAX;
      const dailyMeasuredHumiditiesMAX = [];
      // const maxDatesDone = [];

      // umiditaRelativaMax.forEach((measuredHumidityMAX) => {
      //   const date = measuredHumidityMAX.date.split(' ')[0];

      //   if (maxDatesDone.indexOf(date) === -1) {
      //     maxDatesDone.push(date);
      //     const currentDayHumiditiesMAX = umiditaRelativaMax
      //       .filter((humidity) => date === humidity.date.split(' ')[0]);

      //     const currentDayHumiditiesMAXValues = currentDayHumiditiesMAX
      //       .map((currentDayHumidity) => currentDayHumidity.value);

      //     const max = Math.max(...currentDayHumiditiesMAXValues);

      //     dailyMeasuredHumiditiesMAX.push({ date, unit: 'celsius', value: max });
      //   }
      // });
      const dailyMeasuredHumidities = [];

      for (let i = 0; i < dailyMeasuredHumiditiesAVG.length; i += 1) {
        dailyMeasuredHumidities.push({
          // minTemp: dailyMeasuredHumiditiesMIN[i].value,
          // maxTemp: dailyMeasuredHumiditiesMAX[i].value,
          [`avgmeasured${i18next.t('Humidity')}`]: dailyMeasuredHumiditiesAVG[i].value,
          date: dailyMeasuredHumiditiesAVG[i].date,
        });
      }
      return dailyMeasuredHumidities;
    },
    dailyForecastedHumidities(state) {
      const locationForecastedHumidities = state.weatherForecastedDataByLocations;
      if (!locationForecastedHumidities[0].parameters.HUMIDITY) {
        return [];
      }
      const humidities = locationForecastedHumidities[0].parameters.HUMIDITY;

      const dailyForecastedHumiditiesAVG = [];
      const dailyForecastedHumiditiesMIN = [];
      const dailyForecastedHumiditiesMAX = [];
      const datesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      humidities.forEach((humidityData) => {
        const date = humidityData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);

          const currentDayHumidities = humidities
            .filter((humidity) => date === humidity.date.split(' ')[0]);

          const currentDayHumiditiesValues = currentDayHumidities
            .map((currentDayHumidity) => currentDayHumidity.value);

          const totalHumiditiesAVG = currentDayHumiditiesValues.reduce(sumReducer);

          const avg = totalHumiditiesAVG / currentDayHumiditiesValues.length;

          const min = Math.min(...currentDayHumiditiesValues);
          const max = Math.max(...currentDayHumiditiesValues);

          dailyForecastedHumiditiesAVG.push({ date, unit: 'percentage', value: avg });
          dailyForecastedHumiditiesMIN.push({ date, unit: 'percentage', value: min });
          dailyForecastedHumiditiesMAX.push({ date, unit: 'percentage', value: max });
        }
      });
      const dailyForecastedHumidities = [];

      for (let i = 0; i < dailyForecastedHumiditiesAVG.length; i += 1) {
        dailyForecastedHumidities.push({
          minTemp: dailyForecastedHumiditiesMIN[i].value,
          maxTemp: dailyForecastedHumiditiesMAX[i].value,
          [`avgforecasted${i18next.t('Humidity')}`]: dailyForecastedHumiditiesAVG[i].value,
          date: dailyForecastedHumiditiesAVG[i].date,
        });
      }
      return dailyForecastedHumidities;
    },
    hourlyMeasuredPrecipitations(state) {
      const locationMeasuredPrecipitations = state.weatherMeasuredDataByLocations;
      if (!locationMeasuredPrecipitations[0].parameters.PLUVIOMETER) {
        return [];
      }
      const pioggia = locationMeasuredPrecipitations[0].parameters.PLUVIOMETER;
      const measuredPrecipitations = [];
      pioggia.forEach((precipitation) => {
        measuredPrecipitations.push({
          [`avgmeasured${i18next.t('Precipitation')}`]: precipitation.value,
          date: precipitation.date,
          unitOfMessurement: precipitation.unit,
        });
      });
      return measuredPrecipitations;
    },
    hourlyForecastedPrecipitations(state) {
      const locationForecastedPrecipitations = state.weatherForecastedDataByLocations;
      if (!locationForecastedPrecipitations[0].parameters.PLUVIOMETER) {
        return [];
      }
      const precipIntensity = locationForecastedPrecipitations[0].parameters.PLUVIOMETER;
      const forecastedPrecipitations = [];

      precipIntensity.forEach((precipitation) => {
        forecastedPrecipitations.push({
          [`avgforecasted${i18next.t('Precipitation')}`]: precipitation.value,
          date: precipitation.date,
          unitOfMessurement: precipitation.unit,
        });
      });
      return forecastedPrecipitations;
    },
    dailyMeasuredPrecipitations(state) {
      const locationMeasuredPrecipitations = state.weatherMeasuredDataByLocations;
      if (!locationMeasuredPrecipitations[0].parameters.PLUVIOMETER) {
        return [];
      }
      const measuredPrecipitations = locationMeasuredPrecipitations[0].parameters.PLUVIOMETER;
      const dailyMeasuredPrecipitations = [];
      const datesDone = [];
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      measuredPrecipitations.forEach((precipitationData) => {
        const date = precipitationData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);
          const currentDayPrecipitations = measuredPrecipitations
            .filter((precipitation) => date === precipitation.date.split(' ')[0]);

          const currentDayPrecipitationsValues = currentDayPrecipitations
            .map((currentDayHumidity) => currentDayHumidity.value);

          const totalPrecipitations = currentDayPrecipitationsValues.reduce(sumReducer);

          dailyMeasuredPrecipitations.push({ date, [`avgmeasured${i18next.t('Precipitation')}`]: totalPrecipitations });
        }
      });

      return dailyMeasuredPrecipitations;
    },
    dailyForecastedPrecipitations(state) {
      const locationForecastedPrecipitations = state.weatherForecastedDataByLocations;
      if (!locationForecastedPrecipitations[0].parameters.PLUVIOMETER) {
        return [];
      }
      const forecastedPrecipitations = locationForecastedPrecipitations[0].parameters.PLUVIOMETER;

      const dailyForecastedPrecipitations = [];
      const datesDone = [];
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      forecastedPrecipitations.forEach((precipitationData) => {
        const date = precipitationData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);
          const currentDayPrecipitations = forecastedPrecipitations
            .filter((precipitation) => date === precipitation.date.split(' ')[0]);

          const currentDayPrecipitationsValues = currentDayPrecipitations
            .map((currentDayPrecipitation) => currentDayPrecipitation.value);

          const totalPrecipitations = currentDayPrecipitationsValues.reduce(sumReducer);

          dailyForecastedPrecipitations.push({ date, [`avgforecasted${i18next.t('Precipitation')}`]: totalPrecipitations });
        }
      });
      return dailyForecastedPrecipitations;
    },
    dailyMeasuredEvapo(state) {
      const locationForecasted = state.weatherMeasuredDataByLocations;
      const measuredEvapo = locationForecasted[0].parameters.EVAPO_ETO;
      const dailyMeasuredEvapo = [];
      const datesDone = [];
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      measuredEvapo.forEach((evapoData) => {
        const date = evapoData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);
          const currentDayPrecipitations = measuredEvapo.filter((evapo) => date === evapo.date.split(' ')[0]);
          const currentDayPrecipitationsValues = currentDayPrecipitations.map((currentDayEvapoData) => currentDayEvapoData.value);
          const totalEvapo = currentDayPrecipitationsValues.reduce(sumReducer);
          dailyMeasuredEvapo.push({ date, [`avgmeasured${i18next.t('Evaporacion')}`]: totalEvapo });
        }
      });

      return dailyMeasuredEvapo;
    },
    dailyForecastedEvapo(state) {
      const locationForecasted = state.weatherForecastedDataByLocations;
      const forecastedEvapoEto = locationForecasted[0].parameters.EVAPO_ETO;

      const dailyForecastedEvapo = [];
      const datesDone = [];
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      forecastedEvapoEto.forEach((evapoData) => {
        const date = evapoData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);
          const currentDayEvapo = forecastedEvapoEto.filter((evapo) => date === evapo.date.split(' ')[0]);
          const currentDayPrecipitationsValues = currentDayEvapo.map((currentDayEvapoData) => currentDayEvapoData.value);
          const totalEvapo = currentDayPrecipitationsValues.reduce(sumReducer);

          dailyForecastedEvapo.push({ date, [`avgforecasted${i18next.t('Evaporacion')}`]: totalEvapo });
        }
      });
      return dailyForecastedEvapo;
    },
    hourlyMeasuredWindSpeeds(state) {
      const locationMeasuredWindSpeeds = state.weatherMeasuredDataByLocations;

      if (!locationMeasuredWindSpeeds[0].parameters.WIND_SPEED) {
        return [];
      }
      const hourlyMeasuredWindSpeedsAVG = locationMeasuredWindSpeeds[0].parameters.WIND_SPEED;
      const hourlyMeasuredWindSpeedsMAX = locationMeasuredWindSpeeds[0].parameters.WIND_SPEED_MAX;

      const hourlyMeasuredWindSpeeds = [];
      for (let i = 0; i < hourlyMeasuredWindSpeedsAVG.length; i += 1) {
        hourlyMeasuredWindSpeeds.push({
          [`avgmeasured${i18next.t('WindSpeed')}`]: hourlyMeasuredWindSpeedsAVG[i].value,
          date: hourlyMeasuredWindSpeedsAVG[i].date,
          unitOfMessurement: hourlyMeasuredWindSpeedsAVG[i].unit,
        });
      }
      for (let i = 0; i < hourlyMeasuredWindSpeedsMAX.length; i += 1) {
        hourlyMeasuredWindSpeeds.push({
          [`maxmeasured${i18next.t('WindSpeed')}`]: hourlyMeasuredWindSpeedsMAX[i].value,
          date: hourlyMeasuredWindSpeedsMAX[i].date,
          unitOfMessurement: hourlyMeasuredWindSpeedsMAX[i].unit,
        });
      }
      hourlyMeasuredWindSpeeds.avgmeasured = true;
      hourlyMeasuredWindSpeeds.maxmeasured = true;
      hourlyMeasuredWindSpeeds.avgforecasted = true;
      return hourlyMeasuredWindSpeeds;
    },
    hourlyForecastedWindSpeeds(state) {
      const locationForecastedWindSpeeds = state.weatherForecastedDataByLocations;
      if (!locationForecastedWindSpeeds[0].parameters.WIND_SPEED) {
        return [];
      }
      const windSpeed = locationForecastedWindSpeeds[0].parameters.WIND_SPEED;
      const hourlyForecastedWindSpeed = [];
      for (let i = 0; i < windSpeed.length; i += 1) {
        hourlyForecastedWindSpeed.push({
          [`avgforecasted${i18next.t('WindSpeed')}`]: windSpeed[i].value,
          date: windSpeed[i].date,
          unitOfMessurement: windSpeed[i].unit,
        });
      }

      return hourlyForecastedWindSpeed;
    },
    dailyMeasuredWindSpeeds(state) {
      const locationForecastedWindSpeeds = state.weatherMeasuredDataByLocations;
      if (!locationForecastedWindSpeeds[0].parameters.WIND_SPEED) {
        return [];
      }
      const windSpeedRelativaMedia = locationForecastedWindSpeeds[0].parameters.WIND_SPEED;
      const dailyMeasuredWindSpeedsAVG = [];
      const averageDatesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      windSpeedRelativaMedia.forEach((measuredWindSpeedAVG) => {
        const date = measuredWindSpeedAVG.date.split(' ')[0];

        if (averageDatesDone.indexOf(date) === -1) {
          averageDatesDone.push(date);
          const currentDayWindSpeedsAVG = windSpeedRelativaMedia
            .filter((windSpeed) => date === windSpeed.date.split(' ')[0]);

          const currentDayWindSpeedAVGValues = currentDayWindSpeedsAVG
            .map((currentDayWindSpeed) => currentDayWindSpeed.value);

          const totalWindSpeedsAVG = currentDayWindSpeedAVGValues.reduce(sumReducer);

          const average = totalWindSpeedsAVG / currentDayWindSpeedAVGValues.length;

          dailyMeasuredWindSpeedsAVG.push({ date, unit: 'percentage', value: average });
        }
      });
      const windSpeedRelativaMin = locationForecastedWindSpeeds[0].parameters.WIND_SPEED;
      const dailyMeasuredWindSpeedsMIN = [];
      const minDatesDone = [];

      windSpeedRelativaMin.forEach((measuredWindSpeedMIN) => {
        const date = measuredWindSpeedMIN.date.split(' ')[0];

        if (minDatesDone.indexOf(date) === -1) {
          minDatesDone.push(date);
          const currentDayWindSpeedsMIN = windSpeedRelativaMin
            .filter((windSpeed) => date === windSpeed.date.split(' ')[0]);

          const currentDayWindSpeedsMINValues = currentDayWindSpeedsMIN
            .map((currentDayWindSpeed) => currentDayWindSpeed.value);

          const min = Math.min(...currentDayWindSpeedsMINValues);

          dailyMeasuredWindSpeedsMIN.push({ date, unit: 'percentage', value: min });
        }
      });

      const windSpeedRelativaMax = locationForecastedWindSpeeds[0].parameters.WIND_SPEED_MAX;
      const dailyMeasuredWindSpeedsMAX = [];
      const maxDatesDone = [];

      windSpeedRelativaMax.forEach((measuredWindSpeedMAX) => {
        const date = measuredWindSpeedMAX.date.split(' ')[0];
        if (maxDatesDone.indexOf(date) === -1) {
          maxDatesDone.push(date);
          const currentDayWindSpeedsMAX = windSpeedRelativaMax
            .filter((windSpeed) => date === windSpeed.date.split(' ')[0]);

          const currentDayWindSpeedsMAXValues = currentDayWindSpeedsMAX
            .map((currentDayWindSpeed) => currentDayWindSpeed.value);

          const max = Math.max(...currentDayWindSpeedsMAXValues);

          dailyMeasuredWindSpeedsMAX.push({ date, unit: 'celsius', value: max });
        }
      });
      const dailyMeasuredWindSpeeds = [];

      for (let i = 0; i < dailyMeasuredWindSpeedsAVG.length; i += 1) {
        dailyMeasuredWindSpeeds.push({
          // minTemp: dailyMeasuredHumiditiesMIN[i].value,
          // maxTemp: dailyMeasuredHumiditiesMAX[i].value,
          [`avgmeasured${i18next.t('WindSpeed')}`]: dailyMeasuredWindSpeedsAVG[i].value,
          date: dailyMeasuredWindSpeedsAVG[i].date,
        });
      }
      return dailyMeasuredWindSpeeds;
    },
    dailyForecastedWindSpeeds(state) {
      const locationForecastedWindSpeeds = state.weatherForecastedDataByLocations;
      if (!locationForecastedWindSpeeds[0].parameters.WIND_SPEED) {
        return [];
      }
      const windSpeeds = locationForecastedWindSpeeds[0].parameters.WIND_SPEED;

      const dailyForecastedWindSpeedsAVG = [];
      const dailyForecastedWindSpeedsMIN = [];
      const dailyForecastedWindSpeedsMAX = [];
      const datesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      windSpeeds.forEach((windSpeedData) => {
        const date = windSpeedData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);

          const currentDayHumidities = windSpeeds
            .filter((windSpeed) => date === windSpeed.date.split(' ')[0]);

          const currentDayWindSpeedsValues = currentDayHumidities
            .map((currentDayWindSpeed) => currentDayWindSpeed.value);

          const totalWindSpeedsAVG = currentDayWindSpeedsValues.reduce(sumReducer);

          const avg = totalWindSpeedsAVG / currentDayWindSpeedsValues.length;

          const min = Math.min(...currentDayWindSpeedsValues);
          const max = Math.max(...currentDayWindSpeedsValues);

          dailyForecastedWindSpeedsAVG.push({ date, unit: 'percentage', value: avg });
          dailyForecastedWindSpeedsMIN.push({ date, unit: 'percentage', value: min });
          dailyForecastedWindSpeedsMAX.push({ date, unit: 'percentage', value: max });
        }
      });
      const dailyForecasteWindSpeeds = [];

      for (let i = 0; i < dailyForecastedWindSpeedsAVG.length; i += 1) {
        dailyForecasteWindSpeeds.push({
          minTemp: dailyForecastedWindSpeedsMIN[i].value,
          maxTemp: dailyForecastedWindSpeedsMAX[i].value,
          [`avgforecasted${i18next.t('WindSpeed')}`]: dailyForecastedWindSpeedsAVG[i].value,
          date: dailyForecastedWindSpeedsAVG[i].date,
        });
      }
      return dailyForecasteWindSpeeds;
    },
    // EMPIEZA EL SOLAR.
    hourlyMeasuredSolarRadiations(state) {
      const locationMeasuredSolarRadiations = state.weatherMeasuredDataByLocations;
      const hourlyMeasuredSolarRadiationsAVG = locationMeasuredSolarRadiations[0].parameters?.SOLAR_RADIATION ?? [];

      const hourlyMeasuredSolarRadiations = [];
      for (let i = 0; i < hourlyMeasuredSolarRadiationsAVG.length; i += 1) {
        hourlyMeasuredSolarRadiations.push({
          [`avgmeasured${i18next.t('SolarRadiation')}`]: hourlyMeasuredSolarRadiationsAVG[i].value,
          date: hourlyMeasuredSolarRadiationsAVG[i].date,
          unitOfMessurement: hourlyMeasuredSolarRadiationsAVG[i].unit,
        });
      }
      return hourlyMeasuredSolarRadiations;
    },
    hourlyForecastedSolarRadiations(state) {
      const locationForecastedSolarRadiations = state.weatherForecastedDataByLocations;
      if (!locationForecastedSolarRadiations[0].parameters.SOLAR_RADIATION) {
        return [];
      }
      const solarRadiation = locationForecastedSolarRadiations[0].parameters.SOLAR_RADIATION;
      if (typeof solarRadiation !== 'undefined') {
        const hourlyForecastedSolarRadiation = [];
        for (let i = 0; i < solarRadiation.length; i += 1) {
          hourlyForecastedSolarRadiation.push({
            [`avgforecasted${i18next.t('SolarRadiation')}`]: solarRadiation[i].value,
            date: solarRadiation[i].date,
            unitOfMessurement: solarRadiation[i].unit,

          });
        }
        return hourlyForecastedSolarRadiation;
      }
    },
    dailyMeasuredSolarRadiations(state) {
      const locationForecastedSolarRadiations = state.weatherMeasuredDataByLocations;
      const solarRadiationRelativaMedia = locationForecastedSolarRadiations.parameters?.SOLAR_RADIATION ?? [];
      const dailyMeasuredSolarRadiationsAVG = [];
      const averageDatesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      solarRadiationRelativaMedia.forEach((measuredWindSpeedAVG) => {
        const date = measuredWindSpeedAVG.date.split(' ')[0];

        if (averageDatesDone.indexOf(date) === -1) {
          averageDatesDone.push(date);
          const currentDaySolarRadiationsAVG = solarRadiationRelativaMedia
            .filter((solarRadiation) => date === solarRadiation.date.split(' ')[0]);

          const currentDaySolarRadiationAVGValues = currentDaySolarRadiationsAVG
            .map((currentDaySolarRadiation) => currentDaySolarRadiation.value);

          const totalSolarRadiationsAVG = currentDaySolarRadiationAVGValues.reduce(sumReducer);

          const average = totalSolarRadiationsAVG / currentDaySolarRadiationAVGValues.length;

          dailyMeasuredSolarRadiationsAVG.push({ date, unit: 'percentage', value: average });
        }
      });
      const solarRadiationRelativaMin = locationForecastedSolarRadiations.parameters?.SOLAR_RADIATION ?? [];
      const dailyMeasuredSolarRadiationsMIN = [];
      const minDatesDone = [];

      solarRadiationRelativaMin.forEach((measuredSolarRadiationMIN) => {
        const date = measuredSolarRadiationMIN.date.split(' ')[0];

        if (minDatesDone.indexOf(date) === -1) {
          minDatesDone.push(date);
          const currentDaySolarRadiationsMIN = solarRadiationRelativaMin
            .filter((solarRadiation) => date === solarRadiation.date.split(' ')[0]);

          const currentDaySolarRadiationsMINValues = currentDaySolarRadiationsMIN
            .map((currentDaySolarRadiation) => currentDaySolarRadiation.value);

          const min = Math.min(...currentDaySolarRadiationsMINValues);

          dailyMeasuredSolarRadiationsMIN.push({ date, unit: 'percentage', value: min });
        }
      });

      const solarRadiationRelativaMax = locationForecastedSolarRadiations.parameters?.SOLAR_RADIATION ?? [];
      const dailyMeasuredSolarRadiationsMAX = [];
      const maxDatesDone = [];

      solarRadiationRelativaMax.forEach((measuredSolarRadiationsMAX) => {
        const date = measuredSolarRadiationsMAX.date.split(' ')[0];
        if (maxDatesDone.indexOf(date) === -1) {
          maxDatesDone.push(date);
          const currentDaySolarRadiationsMAX = solarRadiationRelativaMax
            .filter((windSpeed) => date === windSpeed.date.split(' ')[0]);

          const currentDaySolarRadiationsValues = currentDaySolarRadiationsMAX
            .map((currentDaySolarRadiation) => currentDaySolarRadiation.value);

          const max = Math.max(...currentDaySolarRadiationsValues);

          dailyMeasuredSolarRadiationsMAX.push({ date, unit: 'celsius', value: max });
        }
      });
      const dailyMeasuredSolarRadiations = [];

      for (let i = 0; i < dailyMeasuredSolarRadiationsAVG.length; i += 1) {
        dailyMeasuredSolarRadiations.push({
          // minTemp: dailyMeasuredHumiditiesMIN[i].value,
          // maxTemp: dailyMeasuredHumiditiesMAX[i].value,
          measuredWindSpeed: dailyMeasuredSolarRadiationsAVG[i].value,
          date: dailyMeasuredSolarRadiationsAVG[i].date,
        });
      }
      return dailyMeasuredSolarRadiations;
    },
    dailyForecastedSolarRadiations(state) {
      const locationForecastedSolarRadiations = state.weatherForecastedDataByLocations;
      /** if (!locationForecastedSolarRadiations[0].parameters.SOLAR_RADIATION) {
        return [];
      } */
      const solarRadiations = locationForecastedSolarRadiations[0].parameters.SOLAR_RADIATION;

      const dailyForecastedSolarRadiationsAVG = [];
      const dailyForecastedSolarRadiationsMIN = [];
      const dailyForecastedSolarRadiationsMAX = [];
      const datesDone = [];

      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      solarRadiations.forEach((solarRadiationData) => {
        const date = solarRadiationData.date.split(' ')[0];

        if (datesDone.indexOf(date) === -1) {
          datesDone.push(date);

          const currentDaySolarRadiations = solarRadiations
            .filter((solarRadiation) => date === solarRadiation.date.split(' ')[0]);

          const currentDaySolarRadiationsValues = currentDaySolarRadiations
            .map((currentDaySolarRadiation) => currentDaySolarRadiation.value);

          const totalSolarRadiationsAVG = currentDaySolarRadiationsValues.reduce(sumReducer);

          const avg = totalSolarRadiationsAVG / currentDaySolarRadiationsValues.length;

          const min = Math.min(...currentDaySolarRadiationsValues);
          const max = Math.max(...currentDaySolarRadiationsValues);

          dailyForecastedSolarRadiationsAVG.push({ date, unit: 'percentage', value: avg });
          dailyForecastedSolarRadiationsMIN.push({ date, unit: 'percentage', value: min });
          dailyForecastedSolarRadiationsMAX.push({ date, unit: 'percentage', value: max });
        }
      });
      const dailyForecasteSolarRadiations = [];

      for (let i = 0; i < dailyForecastedSolarRadiationsAVG.length; i += 1) {
        dailyForecasteSolarRadiations.push({
          minTemp: dailyForecastedSolarRadiationsMIN[i].value,
          maxTemp: dailyForecastedSolarRadiationsMAX[i].value,
          [`avgforecasted${i18next.t('SolarRadiation')}`]: dailyForecastedSolarRadiationsAVG[i].value,
          date: dailyForecastedSolarRadiationsAVG[i].date,
        });
      }
      return dailyForecasteSolarRadiations;
    },
  },

  mutations: {
    SET_WEATHER_FORECAST_DATA(state, payload) {
      state.weatherForecastedDataByLocations = payload;
    },
    SET_FORECAST_PROVIDER(state, payload) {
      state.foreCastProvider = payload[0].name;
    },
    SET_MEASURED_PROVIDER(state, payload) {
      state.measuredProvider = payload[0].name;
    },
    SET_HAS_MEASURED_TEMPERATURE(state, payload) {
      state.hasMeasuredTemperature = payload;
    },
    SET_HAS_FORECAST_TEMPERATURE(state, payload) {
      state.hasForecastTemperature = payload;
    },
    SET_WEATHER_MEASURED_DATA(state, payload) {
      state.weatherMeasuredDataByLocations = payload;
    },
    SET_SELECTED_FARM_ID(state, payload) {
      state.selectedFarmID = payload;
    },
    SET_SELECTED_MEASURING_FREQUENCY(state, payload) {
      state.selectedMeasuringFrequency = payload;
    },
  },
  actions: {
    async fetchWeatherForecastData({ commit, rootState }, currentPlantation) {
      let formattedInit = rootState.weather.searchInitDate;
      let formattedEnd = rootState.weather.searchEndDate;

      if (formattedInit === null || formattedEnd === null) {
        formattedInit = moment().format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment.utc().add(7, 'd').format('YYYY-MM-DDT23:59:59');
      } else {
        formattedInit = moment(formattedInit).format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment(formattedEnd).format('YYYY-MM-DDT23:59:59');
      }
      try {
        const forecasts = await Api.getWeatherForParameterByPlantation(currentPlantation.companyId, currentPlantation.id, 'FORECAST', formattedInit, formattedEnd, ['TEMPERATURE', 'TEMPERATURE_AVG', 'TEMPERATURE_MIN', 'TEMPERATURE_MAX', 'PLUVIOMETER', 'HUMIDITY', 'WIND_SPEED', 'WIND_SPEED_MAX', 'SOLAR_RADIATION', 'EVAPO_ETO']);
        commit('SET_WEATHER_FORECAST_DATA', JSON.parse(forecasts.response));
        commit('SET_FORECAST_PROVIDER', JSON.parse(forecasts.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchMeasuredWeatherData({ commit, rootState }, currentPlantation) {
      let formattedInit = rootState.weather.searchInitDate;
      let formattedEnd = rootState.weather.searchEndDate;

      if (formattedInit === null || formattedEnd === null) {
        formattedInit = moment.utc().subtract(7, 'd').format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment().format('YYYY-MM-DDT00:00:00');
      } else {
        formattedInit = moment(formattedInit).format('YYYY-MM-DDT00:00:00');
        formattedEnd = moment(formattedEnd).format('YYYY-MM-DDT00:00:00');
      }
      try {
        const measures = await Api.getWeatherForParameterByPlantation(currentPlantation.companyId, currentPlantation.id, 'MEASURED', formattedInit, formattedEnd, ['TEMPERATURE', 'TEMPERATURE_AVG', 'TEMPERATURE_MIN', 'TEMPERATURE_MAX', 'PLUVIOMETER', 'HUMIDITY', 'WIND_SPEED', 'WIND_SPEED_MAX', 'SOLAR_RADIATION', 'EVAPO_ETO']);
        commit('SET_WEATHER_MEASURED_DATA', JSON.parse(measures.response));
        commit('SET_MEASURED_PROVIDER', JSON.parse(measures.response));

        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    setSelectedFarmID({ commit }, newSelectedFarmID) {
      commit('SET_SELECTED_FARM_ID', newSelectedFarmID);
    },
    setSelectedMeasuringFrequency({ commit }, newMeasuringFrequency) {
      commit('SET_SELECTED_MEASURING_FREQUENCY', newMeasuringFrequency);
    },
    setSelectedRangeOfDays({ commit }, newRangeOfDays) {
      commit('SET_SELECTED_RANGE_OF_DAYS', newRangeOfDays);
    },
    setHasMeasuredTemperature({ commit }, newVal) {
      commit('SET_HAS_MEASURED_TEMPERATURE', newVal);
    },
    setHasForecastTemperature({ commit }, newVal) {
      commit('SET_HAS_FORECAST_TEMPERATURE', newVal);
    },
  },
};
