<template>
  <div
    class="content-devx"
  >
    <CalendarFilter
      @searchFilter="searchFilter"
      @clearFilteredData="clearFilteredData"
    />

    <DxDataGrid
      :data-source="plantations"
      :show-borders="true"
      :remote-operations="true"
      :column-auto-width="true"
      :show-colon="false"
      @exporting="onExporting"
      @contentReady="onInit"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="SearchEngine"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="true"
      />
      <DxPaging :page-size="gridSettings.pageSize" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxExport
        :enabled="false"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('Search_placeholder')"
      />
      <!-- <DxColumn
        data-field="company"
        :group-index="0"
        caption=""
      /> -->
      <DxColumn
        data-field="name"
        :caption="$t('searchEngine_description')"
        alignment="left"
      />
      <DxColumn
        data-field="province"
        :caption="$t('searchEngine_province')"
        alignment="left"
      />
      <DxColumn
        data-field="explotation"
        :caption="$t('searchEngine_farm')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="parcel"
        :caption="$t('searchEngine_parcel')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="sector"
        :caption="$t('searchEngine_sector')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="campaign"
        :caption="$t('searchEngine_campaign')"
        alignment="left"
      />
      <DxColumn
        data-field="status"
        :calculate-cell-value="getStatusCampaign"
        :caption="$t('searchEngine_state')"
        alignment="left"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
        sort-order="asc"
        :width="100"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="cellTemplate"
      />
      <DxColumn
        data-field="species"
        :caption="$t('searchEngine_botanical_species')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('searchEngine_variety')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="initDate"
        data-type="date"
        format="dd/MM/yyyy"
        :caption="$t('searchEngine_init_date')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="endDate"
        data-type="date"
        format="dd/MM/yyyy"
        :caption="$t('searchEngine_end_date')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        type="buttons"
        :width="gridSettings.buttonsWidth"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="goToMainMenu"
        />
      </DxColumn>
      <template #cellTemplate="{ data: dataCampaign }">
        <div :class="setClassByStatus(dataCampaign.data)">
          
          {{ getStatusCampaign(dataCampaign.data.initDate, dataCampaign.data.endDate) }}
        </div>
      </template>
      <DxScrolling
        column-rendering-mode="standard"
        :visible="true"
      />
    </DxDataGrid>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxStateStoring,
  DxScrolling,
  DxPager,
  DxButton,
  DxPaging,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import settings from '../../js/commonSettings';
import CalendarFilter from './calendarFilter/index.vue';

export default {
  name: 'SearchEngine',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxScrolling,
    DxPager,
    DxButton,
    DxPaging,
    CalendarFilter,
  },
  props: {
    plantations: {
      type: Object,
      default: () => {},
    },
    goToView: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tasksAvailables: [],
      pageSizes: [5, 10, 20],
      dataSource: [],
      filteredDataSource: null,
      dataTaskStatus: [{
        text: this.$t('status_campania_active'),
        value: this.$t('status_campania_active'),
      }, {
        text: this.$t('status_campania_end'),
        value: this.$t('status_campania_end'),
      },
      {
        text: this.$t('status_campania_pending'),
        value: this.$t('status_campania_pending'),
      }],
      actualDate: moment().format('YYYY-MM-DD'),
      format: 'shortDate',
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('SearcherFilters', ['searchFromDate', 'searchToDate']),
  },
  methods: {
    onInit() {
      Array.from(document.getElementsByClassName('dx-group-cell')).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    dataFormatted(cellInfo) {
      return moment(cellInfo.valueText).format('DD-MM-YYYY');
    },
    searchFilter() {
      if (!this.searchFromDate || !this.searchToDate) {
        this.$notifyDX(
          {
            message: this.$t('chooseDatesError'),
            width: 450,
          },
          'error',
          2000,
        );
        return;
      }

      this.$emit('search-plantation-by-dates', {
        fromDate: this.searchFromDate,
        toDate: this.searchToDate,
      });
    },
    clearFilteredData() {
      this.$emit('clear-filtered-data');
    },
    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    // SET CSS CLASSES TO CELLS
    setClassByStatus(data) {

      const status = this.getStatusCampaign(data.initDate, data.endDate);

      let colorCell = '';
      switch (status) {
        case this.$t('plantations.grid.active'):
          colorCell = 'Activa';
          break;
        case this.$t('plantations.grid.end'):
          colorCell = 'Finalizada';
          break;
        case this.$t('plantations.grid.pending'):
          colorCell = 'Pendiente';
          break;
        default:
          break;
      }
      return colorCell;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('SearchEngine');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SearchEngine.xlsx');
          });
      });
      e.cancel = true;
    },
    goToMainMenu(rowSelected) {
      const data = {
        companyId: rowSelected.row.data.companyId,
        plantationId: rowSelected.row.data.id,
      };
      this.getPlantationById(data);
      this.setCurrentPlantation(rowSelected.row.data);
      this.setSearcherPlantation(rowSelected.row.data);
      this.setOldPlantation(rowSelected.row.data);
      this.setCurrentPlanId('');
      this.setClearFilter('searcher/weatherGraph');
      this.setCurrentTab('home');
      if (this.goToView.includes('home')) {
        this.$f7.views.main.router.navigate('/searcher/', { reloadCurrent: true });
      } else {
        this.$f7.views.main.router.navigate(this.goToView, { reloadCurrent: true });
      }

      this.setAccordionItemOpened();
    },
    setAccordionItemOpened() {
      Array.from(document.getElementsByClassName('accordion-item'))[0].classList.add('accordion-item-opened');
    },
    ...mapActions('Plantation', ['setCurrentPlantation', 'setOldPlantation', 'setSearcherPlantation']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('CalendarInitEndDate', ['setClearFilter']),
    ...mapActions('Utilities', ['setCurrentTab']),
    ...mapActions('Plans', ['setCurrentPlanId']),
  },

};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.Activa, .Finalizada, .Pendiente {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
  text-align:center;
}

.Activa {
  color: #219653 !important;
  background: #DEFCE8 !important;
  border-width: 1px;
    border-style: solid;
    border-color: #219653;
}
.Finalizada {
  color: #74838C;
  background: #F4F5F7;
  border-width: 1px;
    border-style: solid;
    border-color: #74838C;
}
.Pendiente {
  color: #23465E;
  background: white;
  border-width: 1px;
    border-style: solid;
    border-color: #23465E;
}
</style>
