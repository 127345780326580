import {
  DxChart,
  DxSeries,
  DxTooltip,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
} from 'devextreme-vue/chart';
import { mapGetters } from 'vuex';

export default {
  name: 'nutritional-distribution',
  components: {
    DxChart,
    DxTooltip,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
  },
  props: [],
  data() {
    return {
      dataSourceSeriesElement: [],
      elements: ['CaO', 'K2O', 'MgO', 'N', 'P205', 'SO3'],
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
  },
  mounted() {
    let elementName = '';
    let itemTimeValue;
    this.stepData.dosageProduct.forEach((itemProduct, index) => {
      itemTimeValue = this.stepData.timeValues[index];
      for (const propTimeValue in itemTimeValue) {
        for (const propProduct in itemProduct) {
          elementName = this.getElementByName(propProduct);
          if (elementName !== '') {
            this.dataSourceSeriesElement.push({
              [elementName]: this.roundValue((itemProduct[propProduct] / 100)
              * itemTimeValue[propTimeValue]),
              monthWeek: propTimeValue,
            });
          }
        }
      }
    });

    this.dataSourceSeriesElement = this.groupGraphDataByDate(this.dataSourceSeriesElement);
  },
  methods: {
    getElementByName(propName) {
      switch (propName) {
        case 'n':
          return 'N';
        case 'p':
          return 'P205';
        case 'k':
          return 'K2O';
        case 'c':
          return 'CaO';
        case 'm':
          return 'MgO';
        case 's':
          return 'SO3';
        default:
          return '';
      }
    },
    groupGraphDataByDate(array) {
      const groups = {};

      // Este método es distinto del de nutrición, no meter en helpers

      // mapa con la suma de cada elemento agrupado por fecha
      array.forEach((item) => {
        const { monthWeek, ...props } = item;
        if (!groups[monthWeek]) groups[monthWeek] = {};

        Object.keys(props).forEach((key) => {
          // establece el elemento y plan teniendo en cuenta la fecha
          if (!groups[monthWeek][key]) groups[monthWeek][key] = 0;

          // suma del elemento y plan establecido anteriormente con el valor del elemento y plan que se está recorriendo
          groups[monthWeek][key] += item[key];

          // Formato a 2 decimales y conversión de string a número
          groups[monthWeek][key] = Number(groups[monthWeek][key].toFixed(2));
        });
      });

      // transformación a array
      return Object.keys(groups).map((key) => ({
        monthWeek: key,
        ...groups[key], // suma de cada grupo
      }));

      // quitar comentario si es útil mantener la fecha como key
      // return groups;
    },
    roundValue(val) {
      return parseFloat(val.toFixed(2));
    },
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}: ${point.value} kg/ha</span> `,
      };
    },
  },
};
