<template>
  <div class="content-devx no-padding">
    <f7-block class="no-padding">
      <DxForm
        id="form"
        :col-count="1"
        :caption="$t('title_register_plantation')"
      >
        <DxGroupItem :col-count="3">
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.specie_botanic')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: speciesAvailables,
                value: specieSelected,
                searchEnabled:true,
                onValueChanged: onChangeSpecieBotanic,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.variety')"
              :isRequired="true"
            >
              <template #default>
                <DxSelectBox
                  :data-source="varietiesPaginated"
                  :onValueChanged="onChangeVariety"
                  :value="varietySelected"
                  displayExpr="name"
                  valueExpr="name"
                  :searchEnabled="true"
                />
              </template>
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="`${$t('registerPlantation.unit')}`"
              editor-type="dxSelectBox"
              :editor-options="{
                items: plantingUnit,
                value: plantingUnitSelected,
                onValueChanged: onChangePlantingUnit,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.planting_density')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: seedDensity,
                onValueChanged: onChangeSeedDensity,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="`${$t('registerPlantation.plants_density')}(${$t('registerPlantation.unit_plantas_ha')})`"
              editor-type="dxNumberBox"
              :editor-options="{
                value: calculatePlantsDensity,
                readOnly: true,
                onValueChanged: onChangePlantsDensity,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              name="plants_numbere"
              editor-type="dxNumberBox"
              :data-field="$t('registerPlantation.plants_number')"
              :editor-options="{
                value: calculatePlantsNumber,
                readOnly: false,
                onValueChanged: onChangePlantsNumber,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';
import DataSource from "devextreme/data/data_source";
import { DxSelectBox } from 'devextreme-vue/select-box';

export default {
  name: 'RegisterPlantationHerbaceo',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxSelectBox
  },
  data() {
    return {
      actualName: '',
      hectareToM2: 10000,
      seedForKg: 500,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['plantationName', 'protectionTypeSelected', 'managementTypeSelected',
      'initDate', 'endDate', 'cropTypeSelected', 'specieSelected', 'varietySelected', 'speciesAvailables',
      'plantsNumber', 'varieties', 'plantingUnit', 'plantingUnitSelected', 'seedDensity', 'sectorArea', 'isEdit', 'actualPlantation']),
    // SEMILLAS/HA
    calculatePlantsDensity() {
      if (this.plantingUnitSelected === 'SEMILLAS/HA') { return parseInt(this.seedDensity, 10); }
      if (this.plantingUnitSelected === 'KG SEMILLAS/HA') { return parseInt(this.seedDensity * this.seedForKg, 10); }
      return parseInt(this.seedDensity * this.hectareToM2, 10);
    },
    calculatePlantsNumber() {
      if (this.plantingUnitSelected === 'SEMILLAS/HA') { return this.plantsNumber || parseInt(this.sectorArea * this.seedDensity, 10); }
      if (this.plantingUnitSelected === 'KG SEMILLAS/HA') { return this.plantsNumber || parseInt(this.sectorArea * this.seedDensity * this.seedForKg, 10); }
      return this.plantsNumber || parseInt(this.seedDensity * this.sectorArea * this.hectareToM2, 10);
    },
    varietiesPaginated() {
      const selectBoxData = new DataSource({
        store: this.varieties,
        paginate: true,
        pageSize: 100
      });

      return selectBoxData
    }
  },
  async beforeMount() {
    if (this.isEdit) {
      this.setSpecieBotanic(this.actualPlantation.species);
      this.setVariety(this.actualPlantation.variety);
      this.setSeedDensity(this.actualPlantation.planting_density);
      this.setPlantsDensity(this.actualPlantation.plants_density);
      this.setPlantsNumber(this.actualPlantation.plants);
      this.setPlantingUnit(this.actualPlantation.planting_unit);
      try {
        await this.getSelectBoxSpecies(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.cropTypeSelected : this.actualPlantation.crop_type);
        await this.getSelectBoxVariety(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.specieSelected : this.actualPlantation.species);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    }
  },
  methods: {
    async onChangeSpecieBotanic(e) {
      this.setSpecieBotanic(e.value);
      try {
        await this.getSelectBoxVariety(e.value);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    onChangeVariety(e) {
      this.setVariety(e.value);
    },
    onChangeSeedDensity(e) {
      this.setSeedDensity(e.value);
    },
    onChangePlantsDensity(e) {
      this.setPlantsDensity(e.value);
    },
    onChangePlantsNumber(e) {
      this.setPlantsNumber(e.value);
    },
    onChangePlantingUnit(e) {
      this.setPlantingUnit(e.value);
    },
    ...mapActions('RegisterPlantation', ['setPlantationName', 'setProtectionType', 'setManagementType',
      'setInitDate', 'setEndDate', 'setCultiveType', 'setSpecieBotanic', 'setVariety', 'setSeedDensity',
      'setPlantsDensity', 'setPlantsNumber', 'getSelectBoxVariety',
      'setPlantingUnit', 'initStore', 'getSelectBoxSpecies']),
  },
};
</script>
