import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '@/components/wizardNutrition/stepper-navigation/index.vue';
import NutritionalBalance from '@/components/wizardNutrition/nutritionalBalance/index.vue';
import { mapState } from 'vuex';
import PopupConfirmWizardExit from '../../../components/wizardNutrition/popupConfirmWizardExit/index.vue';

export default {
  name: 'nutrition-step-two',
  components: {
    navbar, Stepper, StepperNavigation, NutritionalBalance, PopupConfirmWizardExit,
  },
  props: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};
