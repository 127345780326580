<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Nutritional_Products')}`" />
    <nutritionalProducts />
  </f7-page>
</template>

<script>
import navbar from '../../../components/NavBar.vue';
import nutritionalProducts from '../../../components/utilities/vademecum/nutritionalProducts/index.vue';

export default {
  name: 'nutritionalProductsPage',
  components: {
    navbar,
    nutritionalProducts,
  },
};
</script>
