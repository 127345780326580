import ConverterWater from '../../components/converterWater/index.vue';
import ConverterGround from '../../components/converterGround/index.vue';
import ConverterNutritionalSolutions
  from '../../components/converterNutritionalSolutions/index.vue';
import ConverterMolecularWeight from '../../components/converterMolecularWeight/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    ConverterWater,
    ConverterGround,
    ConverterMolecularWeight,
    ConverterNutritionalSolutions,
    navbar,
  },
  data() {
    return {};
  },
};
