<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Plans.title')}`" />
    <div class="content-navigation">
      <f7-button
        class="dx-btn-cancel padding-left padding-right margin-bottom"
        type="default"
        text-color="gray"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToBack()"
      >
        {{ $t("Button_go_to_back") }}
      </f7-button>
    </div>
    <template v-if="currentTypePlan === 'fertilization'">
      <NutritionDetailComponent
        v-if="loaded"
        :fertilization="currentPlan"
        :plantation-property="actualPlantation"
        :hide-report-button="false"
      />
      <f7-row no-gap>
        <f7-col style="align-self: stretch">
          <ElementResumeComponent
            v-if="loaded"
            class="element-resume"
            :fertilization="currentPlan"
          />
        </f7-col>
        <f7-col>
          <ProductResumeChartComponent
            v-if="loaded"
            :fertilization="currentPlan"
          />
        </f7-col>
      </f7-row>
      <ProductResumeComponent
        v-if="loaded"
        :fertilization="currentPlan"
      />
      <Observations v-if="loaded" :fertilization="currentPlan"/>
    </template>
    <template v-else>
      <TreatmentDetailPlanComponent
        v-if="loaded"
        :id="currentPlanId"
        :hide-back-button="true"
        :called-from-view-plan="true"
        :hide-report-button="false"
      />
    </template>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import navbar from '../../../components/NavBar.vue';
import NutritionDetailComponent from '../../../components/nutritionDetail/nutritionDetail/index.vue';
import ElementResumeComponent from '../../../components/nutritionDetail/elementResume/index.vue';
import ProductResumeComponent from '../../../components/nutritionDetail/productResume/index.vue';
import TreatmentDetailPlanComponent from '../../../components/wizardTreatment/treatmentDetailPlan/index.vue';
import Observations from '../../../components/nutritionDetail/observations/index.vue';
import ProductResumeChartComponent from '../../../components/nutritionDetail/productResumeChart/index.vue';

export default {
  name: 'ViewPlanDetail',
  components: {
    navbar,
    NutritionDetailComponent,
    ElementResumeComponent,
    ProductResumeComponent,
    TreatmentDetailPlanComponent,
    Observations,
    ProductResumeChartComponent
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Plans', ['currentPlanId', 'currentTypePlan', 'currentPlan']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['plantations']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    // TODO: Mirar esto al hacer copia
    // this.setCurrentFertilizationId('');
    try {
      await this.getPlanById({
        planId: this.currentPlanId,
        typePlan: this.currentTypePlan,
      });

      const data = {
        companyId: this.currentPlan.company_id,
        plantationId: this.currentPlan.plantation,
      };

      await this.getPlantationById(data);

      const plantation = this.plantations.find((plant) => plant.id === this.actualPlantation.id);

      this.formatPlantation(plantation);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate('/plans/', { reloadCurrent: true });
    },
    formatPlantation(plantation) {
      const currentPlant = {
        id: plantation.id,
        companyId: plantation.company_id,
        company: plantation.company,
        name: plantation.name,
        province: plantation.province,
        explotation: plantation.explotation,
        parcel: plantation.parcel,
        sector: plantation.sector,
        campaign: plantation.campaign,
        variety: plantation.variety,
        initDate: plantation.init_date,
        endDate: plantation.end_date,
        species: plantation.species,
        status: plantation.status,
        area: plantation.area,
        coordinates: plantation.coordinates,
        sectorId: plantation.sector_id,
        sectorIdLeftComparator: false,
        sectorIdRightComparator: false,
        isPlantationSelected: false,
      };

      this.setCurrentPlantation(currentPlant);
    },
    getStatusCampaign(initDate, endDate) {
      if (moment(this.actualDate).isBetween(initDate, endDate)) {
        return this.$t('status_campania_active');
      } if (endDate < this.actualDate) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    ...mapActions('Plans', ['getPlanById']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
    ...mapActions('fertilizationStore', ['setCurrentFertilizationId']),
  },
};
</script>

<style scoped>
.dx-btn-cancel {
  width: fit-content;
}
.element-resume {
  display: flex;
  height: 100%
}
</style>
