<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Page_subtitle_clients')}`" />
    <DxClients />
  </f7-page>
</template>

<script>
import DxClients from '../../../components/utilities/clients/DxClients.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Clients',
  components: {
    DxClients,
    navbar,
  },
};
</script>
