<template>
  <div class="margin-top-half">
    <div
      v-if="loaded"
      class="content-devx"
    >
      <DxDataGrid
        id="ProductsDatabase-Datagrid"
        :data-source="allProductSync"
        :remote-operations="true"
        :column-min-width="70"
        :column-auto-width="true"
        :allow-column-resizing="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        @RowRemoved="callToDeleteProduct"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="ProductsDatabase"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="gridSettings.pageSize" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          info-text="{2} items"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxEditing
          :allow-deleting="allowDelete"
          :allow-updating="allowUpdate"
          :confirm-delete="true"
          mode="popup"
        >
          <DxTexts
            :confirm-delete-message="$t('Confirm_Delete_Text')"
            :confirm-delete-title="$t('Confirm_Delete_Title')"
          />
        </DxEditing>
        <DxColumn
          data-field="name"
          width="30%"
          :caption="$t('DxProduct_nameCommercial')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="NT"
          :caption="$t('DxProduct_Ntotal')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="P2015_Total"
          :caption="$t('DxProduct_P205')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="K2O"
          :caption="$t('DxProduct_K20')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="CaO"
          :caption="$t('DxProduct_CaO')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          type="buttons"
          :fixed="gridSettings.fixed"
          :fixed-position="gridSettings.fixedPosition"
        >
          <DxButton
            name="find"
            icon="find"
            :on-click="showDetail"
          />
          <DxButton
            name="edit"
            icon="edit"
            :on-click="editProduct"
          />
          <DxButton
            name="delete"
            icon="trash"
          />
        </DxColumn>
      </DxDataGrid>
      <ProductFormPopup />
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
  DxButton,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import ProductFormPopup from '../productFormPopup/index.vue';
import settings from '../../../../js/commonSettings';

export default {
  name: 'ProductsDatabase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxEditing,
    DxButton,
    ProductFormPopup,
    DxTexts,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Product', ['allProduct', 'allProductSync']),
    ...mapGetters('authentication', ['currentUser']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.setShowPopUp(false);
      this.initStore();
      await this.getAllProductSync('user');
    } catch (error) {
      if (error.message.includes('500')) {
        this.$f7.dialog.alert(this.$t('error_500'));
      } else {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {

    async showDetail(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.getProductDetail(rowSelected.row.data.id);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/viewProductDetail/', { reloadCurrent: true });
      }
    },
    async editProduct(rowSelected) {
      const { owner } = rowSelected.row.data;
      if (owner === 'TIMAC AGRO ESPAÑA, S. A.' || owner === null) {
        this.$f7.dialog.alert(this.$t('wrong_user_edit'));
      } else {
        this.$f7.preloader.show();
        try {
          await this.getProductDetail(rowSelected.row.data.id);
        } catch (error) {
          const message = this.$helpers.getFilteredErrorMessage(error);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
          this.setIsEditing(true);
          this.setShowPopUp(true);
        }
      }
    },
    async callToDeleteProduct(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.deleteProduct(rowSelected.data.id);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    allowDelete(e) {
      return e.row.data.owner === this.currentUser;
    },
    allowUpdate(e) {
      return e.row.data.owner === this.currentUser;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.setIsEditing(false);
            this.setShowPopUp(true);
          },
        },
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ProductsDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProductsDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Product', ['getAllProduct', 'getAllProductSync', 'setShowPopUp', 'initStore', 'deleteProduct', 'getProductDetail', 'setIsEditing']),
  },
};
</script>
<style lang="scss" scoped>
@import './ProductsDatabase.styles.scss';
</style>
