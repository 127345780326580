import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    currentParcelId: '',
    currentParcel: {},
    showPopup: false,
  },
  actions: {
    setCurrentName({ commit }, newCurrentName) {
      commit('setCurrentName', newCurrentName);
    },
    setCurrentParcelId({ commit }, newCurrentParcelId) {
      commit('setCurrentParcelId', newCurrentParcelId);
    },
    setCurrentParcel({ commit }, newCurrentParcel) {
      commit('setCurrentParcel', newCurrentParcel);
    },
    async getParcelById({ commit }, { companyId, currentParcelId }) {
      try {
        const xhr = await Api.getParcelById(companyId, currentParcelId);
        commit('setCurrentParcel', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createParcel({ commit }, { companyId, newParcel }) {
      try {
        const xhr = await Api.createLocation(companyId, newParcel);
        commit('setCurrentParcel', JSON.parse(xhr.response));
        commit('setCurrentParcelId', JSON.parse(xhr.response).id);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateParcel(context, { companyId, parcelUpdated, parcelId }) {
      try {
        await Api.updateLocation(companyId, parcelUpdated, parcelId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setShowPopup({ commit }, value) {
      commit('setShowPopup', value);
    },
  },
  mutations: {
    setCurrentName(state, newCurrentName) {
      state.currentParcel.name = newCurrentName;
    },
    setCurrentParcelId(state, newCurrentParcelId) {
      state.currentParcelId = newCurrentParcelId;
    },
    setCurrentParcel(state, newCurrentParcel) {
      state.currentParcel = newCurrentParcel;
    },
    setShowPopup(state, value) {
      state.showPopup = value;
    },
  },
};
