<template>
  <f7-block class="content-devx-margin-block no-margin-horizontal margin-bottom">
    <f7-row>
      <f7-col width="50">
        <div v-if="!loadedGIS || currentIndexes[`${mainMap}`] === ''">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div v-else>
          <GISWMSTimeDimensionMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            :layer="layer"
            :index="currentIndexes[`${mainMap}`]"
            :center="center"
            :bbox="bbox"
            :height="'540px'"
          />
        </div>
      </f7-col>
      <f7-col
        width="50"
      >
        <GISLayerOptions
          :key-id="`${mainMap}`"
          :enable-date-picker="true"
        />
        <f7-block v-if="!timelineIndexes">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>

        <f7-row v-else>
          <TimelineGraph
            :timeline-indexes="timelineIndexes"
            :index="currentIndexes.GisTimeDimension"
          />
        </f7-row>
        <div v-if="!filteredTasks">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <f7-row
          v-else
          class="margin-bottom-5"
        >
          <TimelineTasksGrid
            :tasks="filteredTasks"
          />
        </f7-row>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import moment from 'moment';
  import GISWMSTimeDimensionMap from '../../../Gis/GISWMSTimeDimensionMap/index.vue';
  import GISLayerOptions from '../../../Gis/LayerOptions/index.vue';
  import TimelineGraph from '../timelineGraph/index.vue';
  import TimelineTasksGrid from '../timelineTasksGrid/index.vue';
  import Api from '../../../../services/Api';
  import EventBus from '../../../../js/event-bus';

export default {
  name: 'GisTimeDimension',
  components: {
    GISWMSTimeDimensionMap,
    GISLayerOptions,
    TimelineGraph,
    TimelineTasksGrid,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'GisTimeDimension',
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      center: {},
      bbox: {},
      filteredTasks: null,
      ws: Api.getGeoServerWorkspace(),
    };
  },
  computed: {
    ...mapState('Plantation', ['treeLocations', 'currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'currentInitIntervalDates', 'currentEndIntervalDates', 'timelineIndexes', 'currentBoundsWMSLayer']),
    ...mapState('TaskByCompany', ['tasks']),
  },
  async beforeMount() {
    try {
      this.setTimelineIndexes(null);
      this.setTasks(null);
      this.setCurrentIndexes({
        key: this.mainMap,
        newCurrentIndex: 'NDVI',
      });
      this.setCurrentInitIntervalDates({
        key: this.mainMap,
        newCurrentInitIntervalDate: moment(this.currentPlantation.initDate).format('YYYY-MM-DDT00:00:00'),
      });
      this.setCurrentEndIntervalDates({
        key: this.mainMap,
        newCurrentEndIntervalDate: moment().format('YYYY-MM-DDT00:00:00'),
      });
      this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      await this.boundsWMSLayer(`timacagro:${this.currentPlantation.sectorId}`);
      this.bbox = { ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox };
      this.center = { ...this.currentPlantation.coordinates };
      this.setIdCompanyTask(this.currentPlantation.companyId);
      await this.fetchTimelineIndexes({
        location: this.currentPlantation.sectorId, initDate: this.currentPlantation.initDate, endDate: moment().format('YYYY-MM-DD'), companyId: this.currentPlantation.companyId,
      });
      await this.getTasks(this.currentPlantation.id);
      EventBus.$on('filteringTasks', this.filteringTasks);
      this.filteredTasks = this.filteringTasks();
      this.loadedGIS = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    filteringTasks() {
      const filteredTasks = [];
      for (const task of this.tasks) {
        if (moment(task.init_date).isBetween(moment(this.currentPlantation.initDate), moment(this.currentEndIntervalDates.GisTimeDimension), 'days', '[]')) {
          filteredTasks.push(task);
        }
      }
    },
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchTimelineIndexes', 'setTimelineIndexes', 'boundsWMSLayer']),
    ...mapActions('TaskByCompany', ['setIdCompanyTask', 'getTasks', 'setTasks']),
  },
};
</script>

<style lang="scss" scoped>
  @import './GisTimeDimension.styles.scss';
</style>
