var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-block',[_c('DxForm',{attrs:{"id":"form","col-count":2,"caption":_vm.$t('Campania_title')}},[_c('DxGroupItem',{attrs:{"col-span":1}},[_c('DxSimpleItem',{attrs:{"name":"activity","data-field":_vm.$t('Configuration.Activity'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.activities,
            searchEnabled: true,
            onValueChanged:_vm.setActivityChange,
            value:_vm.taskDefinitionDetail.activity
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_name_require')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":1}},[_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('Configuration.Name'),"editor-type":"dxTextBox","editor-options":{
            onValueChanged:_vm.setNameChange,
            value:_vm.taskDefinitionDetail.name
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_name_require')}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }