var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding-10"},[_c('p',[_vm._v(_vm._s(_vm.$t('Uses_Doses_Detail_Title')))]),_vm._v(" "),_c('DxDataGrid',{attrs:{"data-source":_vm.data,"column-min-width":90,"column-auto-width":true,"show-borders":true,"show-column-lines":true,"show-row-lines":true},scopedSlots:_vm._u([{key:"applicationNum",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v("\n        "+_vm._s(data.data.application_num
            ? Math.round(data.data.application_num.max)
            : '')+"\n      ")])]}},{key:"liquidVol",fn:function(ref){
            var data = ref.data;
return [_c('div',[_vm._v("\n        "+_vm._s(data.data.liquid_vol
            ? Number(data.data.liquid_vol.min).toFixed(2)
            : '')+"\n        -\n        "+_vm._s(data.data.liquid_vol
            ? Number(data.data.liquid_vol.max).toFixed(2)
            : '')+"\n      ")])]}}])},[_c('DxColumn',{attrs:{"data-field":"cultive","caption":_vm.$t('searchEngine_botanical_species'),"allow-header-filtering":false,"alignment":"left"}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"agent","caption":_vm.$t('nutritionDetail.report.plagueAgent'),"allow-header-filtering":false,"alignment":"left"}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"application_num.max","caption":_vm.$t('Application_Num'),"allow-header-filtering":false,"alignment":"left","cell-template":"applicationNum"}}),_vm._v(" "),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('Liquid_Vol') + '(L/ha)',"allow-header-filtering":false,"alignment":"left","cell-template":"liquidVol"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }