import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import authentication from './modules/authentication';
import worker from './modules/worker';
import TaskManager from './modules/TaskManager';
import PlanningManager from './modules/PlanningManager';
import EmployeeManager from './modules/EmployeeManager';
import TaskBadget from './modules/TaskBadget';
import AccessControl from './modules/AccessControl';
import Reporting from './modules/reporting';
import Product from './modules/Product';
import weather from './modules/weather';
import treatment from './modules/treatment';
import fertilizationAnalysis from './modules/fertilizationAnalysis';

import costManager from './modules/CostManager';
import CalendarInitEndDate from './modules/CalendarInitEndDate';
import Scouting from './modules/Scouting';
import weatherFarm from './modules/weatherFarmFerrero';
import referenceValueStore from './modules/referenceValueStore';
import nutritionStepper from './modules/nutritionStepper';
import treatmentStepper from './modules/treatmentStepper';
import productFertilizerStore from './modules/productFertilizerStore';
import Campania from './modules/Campania';
import Explotaciones from './modules/Explotaciones';
import Clients from './modules/Clients';
import Sales from './modules/Sales';
import RegisterLocation from './modules/RegisterLocation';
import Nutrition from './modules/Nutrition';
import Filter from './modules/Filter';
import Utilities from './modules/Utilities';
import Vademecum from './modules/Vademecum';
import Converter from './modules/converter';
import weatherForecast from './modules/weatherForecast';
import Varieties from './modules/Varieties';
import Observations from './modules/Observations';
import Tasks from './modules/Tasks';
import TaskDefinition from './modules/TaskDefinition';
import Slices from './modules/Slices';
import SearchEngine from './modules/SearchEngine';
import Irrigation from './modules/Irrigation';
import Gis from './modules/Gis';
import GisEditor from './modules/GisEditor';
import TaskByCompany from './modules/TaskByCompany';
import fertilizationStore from './modules/fertilizationStore';
import SearcherObservations from './modules/SearcherObservations';
import Plantation from './modules/Plantation';
import RegisterPlantation from './modules/RegisterPlantation';
import Parcel from './modules/Parcel';
import Sector from './modules/Sector';
import Registers from './modules/Registers';
import Plans from './modules/Plans';
import databaseSync from './modules/database/sync';

// Filters
import Filters from './modules/Filters/Filters';
import SearcherFilters from './modules/Filters/SearcherFilters';

// Pagination
import Pagination from './modules/pagination/pagination';

// Reports from Gis page
import ReportGis from './modules/ReportGis';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    worker,
    AccessControl,
    EmployeeManager,
    TaskManager,
    PlanningManager,
    TaskBadget,
    Reporting,
    Product,
    weather,
    // Analysis modules
    treatment,
    fertilizationAnalysis,
    costManager,
    CalendarInitEndDate,
    Scouting,
    weatherFarm,
    Campania,
    Explotaciones,
    Clients,
    Sales,
    RegisterLocation,
    Nutrition,
    Filter,
    Utilities,
    Vademecum,
    Converter,
    weatherForecast,
    Varieties,
    Observations,
    Tasks,
    TaskDefinition,
    Slices,
    referenceValueStore,
    nutritionStepper,
    treatmentStepper,
    productFertilizerStore,
    SearchEngine,
    Irrigation,
    Gis,
    TaskByCompany,
    fertilizationStore,
    SearcherObservations,
    Plantation,
    GisEditor,
    Parcel,
    Sector,
    RegisterPlantation,
    Registers,
    Plans,
    databaseSync,
    Filters,
    SearcherFilters,
    Pagination,
    ReportGis
  },
  plugins: [new VuexPersistence({
    storage: window.localStorage,
    modules: ['authentication', 'CalendarInitEndDate'],
  }).plugin],
});

store.dispatch('authentication/initialize');

export default store;
