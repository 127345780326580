<template>
  <div class="step-two-table">
    <DxDataGrid
      class="data-grid-products"
      :data-source="filteredProducts"
      :show-borders="true"
    >
      <DxColumn
        data-field="product"
        :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.product')"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="agent"
        :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent')"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="dose_type"
        :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.dosage')"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.treatmentType')
        "
        cell-template="selectColumnTemplate"
        :width="136"
      />

      <template #selectColumnTemplate="{ data }">
        <div>
          <DxSelectBox
            v-if="!readOnly"
            :items="treatmentTypes"
            v-model="data.data.treatment_type"
          />
          <span v-else>{{ data.data.treatment_type }}</span>
        </div>
      </template>
    </DxDataGrid>

    <DxDataGrid
      class="data-grid-products-data"
      :data-source="filteredProducts"
      :column-auto-width="true"
      :column-min-width="100"
      :show-borders="true"
      :onCellPrepared="onCellPrepared"
      @row-updated="onRowUpdated"
    >
      <DxColumn
        data-field="dose_volume"
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa')
        "
        :allow-sorting="false"
        :calculate-display-value="getDoseVolumeValueFirstType"
      />
      <DxColumn
        data-field="dose_volume"
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL')
        "
        :allow-sorting="false"
        :calculate-display-value="getDoseVolumeValueSecondType"
      />
      <DxColumn
        data-field="broth_volume"
        :caption="
          $t(
            'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa'
          )
        "
        :allow-sorting="false"
      />
      <DxColumn
        data-field="price"
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL')
        "
        :allow-sorting="false"
      />
      <DxColumn
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costHa')
        "
        :allow-sorting="false"
        :allow-editing="false"
        css-class="disabled-column"
        :calculate-cell-value="getCostHaValue"
      />
      <DxColumn
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal')
        "
        :allow-sorting="false"
        :allow-editing="false"
        css-class="disabled-column"
        :calculate-cell-value="getDoseTotal"
      />
      <DxColumn
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.cost')
        "
        :allow-sorting="false"
        :allow-editing="false"
        css-class="disabled-column"
        :calculate-cell-value="getCostValue"
      />
      <DxColumn
        :caption="
          $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothTotalHa')
        "
        :allow-sorting="false"
        :allow-editing="false"
        css-class="disabled-column"
        :calculate-cell-value="getTotalBrothVolume"
      />
      <DxEditing v-if="!readOnly" :allow-updating="true" mode="cell" />
    </DxDataGrid>

    <DxDataGrid
      class="data-grid-buttons"
      :data-source="filteredProducts"
      :show-borders="true"
    >
      <DxColumn cell-template="buttonsColumnTemplate" alignment="center" />

      <template #buttonsColumnTemplate="{ data }">
        <div class="column-buttons">
          <DxButton
            styling-mode="text"
            type="success"
            @click="viewProduct(data)"
            icon="find"
          />

          <DxButton
            v-if="!readOnly"
            styling-mode="text"
            type="danger"
            @click="deleteItem(data)"
            icon="trash"
          />
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxDataGrid, DxColumn, DxEditing } from "devextreme-vue/data-grid";
import { mapActions, mapState } from "vuex";
import DxButton from "devextreme-vue/button";

export default {
  name: "treatmentStepTwoTableDefault",
  components: {
    DxSelectBox,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
  },
  props: {
    period: {
      type: Object,
      default: () => {},
    },
    selectedIndex: Number,
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      ownerAgent: "TIMAC",
      ownerAgentAll: "TODOS",
      treatmentTypes: ["FOLIAR", "RADICULAR"],
    };
  },
  computed: {
    ...mapState("treatmentStepper", ["treatmentPlan"]),
    ...mapState("Plantation", ["currentPlantation"]),
    filteredProducts() {
      return this.period.products.filter((item) => item.active !== false);
    },
  },
  methods: {
    onRowUpdated(e) {
      const { broth_volume } = e.data
      this.period.products = this.period.products.map(item => {
        item.broth_volume = broth_volume
        return item;
      })
    },
    deleteItem(item) {
      let indexPeriod = 0;
      let count = this.selectedIndex;

      for (const period of this.treatmentPlan.periods) {
        const doseVolumeNotEmpty = period.products.some(
          ({ dose_volume, active }) => (dose_volume > 0 && active)
        );

        if (doseVolumeNotEmpty) {
          if (count === 0) {
            break;
          }

          count--;
        }
        indexPeriod++;
      }

      const index = item.rowIndex;
      const products = this.treatmentPlan.periods[indexPeriod].products;

      let trueIndex = index;

      for (let i = index; i < products.length; i++) {
        if (products[i].active === false) {
          trueIndex++;
        } else {
          break;
        }
      }

      this.treatmentPlan.periods[indexPeriod].products[
        trueIndex
      ].active = false;
      this.treatmentPlan.periods[indexPeriod].products[
        trueIndex
      ].dose_volume = 0;
    },
    async viewProduct(item) {
      const product = this.treatmentPlan.products[item.rowIndex];

      if (product.agent === this.ownerAgent) {
        this.$emit("owner", this.ownerAgent);

        await this.getNutritionalProductDetail(product.id);
      } else if (product.agent === this.ownerAgentAll) {
        this.$emit("owner", this.ownerAgentAll);

        await this.getPhytosanitaryProductDetail({
          id: product.id,
          crop: this.currentPlantation.species,
        });
      } else {
        this.$emit("owner", "");

        await this.getPhytosanitaryProductDetail({
          id: product.id,
          crop: this.currentPlantation.species,
          agent: product.agent,
        });
      }

      this.setDisplayProductDetailPopup(true);
    },
    onCellPrepared(e) {
      if (
        e.rowType === "data" &&
        (e.columnIndex === 0 || e.columnIndex === 1)
      ) {
        if (e.columnIndex === 0 && e.data.dose_type === "kG-L/1000L") {
          e.cellElement.classList.add("disabled-column");
          e.cellElement.style.pointerEvents = "none";
        }

        if (e.columnIndex === 1 && e.data.dose_type === "kG-L/HA") {
          e.cellElement.classList.add("disabled-column");
          e.cellElement.style.pointerEvents = "none";
        }
      }
    },
    getDoseVolumeValueFirstType(data) {
      const type = "kG-L/HA";

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      return this.calculateFormula(2, data);
    },
    getDoseVolumeValueSecondType(data) {
      const type = "kG-L/1000L";

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      return this.calculateFormula(1, data);
    },
    getCostHaValue(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);

      return this.calculateFormula(3, {
        dose_volume,
        price: data.price,
      });
    },
    getCostValue(data) {
      const cost_ha = this.getCostHaValue(data);

      return this.calculateFormula(4, {
        cost_ha,
        area: this.currentPlantation.area,
      });
    },
    getTotalBrothVolume(data) {
      return this.calculateFormula(5, {
        broth_volume: data.broth_volume,
        area: this.currentPlantation.area,
      });
    },
    getDoseTotal(data) {
      return this.calculateFormula(10, {
        dose_volume: this.getDoseVolumeValueFirstType(data),
        area: this.currentPlantation.area,
      });
    },
    calculateFormula(type, data) {
      let result = 0;
      // Dosis (kg-L/1000L)= Dosis (kg-L/ha)/(Vol. Caldo (l/ha)/1000)
      if (type === 1) {
        result = data.dose_volume / (data.broth_volume / 1000);
      }

      // Dosis (kg-L/ha) =Dosis (kg-L/1000L) * (Vol. Caldo (l/ha)/1000)
      if (type === 2) {
        result = data.dose_volume * (data.broth_volume / 1000);
      }

      // Coste/ha = Dosis kg-L/ha * Precio €/Kg-L
      if (type === 3) {
        result = data.dose_volume * data.price;
      }

      // Coste = coste (€/ha) * superficie (ha)
      if (type === 4) {
        result = data.cost_ha * data.area;
      }

      // Vol caldo total = Vol caldo (L/ha) *  Superficie (ha)
      if (type === 5) {
        result = data.broth_volume * data.area;
      }

      // Dosis kg-L Total = Dosis (kg-l/ha) * Superficie (ha)
      if (type === 10) {
        result = data.dose_volume * data.area;
      }

      const fixedResult = Number(result.toFixed(3));

      if (isNaN(fixedResult) || !isFinite(fixedResult)) {
        return 0;
      } else {
        return fixedResult;
      }
    },
    ...mapActions("Vademecum", [
      "getPhytosanitaryProductDetail",
      "getNutritionalProductDetail",
    ]),
    ...mapActions("treatment", ["setDisplayProductDetailPopup"]),
  },
};
</script>

<style src="./treatmentStepTwoTables.styles.scss" scoped lang="scss"></style>