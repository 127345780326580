<template>
  <div class="content-devx">
    <f7-block-title>
      {{ $t("Searcher.nutritionalPlanActive") }}
    </f7-block-title>
    <div v-if="!loaded" class="loadingFalse" style="height: 36px;">
      <div class="preloader" />
    </div>
    <DxDataGrid
      v-else
      class="data-grid-reference-value"
      :remote-operations="true"
      :data-source="lastNutritionPlan"
      :column-auto-width="true"
      :show-borders="true"
    >
      <DxColumn
        :min-width="85"
        data-field="production"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.production')"
      />
      <DxColumn
        :min-width="85"
        data-field="cost"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.cost')"
      />
      <DxColumn
        :min-width="85"
        data-field="n"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.n')"
      />
      <DxColumn
        :min-width="85"
        data-field="p205"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.p')"
      />
      <DxColumn
        :min-width="85"
        data-field="k20"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.k')"
      />
      <DxColumn
        :min-width="85"
        style="text-transform: none !important;"
        data-field="ca0"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.c')"
      />
      <DxColumn
        :min-width="85"
        data-field="mg0"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.m')"
      />
      <DxColumn
        :min-width="85"
        data-field="s03"
        data-type="number"
        :allow-sorting="false"
        :caption="$t('nutritionalList.s')"
      />
      <DxColumn
        type="buttons"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton name="find" icon="find" :on-click="viewItem" />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import settings from "../../../../js/commonSettings";
import fertilizationService from "../../../../services/fertilizationService";

import {
  DxDataGrid,
  DxPaging,
  DxPager,
  DxColumn,
  DxButton,
} from "devextreme-vue/data-grid";
import { mapActions, mapState } from "vuex";

export default {
  name: "LastNutritionPlanActive",
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      lastNutritionPlan: [],
    };
  },
  computed: {
    ...mapState("Plantation", ["currentPlantation"]),
    gridSettings() {
      return settings.datagrid;
    },
  },
  async beforeMount() {
    try {
      const { count } = await fertilizationService.getLast(
        this.currentPlantation.companyId,
        this.currentPlantation.id,
        1,
        0
      );

      const { data } = await fertilizationService.getLast(
        this.currentPlantation.companyId,
        this.currentPlantation.id,
        1,
        count - 1
      );

      this.lastNutritionPlan = data;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 450,
        },
        'error',
        3000,
      );
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions("fertilizationStore", ["setCurrentFertilizationId"]),
    viewItem(e) {
      this.setCurrentFertilizationId(e.row.data.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: "utilities_nutrition_nutrition-detail",
        params: { id: e.row.data.id },
      });
      this.$f7.views.main.router.navigate(url, { reloadCurrent: true });
    },
  },
};
</script>
