<template>
  <div
    class="content-devx"
  >
    <DxDataGrid
      id="plantationsDataGrid-datagrid"
      :data-source="plantationsAvailables"
      :remote-operations="true"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="plantationsDataGrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="5" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="species"
        :caption="$t('plantations.grid.botanicSpecie')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="campaign"
        :caption="$t('plantations.grid.campaign')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="init_date"
        :caption="$t('plantations.grid.initDate')"
        :allow-header-filtering="false"
        alignment="left"
        :customize-text="dateFormatting"
      />
      <DxColumn
        data-field="end_date"
        :caption="$t('plantations.grid.endDate')"
        :allow-header-filtering="false"
        alignment="left"
        :customize-text="dateFormatting"
      />
      <DxColumn
        data-field="status"
        :caption="$t('plantations.grid.status')"
        alignment="center"
        :header-filter="{ dataSource: filterStatus }"
        cell-template="statusTemplate"
      />
      <DxColumn
        type="buttons"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="viewPlantationDetail"
        />
      </DxColumn>
      <template #titlePlantations>
        <f7-block-title>
          {{ $t('plantations.grid.plantations') }}
        </f7-block-title>
      </template>
      <template #statusTemplate="{ data }">
        <div :class="setClassByStatus(data.data.status)">
          {{ data.data.status }}
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import PopUpSelectCampaign from './popUpSelectParcelSector/index.vue';
import settings from '../../../js/commonSettings';

export default {
  name: 'PlantationsDataGrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    PopUpSelectCampaign,
  },
  props: {
    plantationsAvailables: {
      type: Array,
      default: () => [],
    },
    currentClientId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      filterStatus: [{
        text: this.$t('plantations.grid.active'),
        value: this.$t('plantations.grid.active'),
      }, {
        text: this.$t('plantations.grid.end'),
        value: this.$t('plantations.grid.end'),
      },
      {
        text: this.$t('plantations.grid.pending'),
        value: this.$t('plantations.grid.pending'),
      }],
      actualDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Sector', ['currentSectorId', 'currentSector']),
    ...mapState('Parcel', ['currentParcelId']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('Campania', ['actual_Plot']),
  },
  async beforeMount() {
    this.setCampaignId('');
    try {
      await this.getPlantationByCompany(this.currentClientId);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    async viewPlantationDetail(e) {
      this.setCurrentPlantation(e.row.data.id);
      this.setIdPlantation(e.row.data.id);
      this.setSectorArea(this.currentSector.area);
      this.setIsView(true);
      this.setIsEdit(false);
      this.setIsPlantationCreated(false);
      this.$f7.views.main.router.navigate('/ViewPlantation/', { reloadCurrent: true });
    },
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case this.$t('plantations.grid.active'):
          colorCell = 'Activa';
          break;
        case this.$t('plantations.grid.end'):
          colorCell = 'Finalizada';
          break;
        case this.$t('plantations.grid.pending'):
          colorCell = 'Pendiente';
          break;
        default:
          break;
      }
      return colorCell;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'titlePlantations',
      }, {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          text: this.$t('plantations.grid.createPlantation'),
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.setIsView(false);
            this.setActualCampaign({});
            this.fillDataParcelSectorAndSetParcelSector();
            this.$f7.views.main.router.navigate('/RegisterPlantationStep1/', { reloadCurrent: true });
          },
        },
      });
    },
    async fillDataParcelSectorAndSetParcelSector() {
      this.setSectorSelected(this.currentSectorId);
      this.setParcelSelected(this.currentParcelId);
      if (typeof this.actualLocation.id === 'undefined' || this.actualLocation.id === null) {
        this.setActualPlot(this.actualLocation.bdId);
      } else {
        this.setActualPlot(this.actualLocation.id);
      }
      this.setSectorArea(this.currentSector.area);
      this.setIsEdit(false);
      try {
        let data = {
          companyId: this.actualUser.id,
          explotation: this.actual_Plot,
        };
        await this.getParcelByExplotation(data);
        data = {
          companyId: this.actualUser.id,
          parcel: this.currentParcelId,
        };
        await this.getSectorByParcel(data);
        await this.getListExplotation();
        await this.getCampaignsByCompany();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Plantations');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Plantations.xlsx');
          });
      });
      e.cancel = true;
    },
    dateFormatting(date) {
      return moment(date.value).format('DD/MM/YYYY');
    },
    ...mapActions('Plantation', ['setCurrentPlantation', 'setIdPlantation']),
    ...mapActions('Campania', ['getPlantationByCompany', 'setActualPlot', 'getListExplotation', 'getCampaignsByCompany', 'setShowPopUp', 'setIsView', 'setActualCampaign', 'setCampaignId']),
    ...mapActions('RegisterPlantation', ['setSectorSelected', 'setParcelSelected', 'setSectorArea', 'getSectorByParcel', 'getParcelByExplotation', 'setIsEdit', 'setIsPlantationCreated']),
  },
};
</script>
<style lang="scss" scoped>
@import './PlantationsDataGrid.styles.scss';
</style>
