<template>
  <f7-page class="main-viewlaboratoryanalysis">
    <navbar :text="''" />
    <ViewDetailsLaboratoryAnalysis />
    <div class="go-back-button margin-left margin-top">
      <f7-button
        class="dx-btn-cancel no-margin padding-horizontal"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToBack()"
      >
        {{ $t("Button_go_to_back") }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import ViewDetailsLaboratoryAnalysis from '../../components/laboratoryNutrition/viewLaboratoryAnalysis/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'DetailsLaboratoryAnalysis',
  components: {
    ViewDetailsLaboratoryAnalysis,
    navbar,
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
    },
  },
};
</script>
<style lang="sass">
.main-viewlaboratoryanalysis{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>
