<template>
  <f7-page>
    <div v-if="loaded">
      <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Tasks_Title')}`" />
      <TaskDetail :task-definition-detail="taskDefinitionDetail" />
      <f7-block-footer
        class="go-back-button"
      >
        <f7-button
          class="dx-btn-cancel btnBack"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goToTasks"
        >
          {{ $t("Go_Back") }}
        </f7-button>
      </f7-block-footer>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import TaskDetail from '../../../components/configuration/tasks/taskDetail/index.vue';

export default {
  name: 'ViewTaskDetail',
  components: {
    TaskDetail,
    navbar,
  },
  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    ...mapState('TaskDefinition', ['taskDefinitionDetail']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getTaskDefinitionDetail();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToTasks() {
      this.$f7.views.main.router.navigate('/tasks/', { reloadCurrent: true });
    },
    ...mapActions('TaskDefinition', ['getTaskDefinitionDetail']),

  },
};
</script>
