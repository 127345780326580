<template>
  <f7-page
    @page:beforein="beforein"
  >
    <div v-if="loaded">
      <navbar
        v-if="(!isView || isEdit) && !isFromCampaign"
        :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualLocation.name ? actualLocation.name : actualPlantation.explotation} > ${currentParcel.name ? currentParcel.name : actualPlantation.parcel} > ${currentSector.sectorName ? currentSector.sectorName : actualPlantation.farms[0].name} > ${actualCampaign.name ? actualCampaign.name : actualPlantation.campaign} > ${$t('registerPlantation.title_register_plantation')}`"
      />
      <navbar
        v-else
        :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualCampaign ? actualCampaign.name + ' > ' : ''} ${$t('registerPlantation.title_register_plantation')}`"
      />
    </div>
    <RegisterPlantationStep2
      :protection-types="protectionTypes"
      :management-types="managementTypes"
      :crop-types="cropTypes"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="createNewPlantation"
        >
          {{ $t("Button_go_create_new_Campania") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegisterPlantationStep2 from '../../../../components/plantation/registerPlantation/registerPlantationStep2/index.vue';
import navbar from '../../../../components/NavBar.vue';

export default {
  name: 'RegisterPlantationPageStep2',
  components: {
    RegisterPlantationStep2,
    navbar,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['protectionTypes', 'managementTypes', 'cropTypes', 'speciesAvailables', 'varieties', 'cropTypeSelected',
      'managementTypeSelected', 'protectionTypeSelected', 'plantationName', 'initDate', 'endDate', 'specieSelected', 'varietySelected', 'plantsNumber',
      'plantDensity', 'seedDensity', 'plantingUnitSelected', 'mulUno', 'mulDos', 'plantationDate', 'isEdit', 'actualPlantation']),
    ...mapState('Campania', ['isView', 'actualCampaign', 'campaignId', 'parcelSelected', 'isFromCampaign']),
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Sector', ['currentSector']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    currentCampaignName() {
      const currentCampaign = this.allcampaign.filter((campaign) => campaign.id === this.campaignId);

      return currentCampaign[0].name;
    },
  },
  async beforeMount() {
    if (this.isEdit) {
      await this.getCampaignsById(this.actualCampaign.id);
    } else {
      await this.getCampaignsById(this.campaignId);
    }
    this.loaded = true;
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate('/RegisterPlantationStep1/', { reloadCurrent: true });
    },
    async beforein() {
      this.$f7.preloader.show();
      try {
        if (!this.isEdit) {
          this.resetSpecieAvailablesAndVariety();
          this.initStore();
        }
        await this.getSelectBoxOptions();
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async createNewPlantation() {
      if (this.plantationName === '') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_plantation_name'));
        return;
      }
      if (this.initDate === '' || this.initDate === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_init_date'));
        return;
      }
      if (this.initDate > this.endDate) {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_endDate_bigger'));
        return;
      }
      if (this.cropTypeSelected === '') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_cropType'));
        return;
      }
      if (this.managementTypeSelected === '') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_plantation_management'));
        return;
      }
      if (this.protectionTypeSelected === '') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_protection'));
        return;
      }
      if (this.endDate === '' || this.endDate === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_end_date'));
        return;
      }
      if (this.init_date > this.end_date) {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_inicio_mayor_validation'));
        return;
      }
      if (this.specieSelected === '') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_specie_selected'));
        return;
      }
      if (this.varietySelected === '') {
        this.$f7.dialog.alert(this.$t('registerPlantation.required_plantation_variety'));
        return;
      }
      if (this.cropTypeSelected !== '') {
        switch (this.cropTypeSelected) {
          case 'HERBÁCEO':
            if (this.plantingUnitSelected === '') {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_unit'));
              return;
            }
            if (this.seedDensity === 0) {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_plants_density'));
              return;
            }
            break;
          case 'LEÑOSO':
            if (this.mulUno === 0) {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_distance_between_streets'));
              return;
            }
            if (this.mulDos === 0) {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_distance_between_feets'));
              return;
            }
            if (this.plantDensity === 0) {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_plants_density'));
              return;
            }
            if (this.cupDiameter === 0) {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_cupDiameter'));
              return;
            }
            if (this.plantationDate === '') {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_date_plantation'));
              return;
            }
            break;
          case 'HORTÍCOLA':
          default:
            if (this.mulUno === null || this.mulUno === 0) {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_distance_between_streets'));
              return;
            }
            if (this.mulDos === null || this.mulDos === 0) {
              this.$f7.dialog.alert(this.$t('registerPlantation.required_distance_between_feets'));
              return;
            }
            break;
        }
      }
      try {
        const data = {
          clientId: this.actualUser.id,
          campaignId: this.campaignId === '' ? this.actualPlantation.campaign_id : this.campaignId,
          plantationId: this.actualPlantation.id,
        };

        if (this.actualCampaign.id) {
          data.campaignId = this.actualCampaign.id;
        }

        if (this.initDate < this.actualCampaign.init_date) {
          this.$f7.dialog.alert(this.$t('registerPlantation.required_init_date_be_in_range'));
          return;
        }

        if (this.endDate > this.actualCampaign.end_date) {
          this.$f7.dialog.alert(this.$t('registerPlantation.required_end_date_be_in_range'));
          return;
        }

        await this.createPlantation(data);
        this.setShowPopUp(true);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(error));
      }
    },
    ...mapActions('RegisterPlantation', ['setManejo', 'setProtected', 'setTypeCultive', 'getSelectBoxOptions', 'initStore', 'setShowPopUp',
      'getParcelByExplotation', 'createPlantation', 'resetSpecieAvailablesAndVariety', 'setActualPlantation',
      'setParcelSelected', 'setSectorSelected']),
    ...mapActions('Campania', ['getCampaignsById']),
  },
};
</script>
