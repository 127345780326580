var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-two-table"},[_c('DxDataGrid',{staticClass:"data-grid-products",attrs:{"data-source":_vm.filteredProducts,"show-borders":true},scopedSlots:_vm._u([{key:"selectColumnTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',[(!_vm.readOnly)?_c('DxSelectBox',{attrs:{"items":_vm.treatmentTypes},model:{value:(data.data.treatment_type),callback:function ($$v) {_vm.$set(data.data, "treatment_type", $$v)},expression:"data.data.treatment_type"}}):_c('span',[_vm._v(_vm._s(data.data.treatment_type))])],1)]}}])},[_c('DxColumn',{attrs:{"data-field":"product","caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.product'),"allow-sorting":false,"allow-editing":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"agent","caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.plagueAgent'),"allow-sorting":false,"allow-editing":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"dose_type","caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.dosage'),"allow-sorting":false,"allow-editing":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.treatmentType'),"cell-template":"selectColumnTemplate","width":136}})],1),_vm._v(" "),_c('DxDataGrid',{staticClass:"data-grid-products-data",attrs:{"data-source":_vm.filteredProducts,"column-min-width":100,"column-auto-width":true,"show-borders":true,"onCellPrepared":_vm.onCellPrepared},on:{"row-updated":_vm.onRowUpdated}},[_c('DxColumn',{attrs:{"data-field":"dose_volume","caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa'),"allow-sorting":false,"calculate-display-value":_vm.getDoseVolumeValueFirstType}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"dose_volume","caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL'),"allow-sorting":false,"calculate-display-value":_vm.getDoseVolumeValueSecondType}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"broth_volume","caption":_vm.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa'
        ),"allow-sorting":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTree'),"allow-sorting":false,"allow-editing":false,"css-class":"disabled-column","calculate-cell-value":_vm.getDoseTree}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t(
          'treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeTree'
        ),"allow-sorting":false,"allow-editing":false,"css-class":"disabled-column","calculate-cell-value":_vm.getBrothTree}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"price","caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL'),"allow-sorting":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceTree'),"allow-sorting":false,"allow-editing":false,"css-class":"disabled-column","calculate-cell-value":_vm.getPriceTree}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal'),"allow-sorting":false,"allow-editing":false,"css-class":"disabled-column","calculate-cell-value":_vm.getDoseTotal}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal'),"allow-sorting":false,"allow-editing":false,"css-class":"disabled-column","calculate-cell-value":_vm.getCostTotal}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothTotal'),"allow-sorting":false,"allow-editing":false,"css-class":"disabled-column","calculate-cell-value":_vm.getTotalBroth}}),_vm._v(" "),(!_vm.readOnly)?_c('DxEditing',{attrs:{"allow-updating":true,"mode":"cell"}}):_vm._e()],1),_vm._v(" "),_c('DxDataGrid',{staticClass:"data-grid-buttons",attrs:{"data-source":_vm.filteredProducts,"show-borders":true},scopedSlots:_vm._u([{key:"buttonsColumnTemplate",fn:function(ref){
        var data = ref.data;
return [_c('div',{staticClass:"column-buttons"},[_c('DxButton',{attrs:{"styling-mode":"text","type":"success","icon":"find"},on:{"click":function($event){return _vm.viewProduct(data)}}}),_vm._v(" "),(!_vm.readOnly)?_c('DxButton',{attrs:{"styling-mode":"text","type":"danger","icon":"trash"},on:{"click":function($event){return _vm.deleteItem(data)}}}):_vm._e()],1)]}}])},[_c('DxColumn',{attrs:{"cell-template":"buttonsColumnTemplate","alignment":"center"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }