<template>
  <div class="vat-forms margin-bottom">
    <f7-row class="align-items-center">
      <f7-col width="5"></f7-col>
      <f7-col width="25">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatVolume') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox v-model="treatmentPlan.vat_volume" />
          </div>
          <div class="margin-right margin-left">L</div>
        </div>
      </f7-col>

      <f7-col width="20">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumberHa') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox :value="getNumberVatsHa" :read-only="true" />
          </div>
        </div>
      </f7-col>

      <f7-col width="20">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumber') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox :value="getNumberVats" :read-only="true" />
          </div>
        </div>
      </f7-col>

      <f7-col width="30">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox v-model="treatmentPlan.forward_speed" />
          </div>
          <div class="margin-right margin-left">km/h</div>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";
import DxTextBox from "devextreme-vue/text-box";
import DxNumberBox from "devextreme-vue/number-box";
import { mapState } from "vuex";

export default {
  name: "TreatmentStepTwoFormVat",
  components: {
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
  },
  props: {
    period: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState("RegisterPlantation", ["actualPlantation"]),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState("treatmentStepper", ["treatmentPlan"]),
    getNumberVatsHa() {
      const broth_volume = this.period.products[0]?.broth_volume ?? 0;

      return this.calculateFormula(1, {
        broth_volume: broth_volume,
        vat_volume: this.treatmentPlan.vat_volume,
      });
    },
    getNumberVats() {
      const number_vats_ha = this.getNumberVatsHa;

      return this.calculateFormula(2, {
        number_vats_ha,
        area: this.currentPlantation.area,
      });
    },
  },
  methods: {
    calculateFormula(type, data) {
      let result = 0;

      // Nº de cubas/ha = Volumen de caldo (L/ha) / Volumen de cuba (L)
      if (type === 1) {
        result = data.broth_volume / data.vat_volume;
      }

      // Nº de cubas = Nº de cubas/ha * Superficie (ha)
      if (type === 2) {
        result = data.number_vats_ha * data.area;
      }

      const fixedResult = Number(result.toFixed(3));

      if (isNaN(fixedResult) || !isFinite(fixedResult)) {
        return 0;
      } else {
        return fixedResult;
      }
    },
  },
};
</script>

<style src="./TreatmentStepTwoForms.styles.scss" scoped lang="scss"></style>