var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx no-padding-top no-padding-bottom"},[_c('f7-block',{staticClass:"no-padding"},[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('title_register_plantation'),"align-item-labels-in-all-groups":false}},[_c('DxGroupItem',{attrs:{"col-count":3}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.specie_botanic'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.speciesAvailables,
            value: _vm.specieSelected,
            showClearButton: true,
            searchEnabled:true,
            onValueChanged: _vm.onChangeSpecieBotanic,
          }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.variety'),"isRequired":true},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.varietiesPaginated,"onValueChanged":_vm.onChangeVariety,"value":_vm.varietySelected,"displayExpr":"name","valueExpr":"name","searchEnabled":true}})]},proxy:true}])}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('registerPlantation.distance_between_streets')) + " (m)"),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.mulUno,
            onValueChanged: _vm.onChangeMulUno,
          },"isRequired":true}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('registerPlantation.distance_between_feets')) + " (m)"),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.mulDos,
            onValueChanged: _vm.onChangeMulDos,
          },"isRequired":true}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('registerPlantation.plants_density')) + "(" + (_vm.$t('registerPlantation.unit_plantas_ha')) + ")"),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.calculatePlantsDensity,
            onValueChanged: _vm.onChangePlantDensity,
            readOnly: true,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.plants_number'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.calculatePlantsNumber,
            onValueChanged: _vm.onChangePlantsNumber,
            readOnly: false,
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }