<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Page_subtitle_product')}`" />
    <ProductsDatabase />
  </f7-page>
</template>

<script>
import ProductsDatabase from '../../../components/configuration/products/productsDatabase/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Products',
  components: {
    ProductsDatabase,
    navbar,
  },
};
</script>
