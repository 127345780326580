<template>
  <section class="element-resume">
    <div class="content-devx">
      <div class="card-header">
        {{ $t('elementResume.elementResume') }}
      </div>
      <div class="card-body margin-left-block margin-right-block margin-bottom-block">
        <section class="top">
          <DxDataGrid
            class="data-grid-balance-value"
            :data-source="elementResume"
            :show-borders="true"
          >
            <DxColumn
              data-field="uf"
              data-type="number"
              :caption="$t('dosageProduct.uf')"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.n')"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.p')"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.k')"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.c')"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.m')"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.s')"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
            />
          </DxDataGrid>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import {
  DxColumn, DxDataGrid,
} from 'devextreme-vue/data-grid';
import { mapGetters } from 'vuex';

export default {
  name: 'ElementResume',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: [],
  data() {
    return {
      elementResume: [],
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
  },
  mounted() {
    this.elementResume.push(this.addRowDataSource('Nec. nutricionales', this.stepData.nutritionalBalanceData[0]));
    this.elementResume.push(this.addRowDataSource('Balance previo', this.stepData.nutritionalBalanceData[5]));
    this.elementResume.push(this.addRowDataSource('UF aportadas', this.calculateUfContributed()));

    this.elementResume.push(this.addRowDataSource('Balance final',
      this.calculateFinalBalance()));
  },
  methods: {
    calculateUfContributed() {
      const ufContributed = {
        n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };
      this.stepData.timeValues.forEach((item, index) => {
        let totalProps = 0;
        for (const prop in item) {
          totalProps += item[prop];
        }
        this.stepData.dosages[index].dosage = totalProps;
      });

      this.stepData.dosageProduct.forEach((item, index) => {
        for (const prop in item) {
          ufContributed[prop] += this.calculateElementBalance(index, prop);
        }
      });

      return ufContributed;
    },
    calculateElementBalance(index, prop) {
      return Math.round((this.stepData.dosageProduct[index][prop] / 100)
          * this.stepData.dosages[index].dosage);
    },
    addRowDataSource(titleColumn, element) {
      return {
        uf: titleColumn,
        n: this.fixNumberTo(element.n, 3),
        p: this.fixNumberTo(element.p, 3),
        k: this.fixNumberTo(element.k, 3),
        c: this.fixNumberTo(element.c, 3),
        m: this.fixNumberTo(element.m, 3),
        s: this.fixNumberTo(element.s, 3),
      };
    },
    calculateFinalBalance() {
      return {
        n: this.fixNumberTo(this.elementResume[1].n + this.elementResume[2].n, 3),
        p: this.fixNumberTo(this.elementResume[1].p + this.elementResume[2].p, 3),
        k: this.fixNumberTo(this.elementResume[1].k + this.elementResume[2].k, 3),
        c: this.fixNumberTo(this.elementResume[1].c + this.elementResume[2].c, 3),
        m: this.fixNumberTo(this.elementResume[1].m + this.elementResume[2].m, 3),
        s: this.fixNumberTo(this.elementResume[1].s + this.elementResume[2].s, 3),
      };
    },
    fixNumberTo(number, digits) {
      return parseFloat(parseFloat(number).toFixed(digits));
    },
  },

};

</script>
<style src="./elementResume.scss" scoped lang="scss"></style>
