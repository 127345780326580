<template>
  
  <section class="dosage-product">
    <PopupProductAnnotation v-if="showAnnotationProduct" :product="annotationProduct" @close="closeAnnotationProduct" @save="save" />
    <PopupProductDetail v-if="showProductDetail" @close="showProductDetail = false" />
    <div class="content-devx">
      <div class="card-header">
        {{ $t('dosageProduct.title') }}
      </div>
      <div
        class="card-body margin-left-block margin-right-block margin-bottom-block"
      >
        <section class="top">
          <DxDataGrid
            class="data-grid-balance-value"
            :data-source="dosageProductBalance"
            :show-borders="true"
          >
            <DxColumn
              data-field="uf"
              data-type="number"
              :caption="$t('dosageProduct.uf')"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.n')"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.p')"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.k')"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.c')"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.m')"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.s')"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
            />
          </DxDataGrid>
        </section>
        <section class="search margin-top-block margin-bottom-block">
          <div class="dx-field product-finder justify-content-end">
            <div class="margin-right-block">
              {{ $t('dosageProduct.searchFertilizer') }}
            </div>
            <div class="margin-right-block width300">
              <DxAutocomplete
                :data-source="productFertilizers"
                :value="productFertilizerSelectedValue"
                item-template="product-name"
                :placeholder="$t('dosageProduct.searchFertilizerPlaceholder')"
                :open-on-field-click="true"
                :show-drop-down-button="true"
                :search-expr="['name', 'code']"
                value-expr="id"
                :show-clear-button="true"
                @value-changed="selectProductItem"
              >
                <template #product-name="{ data }">
                  <span>
                    <span class="text-color-gray">{{ data.code }}</span> -
                    {{ data.name }}
                  </span>
                </template>
              </DxAutocomplete>
            </div>
            <DxButton
              :text="$t('dosageProduct.add')"
              type="default"
              @click="addProductFertilizer"
            />
          </div>
        </section>
        <section class="bottom">
          <DxDataGrid
            class="data-grid-dosage-product"
            :data-source="stepData.dosageProduct"
            :show-borders="true"
            @cellHoverChanged="cellHoverChanged"
          >
            <DxColumn
              data-field="name"
              :caption="$t('dosageProduct.uf')"
              :allow-sorting="false"
              cell-template="dosageProductName"
            />
            <template #dosageProductName="{ data }">
              <div>
                <div
                  v-if="data.data.product_deleted"
                >
                  <span :id="'alert-' + data.rowIndex">
                    <img
                      :src="getIconPath + 'alert.svg'"
                      alt="Alert icon"
                      @mouseenter="togglePopUpInfoDotsVisible"
                      @mouseleave="togglePopUpInfoDotsVisible"
                    />
                    <span>{{ data.data.name }}</span>
                  </span>

                  <DxPopover
                    :visible="visible && currentRowIndex === data.rowIndex"
                    :animation="animationConfig"
                    :target="'#alert-' + data.rowIndex"
                    :position="position"
                  >
                    <div class="padding-10">
                      {{ $t('productDeleted') }}
                    </div>
                  </DxPopover>
                </div>
                <div v-else>
                  {{ data.data.name }}
                </div>
              </div>
            </template>
            <DxColumn
              :caption="$t('dosageProduct.n') + ' (% P/P)'"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.p') + ' (% P/P)'"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.k') + ' (% P/P)'"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.c') + ' (% P/P)'"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.m') + ' (% P/P)'"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('dosageProduct.s') + ' (% P/P)'"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
            />
          </DxDataGrid>

          <DxDataGrid
            class="data-grid-time-values"
            :data-source="stepData.timeValues"
            :column-min-width="50"
            :column-auto-width="true"
            :show-borders="true"
            :onRowUpdated="onRowUpdated"
          >
            <DxSorting mode="none" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxPaging :enabled="false" />
            <DxEditing :allow-updating="true" mode="cell" />
          </DxDataGrid>
          <DxDataGrid
            class="data-grid-dosages"
            :data-source="stepData.dosages"
            :column-min-width="50"
            :remote-operations="true"
            :show-borders="true"
          >
            <DxColumn
              data-field="dosage"
              :caption="$t('dosageProduct.dose')"
              :allow-sorting="false"
            />
            <DxColumn
              cell-template="buttonsColumnTemplate"
              alignment="center"
            />
            <template #buttonsColumnTemplate="{ data }">
              <div class="column-buttons">
                <DxButton
                  styling-mode="text"
                  icon="edit"
                  @click="openProductAnnotations(data)"
                />
                <DxButton
                  styling-mode="text"
                  type="success"
                  icon="find"
                  @click="viewProduct(data)"
                />
                <DxButton
                  styling-mode="text"
                  type="danger"
                  icon="trash"
                  @click="deleteItem(data)"
                />
              </div>
            </template>
          </DxDataGrid>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
  import {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxScrolling,
    DxPaging,
    DxEditing,
    DxTexts
  } from 'devextreme-vue/data-grid';
  import { DxAutocomplete } from 'devextreme-vue/autocomplete';
  import DxButton from 'devextreme-vue/button';
  import DxSelectBox from 'devextreme-vue/select-box';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import { Device } from 'framework7/framework7-lite.esm.bundle';
  import { DxPopover } from 'devextreme-vue/popover';
  import PopupProductDetail from '../popupProductDetail/index.vue';
  import PopupProductAnnotation from '../PopupProductAnnotation/index.vue';

  export default {
    name: 'DosageProduct',
    components: {
      DxAutocomplete,
      DxDataGrid,
      DxColumn,
      DxButton,
      DxSelectBox,
      DxSorting,
      DxScrolling,
      DxPaging,
      DxEditing,
      DxTexts,
      PopupProductDetail,
      DxPopover,
      PopupProductAnnotation
    },
    props: [],
    data() {
      return {
        productFertilizerSelected: '',
        productFertilizerSelectedValue: '',
        showProductDetail: false,
        showAnnotationProduct: false,
        annotationProduct: null,
        environment: process.env.NODE_ENV,
        visible: false,
        currentRowIndex: 0,
        animationConfig: {
          show: {
            type: 'pop',
            from: {
              scale: 0,
            },
            to: {
              scale: 1,
            },
          },
          hide: {
            type: 'fade',
            from: 1,
            to: 0,
          },
        },
        position: 'top',
      };
    },
    computed: {
      ...mapGetters('nutritionStepper', ['stepData']),
      ...mapGetters('productFertilizerStore', ['productFertilizers']),
      ...mapState('Plantation', ['currentPlantation']),
      ...mapState('Nutrition', ['productsNotInNutritionPlan']),
      ...mapState('nutritionStepper', ['isEditing', 'editingStepData', 'isCopying', 'copyingStepData']),
      dosageProductBalance() {
        return this.stepData.dosageProductBalance.map((item) => {
          if (!item) return item;

          return {
            c: this.fixNumberTo(item.c, 3),
            k: this.fixNumberTo(item.k, 3),
            m: this.fixNumberTo(item.m, 3),
            n: this.fixNumberTo(item.n, 3),
            p: this.fixNumberTo(item.p, 3),
            s: this.fixNumberTo(item.s, 3),
            uf: item.uf,
          };
        });
      },
      getIconPath() {
        if (Device.cordova) {
          return this.environment === 'development'
            ? '../../../static/img/'
            : 'static/img/';
        }
        return this.environment === 'development'
          ? '../../../static/img/'
          : './static/img/';
      },
    },
    watch: {
      'stepData.timeValues': {
        handler(val) {
          if (val.length === 0) {
            return;
          }

          this.calcTotalBalance(val);
        },
        deep: true,
      },
    },
    created() {
      this.getProductFertilizers(this.currentPlantation.companyId);
      if (this.stepData.dosageProduct.length > 0) {
        if (this.stepData.dosageProductBalance.length === 1) {
          this.stepData.dosageProductBalance.push(
            this.getRowDataSource(this.$t('dosageProduct.finalBalance')),
          );

          this.calcTotalBalance(this.stepData.timeValues);
        }
        return;
      }
      this.stepData.dosageProductBalance = [];
      this.stepData.dosageProduct = [];
      this.stepData.timeValues = [];
      this.stepData.dosages = [];

      this.stepData.dosageProductBalance.push(
        this.stepData.nutritionalBalanceData[5],
      );
      this.stepData.dosageProductBalance.push(
        this.getRowDataSource(this.$t('dosageProduct.finalBalance')),
      );
    },
    beforeMount() {
      if (this.stepData.dosageProductBalance.length === 1) {
        this.stepData.dosageProductBalance.push(
          this.getRowDataSource(this.$t('dosageProduct.finalBalance')),
        );
      }
      this.calcTotalBalance(this.stepData.timeValues);
    },
    methods: {
      onRowUpdated(e) {
        const index = e.component.getRowIndexByKey(e.key);

        this.stepData.timeValues[index] = e.data;

        if (this.isEditing) {
          this.editingStepData.timeValues[index] = e.data;
        }

        if (this.isCopying) {
          this.copyingStepData.timeValues[index] = e.data;
        }
      },
      cellHoverChanged(e) {
        this.currentRowIndex = e.rowIndex;
      },
      selectProductItem(e) {
        if (!e.value) {
          this.productFertilizerSelected = '';
          return;
        }

        if (!e.event && e.previousValue !== this.productFertilizerSelected.id) {
          this.productFertilizerSelected = this.productFertilizers.find(
            (item) => item.id === e.value,
          );
          this.productFertilizerSelectedValue =
            this.productFertilizerSelected.name;
        }
      },
      togglePopUpInfoDotsVisible() {
        this.visible = !this.visible;
      },
      getRowDataSource(titleColumn) {
        return {
          uf: titleColumn,
          n: 0,
          p: 0,
          k: 0,
          c: 0,
          m: 0,
          s: 0,
        };
      },
      addProductFertilizer() {
        if (!this.productFertilizerSelected) {
          this.$f7.dialog.alert(this.$t('requiredNutritionFertiliser'));
          return;
        }

        const timeValues = [];
        const dateStart = moment(this.stepData.initDate);
        const dateEnd = moment(this.stepData.endDate);
        const arrayIndex = timeValues.length;
        switch (this.stepData.temporalFrame.id) {
          case 1:
            const firstYear = dateStart.year();
            const lastYear = dateEnd.year();

            for (let i = firstYear; i <= lastYear; i++) {
              const firstDayOfYear = moment([i]).startOf('year');
              const lastDayOfYear = moment([i]).endOf('year');
              let firstWeek = '';
              let lastWeek = '';

              if (dateStart.isAfter(firstDayOfYear)) {
                firstWeek = dateStart.isoWeek();
              } else {
                firstWeek = firstDayOfYear.isoWeek();
              }

              if (lastDayOfYear.isAfter(dateEnd)) {
                lastWeek = dateEnd.isoWeek();
              } else {
                lastWeek = lastDayOfYear.isoWeek();
              }

              if (
                !dateStart.isAfter(firstDayOfYear, 'day') &&
                firstWeek !== 1
              ) {
                const varName = `${firstWeek} - ${i - 1}`;

                if (!timeValues[arrayIndex]) {
                  timeValues.push({ [varName]: 0 });
                } else {
                  timeValues[arrayIndex][varName] = 0;
                }

                firstWeek = 1;
              }

              for (let j = firstWeek; j <= lastWeek; j++) {
                const varName = `${j} - ${i}`;
                if (!timeValues[arrayIndex]) {
                  timeValues.push({ [varName]: 0 });
                } else {
                  timeValues[arrayIndex][varName] = 0;
                }
              }
            }
            break;

        case 0:
        default:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.format('MMM').split('.')[0].toUpperCase()} - ${dateStart.format('yy')}`;
            if (!timeValues[arrayIndex]) {
              timeValues.push({ [varName]: 0 });
            } else {
              timeValues[arrayIndex][varName] = 0;
            }
            dateStart.add(1, 'month');
          }
          break;
      }
      this.stepData.dosageProduct.push(this.productFertilizerSelected);
      this.stepData.dosages.push({ dosage: 0 });
      this.stepData.timeValues = this.stepData.timeValues.concat(timeValues);

      if (this.isEditing) {
        this.editingStepData.timeValues = this.editingStepData.timeValues.concat(timeValues);
      }

      if (this.isCopying) {
        this.copyingStepData.timeValues = this.copyingStepData.timeValues.concat(timeValues);
      }
    },
    calcTotalBalance(array) {
      const ufContributed = {
        n: 0,
        p: 0,
        k: 0,
        c: 0,
        m: 0,
        s: 0,
      };
      array.forEach((item, index) => {
        let totalProps = 0;
        for (const prop in item) {
          totalProps += item[prop];
        }
        this.stepData.dosages[index].dosage = totalProps;
      });

      // Forzar que la tabla se renderice con los datos nuevos.
      this.stepData.dosages.push({ dosage: 0 });
      this.stepData.dosages.pop();

      this.stepData.dosageProduct.forEach((item, index) => {
        for (const prop in item) {
          ufContributed[prop] += this.calculateElementBalance(index, prop);
        }
      });

      this.updateElementBalance(
        ufContributed,
        this.stepData.dosageProductBalance[1],
      );

      this.differenceElementBalance('n');
      this.differenceElementBalance('p');
      this.differenceElementBalance('k');
      this.differenceElementBalance('c');
      this.differenceElementBalance('m');
      this.differenceElementBalance('s');
    },
    calculateElementBalance(index, prop) {
      return Math.round(
        (this.stepData.dosageProduct[index][prop] / 100) *
          this.stepData.dosages[index].dosage,
      );
    },
    differenceElementBalance(prop) {
      this.stepData.dosageProductBalance[1][prop] =
        this.stepData.dosageProductBalance[0][prop] +
        this.stepData.dosageProductBalance[1][prop];

      this.stepData.dosageProductBalance[1][prop] = Number(
        this.stepData.dosageProductBalance[1][prop],
      ).toFixed(3);
    },
    updateElementBalance(elementSource, elementTarget) {
      elementTarget.n = elementSource.n;
      elementTarget.p = elementSource.p;
      elementTarget.k = elementSource.k;
      elementTarget.c = elementSource.c;
      elementTarget.m = elementSource.m;
      elementTarget.s = elementSource.s;
    },
    deleteItem(item) {
      this.$f7.dialog.confirm(
        this.$t('dosageProduct.deleteDialogMessage', {
          product: this.stepData.dosageProduct[item.row.rowIndex].name,
        }),
        this.$t('dosageProduct.deleteDialogTitle'),
        () => {
          this.stepData.timeValues.splice(item.row.rowIndex, 1);
          this.stepData.dosages.splice(item.row.rowIndex, 1);
          this.stepData.dosageProduct.splice(item.row.rowIndex, 1);

          if (this.isEditing) {
            this.editingStepData.timeValues.splice(item.row.rowIndex, 1);
          }

          if (this.isCopying) {
            this.copyingStepData.timeValues.splice(item.row.rowIndex, 1);
          }
        },
      );
    },
    openProductAnnotations(item) {
      const index = item.row.rowIndex;
      this.annotationProduct = { index, name: this.stepData.dosageProduct[index].name, observation: this.stepData.dosageProduct[index].observation ?? "" };
      this.showAnnotationProduct = true;
    },
    save(e) {
      this.stepData.dosageProduct[e.index].observation = e.observation;
    },
    async viewProduct(item) {
      const index = item.row.rowIndex;
      const { id } = this.stepData.dosageProduct[index];

      this.$f7.preloader.show();
      try {
        await this.getNutritionalProductDetail(id);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
        this.showProductDetail = true;
      }
    },
    fixNumberTo(number, digits) {
      return parseFloat(parseFloat(number).toFixed(digits));
    },
    closeAnnotationProduct() {
      this.showAnnotationProduct = false;
    },
    ...mapActions('productFertilizerStore', ['getProductFertilizers']),
    ...mapActions('Vademecum', ['getNutritionalProductDetail'])
    }
  };
</script>
<style src="./dosageProduct.scss" scoped lang="scss"></style>
