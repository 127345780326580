<template>
  <div>
    <!-- <navbar :text="$t('WeatherFarmCockpit_navbar_title')" />
    <f7-subnavbar class="subnavbar">
      <div class="subnavbar__element">
        <span>Data Frequency:</span>
        <DxSelectBox
          ref="measuringSelect"
          :data-source="measuringFrequency"
          :value="measuringFrequency[0]"
          :on-value-changed="updateMeasuringFrequency"
        />
      </div>
    </f7-subnavbar> -->
    <div>
      <div class="content-devx">
        <f7-row>
          <f7-col width="50">
            <f7-block>
              <f7-row>
                <f7-col>
                  <div class="label-text-data">
                    {{ $t("WeatherStationIdentifier") }}
                  </div>
                </f7-col>
              </f7-row>
              <f7-row style="align-items: center">
                <f7-col class="margin-top-half">
                  {{ measuredProvider }}
                </f7-col>
              </f7-row>
            </f7-block>
          </f7-col>

          <f7-col width="50">
            <div class="container-agrupation-range-data">
              <div>
                <div
                  class="label-text-data padding-b-12"
                >
                  {{ $t("weather_agrupation_data") }}
                </div>
                <div>
                  <DxSelectBox
                    :data-source="measuringFrequency"
                    :value="measuringFrequency[0]"
                    :on-value-changed="updateMeasuringFrequency"
                    :display-expr="displayTranslatedMeasuringFrequency"
                    width="max-content"
                  />
                </div>
              </div>

              <div style="width: 100%">
                <div class="label-text-data">
                  {{ $t("dateRange") }}
                </div>
                <div
                  class="label-text-data-opacity-1"
                >
                  <div
                    class="label-text-data-opacity-1"
                  >
                    <CalendarFilter
                      @searchFilterWeather="searchFilterWeather"
                      @clearFilteredDataWeather="clearFilteredDataWeather"
                    />
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
        <f7-row
          v-if="dataIsNotAvailableForRange"
          style="margin-left: 8px"
        >
          <f7-col>
            <div class="alert alert-warning alert-white rounded">
              <div class="icon-container">
                <f7-icon md="material:warning" />
              </div>
              <h5>{{ $t('noDataInRange') }}</h5>
            </div>
          </f7-col>
        </f7-row>
      </div>
    </div>
    <div class="content__graphs">
      <f7-row
        v-if="loaded"
        no-gap
      >
        <f7-col
          width="100"
          medium="100"
        >
          <DxWeatherElementLinesGraph
            v-if="loaded"
            :data-source="temperaturesDataSource"
            :weather-element="$t('Temperature')"
          />

          <DxWeatherElementLinesGraph
            :data-source="humiditiesDataSource"
            :weather-element="$t('Humidity')"
          />
        </f7-col>
      </f7-row>
    </div>
    <div>
      <f7-col
        v-if="loaded"
        width="100"
        medium="100"
      >
        <DxWeatherElementLinesGraph
          :data-source="solarRadiationsDataSource"
          :weather-element="$t('SolarRadiation')"
        />
        <DxWeatherElementLinesGraph
          :data-source="windSpeedDataSource"
          :weather-element="$t('WindSpeed')"
        />
        <DxWeatherElementBarsGraph
          :data-source="precipitationsDataSource"
          :weather-element="$t('Precipitation')"
        />
      </f7-col>
    </div>
  </div>
</template>

<script>

import DxSelectBox from 'devextreme-vue/select-box';
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import DxWeatherElementLinesGraph from './weatherElementLinesGraph/index.vue';
import DxWeatherElementBarsGraph from './weatherElementBarsGraph/index.vue';
import navbar from '../../NavBar.vue';
import CalendarFilter from './calendarFilter/index.vue';

export default {
  name: 'WeatherGraph',
  components: {
    navbar,
    DxWeatherElementLinesGraph,
    DxWeatherElementBarsGraph,
    DxSelectBox,
    CalendarFilter,
  },
  data() {
    return {
      loaded: false,
      locationsAvailables: [],
      locationsToSelect: [],
      temperaturesDataSource: [],
      humiditiesDataSource: [],
      precipitationsDataSource: [],
      windSpeedDataSource: [],
      solarRadiationsDataSource: [],
      keyName: 'searcher/weatherGraph',
      windSpeedsMeasured: null,
      temperaturesMeasured: null,
      humiditiesMeasured: null,
      precipitationsMeasured: null,
      solarRadiationsMeasured: null,
      dataIsNotAvailableForRange: false,
    };
  },
  computed: {
    ...mapState('weatherFarm', [
      'measuringFrequency',
      'selectedFarmID',
      'selectedMeasuringFrequency',
      'measuredProvider',
    ]),
    ...mapState('weather', ['searchInitDate', 'searchEndDate']),
    ...mapState('Reporting', ['locationsTree', 'selectedFarmList']),
    ...mapGetters('weatherFarm', [
      'hourlyMeasuredTemperatures',
      'hourlyForecastedTemperatures',
      'dailyMeasuredTemperatures',
      'dailyForecastedTemperatures',
      'hourlyMeasuredHumidities',
      'hourlyForecastedHumidities',
      'dailyMeasuredHumidities',
      'dailyForecastedHumidities',
      'hourlyMeasuredPrecipitations',
      'hourlyForecastedPrecipitations',
      'dailyMeasuredPrecipitations',
      'dailyForecastedPrecipitations',
      'hourlyMeasuredWindSpeeds',
      'hourlyForecastedWindSpeeds',
      'dailyMeasuredWindSpeeds',
      'dailyForecastedWindSpeeds',
      'hourlyMeasuredSolarRadiations',
      'hourlyForecastedSolarRadiations',
      'dailyMeasuredSolarRadiations',
      'dailyForecastedSolarRadiations',
      // 'dailyForecastedEvapo',
      // 'dailyMeasuredEvapo',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
  },
  created() {
    this.$f7.preloader.show();
    this.getLocationsAvailables();
    this.locationsAvailables.forEach((location) => {
      if (this.selectedFarmList.includes(this.currentPlantation.id)) {
        this.locationsToSelect.push(location);
      }
    });
    this.setSelectedMeasuringFrequency(this.measuringFrequency[0]);
    this.setSelectedFarmID(this.currentPlantation.id).then(() => {
      this.fetchData().then(() => {
        this.updateTemperaturesDataSource();
        this.updateHumiditiesDataSource();
        this.updatePrecipitationsDataSource();
        this.updateWindSpeedsDataSource();
        this.updateSolarRadiationsDataSource();
      }).finally(() => {
        this.loaded = true;
        this.$f7.preloader.hide();
      });
    });
  },
  methods: {
    ...mapActions('weatherFarm', [
      'setSelectedFarmID',
      'setSelectedMeasuringFrequency',
      'fetchWeatherForecastData',
      'fetchMeasuredWeatherData',
      'setHasMeasuredTemperature',
      'setHasForecastTemperature',
    ]),
    searchFilterWeather() {
      this.$f7.preloader.show();
      this.loaded = false;
      this.fetchData().then(() => {
        this.updateTemperaturesDataSource();
        this.updateHumiditiesDataSource();
        this.updatePrecipitationsDataSource();
        this.updateWindSpeedsDataSource();
        this.updateSolarRadiationsDataSource();
      }).finally(() => {
        this.loaded = true;
        this.$f7.preloader.hide();
      });
    },
    clearFilteredDataWeather() {
      this.setWeatherSearchInitDate(null);
      this.setWeatherSearchEndDate(null);

      this.$f7.preloader.show();
      this.fetchData().then(() => {
        this.updateTemperaturesDataSource();
        this.updateHumiditiesDataSource();
        this.updatePrecipitationsDataSource();
        this.updateWindSpeedsDataSource();
        this.updateSolarRadiationsDataSource();
      }).finally(() => {
        this.loaded = true;
        this.$f7.preloader.hide();
      });
    },
    async fetchData() {
      await this.fetchWeatherForecastData(this.currentPlantation);
      await this.fetchMeasuredWeatherData(this.currentPlantation);
    },
    async updateMeasuringFrequency(e) {
      const newMeasuringFrequency = e.value;
      if (newMeasuringFrequency === 'DAILY') {
        this.indexOfRange = 1;
      }
      this.setSelectedMeasuringFrequency(newMeasuringFrequency);
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
      await this.updateWindSpeedsDataSource();
      await this.updateSolarRadiationsDataSource();
    },
    async updateSelectedFarmID(e) {
      const newSelectedFarmID = e.value;
      this.setSelectedFarmID(newSelectedFarmID);
      this.$f7.preloader.show();
      await this.updateTemperaturesDataSource();
      await this.updateHumiditiesDataSource();
      await this.updatePrecipitationsDataSource();
      await this.updateWindSpeedsDataSource();
      await this.updateSolarRadiationsDataSource();
      this.$f7.preloader.hide();
    },
    async updateTemperaturesDataSource() {
      switch (this.selectedMeasuringFrequency) {
        case 'HOURLY': {
          this.temperaturesMeasured = await this.hourlyMeasuredTemperatures;

          // SOLO CONCATENAR SI HAY FORECAST QUE ES CUANDO ES (FECHA PASADO + FUTURA) O SOLO (FECHAS FUTURAS)
          if (this.hourlyForecastedTemperatures.length > 0) {
            this.temperaturesMeasured.push(
              {
                measuredTemperature: this.hourlyForecastedTemperatures[0].forecastedTemperature,
                date: this.hourlyForecastedTemperatures[0].date,
              },
            );

            this.temperaturesDataSource = this.temperaturesMeasured.concat(this.hourlyForecastedTemperatures);
            this.dataIsNotAvailableForRange = false;

            if (moment(this.searchEndDate).isBefore(new Date())) {
              this.setHasForecastTemperature(false);
              this.setHasMeasuredTemperature(true);
            } else {
              this.setHasForecastTemperature(true);

              if (!this.searchInitDate || !this.searchEndDate) {
                this.setHasMeasuredTemperature(true);
              } else if (moment(this.searchInitDate).isBefore(new Date()) && moment(this.searchEndDate).isAfter(new Date())) {
                this.setHasMeasuredTemperature(true);
              } else {
                this.setHasMeasuredTemperature(false);
              }
            }
          } else {
            this.setHasForecastTemperature(false);
            this.temperaturesDataSource = this.temperaturesMeasured;

            // Solo en medidos
            if (moment(this.temperaturesDataSource[0].date).format('YYYY-MM-DD') !== moment(this.searchInitDate).format('YYYY-MM-DD') && moment(this.searchInitDate).format('YYYY-MM-DD') !== '') {
              // DETERMINA SI HAY DATOS EN EL RANGO, EN EL CASO DE QUE EXISTA ALGO ES PORQUE SE HA SELECCIONADO (FECHA PASADA + FUTURA)
              // ESTA LOGICA APLICA PARA EL WARNING
              const atLeastOneInRange = this.temperaturesDataSource.some((temp) => moment(temp.date).format('YYYY-MM-DD') <= moment(this.searchEndDate).format('YYYY-MM-DD'));

              if (atLeastOneInRange) {
                this.dataIsNotAvailableForRange = false;
              } else {
                this.dataIsNotAvailableForRange = true;
              }
            } else {
              this.dataIsNotAvailableForRange = false;
            }
          }

          break;
        }
        case 'DAILY': {
          this.temperaturesMeasured = await this.dailyMeasuredTemperatures;
          if (this.dailyForecastedTemperatures.length > 0) {
            this.temperaturesMeasured.push(
              {
                measuredTemperature: this.dailyForecastedTemperatures[0].forecastedTemperature,
                date: this.dailyForecastedTemperatures[0].date,
              },
            );
            this.temperaturesDataSource = this.temperaturesMeasured.concat(this.dailyForecastedTemperatures);
          } else {
            this.temperaturesDataSource = this.temperaturesMeasured;
          }

          break;
        }

        default:
          break;
      }
    },
    async updateHumiditiesDataSource() {
      switch (this.selectedMeasuringFrequency) {
        case 'HOURLY': {
          this.humiditiesMeasured = await this.hourlyMeasuredHumidities;
          if (this.hourlyForecastedHumidities.length > 0) {
            this.humiditiesMeasured.push(
              {
                measuredHumidity: this.hourlyForecastedHumidities[0].forecastedHumidity,
                date: this.hourlyForecastedHumidities[0].date,
              },
            );
            this.humiditiesDataSource = this.humiditiesMeasured.concat(this.hourlyForecastedHumidities);
          } else {
            this.humiditiesDataSource = this.humiditiesMeasured;
          }

          break;
        }
        case 'DAILY': {
          this.humiditiesMeasured = await this.dailyMeasuredHumidities;
          if (this.dailyForecastedHumidities.length > 0) {
            this.humiditiesMeasured.push(
              {
                measuredHumidity: this.dailyForecastedHumidities[0].forecastedHumidity,
                date: this.dailyForecastedHumidities[0].date,
              },
            );
            this.humiditiesDataSource = this.humiditiesMeasured.concat(this.dailyForecastedHumidities);
          } else {
            this.humiditiesDataSource = this.dailyMeasuredHumidities;
          }

          break;
        }

        default:
          break;
      }
    },
    async updatePrecipitationsDataSource() {
      switch (this.selectedMeasuringFrequency) {
        case 'HOURLY': {
          this.precipitationsMeasured = await this.hourlyMeasuredPrecipitations;
          if (this.hourlyForecastedPrecipitations.length > 0) {
            this.precipitationsMeasured.push(
              {
                measuredPrecipitation: this.hourlyForecastedPrecipitations[0].forecastedPrecipitation,
                date: this.hourlyForecastedPrecipitations[0].date,

              },
            );
            // Removida evapotranspiracion ya que no existe, volver a incluir cuando se corrija desde margaret
            const total = this.precipitationsMeasured.concat(this.hourlyForecastedPrecipitations);
            this.precipitationsDataSource = total;
          } else {
            this.precipitationsDataSource = this.precipitationsMeasured;
          }
          break;
        }
        case 'DAILY': {
          this.precipitationsMeasured = await this.dailyMeasuredPrecipitations;

          if (this.dailyForecastedPrecipitations.length > 0) {
            this.precipitationsMeasured.push(
              {
                measuredPrecipitation: this.dailyForecastedPrecipitations[0].forecastedPrecipitation,
                date: this.dailyForecastedPrecipitations[0].date,
              },
            );
            // Removida evapotranspiracion
            const total2 = this.precipitationsMeasured.concat(this.dailyForecastedPrecipitations);
            this.precipitationsDataSource = total2;
          } else {
            this.precipitationsDataSource = this.precipitationsMeasured;
          }

          break;
        }
        default:
          break;
      }
    },
    async updateWindSpeedsDataSource() {
      switch (this.selectedMeasuringFrequency) {
        case 'HOURLY': {
          this.windSpeedsMeasured = await this.hourlyMeasuredWindSpeeds;

          if (this.hourlyForecastedWindSpeeds.length > 0) {
            this.windSpeedsMeasured.push(
              {
                measuredWindSpeed: this.hourlyForecastedWindSpeeds.forecastedWindSpeed,
                date: this.hourlyForecastedWindSpeeds.date,
                unit: this.hourlyMeasuredTemperatures.unit,
              },
            );
            this.windSpeedDataSource = this.windSpeedsMeasured.concat(this.hourlyForecastedWindSpeeds);
          } else {
            this.windSpeedDataSource = this.windSpeedsMeasured;
          }

          break;
        }
        case 'DAILY': {
          this.windSpeedsMeasured = await this.dailyMeasuredWindSpeeds;
          if (this.dailyForecastedWindSpeeds.length > 0) {
            this.windSpeedsMeasured.push(
              {
                measuredWindSpeed: this.dailyForecastedWindSpeeds.forecastedWindSpeed,
                date: this.dailyForecastedWindSpeeds.date,
              },
            );

            this.windSpeedDataSource = this.windSpeedsMeasured.concat(this.dailyForecastedWindSpeeds);
          } else {
            this.windSpeedDataSource = this.windSpeedsMeasured;
          }

          break;
        }

        default:
          break;
      }
    },
    async updateSolarRadiationsDataSource() {
      switch (this.selectedMeasuringFrequency) {
        case 'HOURLY': {
          this.solarRadiationsMeasured = await this.hourlyMeasuredSolarRadiations ?? [];

          if (this.hourlyForecastedSolarRadiations.length > 0) {
            this.solarRadiationsDataSource = this.solarRadiationsMeasured.concat(this.hourlyForecastedSolarRadiations);
          } else {
            this.solarRadiationsDataSource = this.solarRadiationsMeasured;
          }
          break;
        }
        case 'DAILY': {
          this.solarRadiationsMeasured = await this.hourlyMeasuredSolarRadiations ?? [];

          this.solarRadiationsMeasured.push(
            {
              measuredSolarRadiation: this.dailyForecastedSolarRadiations.forecastedSolarRadiation,
              date: this.dailyForecastedSolarRadiations.date,
            },
          );
          this.solarRadiationsDataSource = this.solarRadiationsMeasured.concat(this.dailyForecastedSolarRadiations);
          break;
        }

        default:
          break;
      }
    },
    getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    displayTranslatedMeasuringFrequency(measuringFrequency) {
      if (measuringFrequency === null) {
        return '';
      }
      return `${this.$t(`MeasuringFrequency.${measuringFrequency}`)}`;
    },
    ...mapActions('weather', ['setWeatherSearchInitDate', 'setWeatherSearchEndDate']),

  },

};
</script>

<style lang="scss" scoped>
@import './weatherGraph.styles.scss';
</style>
