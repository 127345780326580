<template>
  <div>
    <DxPopup
      :visible="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :width="900"
      height="auto"
      :show-close-button="true"
      :title="$t('Product_Detail_Title')"
      @hiding="close"
    >
      <div v-if="owner.length === 0">
        <ProductDetail
          :product-detail="phytosanitaryProductDetail[0].productDetail"
        />
        <UsesDosesAuthPlague
          :uses-doses-auth="phytosanitaryProductDetail[0].usesDosesAuth"
        />
      </div>
      <div v-if="owner === 'TIMAC'">
        <ProductDetailNutrition
          :product-detail="nutritionalProductDetail[0].productDetail"
        />
        <ApplicationNutrition
          :application="nutritionalProductDetail[0].application"
        />
      </div>

      <div v-if="owner === 'TODOS'">
        <ProductDetail
          :product-detail="phytosanitaryProductDetail[0].productDetail"
        />

        <DosesAuthPlagueGrid
          :data="phytosanitaryProductDetail[0].usesDosesAuth.recommendations"
        />
      </div>

      <f7-block class="margin">
        <f7-row>
          <f7-col class="justify-content-center">
            <f7-button
              class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block standard-blue-btn"
              raised
              type="default"
              styling-mode="contained"
              @click="close"
            >
              {{ $t('Go_Back') }}
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </DxPopup>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { DxPopup } from 'devextreme-vue/popup';
  import ProductDetail from '@/components/utilities/vademecum/phytosanitaryProductDetail/productDetail/index.vue';
  import UsesDosesAuthPlague from '@/components/utilities/vademecum/phytosanitaryProductDetail/usesDosesAuthPlague/index.vue';
  import ProductDetailNutrition from '@/components/utilities/vademecum/nutritionalProductDetail/productDetail/index.vue';
  import ApplicationNutrition from '@/components/utilities/vademecum/nutritionalProductDetail/application/index.vue';
  import DosesAuthPlagueGrid from '@/components/wizardTreatment/DosesAuthPlagueGrid/index.vue';

  export default {
    name: 'PopupProductDetailTreatment',
    components: {
      DxPopup,
      ProductDetail,
      UsesDosesAuthPlague,
      ProductDetailNutrition,
      ApplicationNutrition,
      DosesAuthPlagueGrid,
    },
    props: {
      owner: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('Product', ['productDetail']),
      ...mapState('Vademecum', [
        'phytosanitaryProductDetail',
        'nutritionalProductDetail',
      ]),
    },
    methods: {
      close() {
        this.setDisplayProductDetailPopup(false);
      },
      ...mapActions('treatment', ['setDisplayProductDetailPopup']),
    },
  };
</script>

<style scoped></style>
