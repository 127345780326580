import CustomStore from 'devextreme/data/custom_store';
import Api from '../../../services/Api';

const endpoints = {
  companies: Api.getCompanies,
  observations: Api.getObservations,
  contactPlantation: Api.getContactPlantation,
  contactPlantationByDate: Api.getContactPlantationByDate,
  analysisTreatment: Api.getTreatmentDataForAnalisis,
  analysisFertilization: Api.fetchFertilizationsForAnalysis,
  configVarieties: Api.getVarieties,
  fertilizations: Api.fetchFertilizations,
  treatments: Api.fetchTreatments,
};

const formatData = (type, data) => {
  if (type === 'contactPlantation' || type === 'contactPlantationByDate') {
    return data.map((item) => ({
      ...item,
      companyId: item.company_id,
      initDate: item.init_date,
      endDate: item.end_date,
      sectorId: item.sector_id,
    }));
  }

  if (type === 'observations') {
    return data.map((item) => {
      let coordinates = [];
      let pointName = '';

      if (item.scouting_point.type === 'POINT') {
        coordinates = [item.scouting_point.longitude, item.scouting_point.latitude];
        pointName = item.scouting_point.name;
      }

      return {
        ...item,
        coordinates,
        pointName,
      };
    });
  }

  return data;
};

export default {
  namespaced: true,
  actions: {
    async getSamples({ rootState }, { params, type, data }) {
      try {
        let xhr = null;
        await Api.renewAccessToken();
        if (data !== undefined) {
          xhr = await endpoints[type](params, data);
        } else {
          xhr = await endpoints[type](params);
        }

        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: formatData(type, response.results),
          totalCount: response.count,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getStore({ dispatch }, { type, data }) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';

          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace('take', 'limit');

          subsistuteParams += `&sort=${encodeURI(JSON.stringify(sortSettings))}`;

          if (!subsistuteParams.startsWith('?')) {
            subsistuteParams = `?${subsistuteParams}`;
          }
          if (loadOptions.filter) {
            subsistuteParams += `&filter=${encodeURI(
              JSON.stringify([loadOptions.filter]),
            )}`;
          }

          return dispatch('getSamples', { params: subsistuteParams, type, data });
        },
      });
    },
  },
};
