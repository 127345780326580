export default {
  namespaced: true,
  state: {
    loadOffline: false,
  },
  actions: {
    setLoadOffline({ commit }, payload) {
      commit('setLoadOffline', payload);
    },
  },
  mutations: {
    setLoadOffline(state, payload) {
      state.loadOffline = payload;
    },
  },
};
