var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DxPopup',{attrs:{"visible":_vm.showPopUp,"drag-enabled":false,"close-on-outside-click":true,"on-hidden":_vm.onHidden,"show-title":true,"width":500,"height":"auto","title":_vm.$t('registerPlantation.title_select_campaign')}},[[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t('registerPlantation.subtitle_select_campaign')))])]),_vm._v(" "),_c('div',{staticClass:"content-form"},[_c('DxForm',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_campaign'),"caption":_vm.$t('weather_campaign'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.allcampaign,
              displayExpr: 'name',
              value: _vm.campaignId,
              valueExpr: 'id',
              placeholder: _vm.$t('registerClima_campaign_placeholder'),
              onValueChanged: _vm.onSelectedCampaign,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('')}})],1)],1)],1)],_vm._v(" "),_c('f7-block-footer',{staticClass:"content-action-botton"},[_c('f7-button',{staticClass:"dx-btn-succes-pop-up",attrs:{"raised":"","type":"success","styling-mode":"contained"},on:{"click":_vm.createNewPlantation}},[_vm._v("\n        "+_vm._s(_vm.$t("title_create_campania"))+"\n      ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }