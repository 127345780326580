<template>
  <div>
    <DxPopup
      :visible="showPopUp"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :width="500"
      height="auto"
      :show-close-button="false"
      :title="$t('ploit_register')"
    >
      <template>
        <f7-block
          class="text-align-center center"
        >
          {{ $t('ploit_completed') }}
        </f7-block>
        <div class="content-devx">
          <f7-button
            class="dx-btn-succes-pop-up"
            raised
            type="success"
            styling-mode="contained"
            @click="createNewParcel"
          >
            {{ $t("title_create_parcel") }}
          </f7-button>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';

export default {
  name: 'DxPopUpRegisterPloit',
  components: {
    DxPopup,
  },
  data() {
    return {
      actualPlot: '',
    };
  },
  computed: {
    ...mapState('Explotaciones', ['showPopUp']),
  },
  methods: {
    onHidden() {
      this.setShowPopUp(false);
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
    createNewParcel() {
      this.setCurrentParcel({});
      this.setTreeLocations([]);
      this.$f7.views.main.router.navigate('/registerParcelStep2/', { reloadCurrent: true });
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
    onChangePlot(e) {
      this.actualPlot = e.value;
      this.setActualPlot(e.value);
    },
    ...mapActions('Explotaciones', ['setShowPopUp']),
    ...mapActions('Parcel', ['setCurrentParcel']),
    ...mapActions('Plantation', ['setTreeLocations']),
  },
};
</script>

<style>
.finishedRegister {
    margin-left: 55%;
}
.center{
  padding-top: 3%;
  font-weight: bold;
}
</style>
