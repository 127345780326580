<template>
  <section class="nutritional-balance">
    <div class="content-devx">
      <div class="card-header">
        {{ $t('nutritionalBalance.title') }}
      </div>
      <div class="card-body">
        <DxDataGrid
          class="data-grid-reference-value"
          :data-source="stepData.nutritionalBalanceData"
          :on-editing-start="onEditingStart"
          :show-borders="true"
          @editor-preparing="onEditorPreparing"
        >
          <DxEditing
            :allow-updating="true"
            mode="cell"
          />
          <DxColumn
            data-field="uf"
            :allow-editing="false"
            :caption="$t('nutritionalBalance.uf')"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionalBalance.n')"
            data-field="n"
            data-type="number"
            :set-cell-value="setCellValue"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionalBalance.p')"
            data-field="p"
            data-type="number"
            :set-cell-value="setCellValue"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionalBalance.k')"
            data-field="k"
            data-type="number"
            :set-cell-value="setCellValue"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionalBalance.c')"
            data-field="c"
            data-type="number"
            :set-cell-value="setCellValue"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionalBalance.m')"
            data-field="m"
            data-type="number"
            :set-cell-value="setCellValue"
            :allow-sorting="false"
          />
          <DxColumn
            :caption="$t('nutritionalBalance.s')"
            data-field="s"
            data-type="number"
            :set-cell-value="setCellValue"
            :allow-sorting="false"
          />
        </DxDataGrid>
      </div>
    </div>
  </section>
</template>
<script>
import {
  DxDataGrid, DxColumn, DxEditing, DxFormat,
} from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';

export default {
  name: 'NutritionalBalance',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFormat,
  },
  props: [],
  data() {
    return {
      editingCell: null,
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['stepData']),
  },
  watch: {
    'stepData.nutritionalBalanceData': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalBalance(val, 'n');
        this.calcTotalBalance(val, 'p');
        this.calcTotalBalance(val, 'k');
        this.calcTotalBalance(val, 'c');
        this.calcTotalBalance(val, 'm');
        this.calcTotalBalance(val, 's');

        this.calcDosageProductBalance(val)
      },
      deep: true,
    },
  },
  created() {
    if (this.stepData.nutritionalBalanceData.length > 0) {
      this.stepData.nutritionalBalanceData = this.stepData.nutritionalBalanceData.map((item) => item);
      return;
    }
    this.stepData.nutritionalBalanceData = [];
    this.stepData.nutritionalBalanceData.push(this.stepData.nutritionalNeeds[1]);
    this.stepData.nutritionalBalanceData[0].uf = this.$t('nutritionalBalance.necNutritional');

    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.availableGround')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.irrigationWater')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.harvestScraps')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.availableTotal')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.advanceFertilization')));
  },
  methods: {
    onEditingStart(e) {
      this.editingCell = e.column.dataField;
    },
    setCellValue(newData, value, currentRowData) {
      newData[this.editingCell] = parseFloat(Math.abs(value).toFixed(3));
    },
    getRowDataSource(titleColumn) {
      return {
        uf: titleColumn, n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };
    },
    calcTotalBalance(array, param) {
      array[4][param] = this.roundValue(parseFloat(array[1][param]) + parseFloat(array[2][param]) + parseFloat(array[3][param]));
      array[5][param] = this.roundValue(parseFloat(array[4][param]) - parseFloat(array[0][param]));
    },
    calcDosageProductBalance(data) {
      const balance = data.find(item => item?.uf === this.$t('nutritionalBalance.advanceFertilization'))
      if (balance) {
        this.stepData.dosageProductBalance[0] = balance;
      }
    },
    onEditorPreparing(e) {
      if (e.row.dataIndex === 0 || e.row.dataIndex > 3) {
        e.editorOptions.disabled = true;
      }
    },
    roundValue(val) {
      return parseFloat(val.toFixed(3));
    },
  },
};

</script>
<style src="./nutritionalBalance.scss" scoped lang="scss"></style>
