import { mapActions, mapGetters, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';

export default {
  name: 'stepper-navigation',
  components: { DxButton },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    alertMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['nutritionSteps', 'currentStep', 'stepIndex']),
    ...mapState('Plantation', ['isPlantationSelected', 'oldPlantation']),
    ...mapState('Nutrition', ['editingFromContextMenu']),
  },
  methods: {
    getNextLabel() {
      if (this.stepIndex === this.nutritionSteps.length - 1) {
        return this.$t('Utilities.stepperForm.save');
      }
      return this.$t('Utilities.stepperForm.next');
    },
    nextStep() {
      if (this.disabled) {
        this.$notifyDX(
          {
            message: this.alertMessage,
            width: 550,
          },
          'error',
          3000,
        );
        return
      };

      if (this.stepIndex === this.nutritionSteps.length - 1) {
        this.$emit('save');
        return;
      }
      this.next();
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    backStep() {
      if (this.stepIndex === 0 ) {
        this.setIsEditing(false);
        this.setEditingStepData(null);
        this.setIsCopying(false);
        this.setCopyingStepData(null);
      }
      if (this.stepIndex === 0 && this.editingFromContextMenu !== null) {
        this.setCurrentFertilizationId(this.editingFromContextMenu);
        const url = this.$f7.views.main.router.generateUrl({
          name: 'utilities_nutrition_nutrition-detail',
          params: { id: this.editingFromContextMenu },
        });

        this.$f7.views.main.router.navigate(
          url,
          { reloadCurrent: true },
        );
        return;
      }

      if (this.isPlantationSelected && this.stepIndex === 0) {
        this.setCurrentPlantation(this.oldPlantation);
        this.$f7.views.main.router.navigate('/viewCopyNutritionPlan/', { reloadCurrent: true });
        return;
      }

      if (this.stepIndex === 0) {
        this.$f7.views.main.router.navigate('/searcher/nutrition/',
          { reloadCurrent: true });
        return;
      }
      this.back();
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    ...mapActions('nutritionStepper', ['next', 'back']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
    ...mapActions('fertilizationStore', ['setCurrentFertilizationId']),
    ...mapActions('nutritionStepper', ['setIsEditing', 'setEditingStepData', 'setIsCopying', 'setCopyingStepData']),
  },
};
