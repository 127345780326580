<template>
  <div>
    <div class="content-devx margin">
      <div class="margin title-section">
        {{ $t("itemsToDisplay") }}
      </div>
      <div class="margin">
        <div class="margin-bottom-half">
          <f7-row>
            <f7-col>
              <DxCheckBox v-model="showObservations" />
              {{ $t("Searcher.observations.observations") }}
            </f7-col>
            <f7-col>
              <div class="dx-field">
                <div class="dx-field-label">
                  <span>{{ $t("Searcher.observations.category") }}</span>
                </div>
                <div class="dx-field-value">
                  <DxSelectBox
                    :items="categoriesOfTemplates"
                    :onValueChanged="onCategoryChange"
                  />
                </div>
              </div>
            </f7-col>
            <f7-col>
              <div class="dx-field">
                <div class="dx-field-label">
                  <span>{{ $t("Searcher.observations.subcategory") }}</span>
                </div>
                <div class="dx-field-value">
                  <DxTagBox
                    :items="templatesByCategory"
                    displayExpr="subcategory"
                    valueExpr="id"
                    v-model="selectedSubCategories"
                  />
                </div>
              </div>
            </f7-col>
          </f7-row>
        </div>

        <div class="margin-bottom-half">
          <f7-row>
            <f7-col>
              <DxCheckBox :value="true" />
              {{ $t("Configuration.Tasks") }}
            </f7-col>
            <f7-col>
              <div class="dx-field">
                <div class="dx-field-label">
                  <span>{{ $t("group") }}</span>
                </div>
                <div class="dx-field-value">
                  <DxTagBox
                    :items="taskActivity"
                    v-model="selectedTaskActivities"
                    :onValueChanged="onTaskActivityChange"
                  />
                </div>
              </div>
            </f7-col>
            <f7-col></f7-col>
          </f7-row>
        </div>
      </div>

      <div class="margin-top padding-top margin title-section">
        {{ $t("weatherVariables") }}
      </div>
      <div class="weather-variables-container margin">
        <div>
          <DxCheckBox
            :value="showPrecipitations"
            v-model="showPrecipitations"
          />
          {{ $t("Precipitation") }}
        </div>
        <div>
          <DxCheckBox :value="showTemperatures" v-model="showTemperatures" />
          {{ $t("Temperature") }}
        </div>
        <div v-if="loadingWeatherData" class="spinner-weather-container">
          <div class="spinner-weather"></div>
          <div class="margin-left">{{ $t("Sync.InfoLoading.Title") }}</div>
        </div>
      </div>
    </div>

    <div class="content-devx margin padding">
      <DxChart
        id="chart-gis-report"
        :data-source="datSourceGraph"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings type="line" argument-field="date" />
        <DxSeries
          value-field="mean"
          color="#facc15"
          :name="currentIndexes.GisTimeDimension"
        />
        <DxSeries
          v-if="showPrecipitations"
          pane="rainfall"
          value-field="avgmeasuredPrecipitación"
          :name="`${$t(
            'DxWeatherElementLinesGraph_total'
          )} Precipitación, L/m²`"
          type="bar"
          color="#38bdf8"
        />
        <!-- <DxSeries
          v-if="showPrecipitations"
          pane="rainfall"
          value-field="avgforecastedPrecipitación"
          :name="`${$t(
            'DxWeatherElementLinesGraph_totalForecasted'
          )} Precipitación, L/m²`"
          type="bar"
        /> -->
        <DxSeries
          v-if="showTemperatures"
          pane="rainfall"
          value-field="avgmeasuredTemperatura"
          :name="`${$t('DxWeatherElementLinesGraph_average')} Temperatura, °C`"
          color="#f87171"
        />
        <!-- <DxSeries
          v-if="showTemperatures"
          pane="rainfall"
          value-field="avgforecastedTemperatura"
          :name="`${$t(
            'DxWeatherElementLinesGraph_totalForecasted'
          )} Temperatura, °C`"
        /> -->

        <DxSeries
          v-if="showObservations"
          name="Observaciones"
          color="#8c8cff"
        />
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="right"
        />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxArgumentAxis argumentType="datetime">
          <DxConstantLine
            v-for="(constantLine, index) in randomConstantLines"
            :key="index"
            :width="2"
            :value="constantLine.date"
            color="#8c8cff"
            dash-style="dash"
          >
            <DxLabel text="" />
          </DxConstantLine>
          <DxLabel display-mode="stagger" format="yyyy-MM-dd" />
        </DxArgumentAxis>

        <DxPane
          v-if="
            (showTemperatures || showPrecipitations) &&
            fetchedWeatherData === true &&
            loadingWeatherData === false
          "
          name="rainfall"
        />
        <DxPane name="bottomPane" />

        <DxValueAxis pane="bottomPane"></DxValueAxis>
        <DxValueAxis
          v-if="showPrecipitations"
          pane="rainfall"
          name="rainfall"
          position="right"
        >
          <DxGrid :visible="true" />
          <DxTitle text="L/m²" />
        </DxValueAxis>
        <DxValueAxis v-if="showTemperatures" pane="rainfall" position="left">
          <DxGrid :visible="true" />
          <DxTitle text="Cº" />
        </DxValueAxis>
      </DxChart>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from "devextreme-vue/tag-box";
import DxCheckBox from "devextreme-vue/check-box";
import DxSelectBox from "devextreme-vue/select-box";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  DxChart,
  DxSeries,
  DxValueAxis,
  DxVisualRange,
  DxLabel,
  DxConstantLine,
  DxLegend,
  DxExport,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxTooltip,
  DxGrid,
  DxTitle,
  DxPane,
} from "devextreme-vue/chart";
import moment from "moment";

export default {
  name: "ReportStep2",
  components: {
    DxTooltip,
    DxCheckBox,
    DxSelectBox,
    DxTagBox,
    DxChart,
    DxSeries,
    DxValueAxis,
    DxVisualRange,
    DxLabel,
    DxConstantLine,
    DxLegend,
    DxExport,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxTooltip,
    DxGrid,
    DxTitle,
    DxPane,
  },
  data() {
    return {
      step: 1,
      fetchedWeatherData: false,
      loadingWeatherData: false,
      selectedDates: [],
      selectedSubCategories: [],
      selectedTaskActivities: [],
      selectedTaskDefinition: {},
      dataSource: [
        {
          date: "2020-05-01",
          value: 0,
        },
        {
          date: "2020-05-02",
          value: 0,
        },
        {
          date: "2020-05-03",
          value: 200,
        },
        {
          date: "2020-05-04",
          value: 0,
        },
        {
          date: "2020-05-05",
          value: 0,
        },
        {
          date: "2020-05-06",
          value: 0,
        },
        {
          date: "2020-05-07",
          value: 0,
        },
        {
          date: "2020-05-08",
          value: 0,
        },
      ],
      temperaturesDataSource: [],
      precipitationsDataSource: [],
      showTemperatures: false,
      showPrecipitations: false,
      showObservations: false,
    };
  },
  computed: {
    ...mapState("SearcherObservations", [
      "categoriesOfTemplates",
      "templatesByCategory",
    ]),
    ...mapState("TaskByCompany", ["taskActivity", "taskDefinition"]),
    ...mapState("Gis", [
      "currentIndexes",
      "currentInitIntervalDates",
      "currentEndIntervalDates",
      "timelineIndexes",
      "datesList",
    ]),
    ...mapState("Plantation", ["currentPlantation"]),
    ...mapState("ReportGis", ["initDate", "endDate"]),
    ...mapGetters("weatherFarm", [
      "dailyMeasuredTemperatures",
      "dailyForecastedTemperatures",
      "dailyMeasuredPrecipitations",
      "dailyForecastedPrecipitations",
    ]),
    timelineIndexesFormatted() {
      const indexes = this.timelineIndexes.map((item) => {
        return {
          date: item.date,
          ...(item.values.find(
            (value) =>
              value.index === this.currentIndexes.GisTimeDimension.toLowerCase()
          ) ?? {}),
        };
      });

      return indexes;
    },
    datSourceGraph() {
      let indexes = this.timelineIndexes.map((item) => {
        return {
          date: item.date,
          ...(item.values.find(
            (value) =>
              value.index === this.currentIndexes.GisTimeDimension.toLowerCase()
          ) ?? {}),
        };
      });

      if (this.showTemperatures) {
        indexes = indexes.concat(this.temperaturesDataSource);
      }

      if (this.showPrecipitations) {
        indexes = indexes.concat(this.precipitationsDataSource);
      }

      return indexes;
    },
    randomConstantLines() {
      if (!this.showObservations) return [];
      return this.timelineIndexesFormatted
        .sort(() => 0.5 - Math.random())
        .slice(0, 10)
        .sort((a, b) => {
          return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
        });
    },
    getAvaiableDates() {
      return this.datesList.avaiable_dates.map((item) =>
        moment(item).format("DD-MM-YYYY")
      );
    },
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.initStore();
      await this.getCategoriesOfTemplates();
      await this.getTasksForActivity();

      this.setCurrentInitIntervalDates({
        key: "GisTimeDimension",
        newCurrentInitIntervalDate: moment(this.initDate).format(
          "YYYY-MM-DDT00:00:00"
        ),
      });
      this.setCurrentEndIntervalDates({
        key: "GisTimeDimension",
        newCurrentEndIntervalDate: moment(this.endDate).format(
          "YYYY-MM-DDT00:00:00"
        ),
      });

      this.setWeatherSearchInitDate(
        moment(this.initDate).format("YYYY-MM-DDT00:00:00")
      );
      this.setWeatherSearchEndDate(
        moment(this.endDate).format("YYYY-MM-DDT00:00:00")
      );

      await this.fetchTimelineIndexes({
        location: this.currentPlantation.sectorId,
        initDate: moment(this.initDate).format("YYYY-MM-DD"),
        endDate: moment(this.endDate).format("YYYY-MM-DD"),
        companyId: this.currentPlantation.companyId,
      });
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions("SearcherObservations", [
      "initStore",
      "getCategoriesOfTemplates",
      "getTemplatesByCategory",
    ]),
    ...mapActions("TaskByCompany", [
      "getTasksForActivity",
      "getTasksForDefinition",
    ]),
    ...mapActions("weatherFarm", [
      "fetchWeatherForecastData",
      "fetchMeasuredWeatherData",
    ]),
    ...mapActions("weather", [
      "setWeatherSearchInitDate",
      "setWeatherSearchEndDate",
    ]),
    ...mapActions("Gis", [
      "setCurrentInitIntervalDates",
      "setCurrentEndIntervalDates",
      "fetchTimelineIndexes",
    ]),
    async onCategoryChange(e) {
      if (e.event) {
        if (typeof e.value !== "undefined" && e.value !== "") {
          try {
            await this.getTemplatesByCategory(e.value);
          } catch (error) {
            this.$f7.dialog.alert(this.$t(`${error}`));
          }
        }
      }
    },
    async onTaskActivityChange(e) {
      await this.getTasksForDefinition(e.value[e.value.length - 1]);

      this.selectedTaskDefinition[e.value[e.value.length - 1]] =
        this.taskDefinition;
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${moment(
          pointInfo.argument
        ).format("DD-MM-YYYY")}</div><div class='tooltip-body'>
          <span class='bold'>${this.$t("index")}: </span>${pointInfo.seriesName}
          </span> </div>
          <span class="bold"> ${this.$t("mean")}:</span> ${pointInfo.valueText}
        </span> </div></div></div>`,
      };
    },
    async fetchData() {
      this.loadingWeatherData = true;
      // await this.fetchWeatherForecastData(this.currentPlantation);
      await this.fetchMeasuredWeatherData(this.currentPlantation);

      setTimeout(() => (this.loadingWeatherData = false), 50);
    },
    async updateTemperaturesDataSource() {
      this.temperaturesDataSource = await this.dailyMeasuredTemperatures;
      // this.temperaturesMeasured = await this.dailyMeasuredTemperatures;

      // if (this.dailyForecastedTemperatures.length > 0) {
      //   this.temperaturesMeasured.push({
      //     measuredTemperature:
      //       this.dailyForecastedTemperatures[0].forecastedTemperature,
      //     date: this.dailyForecastedTemperatures[0].date,
      //   });
      //   this.temperaturesDataSource = this.temperaturesMeasured.concat(
      //     this.dailyForecastedTemperatures
      //   );
      // } else {
      //   this.temperaturesDataSource = this.temperaturesMeasured;
      // }
    },
    async updatePrecipitationsDataSource() {
      this.precipitationsDataSource = await this.dailyMeasuredPrecipitations;
      // this.precipitationsMeasured = await this.dailyMeasuredPrecipitations;

      // if (this.dailyForecastedPrecipitations.length > 0) {
      //   this.precipitationsMeasured.push({
      //     measuredPrecipitation:
      //       this.dailyForecastedPrecipitations[0].forecastedPrecipitation,
      //     date: this.dailyForecastedPrecipitations[0].date,
      //   });

      //   const total2 = this.precipitationsMeasured.concat(
      //     this.dailyForecastedPrecipitations
      //   );
      //   this.precipitationsDataSource = total2;
      // } else {
      //   this.precipitationsDataSource = this.precipitationsMeasured;
      // }
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }

      if (series.name === "Observaciones") {
        this.showObservations = !this.showObservations;
      }
    },
  },
  watch: {
    async showTemperatures() {
      if (!this.fetchedWeatherData) {
        this.fetchedWeatherData = true;

        await this.fetchData().then(() => {
          this.updateTemperaturesDataSource();
          this.updatePrecipitationsDataSource();
        });
      }
    },
    async showPrecipitations() {
      if (!this.fetchedWeatherData) {
        this.fetchedWeatherData = true;

        await this.fetchData().then(() => {
          this.updateTemperaturesDataSource();
          this.updatePrecipitationsDataSource();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./reportGis.styles.scss";
</style>