<template>
  <DxForm :show-colon-after-label="false">
    <DxGroupItem>
      <DxSimpleItem
        :data-field="$t('Searcher.observations.name')"
        :editor-options="{
          onValueChanged: onChangeName,
        }"
      />
      <DxSimpleItem
        :data-field="$t('Searcher.observations.latitude')"
        :editor-options="{
          onValueChanged: onChangeLatitude,
          value: parseFloat(currentPoint.latitude).toFixed(6),
          readOnly:true,
        }"
      />
      <DxSimpleItem
        :data-field="$t('Searcher.observations.longitude')"
        :editor-options="{
          onValueChanged: onChangeLongitude,
          value: parseFloat(currentPoint.longitude).toFixed(6),
          readOnly:true,
        }"
      />
    </DxGroupItem>
  </DxForm>
</template>

<script>

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'PointTab',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
  },
  computed: {
    ...mapState('Gis', ['currentPoint']),
  },
  beforeMount() {
    this.setTypePoint('POINT');
  },
  methods: {
    onChangeName(e) {
      this.setName(e.value);
    },
    onChangeLatitude(e) {
      this.setLatitude(e.value);
    },
    onChangeLongitude(e) {
      this.setLongitude(e.value);
    },
    ...mapActions('SearcherObservations', ['setName', 'setLatitude', 'setLongitude', 'setTypePoint']),
  },
};
</script>
<style lang="scss" scoped>
@import './PointTab.styles.scss';
</style>
