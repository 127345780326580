<template>
  <div>
    <DxPopup
      :visible="showPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :width="500"
      :height="230"
      :show-close-button="false"
      :title="$t('sector.register.registeredSector')"
    >
      <template>
        <f7-block
          class="center"
        >
          <div class="title">
            {{ $t('sector.register.completedSuccessfullySectorRegister') }}
          </div>

          <f7-button
            class="button-popup"
            raised
            type="success"
            styling-mode="contained"
            @click="goToviewSector()"
          >
            {{ $t("sector.register.finish") }}
          </f7-button>
        </f7-block>
      </template>
    </DxPopup>
  </div>
</template>

<script>

import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PopupRegisterSectorStep2',
  components: {
    DxPopup,
  },
  computed: {
    ...mapState('Sector', ['showPopup']),
  },
  methods: {
    onHidden() {
      this.setShowPopup(false);
    },
    goToviewSector() {
      this.setShowPopup(false);
      this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
    },
    ...mapActions('Sector', ['setShowPopup']),
  },
};
</script>

<style>
@import './PopupRegisterSectorStep2.styles.scss';
</style>
