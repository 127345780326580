<template>
  <section class="reference-value">
    <div class="content-devx">
      <div class="card-header">
        {{ $t('referenceValue.title') }}
      </div>
      <div class="card-body margin-left-block margin-right-block">
        <DxDataGrid
          class="data-grid-reference-value"
          :data-source="stepData.referenceValues"
          :show-borders="true"
        >
          <DxColumn caption="">
            <DxColumn
              :caption="$t('referenceValue.production')"
              data-field="production"
              data-type="number"
            />
          </DxColumn>
          <DxColumn
            alignment="center"
            :caption="$t('nutrition_reference_value_info')"
          >
            <DxColumn
              :caption="$t('referenceValue.n')"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('referenceValue.p2o5')"
              data-field="p"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('referenceValue.k2o')"
              data-field="k"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('referenceValue.cao')"
              data-field="c"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('referenceValue.mgo')"
              data-field="m"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('referenceValue.so3')"
              data-field="s"
              data-type="number"
              :allow-sorting="false"
            />
          </DxColumn>
        </DxDataGrid>

        <f7-row>
          <f7-col>
            <p>{{ $t('nutritionalNeedsTips.observation') }}: <b>{{ observation }}</b></p>
          </f7-col>
          <f7-col style="text-align:right">
            <p><i>{{ $t('nutritionalNeedsTips.reference') }}: {{ referenceProduction }}</i></p>
          </f7-col>
        </f7-row>
        <div>
          <div />
          <div />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReferenceValue',
  components: { DxDataGrid, DxColumn },
  props: [],
  data() {
    return {
      referenceProduction: '',
      observation: '',
    };
  },
  computed: {
    ...mapGetters('referenceValueStore', ['referenceValues']),
    ...mapState('nutritionStepper', ['stepData']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  async created() {
    if (this.stepData.referenceValues.length > 0) {
      return;
    }

    await this.getReferenceValues(this.currentPlantation.species);
    this.stepData.referenceValues = this.referenceValues;

    this.referenceValues.forEach((item) => {
      this.referenceProduction = item.referenceProduction;
      this.observation = item.observation;
    });
  },
  methods: {
    ...mapActions('referenceValueStore', ['getReferenceValues']),
  },
};
</script>
<style src="./referenceValue.scss" scoped lang="scss"></style>
