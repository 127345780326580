// - General
import logo from '../static/img/logo.png';
import logoText from '../static/img/timac_agro_logo.svg';

export const ENV = process.env.NODE_ENV || 'development';
export const { TARGET } = process.env;
export const APP_VERSION = '1.7.0-dev';
export const APPLICATION_NAME = 'FARMING_UP';
export const DATABASE_NAME = 'dbTimac';

// export const CONFIG_BASE_URL = ENV === 'development' && TARGET === 'web'
//   ? LOCAL_DEV_PROXY : CONFIG_PRODUCTION_BASE;

// - Backend API
export const MILISECONDS_TO_REFRESH_AUTOMATICALLY = 180 * 1000; // 3 minutos
export const SECONDS_BETWEEN_SCANS = 3;
export const MIN_SECONDS_TO_REFRESH_TOKEN = 60;

// - Color Theme
export const DEFAULT_PRIMARY_COLOR = '#42526E';
export const DEFAULT_SECONDARY_COLOR = '#003caa';

// Routes
export const APP_LOGO = logo;
export const APP_TEXT_LOGO = logoText;
