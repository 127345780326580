<template>
  <div>
    <DxPopup
      :visible="displayConfirmExitPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :width="500"
      height="auto"
      :show-close-button="false"
      :title="$t('Confirm_Delete_Title')"
    >
      <template>
        <f7-block
          class="text-align-center center"
        >
          <div>
            <f7-icon
              class="warning-icon"
              md="material:warning"
            />
          </div>
          <div
            class="title font-size-20"
          >
            {{ $t('pendingRegister') }}
          </div>
        </f7-block>
        <f7-block class="text-align-center">
          <p>{{ $t('pendingRegisterMessage') }}</p>
        </f7-block>
      </template>
      <div class="padding-top">
        <f7-row>
          <f7-col>
            <f7-button
              class="dx-btn-cancel margin-left-block btn-width-150 outlined-gray margin-top-block margin-bottom-block"
              raised
              type="default"
              styling-mode="contained"
              @click="goToPage"
            >
              Abandonar
            </f7-button>
          </f7-col>
          <f7-col class="justify-content-end">
            <f7-button
              class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block standard-blue-btn"
              raised
              type="success"
              styling-mode="contained"
              @click="onHidden"
            >
              {{ $t("continue") }}
            </f7-button>
          </f7-col>
        </f7-row>
      </div>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';

export default {
  name: 'PopupConfirmWizardExit',
  components: {
    DxPopup,
  },
  computed: {
    ...mapState('treatment', ['displayConfirmExitPopup', 'routeToGo']),
    ...mapState('Utilities', ['tabToGo']),
    ...mapState('treatment', ['editingFromContextMenu']),
  },
  methods: {
    onHidden() {
      this.setDisplayConfirmExitPopup(false);
    },
    goToPage() {
      if (this.editingFromContextMenu !== null) {
        this.setCurrentTreatmentId(this.editingFromContextMenu)
      }

      this.setDisplayConfirmExitPopup(false);
      this.setCurrentTab(this.tabToGo);
      this.setActualMenuSelected(this.routeToGo);
      this.$f7.views.main.router.navigate(this.routeToGo, { reloadCurrent: true });
    },
    ...mapActions('treatment', ['setDisplayConfirmExitPopup']),
    ...mapActions('Utilities', ['setCurrentTab']),
    ...mapActions('authentication', ['setActualMenuSelected']),
    ...mapActions('treatmentStepper', ['setCurrentTreatmentId']),
  },
};
</script>

<style src="./popupConfirmWizardExit.styles.scss" scoped lang="scss"></style>
