<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('LeftPanel_subtitle_lonjas')}`" />
    <f7-block class="content-devx">
      <SlicesComponent />
      <f7-row>
        <f7-col />
        <f7-col class="justify-content-end">
          <f7-button
            class="dx-btn-success button-margin-right"
            raised
            type="success"
            styling-mode="contained"
            @click="addSeriesGraphic"
          >
            {{ $t("Button_add_weather") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
    <GraphicProductComponent
      v-if="loaded"
      :date="date"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SlicesComponent from '../../../components/utilities/slices/index.vue';
import GraphicProductComponent from '../../../components/utilities/slices/graphicProduct/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Slices',
  components: {
    SlicesComponent,
    navbar,
    GraphicProductComponent,
  },
  data() {
    return {
      visible: false,
      date: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('Slices', ['initDate', 'endDate', 'SelectedSlices', 'SelectedFamily', 'SelectedProduct',
      'dataDate', 'SelectedOrigin']),
  },
  beforeMount() {
  },
  methods: {
    async addSeriesGraphic() {
      this.loaded = false;
      if (this.campaign === '') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_init_date_require'));
      } else if (this.initDate === '' || this.initDate === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_inicio_validation'));
      } else if (this.endDate === '' || this.endDate === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_finalizacion_validation'));
      } else if (this.initDate > this.endDate) {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_inicio_mayor_validation'));
      } else if (this.SelectedSlices === '') {
        this.$f7.dialog.alert(this.$t('Slices_validate_slice'));
      } else if (this.SelectedFamily === '') {
        this.$f7.dialog.alert(this.$t('Slices_validate_family'));
      } else if (this.SelectedProduct === '') {
        this.$f7.dialog.alert(this.$t('Slices_validate_product'));
      } else if (this.SelectedOrigin === '') {
        this.$f7.dialog.alert(this.$t('Slices_validate_origin'));
      } else {
        try {
          this.$f7.preloader.show();
          await this.getData();
          this.date = this.dataDate;
          this.loaded = true;
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    ...mapActions('Slices', ['getData']),
  },
};
</script>
<style scoped>
@import './slices.styles.scss';
</style>
