<template>
  <div class="margin-top-half">
    <div
      class="content-devx"
    >
      <f7-block-title class="margin-bottom">
        <f7-row>
          <f7-col width="20">
            {{ $t('Configuration.Task_Detail_Title') }}
          </f7-col>
          <f7-col width="80" />
        </f7-row>
      </f7-block-title>
      <f7-block>
        <f7-row class="margin-bottom">
          <f7-col>
            {{ $t('Configuration.Activity') }}
          </f7-col>
          <f7-col>
            {{ $t('name') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold margin-bottom">
          <f7-col>
            {{ taskDefinitionDetail.activity }}
          </f7-col>
          <f7-col>
            {{ taskDefinitionDetail.name }}
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TaskDetail',
  props: {
    taskDefinitionDetail: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './TaskDetail.styles.scss';
</style>
