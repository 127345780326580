import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardTreatment/stepper/index.vue';
import StepperNavigation from '@/components/wizardTreatment/stepper-navigation/index.vue';
import ProductResume from '@/components/wizardTreatment/productResume/index.vue';
import treatmentPlanService from '@/services/treatmentPlanService';
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import PopupConfirmWizardExit from '@/components/wizardTreatment/popupConfirmWizardExit/index.vue';

export default {
  name: 'treatment-step-three',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    ProductResume,
    PopupConfirmWizardExit
  },
  props: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('treatmentStepper', ['treatmentPlan']),
    ...mapState('Plantation', ['currentPlantation', 'isPlantationSelected']),
    ...mapState('Plans', ['currentPlanId']),
  },

  methods: {
    validateForm() {
      if (typeof this.treatmentPlan.description === 'undefined' || this.treatmentPlan.description === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentPlan.treatmentCreatePlan.description_validation'));
        return false;
      }
      if (typeof this.treatmentPlan.dose_type === 'undefined' || this.treatmentPlan.dose_type === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentPlan.treatmentCreatePlan.dose_type_validation'));
        return false;
      }
      if (typeof this.treatmentPlan.agent === 'undefined' || this.treatmentPlan.agent === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentPlan.treatmentCreatePlan.agent_validation'));
        return false;
      }
      if (!this.treatmentPlan.products || this.treatmentPlan.products.length == 0) {
        this.$f7.dialog.alert(this.$t('treatment.treatmentPlan.treatmentCreatePlan.emptyProducts'));
        return false;
      }

      return true;
    },
    async save() {
      // if (this.validateForm()) {
      if (true) {
        const payload = {
          plantation: this.currentPlantation.id,
          description: this.treatmentPlan.description,
          observation: this.treatmentPlan.observation,
          init_date: moment(this.treatmentPlan.init_date).format('YYYY-MM-DD'),
          end_date: moment(this.treatmentPlan.end_date).format('YYYY-MM-DD'),
          period: this.treatmentPlan.period,
          vat_volume: this.treatmentPlan.vat_volume,
          forward_speed: this.treatmentPlan.forward_speed,
          periods: this.treatmentPlan.periods,
          default_apply_unit: this.treatmentPlan.default_apply_unit
        };
        if (this.isPlantationSelected) {
          try {
            await treatmentPlanService.save(payload, this.currentPlantation.companyId);

            this.setOldPlantation(this.currentPlantation);
            if (this.currentPlanId) {
              this.$f7.views.main.router.navigate('/plans/', { reloadCurrent: true });
            } else {
              this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
            }
          } catch (error) {
            this.$f7.dialog.alert(this.$t(`${error}`));
          }
          return;
        }

        try {
          if (this.treatmentPlan.id) {
            await treatmentPlanService.edit(this.treatmentPlan.id, payload, this.currentPlantation.companyId);
          } else {
            await treatmentPlanService.save(payload, this.currentPlantation.companyId);
          }
          this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }
      }
    },
    ...mapActions('Plantation', ['setOldPlantation']),
  },
};
