var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-row',{attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"50"}},[_c('DxForm',{attrs:{"id":"mainData-form","show-colon-after-label":true,"read-only":_vm.readOnly}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"name":"date","data-field":_vm.$t('Searcher.observations.date'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeDate,
              width: 250,
              value: _vm.currentValuesMainData.date,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: _vm.$t('dates_isInvalid'),
              onInitialized:_vm.init,
              pickerType: 'calendar',
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.dateValidation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"geolocation","data-field":_vm.$t('Searcher.observations.geolocation'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.scoutingPoints,
              onValueChanged: _vm.onGeolocationChange,
              value: _vm.currentValuesMainData.idGeolocationPoint,
              displayExpr: 'name',
              valueExpr: 'id',
              width: 250,
              searchEnabled: true,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.geolocationValidation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"category","data-field":_vm.$t('Searcher.observations.category'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.categoriesOfTemplates,
              onValueChanged: _vm.onCategoryChange,
              value: _vm.currentValuesMainData.category,
              searchEnabled: true,
              width: 250,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.categoryValidation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"subcategory","data-field":_vm.$t('Searcher.observations.subcategory'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.templatesByCategory,
              onValueChanged: _vm.onSubcategoryChange,
              value: _vm.currentValuesMainData.subcategory,
              displayExpr: 'subcategory',
              valueExpr: 'id',
              searchEnabled: true,
              width: 250,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.subcategoryValidation')}})],1)],1)],1)],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"50"}},[(!_vm.readOnly)?_c('DxForm',{attrs:{"id":"form","show-colon-after-label":false}},[_c('DxSimpleItem',{attrs:{"data-field":"","editor-options":{
            disabled: true,
            height: 41
          }}}),_vm._v(" "),_c('DxButtonItem',{attrs:{"button-options":_vm.addGeolocationPoint,"horizontal-alignment":_vm.isMobile ? 'center' : 'left'}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }