<template>
  <div class="content-devx">
    <f7-block>
      {{ $t('Register_campaign') }}
    </f7-block>
    <f7-block>
      <DxForm
        id="form"
        :col-count="1"
        :caption="$t('Campania_title')"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :data-field="$t('DxRegisterCampania_nombre')"
            name="name"
            :caption="$t('DxRegisterCampania_nombre')"
            :editor-options="{
              value: currentCampaign.name,
              showClearButton: true,
              onValueChanged: setNameChanged,
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_name_require')" />
          </DxSimpleItem>
          <DxSimpleItem :data-field="$t('dateRange')" :isRequired="true">
            <template #default>
              <f7-input
                type="datepicker"
                class="range-date-picker"
                style="width: 200px"
                :placeholder="
                  $t('PlanningComponent_datepicker_multiple_placeholder')
                "
                :clear-button="true"
                :close-on-select="true"
                :calendar-params="{
                  value: [currentCampaign.initDate, currentCampaign.endDate],
                  closeOnSelect: true,
                  rangePicker: true,
                  dateFormat: {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                  },
                }"
                @calendar:change="setSearcherDates"
              />
            </template>
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
      <DxPopUpRegisterCamapnia />
    </f7-block>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment';
import { mapActions } from 'vuex';
import DxPopUpRegisterCamapnia from '../popUpRegisterCampania/index.vue';

export default {
  name: 'RegisterCampania',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxPopUpRegisterCamapnia,
  },
  props: {
    currentCampaign: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setSearcherDates(e) {
      if (e.length === 0) {
        this.setInitDate('');
        this.setEndDate('');
        return;
      }

      if (e.length === 2) {
        this.setInitDate(moment(e[0], 'YYYY/MM/DD').format('YYYY-MM-DD'));
        this.setEndDate(moment(e[1], 'YYYY/MM/DD').format('YYYY-MM-DD'));
      }
    },
    setNameChanged(e) {
      if (e.value === null) {
        e.value = '';
      }
      this.actualName = e.value;
      this.setName(e.value);
    },
    ...mapActions('Campania', ['setEndDate', 'setInitDate', 'setName']),
  },
};
</script>

<style lang="scss" scoped>
@import './registerCampania.styles.scss';
</style>