<template>
  <f7-block class="no-padding-left no-padding-right">
    <div
      class="content-devx"
    >
      <DxDataGrid
        id="attributesTemplate-datagrid"
        :data-source="attributes"
        :column-min-width="70"
        :column-auto-width="true"
        :allow-column-resizing="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="false"
        @toolbar-preparing="onToolbarPreparing($event)"
        @exporting="onExporting"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="attributesTemplate"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="gridSettings.pageSize" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="description"
          :caption="$t('Configuration.ObservationsForms.description')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="type"
          :caption="$t('Configuration.ObservationsForms.tipology')"
          :allow-header-filtering="false"
          alignment="left"
          :calculate-display-value="displayTranslatedType"
        />
        <DxColumn
          type="buttons"
        >
          <DxButton
            name="find"
            icon="find"
            :on-click="viewAttribute"
          />
        </DxColumn>
        <template #titleAttributesForms>
          <span class="card-header">
            {{ $t('Configuration.ObservationsForms.attributesTitle') }}
          </span>
        </template>
      </DxDataGrid>
      <AttributesPopup
        v-if="showAttributePopup"
        :current-attribute="currentAttribute"
      />
    </div>
  </f7-block>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapState, mapActions } from 'vuex';
import AttributesPopup from '../attributesPopup/index.vue';
import settings from '../../../../js/commonSettings';

export default {
  name: 'AttributesTemplate',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    AttributesPopup,
  },
  props: {
    attributes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Observations', ['showAttributePopup', 'currentAttribute']),
  },
  methods: {
    viewAttribute(attribute) {
      this.initCurrentAttribute();
      this.setCurrentAttribute(attribute.row.data);
      this.setReadOnly(true);
      this.setShowAttributePopup(true);
    },
    displayTranslatedType(attribute) {
      return `${this.$t(`Configuration.AttributesPopup.type${attribute.type}`)}`;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'titleAttributesForms',
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('AttributesTemplate');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'AttributesTemplate.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Observations', ['setShowAttributePopup', 'setCurrentAttribute', 'initCurrentAttribute', 'setReadOnly']),
  },
};
</script>
<style lang="scss" scoped>
@import './AttributesTemplate.styles.scss';
</style>
