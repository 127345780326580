<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Observations_Title')}`" />
    <HeaderTemplate
      :form-name="currentObservationTemplate.subcategory"
    />
    <AttributesTemplate
      :attributes="currentObservationTemplate.form"
    />
    <f7-block-footer
      class="go-back-button"
    >
      <f7-button
        class="dx-btn-cancel"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToObservations"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import HeaderTemplate from '../../../components/configuration/observations/headerTemplate/index.vue';
import AttributesTemplate from '../../../components/configuration/observations/attributesTemplate/index.vue';

export default {
  name: 'ViewObservationTemplate',
  components: {
    navbar,
    HeaderTemplate,
    AttributesTemplate,

  },
  computed: {
    ...mapState('Observations', ['currentObservationTemplateId', 'currentObservationTemplate']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getTemplateById(this.currentObservationTemplateId);
      this.setReadOnly(true);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    goToObservations() {
      this.$f7.views.main.router.navigate('/observations/', { reloadCurrent: true });
    },
    ...mapActions('Observations', ['getTemplateById', 'setReadOnly']),
  },
};
</script>
