import Api from '../../services/Api';

export default {
  namespaced: true,
  state: {
    area: 0,
    centroide: null,
    currentGeoFeature: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    featureInfoSigpac: {},
    sigpacIntersect: {},
    dissolveFeatures: {},
    sigpacMap: '',
    sigpacWFS: '',
  },
  getters: {
    viewCentroide(state) {
      if (state.centroide === null) {
        return { lat: 0, lng: 0 };
      }
      return state.centroide;
    },
  },
  actions: {
    setLocation({ commit }, {
      newCoordinates,
      index,
      area,
      centroide,
    }) {
      commit('setArea', area);
      commit('setCentroide', centroide);
      commit('setCurrentGeoFeature', { newCoordinates, index });
    },
    setCurrentGeoFeature(context, { newCoordinates, index }) {
      context.commit('setCurrentGeoFeature', { newCoordinates, index });
    },
    resetCurrentGeoFeature({ commit }) {
      commit('resetCurrentGeoFeature');
    },
    resetAll({ commit }) {
      commit('resetCurrentGeoFeature');
      commit('resetArea');
      commit('resetCentroide');
    },
    resetCentroide({ commit }) {
      commit('resetCentroide');
      commit('resetArea');
    },
    async fetchFeatureInfoSigpac({ commit }, url) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };

        await fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            commit('setFeatureInfoSigpac', result);
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchSigpacIntersect({ commit }, layer) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServerSigpacSurface()}?polygon=${layer}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setSigpacIntersect', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchDissolveFeatures({ commit }, featureCollection) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServerDissolve()}?fc=${featureCollection}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setDissolveFeatures', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchSigpacMap({ commit }, url) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };

        await fetch(url, requestOptions)
          .then((response) => {
            commit('setSigpacMap', response);
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchSigpacWFS({ commit }, parameters) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServerSigpacWFSGeom()}?LowerCorner=${parameters.LowerCorner}&UpperCorner=${parameters.UpperCorner}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((data) => JSON.parse(data))
          .then((geodata) => {
            commit('setSigpacWFS', geodata);
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    setCentroide({ commit }, centroide) {
      commit('setCentroide', centroide);
    },
    setArea({ commit }, area) {
      commit('setArea', area);
    },
  },
  mutations: {
    setArea(state, newArea) {
      state.area = newArea;
    },
    setCentroide(state, newCentroide) {
      state.centroide = newCentroide;
    },
    setCurrentGeoFeature(state, { newCoordinates, index }) {
      state.currentGeoFeature.features[index].geometry.coordinates = newCoordinates;
    },
    resetCurrentGeoFeature(state) {
      state.currentGeoFeature = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [],
            },
          },
        ],
      };
    },
    resetArea(state) {
      state.area = 0;
    },
    resetCentroide(state) {
      state.centroide = null;
    },
    setFeatureInfoSigpac(state, newFeatureInfoSigpac) {
      state.featureInfoSigpac = newFeatureInfoSigpac;
    },
    setSigpacIntersect(state, newSigpacIntersect) {
      state.sigpacIntersect = newSigpacIntersect;
    },
    setDissolveFeatures(state, newDissolveFeatures) {
      state.dissolveFeatures = newDissolveFeatures;
    },
    setSigpacMap(state, newSigpacMap) {
      state.sigpacMap = newSigpacMap;
    },
    setSigpacWFS(state, newSigpacWFS) {
      state.sigpacWFS = newSigpacWFS;
    },
  },
};
