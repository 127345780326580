import pdfmake from 'pdfmake';
import html2canvas from 'html2canvas';

export default class PDFactory {
  constructor() {
    pdfmake.fonts = {
      //
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    // margin: [ Left, Top, Right, Bottom ]
    this.pdfData = {
      content: [],
      styles: {
        // Cabeceras
        h1: { fontSize: 18, bold: true, margin: [0, 14, 0, 8] },
        h2: {
          fontSize: 16, bold: true, margin: [0, 20, 0, 8], color: '#444',
        },
        h3: {
          fontSize: 14, bold: true, margin: [0, 22, 0, 8], color: '#666',
        },

        // Tablas
        tableHeaderCell: {
          margin: [8, 8, 8, 8], fontSize: 8, fillColor: '#eee', bold: true,
        },
        tableNormalCell: {
          margin: [8, 8, 8, 8], fontSize: 8, fillColor: '#fff', bold: false,
        },
      },
    };
  }

  /**
   *
   * @param {string} text
   * @param {string} type Misma convención que los elementos HTML. (h1, h2, h3)
   */
  insertHeader(text, type = 'h1') {
    this.pdfData.content.push({ text, style: type });
  }

  /**
   *
   * @param {string} url La Url desde un CDN o una dataURL
   * @param {number} width (opcional) Ancho máximo de la imagen en el PDF
   */
  insertImage(url, width) {
    const img = { image: url };
    if (width) { img.width = width; }

    this.pdfData.content.push(img);
  }

  /**
   *
   * @param {HTMLElement} domElement
   * @param {number} width
   */
  async insertImageFromHTMLElementAsync(domElement, width) {
    const headerImgCanvas = await html2canvas(domElement);
    this.insertImage(headerImgCanvas.toDataURL(), width);
  }

  /**
   *
   * @param {string[]} headerCells
   * @param {string[][]} rows
   */
  insertTable(headerCells, rows) {
    const table = {
      widths: [],
      body: [],
    };

    // Calculamos el porcentaje del ancho de cada celda para forzar que el ancho
    //   de la tabla al completo ocupe todo el ancho de la página.
    const celldimension = (100 / headerCells.length).toFixed(2);
    headerCells.forEach((_) => {
      table.widths.push(`${celldimension}%`);
    });

    // Convertimos los datos de cada fila en el formato que espera 'pdfmake'
    const headers = headerCells.map((cellText) => ({ style: 'tableHeaderCell', text: cellText }));
    table.body.push(headers);

    rows.forEach((row) => {
      // Convertimos los datos de cada fila en el formato que espera 'pdfmake'
      const bodyRow = row.map((cellText) => ({ style: 'tableNormalCell', text: cellText }));

      table.body.push(bodyRow);
    });

    this.pdfData.content.push({ table, layout: 'lightHorizontalLines' });
  }

  /**
   *
   * @param {string[]} headerCells
   * @param {string[][]} columns
   */
  insertVerticalTable(headers, columns) {
    const table = {
      // widths: ['*'],
      body: [],
    };

    headers.forEach((text) => {
      const cell = { style: 'tableHeaderCell', text };

      table.body.push([cell]);
    });

    columns.forEach((column) => {
      column.forEach((text, i) => {
        const cell = { style: 'tableNormalCell', text };

        table.body[i].push(cell);
        // table.widths.push('*');
      });
    });

    this.pdfData.content.push({ table, layout: 'lightHorizontalLines' });
  }

  insertPageBreak() {
    this.pdfData.content.push({ text: '', pageBreak: 'before' });
  }

  buildAndOpen() {
    pdfmake.createPdf(this.pdfData).open();
  }

  /**
   *
   * @param {string} filename
   */
  buildAndDownload(filename) {
    pdfmake.createPdf(this.pdfData).download(filename);
  }

  buildForMobile() {
    const pdfBuild = pdfmake.createPdf(this.pdfData);
    return pdfBuild;
  }
}
