<template>
  <f7-page class="main-viewlocation">
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} >  ${actualUser.name} > ${actualLocation.name}`" />
    <ViewLocationComponent />
    <div
      class="go-back-button margin-left margin-top"
    >
      <f7-button
        class="dx-btn-cancel no-margin padding-horizontal"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToBack()"
      >
        {{ $t('Button_go_to_back') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import ViewLocationComponent from '../../components/systemParcelExplotaciones/ViewLocation/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ViewLocation',
  components: {
    ViewLocationComponent,
    navbar,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
  },
};
</script>

<style lang="scss">
.main-viewlocation{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>
