var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-block',{staticClass:"content-linesgraph"},[_c('DxChart',{attrs:{"id":"chart","data-source":_vm.dataSourceFiltered},on:{"legend-click":_vm.onLegendClick}},[_c('DxCommonSeriesSettings',{attrs:{"argument-field":"date"}}),_vm._v(" "),(_vm.weatherElement !== ("" + (_vm.$t('SolarRadiation')))
          && _vm.weatherElement !== ("" + (_vm.$t('Humidity')))
          && _vm.weatherElement !== _vm.$t('WindSpeed') && _vm.hasMeasuredTemperature)?_c('DxSeries',{attrs:{"pane":"top","color":"#0571b0","value-field":("minmeasured" + _vm.weatherElement),"name":("Min " + _vm.weatherElement + ", " + _vm.measurement)}}):_vm._e(),_vm._v(" "),(_vm.hasMeasuredTemperature)?_c('DxSeries',{attrs:{"pane":"top","color":"#7b3294","value-field":("avgmeasured" + _vm.weatherElement),"name":((_vm.$t('DxWeatherElementLinesGraph_average')) + " " + _vm.weatherElement + ", " + _vm.measurement)}}):_vm._e(),_vm._v(" "),(_vm.weatherElement !== ("" + (_vm.$t('SolarRadiation')))
          && _vm.weatherElement !== ("" + (_vm.$t('Humidity'))) && _vm.hasMeasuredTemperature)?_c('DxSeries',{attrs:{"pane":"top","color":"#ca0020","value-field":("maxmeasured" + _vm.weatherElement),"name":("Max " + _vm.weatherElement + ", " + _vm.measurement)}}):_vm._e(),_vm._v(" "),(_vm.hasForecastTemperature && _vm.weatherElement !== ("" + (_vm.$t('Humidity')))
          && _vm.weatherElement !== _vm.$t('WindSpeed')
          && _vm.weatherElement !== ("" + (_vm.$t('SolarRadiation'))))?_c('DxSeries',{attrs:{"pane":"top","value-field":("minforecasted" + _vm.weatherElement),"type":"line","color":"#0571b099","name":((_vm.$t('DxWeatherElementLinesGraph_minForecasted')) + " " + _vm.weatherElement + ", " + _vm.measurement)}}):_vm._e(),_vm._v(" "),(_vm.hasForecastTemperature)?_c('DxSeries',{attrs:{"pane":"top","value-field":("avgforecasted" + _vm.weatherElement),"type":"line","color":"#7b329499","name":((_vm.$t('DxWeatherElementLinesGraph_averageForecasted')) + " " + _vm.weatherElement + ", " + _vm.measurement)}}):_vm._e(),_vm._v(" "),(_vm.hasForecastTemperature && _vm.weatherElement !== ("" + (_vm.$t('Humidity')))
          && _vm.weatherElement !== _vm.$t('WindSpeed')
          && _vm.weatherElement !== ("" + (_vm.$t('SolarRadiation'))))?_c('DxSeries',{attrs:{"pane":"top","value-field":("maxforecasted" + _vm.weatherElement),"type":"line","color":"#ca002099","name":((_vm.$t('DxWeatherElementLinesGraph_maxForecasted')) + " " + _vm.weatherElement + ", " + _vm.measurement)}}):_vm._e(),_vm._v(" "),_c('DxPane',{attrs:{"name":"top"}}),_vm._v(" "),_c('DxArgumentAxis',{attrs:{"discrete-axis-division-mode":"crossLabels","argument-type":"datetime","value-margins-enabled":false}},[_c('DxLabel',{attrs:{"display-mode":"stagger","format":"d MMM yyyy - hh:mm"}})],1),_vm._v(" "),_c('DxAdaptiveLayout',{attrs:{"width":80,"keep-labels":true}}),_vm._v(" "),_c('DxValueAxis',{attrs:{"pane":"top","axis-division-factor":20}},[_c('DxGrid',{attrs:{"visible":true}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":(_vm.weatherElement + ", " + _vm.measurement)}})],1),_vm._v(" "),_c('DxLegend',{attrs:{"margin":30,"vertical-alignment":"bottom","horizontal-alignment":"center","item-text-position":"top"}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":false}}),_vm._v(" "),_c('DxTooltip',{attrs:{"enabled":true,"z-index":999999,"customize-tooltip":_vm.customizeTooltip}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":("" + _vm.weatherElement)}}),_vm._v(" "),_c('DxScrollBar',{attrs:{"visible":true}}),_vm._v(" "),_c('DxZoomAndPan',{attrs:{"argument-axis":"both"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }