import moment from 'moment';

export default {
  namespaced: true,
  state: {
    filters: {},
    currentFilters: {},
    filterRangeDate: null,
    filterEstate: null,
    filterPlot: null,
    filterService: null,
    filterStatus: null,
    filterTypeSamples: null,
    filterVariety: null,
    filterAgent: null,
    filterCommercialName: null,
    filterCrop: null,
  },
  getters: {
    getFilterByFilterName: (state) => (filterName) => {
      if (filterName in state) {
        return state[filterName];
      }
      return null;
    },
  },
  mutations: {
    SET_FILTER(state, value) {
      state.filters[value.keyName] = value;
    },
    CLEAR_FILTER(state, value) {
      state.filters[value] = {};
    },
    SET_FILTER_RANGE_DATE(state, value) {
      state.filterRangeDate = {
        start: moment(value.start).format('YYYY-MM-DD'),
        end: moment(value.end).format('YYYY-MM-DD'),
      };
    },
    SET_FILTER_ESTATE(state, value) {
      state.filterEstate = value;
    },
    SET_FILTER_PLOT(state, value) {
      state.filterPlot = value;
    },
    SET_FILTER_SERVICE(state, value) {
      state.filterService = value;
    },
    SET_FILTER_STATUS(state, value) {
      state.filterStatus = value;
    },
    SET_FILTER_TYPE_SAMPLES(state, value) {
      state.filterTypeSamples = value;
    },
    SET_FILTER_VARIETY(state, value) {
      state.filterVariety = value;
    },
    CLEAR_ALL_FILTERS_COMPONENTS(state) {
      state.currentFilters = {};
      state.filterRangeDate = null;
      state.filterEstate = null;
      state.filterPlot = null;
      state.filterService = null;
      state.filterStatus = null;
      state.filterTypeSamples = null;
      state.filterVariety = null;
      state.filterAgent = null;
      state.filterCommercialName = null;
      state.filterCrop = null;
    },
    SET_FILTER_COMPONENT(state, value) {
      state[value.filter] = value.valueFilter;
    },
    SET_FILTER_AGENT(state, value) {
      state.filterAgent = value;
    },
    SET_FILTER_COMMERCIALNAME(state, value) {
      state.filterCommercialName = value;
    },
    SET_FILTER_CROP(state, value) {
      state.filterCrop = value;
    },
  },
  actions: {
    setFilter(context, value) {
      context.commit('SET_FILTER', value);
    },
    clearFilter(context, value) {
      context.commit('CLEAR_FILTER', value);
    },
    setFilterRangeDate(context, value) {
      context.commit('SET_FILTER_RANGE_DATE', value);
    },
    setFilterEstate(context, value) {
      context.commit('SET_FILTER_ESTATE', value);
    },
    setFilterPlot(context, value) {
      context.commit('SET_FILTER_PLOT', value);
    },
    setFilterService(context, value) {
      context.commit('SET_FILTER_SERVICE', value);
    },
    setFilterStatus(context, value) {
      context.commit('SET_FILTER_STATUS', value);
    },
    setFilterTypeSamples(context, value) {
      context.commit('SET_FILTER_TYPE_SAMPLES', value);
    },
    setFilterVariety(context, value) {
      context.commit('SET_FILTER_VARIETY', value);
    },
    clearAllFiltersComponents(context) {
      context.commit('CLEAR_ALL_FILTERS_COMPONENTS');
    },
    setFilterComponent(context, value) {
      context.commit('SET_FILTER_COMPONENT', value);
    },
    setFilterAgent(context, value) {
      context.commit('SET_FILTER_AGENT', value);
    },
    setFilterCommercialName(context, value) {
      context.commit('SET_FILTER_COMMERCIALNAME', value);
    },
    setFilterCrop(context, value) {
      context.commit('SET_FILTER_CROP', value);
    },
  },
};
