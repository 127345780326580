<template>
  <section class="treatment-detail-plan">
    <ProductPopupDetail :owner="owner" v-if="displayProductDetailPopup" />
    <PopupSelectObservationsReport
      v-if="showSelectObservationsReport"
      @close="closeSelectObservationsReport"
      @download="downloadReport"
    />
    <div class="main-container">
      <div
        class="content-devx margin-half treatment-order-form"
        v-if="!hideDetail"
      >
        <div class="card-header">
          <div>
            {{ $t("treatment.treatmentPlan.treatmentDetailPlan.title") }}
          </div>
          <div>
            <contextMenu :items="items"></contextMenu>
          </div>
        </div>
        <div class="card-body main">
          <div class="field">
            <span class="field-label">{{
              $t("treatment.treatmentPlan.treatmentDetailPlan.description")
            }}</span>
            <span class="field-value">{{ treatment.description }}</span>
          </div>
          <div class="field">
            <span class="field-label">{{
              $t("treatment.treatmentPlan.treatmentDetailPlan.initDate")
            }}</span>
            <span class="field-value"
              >{{ formatingDate(treatment.init_date) }} -
              {{ formatingDate(treatment.end_date) }}</span
            >
          </div>
          <div class="field">
            <span class="field-label">{{ $t("area") }}</span>
            <span class="field-value">{{ treatment.area }} ha</span>
          </div>
          <div class="field">
            <span class="field-label">{{
              $t("treatment.treatmentPlan.treatmentDetailPlan.status")
            }}</span>
            <span class="field-value text-uppercase">
              <span :class="`label ${getClassStatus(treatment.status)}`">{{
                getStatus
              }}</span>
            </span>
          </div>
          <div class="field"></div>
        </div>
      </div>
      <f7-row no-gap>
        <f7-col style="align-self: stretch">
          <section style="display: flex; height: 100%">
            <div class="content-devx margin-half treatment-order-product-list">
              <div class="card-header">
                {{
                  $t(
                    "treatment.treatmentPlan.treatmentDetailPlan.products.title"
                  )
                }}
              </div>
              <div class="card-body">
                <section class="product-table">
                  <DxDataGrid
                    class="data-grid-balance-value"
                    :data-source="getProducts"
                    :show-borders="true"
                  >
                    <DxColumn
                      data-field="product"
                      :caption="
                        $t(
                          'treatment.treatmentPlan.treatmentDetailPlan.products.product'
                        )
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                      cell-template="cellTemplate"
                    />
                    <template #cellTemplate="{ data }">
                      <div>
                        <span v-if="data.data.product_deleted">
                          <span :id="'alert-' + data.rowIndex">
                            <img
                              :src="getIconPath + 'alert.svg'"
                              alt="Alert icon"
                            />
                            <span>{{ data.data.name }}</span>
                          </span>
                        </span>
                        <span v-else>
                          {{ data.data.name }}
                        </span>
                      </div>
                    </template>

                    <DxColumn
                      data-field="agent"
                      :caption="
                        $t(
                          'treatment.treatmentPlan.treatmentDetailPlan.plagueAgent'
                        )
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                    />
                    <DxColumn
                      data-field="dose_type"
                      :caption="
                        $t('treatment.treatmentPlan.treatmentDetailPlan.dosage')
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                    />
                    <DxColumn
                      data-field="dosage_total"
                      :caption="
                        $t(
                          'treatment.treatmentPlan.treatmentDetailPlan.doseTotal'
                        )
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                    />
                    <DxColumn
                      data-field="observation"
                      :caption="
                        $t(
                          'treatment.treatmentPlan.treatmentDetailPlan.annotation'
                        )
                      "
                      :allow-sorting="false"
                      :allow-editing="false"
                    />
                  </DxDataGrid>
                </section>
              </div>
            </div>
          </section>
        </f7-col>
        <f7-col style="align-self: stretch">
          <section style="display: flex; height: 100%">
            <div
              class="content-devx margin-half treatment-plan-product-chart"
              style="width: 100%"
            >
              <div class="card-header">
                {{
                  $t("treatment.treatmentPlan.treatmentDetailPlan.chartTitle")
                }}
              </div>
              <div>
                <section class="product-table">
                  <DxChart palette="SoftPastel" :data-source="chartSeries">
                    <DxSeriesTemplate name-field="productName" />

                    <DxTooltip
                      :enabled="true"
                      :z-index="999999"
                      :customize-tooltip="customizeTooltip"
                    />

                    <DxCommonSeriesSettings
                      argument-field="monthWeek"
                      value-field="value"
                      type="stackedbar"
                    />
                    <DxLegend
                      vertical-alignment="top"
                      horizontal-alignment="center"
                      item-text-position="right"
                    />
                    <DxSize :height="300" />
                  </DxChart>
                </section>
              </div>
            </div>
          </section>
        </f7-col>
      </f7-row>

      <div class="content-devx">
        <div class="card-header">
          {{
            $t("treatment.treatmentPlan.treatmentCreatePlan.totalApplications")
          }}
        </div>
        <DxTabPanel
          :data-source="getPeriods"
          :selected-index.sync="selectedIndex"
          :loop="false"
          :animation-enabled="false"
          :swipe-enabled="false"
        >
          <template #title="{ data: period }">
            <span> {{ period.title }} </span>
          </template>
          <template #item="{ data: period }">
            <div>
              <TreatmentStepTwoTableDefault
                v-if="period.default_apply_unit === 'default'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
                readOnly
              />
              <TreatmentStepTwoTablePlant
                v-if="period.default_apply_unit === 'Kg-L / planta'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
                readOnly
              />
              <TreatmentStepTwoTableVat
                v-if="period.default_apply_unit === 'Kg-L / cuva'"
                :period="period"
                :selected-index="selectedIndex"
                @owner="setOwner"
                readOnly
              />
            </div>
          </template>
        </DxTabPanel>
      </div>

      <div>
        <section>
          <div class="content-devx padding-half">
            <div class="card-header-title margin-left-half margin-bottom">
              {{
                $t("treatment.treatmentPlan.treatmentCreatePlan.observations")
              }}
            </div>
            <f7-block>
              <f7-row>
                <f7-col>
                  {{ getObservations }}
                </f7-col>
              </f7-row>
            </f7-block>
          </div>
        </section>
      </div>
      <section class="buttons" v-if="!hideBackButton">
        <f7-button
          class="dx-btn-cancel btnBack"
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="cancel"
          style="margin-bottom: 10px"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </section>
    </div>
  </section>
</template>

<script>
import { DxTabPanel } from "devextreme-vue/tab-panel";
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
  DxTooltip,
  DxSize,
} from "devextreme-vue/chart";
import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
  DxEditing,
} from "devextreme-vue/data-grid";
import { mapState, mapActions } from "vuex";
import DxButton from "devextreme-vue/button";
import treatmentPlanService from "@/services/treatmentPlanService";
import moment from "moment";
import { treatmentPlanStatus } from "@/services/treatmentPlanService";

import contextMenu from "@/components/contextMenu/index.vue";
import PopupSelectObservationsReport from "@/components/reports/popupSelectObservationsReport.vue";

import TreatmentStepTwoTableVat from "../treatmentStepTwoTables/vat.vue";
import TreatmentStepTwoTableDefault from "../treatmentStepTwoTables/default.vue";
import TreatmentStepTwoTablePlant from "../treatmentStepTwoTables/plant.vue";
import ProductPopupDetail from "../productDetail/index.vue";
import { Device } from "framework7/framework7-lite.esm.bundle";

import PDFMaker from "../../../js/pdf-maker-plans.js";
import Api from "../../../services/Api";

import reportHeaderImage from "../../../static/img/TIMAC-Agro.png";
import reportHeaderImageTwo from "../../../static/img/reportHeaderTwo.png";

export default {
  name: "treatment-detail-plan",
  components: {
    DxDataGrid,
    DxColumn,
    DxSummary,
    DxTotalItem,
    DxEditing,
    DxButton,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTooltip,
    contextMenu,
    DxSize,
    DxTabPanel,
    TreatmentStepTwoTableVat,
    TreatmentStepTwoTableDefault,
    TreatmentStepTwoTablePlant,
    ProductPopupDetail,
    PopupSelectObservationsReport,
  },
  props: {
    id: { type: String, default: "" },
    hideBackButton: { type: Boolean, default: false },
    hideDetail: { type: Boolean, default: false },
    hideReportButton: { type: Boolean, default: false },
    calledFromViewPlan: { type: Boolean, default: false },
  },
  data() {
    return {
      treatment: {},
      chartSeries: [],
      selectedIndex: 0,
      owner: "",
      showSelectObservationsReport: false,
      reportHeaderImage,
      reportHeaderImageTwo,
    };
  },
  computed: {
    ...mapState("Plantation", ["currentPlantation"]),
    ...mapState("Plans", ["currentPlan"]),
    ...mapState("treatmentStepper", ["treatmentPlan"]),
    ...mapState("treatment", ["displayProductDetailPopup"]),
    ...mapState("authentication", ["user"]),
    ...mapState("RegisterPlantation", ["actualPlantation"]),
    items() {
      if (this.hideReportButton) {
        return [
          {
            icon: "copy",
            text: this.$t("ContextMenu.Copy"),
            onItemClick: this.goToViewCopyTreatmentPlan,
          },
          {
            icon: "edit",
            text: this.$t("ContextMenu.Edit"),
            onItemClick: this.editItem,
          },
        ];
      }

      return [
        {
          icon: "download",
          text: this.$t("ContextMenu.Download"),
          onItemClick: this.viewReport,
        },
        {
          icon: "copy",
          text: this.$t("ContextMenu.Copy"),
          onItemClick: this.goToViewCopyTreatmentPlan,
        },
        {
          icon: "edit",
          text: this.$t("ContextMenu.Edit"),
          onItemClick: this.editItem,
        },
      ];
    },
    getProducts() {
      return this.treatmentPlan?.products ?? [];
    },
    getObservations() {
      return this.treatment.observation?.length > 0
        ? this.treatment.observation
        : this.$t("No_Observation");
    },
    getPeriods() {
      const dateStart = moment(this.treatment.init_date);
      const dateEnd = moment(this.treatment.end_date);
      const timeValues = this.getTimeValues(
        dateStart,
        dateEnd,
        this.treatment.period
      );
      return this.treatment?.periods
        ?.map((period, index) => ({
          ...period,
          title: Object.keys(timeValues)[index],
          default_apply_unit: this.treatment.default_apply_unit[index],
        }))
        .filter((period) => {
          const doseVolumeNotEmpty = period.products.some(
            ({ dose_volume, active }) => dose_volume > 0 && active
          );

          return doseVolumeNotEmpty;
        });
    },
    getStatus() {
      return this.$t(this.treatment?.status?.toUpperCase()) ?? "";
    },
    getIconPath() {
      if (Device.cordova) {
        return this.environment === "development"
          ? "../../../static/img/"
          : "static/img/";
      }
      return this.environment === "development"
        ? "../../../static/img/"
        : "./static/img/";
    },
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      this.treatment = await treatmentPlanService.getById(
        this.id,
        this.currentPlantation.companyId
      );

      this.treatment.species = this.currentPlantation.species;
      await this.updateTreatmentPlan(this.treatment);

      const timeValues = this.getTimeValues(
        moment(this.treatment.init_date, "YYYY/MM/DD"),
        moment(this.treatment.end_date, "YYYY/MM/DD"),
        this.treatment.period
      );

      Object.entries(timeValues).forEach(([key, value], index) => {
        this.treatment.periods[index].products.forEach((item) => {
          if (item.dose_volume > 0 && item.active) {
            this.chartSeries.push({
              productName: item.product,
              monthWeek: key,
              value: item.dose_volume,
            });
          }
        });
      });
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    getClassStatus(status) {
      switch (status) {
        case treatmentPlanStatus.ACTIVE:
          return "label-success";
        case treatmentPlanStatus.CANCELLED:
          return "label-danger";
        default:
        case treatmentPlanStatus.PLANNED:
          return "label-primary";
      }
    },
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t("product")}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t("Dose")}: ${
          point.value
        } KG-L/HA</span> `,
      };
    },
    getTimeValues(dateStart, dateEnd, period) {
      const timeValues = {};
      switch (period) {
        case "SEMANAL":
          const firstYear = dateStart.year();
          const lastYear = dateEnd.year();

          for (let i = firstYear; i <= lastYear; i++) {
            const firstDayOfYear = moment([i]).startOf("year");
            const lastDayOfYear = moment([i]).endOf("year");
            let firstWeek = "";
            let lastWeek = "";

            if (dateStart.isAfter(firstDayOfYear)) {
              firstWeek = dateStart.isoWeek();
            } else {
              firstWeek = firstDayOfYear.isoWeek();
            }

            if (lastDayOfYear.isAfter(dateEnd)) {
              lastWeek = dateEnd.isoWeek();
            } else {
              lastWeek = lastDayOfYear.isoWeek();
            }

            if (!dateStart.isAfter(firstDayOfYear) && firstWeek !== 1) {
              const varName = `${firstWeek} - ${i - 1}`;
              timeValues[varName] = 0;
              firstWeek = 1;
            }

            for (let j = firstWeek; j <= lastWeek; j++) {
              const varName = `${j} - ${i}`;
              timeValues[varName] = 0;
            }
          }

          break;
        case "MENSUAL":
        default:
          while (
            dateEnd > dateStart ||
            dateStart.format("M") === dateEnd.format("M")
          ) {
            const varName = `${dateStart.format("MMM")} - ${dateStart.format(
              "yy"
            )}`;
            timeValues[varName] = 0;
            dateStart.add(1, "month");
          }
          break;
      }
      return timeValues;
    },
    goToViewCopyTreatmentPlan() {
      this.$f7.views.main.router.navigate("/viewCopyTreatmentPlan/", {
        reloadCurrent: true,
      });
    },
    cancel() {
      this.$f7.views.main.router.navigate("/searcher/treatment/", {
        reloadCurrent: true,
      });
    },
    formatingDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    viewReport() {
      this.openSelectObservationsReport();
    },
    async downloadReport(selectedObservations) {
      const pdfmaker = new PDFMaker();

      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;
      const headerDefault = [
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.product"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.plagueAgent"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.dosage"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.treatmentType"),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa"
        ),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costHa"),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal"
        ),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.cost"),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothTotalHa"
        ),
      ];
      const headerPlant = [
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.product"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.plagueAgent"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.dosage"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.treatmentType"),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa"
        ),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTree"),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeTree"
        ),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL"),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceTree"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothTotal"
        ),
      ];
      const headerCuva = [
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.product"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.plagueAgent"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.dosage"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.treatmentType"),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeHa"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVolumeL"
        ),
        this.$t(
          "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.brothVolumeHa"
        ),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceL"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costHa"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseVat"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.priceVat"),
        this.$t("treatment.treatmentPlan.treatmentCreatePlan.stepTwo.cost"),
      ];

      pdfmaker.insertTableWithoutBorders([
        [
          { title: this.$t("nutritionDetail.report.client"), text: AtcName },
          {
            title: this.$t("nutritionDetail.report.date"),
            text: `${this.treatment.init_date} - ${this.treatment.end_date}`,
          },
          {
            title: this.$t("nutritionDetail.report.description"),
            text: this.treatment.description,
          },
        ],
        [
          {
            title: this.$t("nutritionDetail.report.cropType"),
            text: this.currentPlantation.species,
          },
          {
            title: this.$t("nutritionDetail.report.variety"),
            text: this.currentPlantation.variety,
          },
          {
            title: this.$t("nutritionDetail.report.area"),
            text: `${this.treatment.area} ha`,
          },
        ],
      ]);

      const products = [];
      this.getProducts.forEach((product) => {
        products.push([product.name, product.agent, product.observation]);
      });

      pdfmaker.insertTableGeneric("RESUMEN POR ELEMENTOS", [
        [
          this.$t(
            "treatment.treatmentPlan.treatmentDetailPlan.products.product"
          ),
          this.$t("treatment.treatmentPlan.treatmentDetailPlan.plagueAgent"),
          this.$t("treatment.treatmentPlan.treatmentDetailPlan.annotation"),
        ],
        ...products,
      ]);

      this.getPeriods.forEach((period, index) => {
        const products = [];

        period.products.forEach((product) => {
          if (
            this.getDoseVolumeValueFirstType(product) > 0 ||
            this.getDoseVolumeValueSecondType(product) > 0
          ) {
            if (this.treatment.default_apply_unit[index] === "default") {
              products.push([
                product.product,
                product.agent,
                product.dose_type,
                product.treatment_type,
                this.getDoseVolumeValueFirstType(product),
                this.getDoseVolumeValueSecondType(product),
                product.broth_volume,
                product.price,
                this.getCostHaValue(product),
                this.getDoseTotal(product),
                this.getCostValue(product),
                this.getTotalBrothVolume(product),
              ]);
            } else if (
              this.treatment.default_apply_unit[index] === "Kg-L / planta"
            ) {
              products.push([
                product.product,
                product.agent,
                product.dose_type,
                product.treatment_type,
                this.getDoseVolumeValueFirstType(product),
                this.getDoseVolumeValueSecondType(product),
                product.broth_volume,
                this.getDoseTree(product),
                this.getBrothTree(product),
                product.price,
                this.getPriceTree(product),
                this.getDoseTotal(product),
                this.getCostTotal(product),
                this.getTotalBroth(product),
              ]);
            } else if (
              this.treatment.default_apply_unit[index] === "Kg-L / cuva"
            ) {
              products.push([
                product.product,
                product.agent,
                product.dose_type,
                product.treatment_type,
                this.getDoseVolumeValueFirstType(product),
                this.getDoseVolumeValueSecondType(product),
                product.broth_volume,
                product.price,
                this.getCostHaValue(product),
                this.getDoseVat(product),
                this.getPriceBroth(product),
                this.getCostValue(product),
              ]);
            }
          }
        });

        if (this.treatment.default_apply_unit[index] === "default") {
          pdfmaker.insertTableTreatmentTable(
            period.title,
            [headerDefault, ...products],
            [
              [
                this.$t(
                  "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed"
                ),
                this.treatment?.forward_speed
                  ? `${this.treatment?.forward_speed} km/h`
                  : "",
              ],
            ]
          );
        } else if (
          this.treatment.default_apply_unit[index] === "Kg-L / planta"
        ) {
          pdfmaker.insertTableTreatmentTable(
            period.title,
            [headerPlant, ...products],
            [
              [
                this.$t("nutritionDetail.report.plantsHa"),
                this.actualPlantation?.plants_density ?? "",
              ],
              [
                this.$t(
                  "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed"
                ),
                this.treatment?.forward_speed
                  ? `${this.treatment?.forward_speed} km/h`
                  : "",
              ],
            ]
          );
        } else if (this.treatment.default_apply_unit[index] === "Kg-L / cuva") {
          pdfmaker.insertTableTreatmentTable(
            period.title,
            [headerCuva, ...products],
            [
              [
                this.$t(
                  "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatVolume"
                ),
                this.treatment?.vat_volume
                  ? `${this.treatment?.vat_volume} L`
                  : "",
              ],
              [
                this.$t(
                  "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumberHa"
                ),
                this.getNumberVatsHa(period),
              ],
              [
                this.$t(
                  "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumber"
                ),
                this.getNumberVats(period),
              ],
              [
                this.$t(
                  "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed"
                ),
                this.treatment?.forward_speed
                  ? `${this.treatment?.forward_speed} km/h`
                  : "",
              ],
            ]
          );
        }
      });

      // const totalProducts = {};

      // this.getPeriods.forEach((period) => {
      //   period.products.forEach((product, index) => {
      //     if (totalProducts[index] == null) {
      //       totalProducts[index] = {
      //         name: product.product,
      //         dose: product.dose_volume,
      //         cost: product.dose_volume * product.price
      //       }
      //     } else {
      //       totalProducts[index].dose += product.dose_volume;
      //       totalProducts[index].cost += product.dose_volume * product.price;
      //     }
      //   })
      // })

      // console.log(this.getProducts)
      // console.log(totalProducts)

      const totalProducts = [];

      this.getProducts.forEach((product) => {
        totalProducts.push([product.name, 0, 0]);
      });

      pdfmaker.insertTableGeneric("TOTAL", [
        [
          this.$t("treatment.treatmentPlan.treatmentCreatePlan.product"),
          this.$t(
            "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.doseTotal"
          ),
          this.$t(
            "treatment.treatmentPlan.treatmentCreatePlan.stepTwo.costTotal"
          ),
        ],
        ...totalProducts,
      ]);

      const observations = await Promise.all(
        selectedObservations.map(async (item, index) => {
          const xhr = await Api.getObservationDetail(
            this.currentPlantation.companyId,
            item.id
          );
          const observation = JSON.parse(xhr.response);

          const comment = [];

          observation.template_form.forEach((element) => {
            comment.push([element.description, observation[element.field]]);
          });

          return {
            id: observation.id,
            title: `Observación ${index + 1}:`,
            date: observation.date,
            multimedia: observation.multimedia.filter(
              (extension) =>
                extension.extension === ".jpg" || extension.extension === ".png"
            ),
            comment,
          };
        })
      );

      const temporalImage = await this.imageToDataUrl(this.reportHeaderImage);

      if (
        observations.length > 0 ||
        (this.treatment.observation !== "" &&
          this.treatment.observation != null)
      ) {
        pdfmaker.insertObservations(
          this.treatment.observation,
          [...observations],
          temporalImage
        );
      }

      pdfmaker.insertTableBottomSignature([
        [
          ["Nombre", AtcName],
          ["Fecha", moment().format("DD/MM/YYYY")],
          ["Firma", ""],
        ],
      ]);

      const title = `${this.currentPlantation.name} - ${this.treatment.description} - ${moment().format("DD-MM-YYYY")}`;

      if (Device.desktop) {
        pdfmaker.buildAndDownload(title);
      } else {
        const pdfdata = pdfmaker.buildForMobile();

        pdfdata.getBase64((base64) => {
          const path = `${cordova.file.externalRootDirectory}Download/`;
          const fileExtension = ".pdf";

          this.savebase64AsPDF(
            path,
            title + fileExtension,
            base64,
            "application/pdf"
          );
        });
      }
    },
    editItem() {
      this.setEditingFromContextMenu(this.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: "utilities_treatment_treatment-step-one",
        params: { id: this.id },
      });
      this.$f7.views.main.router.navigate(url, { reloadCurrent: true });
    },
    setOwner(data) {
      this.owner = data;
    },
    async imageToDataUrl(path) {
      const blob = await fetch(path).then((res) => res.blob());
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              this.$f7.dialog.alert(this.$t("FileDownloadedInDownloads"));
            },
            () => {
              this.$f7.dialog.alert(this.$t("SheetExport_sheet_export_error"));
            }
          );
        });
      });
    },
    openSelectObservationsReport() {
      this.showSelectObservationsReport = true;
    },
    closeSelectObservationsReport() {
      this.showSelectObservationsReport = false;
    },
    getNumberVatsHa(period) {
      const broth_volume = period.products[0]?.broth_volume ?? 0;

      return this.calculateFormula(11, {
        broth_volume: broth_volume,
        vat_volume: this.treatmentPlan.vat_volume,
      });
    },
    getNumberVats(period) {
      const number_vats_ha = this.getNumberVatsHa(period);

      return this.calculateFormula(12, {
        number_vats_ha,
        area: this.currentPlantation.area,
      });
    },
    getDoseVolumeValueFirstType(data) {
      const type = "kG-L/HA";

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      return this.calculateFormula(2, data);
    },
    getDoseVolumeValueSecondType(data) {
      const type = "kG-L/1000L";

      if (data.dose_type === type) {
        return data.dose_volume;
      }

      return this.calculateFormula(1, data);
    },
    getCostHaValue(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);

      return this.calculateFormula(3, {
        dose_volume,
        price: data.price,
      });
    },
    getCostValue(data) {
      const cost_ha = this.getCostHaValue(data);

      return this.calculateFormula(4, {
        cost_ha,
        area: this.currentPlantation.area,
      });
    },
    getTotalBrothVolume(data) {
      return this.calculateFormula(5, {
        broth_volume: data.broth_volume,
        area: this.currentPlantation.area,
      });
    },
    getDoseTotal(data) {
      return this.calculateFormula(10, {
        dose_volume: this.getDoseVolumeValueFirstType(data),
        area: this.currentPlantation.area,
      });
    },
    getDoseTree(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);

      return this.calculateFormula(13, {
        dose_volume,
        plants_density: this.actualPlantation.plants_density,
      });
    },
    getBrothTree(data) {
      return this.calculateFormula(14, {
        broth_volume: data.broth_volume,
        plants_density: this.actualPlantation.plants_density,
      });
    },
    getCostTotal(data) {
      const dose_total = this.getDoseTotal(data);

      return this.calculateFormula(15, {
        price: data.price,
        dose_total,
      });
    },
    getTotalBroth(data) {
      return this.calculateFormula(5, {
        broth_volume: data.broth_volume,
        area: this.currentPlantation.area,
      });
    },
    getPriceTree(data) {
      const dose_tree = this.getDoseTree(data);

      return this.calculateFormula(16, {
        price: data.price,
        dose_tree,
      });
    },
    getDoseVat(data) {
      const dose_volume = this.getDoseVolumeValueFirstType(data);
      const vat_volume = data.broth_volume / this.treatment.vat_volume;

      return this.calculateFormula(17, {
        dose_volume,
        vat_volume,
      });
    },
    getPriceBroth(data) {
      const dose_volume_cuba = this.getDoseVat(data);

      return this.calculateFormula(18, {
        dose_volume_cuba,
        price: data.price,
      });
    },
    calculateFormula(type, data) {
      let result = 0;
      // Dosis (kg-L/1000L)= Dosis (kg-L/ha)/(Vol. Caldo (l/ha)/1000)
      if (type === 1) {
        result = data.dose_volume / (data.broth_volume / 1000);
      }

      // Dosis (kg-L/ha) =Dosis (kg-L/1000L) * (Vol. Caldo (l/ha)/1000)
      if (type === 2) {
        result = data.dose_volume * (data.broth_volume / 1000);
      }

      // Coste/ha = Dosis kg-L/ha * Precio €/Kg-L
      if (type === 3) {
        result = data.dose_volume * data.price;
      }

      // Coste = coste (€/ha) * superficie (ha)
      if (type === 4) {
        result = data.cost_ha * data.area;
      }

      // Vol caldo total = Vol caldo (L/ha) *  Superficie (ha)
      if (type === 5) {
        result = data.broth_volume * data.area;
      }

      // Dosis kg-L Total = Dosis (kg-l/ha) * Superficie (ha)
      if (type === 10) {
        result = data.dose_volume * data.area;
      }

      // Nº de cubas/ha = Volumen de caldo (L/ha) / Volumen de cuba (L)
      if (type === 11) {
        result = data.broth_volume / data.vat_volume;
      }

      // Nº de cubas = Nº de cubas/ha * Superficie (ha)
      if (type === 12) {
        result = data.number_vats_ha * data.area;
      }

      // Dosis (g-ml)/árbol = Dosis (kg-L/ha) / nº plantas (ha) *1000
      if (type === 13) {
        result = (data.dose_volume / data.plants_density) * 1000;
      }

      // Vol caldo (L/árbol) = Vol. caldo (l/ha) / nº plantas (ha)
      if (type === 14) {
        result = data.broth_volume / data.plants_density;
      }

      // Coste total = Precio (€/kg-L) * Dosis kg-L Total
      if (type === 15) {
        result = data.price * data.dose_total;
      }

      // Precio/árbol = Precio (€/kg-L) * Dosis (g-ml)/árbol /1000
      if (type === 16) {
        result = (data.price * data.dose_tree) / 1000;
      }

      // Dosis (kg-L/Cuba) = Dosis (kg-l/ha) / Vol.Caldo (l/ha)
      // Dosis (kg-L/Cuba) = Dosis (kg-l/ha) / Vol.cuba
      if (type === 17) {
        result = data.dose_volume / data.vat_volume;
      }

      // Precio (€/cuba) = precio (€/kg-l) / dosis (kg-l/cuba)
      // Precio (€/cuba) = precio (€/kg-l) * dosis (kg-l/cuba)
      if (type === 18) {
        result = data.price * data.dose_volume_cuba;
      }

      const fixedResult = Number(result.toFixed(3));

      if (isNaN(fixedResult) || !isFinite(fixedResult)) {
        return 0;
      } else {
        return fixedResult;
      }
    },
    ...mapActions("Plans", ["getPlanById"]),
    ...mapActions("treatmentStepper", ["updateTreatmentPlan"]),
    ...mapActions("treatment", ["setEditingFromContextMenu"]),
  },
};
</script>
<style src="./treatmentDetailPlan.scss" scoped lang="scss"></style>
