<template>
  <f7-page name="treatment-step-one">
    <!--
            Se espera a que todo termine de cargar, porque la tabla 'dosage-product'
            falla si no tiene los datos a tiempo.
        -->
    <DxLoadPanel
      v-if="!loaded"
      :visible="true"
      shading-color="rgba(0,0,0,0.4)"
    />
    <div v-else>
      <navbar
        :text="`${currentPlantation.company} > ${
          currentPlantation.explotation
        } > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${
          currentPlantation.campaign
        } > ${currentPlantation.name} > ${$t('treatment.title')}`"
      />
      <stepper />
      <dosage-product @owner="setOwner" :isEditing="isEditing" />
      <treatment-observations />
      <stepper-navigation
        :disabled="disabledNextButton"
        :alertMessage="alertMessage"
      />
      <PopupConfirmWizardExit />
      <ProductPopupDetail :owner="owner" v-if="displayProductDetailPopup" />
    </div>
  </f7-page>
</template>

<script>
  import { DxLoadPanel } from 'devextreme-vue/load-panel';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import navbar from '../../../components/NavBar.vue';
  import Stepper from '../../../components/wizardTreatment/stepper/index.vue';
  import StepperNavigation from '../../../components/wizardTreatment/stepper-navigation/index.vue';
  import DosageProduct from '../../../components/wizardTreatment/dosageProduct/index.vue';
  import { treatmentPlanService } from '../../../services/treatmentPlanService';
  import PopupConfirmWizardExit from '@/components/wizardTreatment/popupConfirmWizardExit/index.vue';
  import ProductPopupDetail from '@/components/wizardTreatment/productDetail/index.vue';
  import TreatmentObservations from '../../../components/wizardTreatment/treatmentObservations/index.vue';
  import moment from 'moment';

  export default {
    name: 'treatment-step-one',
    components: {
      Stepper,
      StepperNavigation,
      navbar,
      DosageProduct,
      DxLoadPanel,
      PopupConfirmWizardExit,
      ProductPopupDetail,
      TreatmentObservations
    },
    props: ['id'],
    data() {
      return {
        loaded: false,
        productDetailVisible: false,
        owner: '',
      };
    },
    computed: {
      isEditing() {
        return this.id;
      },
      disabledNextButton() {
        return this.alertMessage.length > 0;
      },
      alertMessage() {
        if (
          this.treatmentPlan.products.some(product => product?.product_deleted ?? false)
        ) {
          return this.$t('productsDeleted');
        }

        if (this.treatmentPlan.description === '') {
          return this.$t(
            'treatment.treatmentCreateOrder.form.description_validation',
          );
        }

        if (
          this.treatmentPlan.init_date === undefined ||
          this.treatmentPlan.init_date === '' || !moment(this.treatmentPlan.init_date).isValid()
        ) {
          return this.$t('treatment.treatmentCreateOrder.form.date_validation');
        }

        if (
          this.treatmentPlan.end_date === undefined ||
          this.treatmentPlan.end_date === '' || !moment(this.treatmentPlan.end_date).isValid()
        ) {
          return this.$t('treatment.treatmentCreateOrder.form.date_validation');
        }

        if (this.treatmentPlan.default_apply_unit.some(unit => unit === "Kg-L / cuva") && (this.treatmentPlan.vat_volume === "" || this.treatmentPlan.vat_volume === null)) {
          return this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.vatVolumeRequired',
          );
        }

        if (this.treatmentPlan.forward_speed === "" || this.treatmentPlan.forward_speed === null) {
          return this.$t(
            'treatment.treatmentPlan.treatmentCreatePlan.forwardSpeedRequired',
          );
        }

        return '';
      },
      ...mapState('Plantation', ['currentPlantation']),
      ...mapState('treatmentStepper', ['treatmentPlan']),
      ...mapState('treatment', ['displayProductDetailPopup']),
      ...mapGetters('treatmentStepper', ['treatmentError']),
    },
    async mounted() {
      this.loaded = false;
      if (this.id) {
        const response = await treatmentPlanService.getById(
          this.id,
          this.currentPlantation.companyId,
        );
        response.species = this.currentPlantation.species;
        await this.updateTreatmentPlan(response);
      }

      this.loaded = true;
      this.setStep(0);
    },
    beforeDestroy() {
      this.setEditingFromContextMenu(null);
    },
    methods: {
      setOwner(data) {
        this.owner = data;
      },
      ...mapActions('treatmentStepper', ['setStep', 'updateTreatmentPlan']),
      ...mapActions('treatment', ['setEditingFromContextMenu']),
    },
  };
</script>
<style src="./treatment-step-one.scss" scoped lang="scss"></style>
