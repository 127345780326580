import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    allSlices: [],
    SelectedSlices: '',
    allFamilies: [],
    SelectedFamily: '',
    aLLProduct: [],
    SelectedProduct: '',
    allData: [],
    dataProduct: [],
    dataDate: [],
    allOrigins: [],
    SelectedOrigin: '',
    initDate: '',
    endDate: '',
    AllVarieties: '',
    SelectedVariety: '',
  },
  mutations: {
    UPDATE_ALL_SLICES(state, value) {
      state.allSlices = value;
    },
    UPDATE_ALL_FAMILIES(state, value) {
      state.allFamilies = value;
    },
    UPDATE_ALL_PRODUCTS(state, value) {
      state.aLLProduct = value;
    },
    UPDATE_ALL_DATA(state, value) {
      state.allData = value;
    },
    UPDATE_ALL_ORIGINS(state, value) {
      state.allOrigins = value;
    },
    UPDATE_ORIGIN(state, value) {
      state.SelectedOrigin = value;
    },
    UPDATE_SLICE(state, value) {
      state.SelectedSlices = value;
    },
    UPDATE_FAMILY(state, value) {
      state.SelectedFamily = value;
    },
    UPDATE_PRODUCT(state, value) {
      state.SelectedProduct = value;
    },
    UPDATE_INIT_DATE(state, value) {
      state.initDate = value;
    },
    UPDATE_END_DATE(state, value) {
      state.endDate = value;
    },
    UPDATE_DATA_PRODUCT(state, value) {
      state.dataProduct.push(value);
    },
    UPDATE_DATA_DATE(state, value) {
      if (state.dataDate.length !== 0) {
        [].push.apply(state.dataDate, value); // concatenar
      } else {
        state.dataDate = value;
      }
    },
    UPDATE_DATAPRODUCT_RESET(state, value) {
      state.dataProduct = value;
    },
    UPDATE_DATADATE_RESET(state, value) {
      state.dataDate = value;
    },
  },
  actions: {
    async getAllSlices({ commit }) {
      try {
        const xhr = await Api.getDataMarketSlices();
        const Slices = JSON.parse(xhr.response);

        commit(
          'UPDATE_ALL_SLICES',
          Slices.markets,
        );
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
    async getAllFamilies({ commit, state }) {
      try {
        const xhr = await Api.getDataMarketFamilies(state.SelectedSlices);
        const families = JSON.parse(xhr.response);
        commit(
          'UPDATE_ALL_FAMILIES',
          families,
        );
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
    async getProductForFamilyAndSlices({ commit, state }) {
      try {
        const xhr = await Api.getDataMarketProduct(state.SelectedSlices, state.SelectedFamily);
        const { products } = JSON.parse(xhr.response);
        commit(
          'UPDATE_ALL_PRODUCTS',
          products,
        );
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
    async getOrigins({ commit, state }) {
      try {
        const xhr = await Api.getDataMarketOrigins(state.SelectedSlices, state.SelectedFamily, state.SelectedProduct,
          state.initDate, state.endDate);
        const { origins } = JSON.parse(xhr.response);
        commit(
          'UPDATE_ALL_ORIGINS',
          origins,
        );
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
    async getData({ commit, state }) {
      try {
        const xhr = await Api.getDataMarketData(state.SelectedSlices, state.SelectedFamily, state.SelectedProduct,
          state.SelectedOrigin, state.initDate, state.endDate);
        const data = JSON.parse(xhr.response);
        const dataParameters = [];
        const dataDate = [];
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].origin === state.SelectedOrigin) {
            dataDate.push({
              date: data[i].date,
              [state.SelectedProduct]: Number(data[i].price),
            });
          }
        }
        dataParameters.push({
          name: `${state.SelectedProduct}-${state.SelectedOrigin}`,
          value: state.SelectedProduct,
        });
        commit(
          'UPDATE_DATA_PRODUCT',
          dataParameters,
        );
        commit(
          'UPDATE_DATA_DATE',
          dataDate,
        );
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
    setSlice(context, value) {
      context.commit('UPDATE_SLICE', value);
    },
    setFamily(context, value) {
      context.commit('UPDATE_FAMILY', value);
    },
    setProduct(context, value) {
      context.commit('UPDATE_PRODUCT', value);
    },
    setInitDate(context, value) {
      context.commit('UPDATE_INIT_DATE', value);
    },
    setEndDate(context, value) {
      context.commit('UPDATE_END_DATE', value);
    },
    setOrigin(context, value) {
      context.commit('UPDATE_ORIGIN', value);
    },
    initStoreGraph({ commit }) {
      commit('UPDATE_DATAPRODUCT_RESET', []);
      commit('UPDATE_DATADATE_RESET', []);
    },
    initStoreRegister({ commit }) {
      commit('UPDATE_INIT_DATE', '');
      commit('UPDATE_END_DATE', '');
      commit('UPDATE_SLICE', '');
      commit('UPDATE_FAMILY', '');
      commit('UPDATE_PRODUCT', '');
      commit('UPDATE_ALL_ORIGINS', []);
    },
  },
};
