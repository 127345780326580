<template>
  <div
    class="content-devx"
  >
    <f7-block-title>
      <f7-row>
        <f7-col width="20">
          {{ $t('Application') }}
        </f7-col>
        <f7-col width="80" />
      </f7-row>
    </f7-block-title>
    <f7-block>
      <f7-row class="margin-bottom">
        <f7-col width="50">
          <f7-row class="margin-bottom">
            {{ $t('Main_Elements') }}
          </f7-row>
          <f7-row />
          <DxDataGrid
            id="mainElements-grid"
            :data-source="[application]"
            :column-min-width="90"
            :column-auto-width="true"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
          >
            <DxColumn
              data-field="NT"
              :caption="'N ' + unit"
              :allow-header-filtering="false"
              alignment="left"
            />
            <DxColumn
              data-field="P2015_Total"
              :caption="'P₂O₅ ' + unit"
              :allow-header-filtering="false"
              alignment="left"
            />
            <DxColumn
              data-field="K2O"
              :caption="'K₂O ' + unit"
              :allow-header-filtering="true"
              alignment="left"
            />
          </dxdatagrid>
        </f7-col>
        <f7-col width="50">
          <f7-row class="margin-bottom">
            {{ $t('Secondary_Elements') }}
          </f7-row>
          <f7-row>
            <DxDataGrid
              id="secondaryElements-grid"
              :data-source="[application]"
              :column-min-width="90"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
            >
              <DxColumn
                data-field="CaO"
                :caption="'CaO ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="MgO"
                :caption="'MgO ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="SO3"
                :caption="'SO₃ ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
            </Dxdatagrid>
          </f7-row>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="50">
          <f7-row>
            {{ $t('Microelements') }}
          </f7-row>
          <f7-row>
            <DxDataGrid
              id="microelements-grid"
              :data-source="[application]"
              :column-min-width="90"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
            >
              <DxColumn
                data-field="Cu"
                :caption="'Cu ' + unit"
                :allow-header-filtering="true"
                alignment="left"
              />
              <DxColumn
                data-field="Fe"
                :caption="'Fe ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="Mn"
                :caption="'Mn ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="Zn"
                :caption="'Zn ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="Se"
                :caption="'Se ' + unit"
                :allow-header-filtering="false"
                alignment="left"
              />
            </Dxdatagrid>
          </f7-row>
        </f7-col>
        <f7-col width="50" />
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
} from 'devextreme-vue/data-grid';

export default {
  name: 'Application',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    application: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      unit: '(% p/p)',
    };
  },
};
</script>
<style lang="scss" scoped>
@import './Application.styles.scss';
</style>
