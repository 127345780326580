var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DxPopup',{attrs:{"id":"attributesPopup-popup","title":_vm.$t('Configuration.ObservationsForms.attributesTitle'),"visible":_vm.showAttributePopup,"show-close-button":true,"on-hidden":_vm.onHidden,"close-on-outside-click":false,"height":"auto","width":650,"position":{my: 'bottom', at: 'center'}}},[_c('DxForm',{attrs:{"read-only":_vm.readOnly}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.ObservationsForms.description'),"editor-options":{
            value: _vm.currentAttribute.description,
            placeholder: _vm.$t('Configuration.ObservationsForms.description'),
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
            width: '50%'
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Configuration.ObservationsForms.descriptionRequired')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.ObservationsForms.tipology'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.inputTypes,
            value: _vm.currentAttribute.type,
            placeholder: _vm.$t('Configuration.ObservationsForms.tipology'),
            onValueChanged: _vm.onValueChanged,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            displayExpr: _vm.displayTranslatedType,
            width: '50%'
          }}},[_c('DxRequiredRule')],1)],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.required'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.currentAttribute.required,
            onValueChanged: _vm.onValueChanged,
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"visible":_vm.currentAttribute.type === 'STRING'}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.minLength'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.currentAttribute.min_length,
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
            width: '50%',
            min: 0,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.maxLength'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.currentAttribute.max_length,
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
            width: '50%',
            min: 0,
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"visible":_vm.currentAttribute.type === 'INT' || _vm.currentAttribute.type === 'FLOAT'}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.minValue'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.currentAttribute.min_value,
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
            width: '50%',
            min: 0,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.maxValue'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.currentAttribute.max_value,
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
            width: '50%',
            min: 0,
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"visible":_vm.currentAttribute.type === 'FLOAT'}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.decimalPlaces'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.currentAttribute.decimal_places,
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
            width: '50%',
            max: 5,
            min: 1,
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"visible":_vm.currentAttribute.type === 'SELECT' || _vm.currentAttribute.type === 'LIST'}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.choices'),"editor-options":{
            value: _vm.currentAttribute.choices.join(),
            placeholder: _vm.$t('Configuration.AttributesPopup.choices'),
            showClearButton: true,
            onValueChanged: _vm.onValueChanged,
          },"help-text":_vm.$t('Configuration.ObservationsForms.choicesHelpText')}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"visible":_vm.currentAttribute.type === 'BOOLEAN'}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Configuration.AttributesPopup.default'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.currentAttribute.default,
            onValueChanged: _vm.onValueChanged,
          }}})],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col'),_vm._v(" "),_c('f7-col',[(!_vm.readOnly)?_c('f7-button',{staticClass:"dx-btn-success margin-bottom-block",attrs:{"raised":"","type":"success","styling-mode":"contained","name":"ButtonSave"},on:{"click":_vm.savePopup}},[_vm._v("\n          "+_vm._s(_vm.$t('Button_save_text'))+"\n        ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }