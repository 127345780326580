import { mapGetters, mapActions } from 'vuex';
import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';

export default {
  name: 'stepper',
  components: {
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
  },
  props: [],
  data() {
    return {
      stepSelected: {},
      temporalFrameId: 0,
      planPeriods: [],
    };
  },
  computed: {
    title() {
      if (typeof this.treatmentPlan.id === 'undefined') {
        return this.$t('treatment.treatmentPlan.treatmentCreatePlan.title');
      }

      return this.$t('treatment.treatmentPlan.treatmentCreatePlan.editTitle');
    },
    ...mapGetters('treatmentStepper', ['treatmentSteps', 'currentStep', 'stepIndex', 'treatmentPlan']),
  },
  created() {
    this.updateClasses();
  },
  methods: {

    setCurrentStep(index) {
      this.setStep(index);
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    ...mapActions('treatmentStepper', ['next', 'back', 'findSelectedIndex', 'updateClasses', 'setStep']),
  },
  watch: {
  },
};
