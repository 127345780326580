<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Vademecum_Title')}`" />
    <phytosanitaryProducts />
  </f7-page>
</template>

<script>
import navbar from '../../../components/NavBar.vue';
import phytosanitaryProducts from '../../../components/utilities/vademecum/phytosanitaryProducts/index.vue';
import nutritionalProducts from '../../../components/utilities/vademecum/nutritionalProducts/index.vue';

export default {
  name: 'Vademecum',
  components: {
    navbar,
    phytosanitaryProducts,
    nutritionalProducts,
  },
};
</script>
