<template v-if="loaded">
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Tasks_Title')} > ${$t('Configuration.Create_Task')} `" />

    <task-definition-form
      :activities="activities"
      :task-definition-detail="taskDefinitionDetail"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goToTasks"
        >
          {{ $t("Go_Back") }}
        </f7-button>
      </f7-col>
      <f7-col
        class="justify-content-end margin-right"
      >
        <f7-button
          class="dx-btn-success btn-width-100"
          raised
          type="success"
          styling-mode="contained"
          @click="createNewTask"
        >
          {{ $t("Configuration.Create_Task") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import TaskDefinitionForm from '../../../components/configuration/tasks/taskDefinitionForm/index.vue';

export default {
  name: 'CreateTaskDefinition',
  components: {
    navbar,
    TaskDefinitionForm,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('TaskDefinition', ['activities', 'taskDefinitionDetail']),
  },
  async beforeMount() {
    this.$f7.preloader.show();

    try {
      await this.fetchActivities();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToTasks() {
      this.$f7.views.main.router.navigate('/tasks/', { reloadCurrent: true });
    },
    async createNewTask() {
      if (this.taskDefinitionDetail.name === '') {
        this.$f7.dialog.alert(this.$t('Configuration.Name_validation'));
      } else if (this.taskDefinitionDetail.activity === '') {
        this.$f7.dialog.alert(this.$t('Configuration.Activity_validation'));
      } else {
        try {
          await this.createTaskDefinition(this.taskDefinitionDetail);
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.views.main.router.navigate('/tasks/', { reloadCurrent: true });
        }
      }
    },
    ...mapActions('TaskDefinition', ['fetchActivities', 'createTaskDefinition']),
  },

};
</script>
