import Api from '../../services/Api';
import Product from '../../js/models/Product';

export default {
  namespaced: true,
  state: {
    allProduct: [],
    allProductSync: [],
    showPopUp: false,
    isEditing: false,
    productDetail: {},
    mainElement: [{
      NT: 0,
      P2015_Total: 0,
      K2O: 0,
    }],
    alterElement: [{
      CaO: 0,
      MgO: 0,
      SO3: 0,
    }],
    microElement: [{
      B: 0,
      Cu: 0,
      Fe: 0,
      Mn: 0,
      Zn: 0,
      Se: 0,
      MO: 0,
      I: 0,
    }],
    actualName: '',
    actualTipology: 4,
    actualEcology: '',
  },
  mutations: {
    UPDATE_ALL_PRODUCT(state, value) {
      state.allProduct = value;
    },
    UPDATE_ALL_PRODUCT_SYNC(state, value) {
      state.allProductSync = value;
    },
    UPDATE_SHOW_POPUP(state, value) {
      state.showPopUp = value;
    },
    UPDATE_MAIN_ELEMENTS(state, value) {
      state.mainElement = value;
    },
    UPDATE_SECONDARY_ELEMENTS(state, value) {
      state.alterElement = value;
    },
    UPDATE_MICROELEMENTS(state, value) {
      state.microElement = value;
    },
    UPDATE_NAME(state, value) {
      state.actualName = value;
    },
    UPDATE_ACTUAL_TIPOLOGY(state, value) {
      state.actualTipology = value;
    },
    UPDATE_ACTUAL_ECOLOGY(state, value) {
      state.actualEcology = value;
    },
    GET_PRODUCT_DETAIL(state, value) {
      state.productDetail = value;
      state.actualName = value.name;
      state.actualTipology = value.tipology;
      state.actualEcology = value.ecologic;
      state.mainElement = [{
        NT: value.NT,
        P2015_Total: value.P2015_Total,
        K2O: value.K2O,
      }];
      state.alterElement = [{
        CaO: value.CaO,
        MgO: value.MgO,
        SO3: value.SO3,
      }];
      state.microElement = [{
        B: value.B,
        Cu: value.Cu,
        Fe: value.Fe,
        Mn: value.Mn,
        Zn: value.Zn,
        Se: value.Se,
        MO: value.MO,
        I: value.I,
      }];
    },
    SET_IS_EDITING(state, value) {
      state.isEditing = value;
    },
  },
  actions: {
    async getAllProduct({ commit }) {
      try {
        const xhr = await Api.getProducts();
        const productAvailables = JSON.parse(xhr.response).data;

        const dataProductFormatted = [];
        for (let i = 0; i < productAvailables.length; i += 1) {
          dataProductFormatted.push({
            id: productAvailables[i].id,
            name: productAvailables[i].name,
            code: productAvailables[i].code,
            NT: productAvailables[i].NT,
            P2015_Total: productAvailables[i].P2015_Total,
            K2O: productAvailables[i].K2O,
            CaO: productAvailables[i].CaO,
            MgO: productAvailables[i].MgO,
            SO3: productAvailables[i].SO3,
            B: productAvailables[i].B,
            Cu: productAvailables[i].Cu,
            Fe: productAvailables[i].Fe,
            Mn: productAvailables[i].Mn,
            Zn: productAvailables[i].Zn,
            Se: productAvailables[i].Se,
            MO: productAvailables[i].MO,
            I: productAvailables[i].I,
            owner: productAvailables[i].owner,
            tipology: productAvailables[i].tipology,
            ecologic: productAvailables[i].ecologic,
            status: productAvailables[i].status,
          });
        }
        commit(
          'UPDATE_ALL_PRODUCT',
          dataProductFormatted,
        );
      } catch (e) {
        // Error controlado en el componente.
      }
    },
    async getAllProductSync({ commit }, owner) {
      try {
        const xhr = await Api.getProductsSync(owner);
        const productAvailables = JSON.parse(xhr.response).data;

        // productAvailables = productAvailables.filter(product => product.owner === owner);

        const dataProductFormatted = [];
        for (let i = 0; i < productAvailables.length; i += 1) {
          dataProductFormatted.push({
            id: productAvailables[i].id,
            name: productAvailables[i].name,
            code: productAvailables[i].code,
            NT: productAvailables[i].NT,
            P2015_Total: productAvailables[i].P2015_Total,
            K2O: productAvailables[i].K2O,
            CaO: productAvailables[i].CaO,
            MgO: productAvailables[i].MgO,
            SO3: productAvailables[i].SO3,
            B: productAvailables[i].B,
            Cu: productAvailables[i].Cu,
            Fe: productAvailables[i].Fe,
            Mn: productAvailables[i].Mn,
            Zn: productAvailables[i].Zn,
            Se: productAvailables[i].Se,
            MO: productAvailables[i].MO,
            I: productAvailables[i].I,
            owner: productAvailables[i].owner,
            tipology: productAvailables[i].tipology,
            ecologic: productAvailables[i].ecologic,
            status: productAvailables[i].status,
          });
        }

        commit(
          'UPDATE_ALL_PRODUCT_SYNC',
          dataProductFormatted,
        );
      } catch (e) {
        // Error controlado en el componente.
      }
    },
    async createProduct({ rootGetters }, newProduct) {
      try {
        await Api.createNewProduct(rootGetters['Explotaciones/actualUser'].id, newProduct);
      } catch (reason) {
        // Error controlado en el componente.
      }
    },
    setShowPopUp(context, value) {
      context.commit('UPDATE_SHOW_POPUP', value);
    },
    setMainElement(context, value) {
      context.commit('UPDATE_MAIN_ELEMENTS', value);
    },
    setAlterElement(context, value) {
      context.commit('UPDATE_SECONDARY_ELEMENTS', value);
    },
    setMicroElement(context, value) {
      context.commit('UPDATE_MICROELEMENTS', value);
    },
    setActualName(context, value) {
      context.commit('UPDATE_NAME', value);
    },
    setActualTipology(context, value) {
      context.commit('UPDATE_ACTUAL_TIPOLOGY', value);
    },
    setActualEcology(context, value) {
      context.commit('UPDATE_ACTUAL_ECOLOGY', value);
    },
    initStore({ commit }) {
      commit('UPDATE_NAME', '');
      commit('UPDATE_ACTUAL_TIPOLOGY', 4);
      commit('UPDATE_ACTUAL_ECOLOGY', '');
      commit('UPDATE_MAIN_ELEMENTS', [{
        NT: 0,
        P2015_Total: 0,
        K2O: 0,
      }]);
      commit('UPDATE_SECONDARY_ELEMENTS', [{
        CaO: 0,
        MgO: 0,
        SO3: 0,
      }]);
      commit('UPDATE_MICROELEMENTS', [{
        B: 0,
        Cu: 0,
        Fe: 0,
        Mn: 0,
        Zn: 0,
        Se: 0,
        MO: 0,
        I: 0,
      }]);
    },
    async deleteProduct(context, idProduct) {
      try {
        await Api.deleteProduct(idProduct);
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    async getProductDetail(context, idProduct) {
      try {
        const xhr = await Api.getProductDetail(idProduct);
        const productDetail = JSON.parse(xhr.response);
        context.commit('GET_PRODUCT_DETAIL', productDetail);
      } catch (error) {
      // Error controlado en el componente.
      }
    },
    async updateProduct({ state }) {
      try {
        await Api.updateProduct({
          code: state.productDetail.code,
          name: state.actualName,
          NT: state.mainElement[0].NT,
          P2015_Total: state.mainElement[0].P2015_Total,
          K2O: state.mainElement[0].K2O,
          CaO: state.alterElement[0].CaO,
          MgO: state.alterElement[0].MgO,
          SO3: state.alterElement[0].SO3,
          B: state.microElement[0].B,
          Cu: state.microElement[0].Cu,
          Fe: state.microElement[0].Fe,
          Mn: state.microElement[0].Mn,
          Zn: state.microElement[0].Zn,
          Se: state.microElement[0].Se,
          MO: state.microElement[0].MO,
          I: state.microElement[0].I,
          tipology: state.actualTipology,
          ecologic: state.actualEcology,
          status: state.productDetail.status,
        },
        state.productDetail.id);
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    setIsEditing(context, value) {
      context.commit('SET_IS_EDITING', value);
    },
  },
};
