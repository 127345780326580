<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${$t('Page_subtitle_clients')} > ${actualUser.name} > ${$t('Location_title')}`" />
    <RegisterLocation
      :title="this.$t('Edit_farm')"
      :current-explotation="{
        name,
        registerNumber,
        province,
        farm,
        ownerFarm,
        mobile,
        postalCode,
      }"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="registerFarmAndCreatePlot"
        >
          {{ $t("Button_go_create_new_Campania") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegisterLocation from '../../components/systemParcelExplotaciones/RegsiterLocation/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'RegisterLocationPage',
  components: {
    RegisterLocation,
    navbar,
  },
  data() {
    return {
      currentExplotation: {},
    };
  },
  computed: {
    ...mapState('RegisterLocation', ['name', 'registerNumber', 'province', 'mobile', 'farm', 'ownerFarm', 'registerExplotationId', 'postalCode']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
  },
  beforeMount() {
    this.setShowPopUp(false);
    this.setName(this.actualLocation.name);
    this.setRegisterNumber(this.actualLocation.properties.number_register);
    this.setProvince(this.actualLocation.properties.province);
    this.setNumberMobile(this.actualLocation.properties.mobile);
    this.setFarm(this.actualLocation.properties.locality);
    this.setOwnerFarm(this.actualLocation.properties.owner_Farm);
    this.setPostalCode(this.actualLocation.properties.postal_code);
  },
  methods: {
    async registerFarmAndCreatePlot() {
      this.$f7.preloader.show();
      try {
        if (this.name === '') {
          this.$f7.dialog.alert(this.$t('DxRegisterLocation_nombre_validation'));
        } else {
          this.setallProperties();
          await this.updateExplotation({ companyId: this.actualUser.id, explotationId: this.actualLocation.bdId });
          this.$f7.dialog.alert(this.$t('Edit_Farm_successfuly'));
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
        this.$f7.views.main.router.navigate('/ViewLocation/', { reloadCurrent: true });
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/viewLocation/', { reloadCurrent: true });
    },
    setallProperties() {
      const newProperties = [
        { key: 'number_register', value: this.registerNumber },
        { key: 'owner_Farm', value: this.ownerFarm },
        { key: 'mobile', value: this.mobile },
        { key: 'province', value: this.province },
        { key: 'locality', value: this.farm },
        { key: 'postal_code', value: this.postalCode },
      ];
      this.setProperties(newProperties);
    },
    ...mapActions('Explotaciones', ['setShowPopUp']),
    ...mapActions('RegisterLocation', ['setProperties', 'initStore', 'createExplotation', 'setName', 'setRegisterNumber', 'setProvince', 'setNumberMobile',
      'setFarm', 'setOwnerFarm', 'updateExplotation', 'setPostalCode']),
  },
};
</script>

<style scoped>
.dx-btn-cancel.margin-right {
width: 15%;
margin-left: 2%;
}
.dx-btn-success {
width: 30%;
margin-left: 68%;

}
</style>
