<template>
  <section class="product-resume">
    <div class="content-devx position-relative">
      <div class="card-header-title title-top">
        {{ $t('productResume.productResume') }}
      </div>
      <div class="card-body margin-left-block margin-right-block margin-bottom-block">
        <section class="area margin-bottom-block">
          <div class="dx-field justify-content-end">
            <div class="margin-right-block">
              {{ $t('productResume.area') }}
            </div>
            <div>
              <DxNumberBox
                :read-only="true"
                :value="currentPlantation.area"
                format="#0.00"
              />
            </div>
          </div>
        </section>
        <section class="bottom">
          <DxDataGrid
            class="data-grid-product-resume"
            :data-source="stepData.productResume"
            :word-wrap-enabled="true"
            :show-borders="true"
          >
            <DxEditing
              :allow-updating="true"
              mode="cell"
            />
            <DxColumn
              data-field="product"
              :caption="$t('productResume.product')"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('productResume.dosageUnit')"
              data-field="dosageUnit"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('productResume.dosagePlant')"
              data-field="dosagePlant"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('productResume.dosageTotal')"
              data-field="dosageTotal"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('productResume.price')"
              data-field="price"
              alignment="center"
              data-type="number"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('productResume.costUnit')"
              data-field="costUnit"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('nutritionDetail.productResume.costPlant')"
              data-field="costPlant"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('productResume.costTotal')"
              data-field="costTotal"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxSummary css-class="summary-table">
              <DxTotalItem
                column="costUnit"
                summary-type="sum"
                :customize-text="totalItem"
              />
              <DxTotalItem
                column="costPlant"
                summary-type="sum"
                :customize-text="totalItem"
              />
              <DxTotalItem
                column="costTotal"
                summary-type="sum"
                :customize-text="totalItem"
              />
            </DxSummary>
          </DxDataGrid>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import {
  DxColumn, DxDataGrid, DxEditing,
  DxPaging, DxScrolling, DxSorting,
  DxSummary,
  DxTotalItem,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState } from 'vuex';
import plantationService from '@/services/plantationService';

export default {
  name: 'ProductResume',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSelectBox,
    DxSorting,
    DxScrolling,
    DxPaging,
    DxEditing,
    DxNumberBox,
    DxSummary,
    DxTotalItem,
  },
  props: [],
  data() {
    return {
      plantation: {},
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['stepData', 'enableWatchers']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
  },
  watch: {
    'stepData.productResume': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalCost(val);
      },
      deep: true,
    },
  },
  beforeMount() {
    this.stepData.area = this.currentPlantation.area;
    plantationService.getById(this.currentPlantation.id, this.currentPlantation.companyId).then((response) => {
      this.stepData.productResume = this.stepData.dosageProduct.map((product, index) => {
        const dosageUnit = this.stepData.dosages[index].dosage;

        let dosagePlant = this.roundValue((dosageUnit / this.actualPlantation.plants_density) * 1000);
        let costPlant = this.roundValue(product.costTotal / response.plants);

        if (isNaN(dosagePlant) || !isFinite(dosagePlant)) {
          dosagePlant = 0;
        }

        if (isNaN(costPlant) || !isFinite(costPlant)) {
          costPlant = 0;
        }

        return {
          product: product.name,
          dosageUnit,
          dosagePlant,
          dosageTotal: this.roundValue(dosageUnit * this.currentPlantation.area),
          price: product.price,
          costUnit: 0,
          costPlant,
          costTotal: 0,
        };
      });
    });
  },
  methods: {
    calcTotalCost(element) {
      if (!this.enableWatchers) {
        return;
      }
      element.forEach((item) => {
        let calcCostUnit = this.roundValue(item.dosageUnit * item.price);
        let calcCostPlant = this.roundValue(item.costTotal / this.actualPlantation.plants);
        let calcCostTotal = this.roundValue(item.costUnit * this.currentPlantation.area);

        if (isNaN(calcCostUnit) || !isFinite(calcCostUnit)) {
          calcCostUnit = 0;
        }

        if (isNaN(calcCostPlant) || !isFinite(calcCostPlant)) {
          calcCostPlant = 0;
        }

        if (isNaN(calcCostTotal) || !isFinite(calcCostTotal)) {
          calcCostTotal = 0;
        }

        item.costUnit = calcCostUnit;
        item.costPlant = calcCostPlant;
        item.costTotal = calcCostTotal;
      });
    },
    roundValue(val) {
      return parseFloat(val.toFixed(3));
    },
    totalItem(data) {
      const value = parseFloat(data.value.toFixed(3));
      return `Total: ${value}`;
    }
  },
};

</script>
<style src="./productResume.scss" scoped lang="scss"></style>
