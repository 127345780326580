<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${$t('DxViewClient_detail_Ventas')} > ${$t('DxViewClient_detail_Client')}`" />
    <DxViewClient />
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-button
            class="dx-btn-cancel padding-horizontal"
            raised
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="goToBack()"
          >
            {{ $t("Button_go_to_back") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import DxViewClient from '../../../components/utilities/clients/DxViewClient.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'ViewClient',
  components: {
    DxViewClient,
    navbar,
  },
  props: {
    pathBack: { type: String, default: 'clients' },
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate(`/${this.pathBack}/`, {
        reloadCurrent: true,
      });
    },
    ...mapActions('authentication', ['setActualMenuSelected']),
  },
};
</script>
<style scoped>
.dx-btn-cancel {
  width: fit-content;
  background-color: white;
}
.dx-btn-success {
  width: auto;
}
</style>
