import moment from 'moment';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    plantationName: '',
    initDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    cropTypeSelected: '',
    managementTypeSelected: '',
    protectionTypeSelected: '',
    cropTypes: [],
    managementTypes: [],
    protectionTypes: [],
    speciesAvailables: [],
    specieSelected: '',
    varieties: [],
    varietySelected: '',
    mulUno: 0,
    mulDos: 0,
    plantDensity: 0,
    plantsNumber: 0,
    cupDiameter: 0,
    plantationDate: moment().format('YYYY-MM-DD'),
    seedDensity: 0,
    showPopUp: false,
    parcels: [],
    parcelSelected: '',
    sectors: [],
    sectorSelected: '',
    plantingUnit: [],
    plantingUnitSelected: '',
    actualPlantation: {},
    sectorArea: 0,
    treeLocations: [],
    isEdit: false,
    isPlantationCreated: false,
  },
  getters: {
    actualPlantation: (state) => state.actualPlantation,
  },
  mutations: {
    SET_PLANTATION_NAME(state, payload) {
      state.plantationName = payload;
    },
    SET_PROTECTION_TYPE(state, payload) {
      state.protectionTypeSelected = payload;
    },
    SET_MANAGEMENT_TYPE(state, payload) {
      state.managementTypeSelected = payload;
    },
    SET_INIT_DATE(state, payload) {
      state.initDate = payload;
    },
    SET_END_DATE(state, payload) {
      state.endDate = payload;
    },
    SET_CULTIVE_TYPE(state, payload) {
      state.cropTypeSelected = payload;
    },
    SET_FILL_SELECTBOXS(state, dataSelectBoxs) {
      state.cropTypes = dataSelectBoxs.cropType;
      state.managementTypes = dataSelectBoxs.managementTypes;
      state.protectionTypes = dataSelectBoxs.protectionTypes;
      state.plantingUnit = dataSelectBoxs.plantingUnit;
    },
    SET_SPECIES_AVAILABLES(state, payload) {
      state.speciesAvailables = payload;
    },
    SET_SPECIE_SELECTED(state, payload) {
      state.specieSelected = payload;
    },
    SET_VARIETY_SELECTED(state, payload) {
      state.varietySelected = payload;
    },
    SET_VARIETIES(state, payload) {
      state.varieties = payload;
    },
    SET_MUL_UNO(state, payload) {
      state.mulUno = payload;
    },
    SET_MUL_DOS(state, payload) {
      state.mulDos = payload;
    },
    SET_PLANT_DENSITY(state, payload) {
      state.plantDensity = payload;
    },
    SET_PLANTS_NUMBER(state, payload) {
      state.plantsNumber = payload;
    },
    SET_CUP_DIAMETER(state, payload) {
      state.cupDiameter = payload;
    },
    SET_PLANTATION_DATE(state, payload) {
      state.plantationDate = payload;
    },
    SET_SEED_DENSITY(state, payload) {
      state.seedDensity = payload;
    },
    SET_SHOW_POPUP(state, payload) {
      state.showPopUp = payload;
    },
    SET_PARCELS(state, payload) {
      state.parcels = payload;
    },
    SET_PARCEL_SELECTED(state, payload) {
      state.parcelSelected = payload;
    },
    SET_SECTORS(state, payload) {
      state.sectors = payload;
    },
    SET_SECTOR_SELECTED(state, payload) {
      state.sectorSelected = payload;
    },
    SET_PLANTING_UNIT(state, payload) {
      state.plantingUnitSelected = payload;
    },
    SET_ACTUAL_PLANTATION(state, payload) {
      state.actualPlantation = payload;
    },
    SET_SECTOR_AREA(state, payload) {
      state.sectorArea = payload;
    },
    setTreeLocations(state, payload) {
      state.treeLocations = payload;
    },
    setIsEdit(state, payload) {
      state.isEdit = payload;
    },
    setIsPlantationCreated(state, payload) {
      state.isPlantationCreated = payload;
    },
    RESET_SPECIE_SELECTED_ADN_VARIETY_SELECTED(state) {
      state.specieSelected = '';
      state.varietySelected = '';
      state.mulUno = 0;
      state.mulDos = 0;
      state.seedDensity = 0;
      state.plantsNumber = 0;
    },
    RESET_SPECIESAVAILABLES_VARIETY(state, payload) {
      state.speciesAvailables = payload;
      state.variety = payload;
    },
    setCurrentPlantationId(state, value) {
      state.actualPlantation.id = value;
    },
  },
  actions: {
    async getSelectBoxOptions({ commit }) {
      try {
        const xhr = await Api.getKeyValues();
        const keyValues = JSON.parse(xhr.response);
        const dataSelectBoxs = {};
        for (const keyValue of keyValues) {
          if (keyValue.family === 'MANAGEMENT_TYPE') dataSelectBoxs.managementTypes = keyValue.values;
          if (keyValue.family === 'PROTECTION_TYPE') dataSelectBoxs.protectionTypes = keyValue.values;
          if (keyValue.family === 'PLANTING_UNIT') dataSelectBoxs.plantingUnit = keyValue.values;
          if (keyValue.family === 'CROP_TYPE') dataSelectBoxs.cropType = keyValue.values;
        }
        commit('SET_FILL_SELECTBOXS', dataSelectBoxs);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getSelectBoxSpecies({ commit }, crop) {
      try {
        const xhr = await Api.getSpeciesAvailables(crop);
        const SpeciesAvailables = JSON.parse(xhr.response).data;
        commit('SET_SPECIES_AVAILABLES', SpeciesAvailables);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getSelectBoxVariety({ commit }, specie) {
      try {
        const xhr = await Api.getVarietyBySpecie(specie);
        const varieties = JSON.parse(xhr.response).data;
        const varietiesFormated = [];
        for (const variety of varieties) {
          varietiesFormated.push(Object.freeze({ name: variety.name }));
        }
        commit('SET_VARIETIES', varietiesFormated);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createPlantation({ state, commit }, data) {
      try {
        let newPlantation = {};
        const commonData = {
          name: state.plantationName,
          management: state.managementTypeSelected,
          protection: state.protectionTypeSelected,
          crop_type: state.cropTypeSelected,
          init_date: state.initDate,
          end_date: state.endDate,
          species: state.specieSelected,
          variety: state.varietySelected,
          plants_density: state.plantDensity,
          plants: state.plantsNumber,
          farms: [state.sectorSelected],
          campaign: data.campaignId,
        };
        if (state.cropTypeSelected === 'HERBÁCEO') {
          newPlantation = {
            planting_density: state.seedDensity,
            planting_unit: state.plantingUnitSelected,
          };
        }
        if (state.cropTypeSelected === 'HORTÍCOLA') {
          newPlantation = {
            plantation_frame: {
              x: state.mulUno,
              y: state.mulDos,
            },
          };
        }
        if (state.cropTypeSelected === 'LEÑOSO') {
          newPlantation = {
            plantation_frame: {
              x: state.mulUno,
              y: state.mulDos,
            },
            plantation_date: state.plantationDate,
            cup_diameter: state.cupDiameter,
          };
        }
        if (state.isEdit) {
          await Api.updatePlantation(data.clientId, { ...commonData, ...newPlantation }, data.plantationId);
        } else {
          const xhr = await Api.createPlantation(data.clientId, { ...commonData, ...newPlantation });
          commit('SET_ACTUAL_PLANTATION', {});
          commit('setCurrentPlantationId', JSON.parse(xhr.response).id);
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getParcelByExplotation({ commit }, data) {
      try {
        const xhr = await Api.getParcelByExplotation(data.companyId, data.explotation);
        const parcels = JSON.parse(xhr.response).parcel;
        commit('SET_PARCELS', parcels);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getSectorByParcel({ commit }, data) {
      try {
        const xhr = await Api.getSectorByParcel(data.companyId, data.parcel);
        const sectors = JSON.parse(xhr.response).children;
        commit('SET_SECTORS', sectors);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getPlantationById({ commit }, data) {
      try {
        const xhr = await Api.getPlantationById(data.companyId, data.plantationId);
        const dataPlantation = JSON.parse(xhr.response);
        commit('SET_ACTUAL_PLANTATION', dataPlantation);
        return Promise.resolve(dataPlantation);
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchLocationsByPlantation({ commit }, data) {
      try {
        const xhr = await Api.getTreeLocation(data.companyId, data.plantationId);
        commit('setTreeLocations', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setPlantationName(context, value) {
      context.commit('SET_PLANTATION_NAME', value);
    },
    setProtectionType(context, value) {
      context.commit('SET_PROTECTION_TYPE', value);
    },
    setManagementType(context, value) {
      context.commit('SET_MANAGEMENT_TYPE', value);
    },
    setInitDate(context, value) {
      context.commit('SET_INIT_DATE', value);
    },
    setEndDate(context, value) {
      context.commit('SET_END_DATE', value);
    },
    setCultiveType(context, value) {
      context.commit('SET_CULTIVE_TYPE', value);
    },
    setSpecieBotanic(context, value) {
      context.commit('SET_SPECIE_SELECTED', value);
    },
    setVariety(context, value) {
      context.commit('SET_VARIETY_SELECTED', value);
    },
    setMulUno(context, value) {
      context.commit('SET_MUL_UNO', value);
    },
    setMulDos(context, value) {
      context.commit('SET_MUL_DOS', value);
    },
    setPlantsDensity(context, value) {
      context.commit('SET_PLANT_DENSITY', value);
    },
    setPlantsNumber(context, value) {
      context.commit('SET_PLANTS_NUMBER', value);
    },
    setCupDiameter(context, value) {
      context.commit('SET_CUP_DIAMETER', value);
    },
    setPlantationDate(context, value) {
      context.commit('SET_PLANTATION_DATE', value);
    },
    setSeedDensity(context, value) {
      context.commit('SET_SEED_DENSITY', value);
    },
    setShowPopUp(context, value) {
      context.commit('SET_SHOW_POPUP', value);
    },
    setParcelSelected(context, value) {
      context.commit('SET_PARCEL_SELECTED', value);
    },
    setSectorSelected(context, value) {
      context.commit('SET_SECTOR_SELECTED', value);
    },
    setPlantingUnit(context, value) {
      context.commit('SET_PLANTING_UNIT', value);
    },
    setActualPlantation(context, value) {
      context.commit('SET_ACTUAL_PLANTATION', value);
    },
    setSectorArea(context, value) {
      context.commit('SET_SECTOR_AREA', value);
    },
    setIsEdit(context, value) {
      context.commit('setIsEdit', value);
    },
    setIsPlantationCreated({ commit }, value) {
      commit('setIsPlantationCreated', value);
    },
    resetSpecieSelectedAndVarietySelected(context) {
      context.commit('RESET_SPECIE_SELECTED_ADN_VARIETY_SELECTED');
    },
    resetSelectBoxStepUno(context) {
      context.commit('SET_PARCELS', []);
      context.commit('SET_SECTORS', []);
    },
    resetSpecieAvailablesAndVariety(context) {
      context.commit('RESET_SPECIESAVAILABLES_VARIETY', []);
    },
    initStore({ commit }) {
      commit('SET_PLANTATION_NAME', '');
      commit('SET_PROTECTION_TYPE', '');
      commit('SET_MANAGEMENT_TYPE', '');
      commit('SET_INIT_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_END_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_CULTIVE_TYPE', '');
      commit('SET_SPECIE_SELECTED', '');
      commit('SET_VARIETY_SELECTED', '');
      commit('SET_MUL_UNO', 0);
      commit('SET_MUL_DOS', 0);
      commit('SET_PLANT_DENSITY', 0);
      commit('SET_PLANTS_NUMBER', 0);
      commit('SET_PLANTATION_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_SHOW_POPUP', false);
      commit('SET_SEED_DENSITY', 0);
      commit('SET_PLANTING_UNIT', '');
    },
  },
};
