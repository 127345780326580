var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-devx"},[_c('f7-block',[_c('DxForm',[_c('DxGroupItem',{attrs:{"col-count":3}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxSimpleItem_PopUpRegister_campania'),"caption":_vm.$t('DxSimpleItem_PopUpRegister_campania'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.listExplotation,
              value: _vm.actual_Plot,
              displayExpr: 'name',
              valueExpr: 'id',
              onValueChanged: _vm.onChangePlot,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('registerPlantation.required_farm')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.popUpRegisterPlantation_parcels'),"caption":_vm.$t('registerPlantation.popUpRegisterPlantation_parcel'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.parcels,
              value: _vm.parcelSelected,
              displayExpr: 'name',
              valueExpr: 'id',
              onValueChanged: _vm.onChangeParcel,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('registerPlantation.required_parcel')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.popUpRegisterPlantation_sectors'),"caption":_vm.$t('registerPlantation.popUpRegisterPlantation_sector'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.sectors,
              value: _vm.sectorSelected,
              displayExpr: 'name',
              valueExpr: 'id',
              onValueChanged: _vm.onChangeSector,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('registerPlantation.required_sector')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('plantations.grid.campaign'),"caption":_vm.$t('plantations.grid.campaign'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.allcampaign,
              value: _vm.actualCampaign.id,
              displayExpr: 'name',
              valueExpr: 'id',
              onValueChanged: _vm.onChangeCampaign,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('registerPlantation.required_campaign')}})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }