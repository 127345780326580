<template>
  <div class="content-devx">
    <f7-block class="content-linesgraph">
      <DxChart
        id="chart"
        :data-source="dataSourceFiltered"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings
          argument-field="date"
        />
        <DxSeries
          v-if="weatherElement !== `${$t('SolarRadiation')}`
            && weatherElement !== `${$t('Humidity')}`
            && weatherElement !== $t('WindSpeed') && hasMeasuredTemperature"
          pane="top"
          color="#0571b0"
          :value-field="`minmeasured${weatherElement}`"
          :name="`Min ${weatherElement}, ${measurement}`"
        />
        <DxSeries
          v-if="hasMeasuredTemperature"
          pane="top"
          color="#7b3294"
          :value-field="`avgmeasured${weatherElement}`"
          :name="`${$t('DxWeatherElementLinesGraph_average')} ${weatherElement}, ${measurement}`"
        />
        <DxSeries
          v-if="weatherElement !== `${$t('SolarRadiation')}`
            && weatherElement !== `${$t('Humidity')}` && hasMeasuredTemperature"
          pane="top"
          color="#ca0020"
          :value-field="`maxmeasured${weatherElement}`"
          :name="`Max ${weatherElement}, ${measurement}`"
        />
        <DxSeries
          v-if="hasForecastTemperature && weatherElement !== `${$t('Humidity')}`
            && weatherElement !== $t('WindSpeed')
            && weatherElement !== `${$t('SolarRadiation')}`"
          pane="top"
          :value-field="`minforecasted${weatherElement}`"
          type="line"
          color="#0571b099"
          :name="`${$t('DxWeatherElementLinesGraph_minForecasted')} ${weatherElement}, ${measurement}`"
        />
        <DxSeries
          v-if="hasForecastTemperature"
          pane="top"
          :value-field="`avgforecasted${weatherElement}`"
          type="line"
          color="#7b329499"
          :name="`${$t('DxWeatherElementLinesGraph_averageForecasted')} ${weatherElement}, ${measurement}`"
        />
        <DxSeries
          v-if="hasForecastTemperature && weatherElement !== `${$t('Humidity')}`
            && weatherElement !== $t('WindSpeed')
            && weatherElement !== `${$t('SolarRadiation')}`"
          pane="top"
          :value-field="`maxforecasted${weatherElement}`"
          type="line"
          color="#ca002099"
          :name="`${$t('DxWeatherElementLinesGraph_maxForecasted')} ${weatherElement}, ${measurement}`"
        />

        <DxPane name="top" />
        <DxArgumentAxis
          discrete-axis-division-mode="crossLabels"
          argument-type="datetime"
          :value-margins-enabled="false"
        >
          <DxLabel
            display-mode="stagger"
            format="d MMM yyyy - hh:mm"
          />
        </DxArgumentAxis>
        <DxAdaptiveLayout
          :width="80"
          :keep-labels="true"
        />
        <DxValueAxis
          pane="top"
          :axis-division-factor="20"
        >
          <DxGrid :visible="true" />
          <DxTitle :text="`${weatherElement}, ${measurement}`" />
        </DxValueAxis>
        <DxLegend
          :margin="30"
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="top"
        />
        <DxExport :enabled="false" />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxTitle :text="`${weatherElement}`" />
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
      </DxChart>
    </f7-block>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxPane,
  DxScrollBar,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxZoomAndPan,
  DxExport,
  DxGrid,
  DxAdaptiveLayout,
} from 'devextreme-vue/chart';
import i18next from 'i18next';

export default {
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxPane,
    DxGrid,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxLabel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxExport,
    DxAdaptiveLayout,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    weatherElement: {
      type: String,
      default: i18next.t('Temperature'),
    },
  },
  data() {
    return {
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      measurement: '',
    };
  },
  beforeMount() {
    if (typeof this.dataSource !== 'undefined' && this.dataSource.length > 0) {
      if (typeof this.dataSource[1].unitOfMessurement !== 'undefined') {
        switch (this.dataSource[1].unitOfMessurement) {
          case 'meter_second':
            this.measurement = 'm/s';
            break;
          case 'liter_m2':
            this.measurement = 'L/m²';
            break;
          case 'percentage':
            this.measurement = '%';
            break;
          case 'celsius':
            this.measurement = '°C';
            break;
          case 'wat_m2':
            this.measurement = 'W/m²';
            break;
          default:
            break;
        }
      }
    }
  },
  computed: {
    ...mapState('weatherFarm', [
      'selectedFarmID',
      'startDate',
      'endDate',
      'selectedMeasuringFrequency',
      'hasMeasuredTemperature',
      'hasForecastTemperature',
    ]),

    ...mapState('Reporting', ['selectedFarmList']),
    lineWidth() {
      return this.selectedMeasuringFrequency === 'DAILY' ? 1 : 0;
    },
    weatherElementMeasure() {
      return this.weatherElement === 'Temperature' ? 'ºC' : '%';
    },

    ...mapState('CalendarInitEndDate', ['actualFilter']),
    dataSourceFiltered() {
      return typeof this.actualFilter['searcher/weatherGraph'].init === 'undefined' || this.actualFilter['searcher/weatherGraph'].init === '' ? this.dataSource : this.dataSource.filter(
        (dataRow) => moment(dataRow.date).format('YYYY-MM-DD') <= moment(this.actualFilter['searcher/weatherGraph'].end).format('YYYY-MM-DD')
        && moment(dataRow.date).format('YYYY-MM-DD') >= moment(this.actualFilter['searcher/weatherGraph'].init).format('YYYY-MM-DD'),
      );
    },
  },
  methods: {
    ...mapActions('weatherFarm', [
      'fetchMeasuredWeatherData',
      'fetchWeatherForecastData',
      'setSelectedFarmID',
    ]),

    customizeTooltip(pointInfo) {
      const seriesName = pointInfo.seriesName.split(',')[0];
      const seriesMeasureUnit = pointInfo.seriesName.split(',')[1].trim();
      let date = '';
      if (this.selectedMeasuringFrequency === 'DAILY') {
        date = moment(pointInfo.argument).locale('es').format('ddd D MMM YYYY');
      } else {
        date = moment(pointInfo.argument).locale('es').format('ddd D MMM YYYY HH:mm');
      }
      if (this.selectedMeasuringFrequency === 'DAILY'
          && pointInfo.lowErrorValue
          && pointInfo.highErrorValue
      ) {
        return {
          text: `${seriesName} on ${date}: 
          ${pointInfo.value.toFixed(2)} ${this.measurement} 
          (range: ${pointInfo.lowErrorValue.toFixed(2)}${seriesMeasureUnit} - ${pointInfo.highErrorValue.toFixed(2)}${seriesMeasureUnit})`,
        };
      }
      return {
        text: `${seriesName} on ${date}: 
          ${pointInfo.value.toFixed(2)} ${this.measurement}`,
      };
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
#chart-demo {
    height: 700px;
}
#chart {
    width: 100%;
    min-width: 600px;
    height: 400px;
    margin: 0 0 15px;
}
.content-linesgraph{
  z-index: 0;
}
</style>
