var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DxPopup',{attrs:{"id":"attach-file-popup","title":_vm.$t('Searcher.observations.attach_file_title'),"visible":_vm.popupShow,"show-close-button":true,"on-hidden":_vm.onHidden,"close-on-outside-click":false,"height":"auto","drag-enabled":false,"position":{my: 'bottom', at: 'center'},"width":460},on:{"update:visible":function($event){_vm.popupShow=$event}}},[_c('DxForm',{attrs:{"show-colon-after-label":false},scopedSlots:_vm._u([{key:"attachFile",fn:function(){return [_c('div',{staticClass:"fileuploader-container width-350"},[_c('attachAFile',{attrs:{"pop-up-is-visible":_vm.popupShow},on:{"setExtension":_vm.setExtensionData}})],1)]},proxy:true}])},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Searcher.observations.description'),"editor-type":"dxTextArea","editor-options":{
            value: _vm.fileDescription,
            onValueChanged: _vm.onChangeDescription,
            placeholder: _vm.$t('Searcher.observations.description'),
            maxLength: 256,
            height: 65,
            width: 350
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Searcher.observations.attach_file'),"template":"attachFile"}})],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col'),_vm._v(" "),_c('f7-col',{staticClass:"justify-content-end"},[_c('f7-button',{staticClass:"dx-btn-save btn-width-100 margin-right-5 margin-bottom-5",attrs:{"raised":"","styling-mode":"outlined","name":"ButtonSave"},on:{"click":_vm.savePopup}},[_vm._v("\n          "+_vm._s(_vm.$t('Button_save_text'))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{attrs:{"width":"55"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"45"}},[_c('f7-button',{staticClass:"dx-btn-save",staticStyle:{"width":"50px"},attrs:{"raised":"","type":"default","styling-mode":"outlined"},on:{"click":_vm.openPopup}},[_c('f7-icon',{staticStyle:{"color":"white"},attrs:{"md":"f7:arrow_up_doc"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }